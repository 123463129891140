import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import RecurringListPage from "./components/RecurringListPage";
import PageTitle from "../../components/PageTitle";
import CountBadge from "../../components/CountBadge";

// Helpers
import ObjectName from "../../helpers/ObjectName";

// Actions
import { fetchList } from "../../actions/table";
import AddButton from "../../components/AddButton";
import RecurringActivityList from "./components/RecurringActivityListPage";

const Tab = {
  RECURRING_TICKET: "Recurring Ticket",
  RECURRING_BILL: "Recurring Bill",
  RECURRING_PAYMENT: "Recurring Payment",
  RECURRING_ACTIVITY: "Recurring Activity",
};

const RecurringTask = (props) => {
  let { taskCount, billCount, paymentCount } = props;

  const [activeTab, setActiveTab] = useState(Tab.RECURRING_TICKET);
  const [row, setRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.replace({
      pathname: props.history.location.pathname,
      state: {},
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between pb-3">
        <PageTitle label="Recurring" />
        {(activeTab === Tab.RECURRING_TICKET || activeTab === Tab.RECURRING_BILL || activeTab === Tab.RECURRING_PAYMENT) && (
          <div className="d-flex">
            <AddButton
              label="Add New"
              onClick={() => {
                setRow("");
                setIsOpen(!isOpen);
              }}
            />
          </div>
        )}
        {(activeTab === Tab.RECURRING_ACTIVITY) && (
          <div className="d-flex">
            <AddButton
              label="Add New"
              onClick={() => {
                setRow("");
                setIsOpen(!isOpen);
              }}
            />
          </div>
        )}
      </div>
      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.RECURRING_TICKET,
            })}
            onClick={() => {
              handleTabChange(Tab.RECURRING_TICKET);
            }}
          >
            {Tab.RECURRING_TICKET}
            <CountBadge
              count={taskCount}
              isActive={classNames({
                active: activeTab === Tab.RECURRING_TICKET,
              })}
            />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.RECURRING_BILL,
            })}
            onClick={() => {
              handleTabChange(Tab.RECURRING_BILL);
            }}
          >
            {Tab.RECURRING_BILL}
            <CountBadge
              count={billCount}
              isActive={classNames({
                active: activeTab === Tab.RECURRING_BILL,
              })}
            />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.RECURRING_PAYMENT,
            })}
            onClick={() => {
              handleTabChange(Tab.RECURRING_PAYMENT);
            }}
          >
            {Tab.RECURRING_PAYMENT}
            <CountBadge
              count={paymentCount}
              isActive={classNames({
                active: activeTab === Tab.RECURRING_PAYMENT,
              })}
            />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.RECURRING_ACTIVITY,
            })}
            onClick={() => {
              handleTabChange(Tab.RECURRING_ACTIVITY);
            }}
          >
            {Tab.RECURRING_ACTIVITY}
            <CountBadge
              isActive={classNames({
                active: activeTab === Tab.RECURRING_ACTIVITY,
              })}
            />
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab === Tab.RECURRING_TICKET && (
        <RecurringListPage
          id="recurringTask"
          setRow={setRow}
          row={row}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          objectName={ObjectName.RECURRING_TICKET}
          tab={activeTab}
          history={props.history}
          isRcurringTask={true}
        />
      )}

      {activeTab === Tab.RECURRING_BILL && (
        <RecurringListPage
          id="recurringBill"
          setRow={setRow}
          row={row}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          objectName={ObjectName.RECURRING_BILL}
          tab={activeTab}
          history={props.history}
        />
      )}

      {activeTab === Tab.RECURRING_PAYMENT && (
        <RecurringListPage
          id="recurringPayment"
          setRow={setRow}
          row={row}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          objectName={ObjectName.RECURRING_PAYMENT}
          tab={activeTab}
          history={props.history}
        />
      )}

      {activeTab === Tab.RECURRING_ACTIVITY && (
        <RecurringActivityList
          id="recurringActivity"
          setRow={setRow}
          row={row}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          objectName={ObjectName.RECURRING_ACTIVITE}
          tab={activeTab}
          history={props.history}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const reduxTable = state.table;
  const taskCount =
    reduxTable["recurringTask"] &&
    reduxTable["recurringTask"].isFetching == false
      ? reduxTable["recurringTask"].totalCount
      : 0;
  // Get Accepted Users count
  const billCount =
    reduxTable["recurringBill"] &&
    reduxTable["recurringBill"].isFetching == false
      ? reduxTable["recurringBill"].totalCount
      : 0;
  // Get Accepted Users count
  const paymentCount =
    reduxTable["recurringPayment"] &&
    reduxTable["recurringPayment"].isFetching == false
      ? reduxTable["recurringPayment"].totalCount
      : 0;
  return {
    taskCount,
    billCount,
    paymentCount,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(RecurringTask);
