import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//components
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import Text from "../../components/Text";
import CancelButton from "../../components/CancelButton";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import DateSelector from "../../components/Date";
import SelectDropdown from "../../components/SelectDropdown";
import Action from "../../components/Action";

// Endpoints
import { endpoints } from "../../api/endPoints";

// Lib
import { isBadRequest } from "../../lib/Http";
import Url from "../../lib/Url";

// API Client
import { apiClient } from "../../apiClient";

// Services
import SprintService from "../../services/SprintService";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";
import ActivityList from "../../components/ActivityList";
import ObjectName from "../../helpers/ObjectName";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

export const SprintTab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const sprintDetail = (props) => {
  const { match, history } = props;
  const sprintId = props.match.params.id;
  const dispatch = useDispatch();

  const [sprintDetails, setSprintDetails] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [nameValue, setNameValue] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(SprintTab.SUMMARY);
  const [editable, setEditable] = useState(true);

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  let showDelete = hasPermission(Permission.SPRINT_DELETE);
  let showEditButton = hasPermission(Permission.SPRINT_EDIT);

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  //status options
  const sprintStatusOptions = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "InActive",
    },
  ];

  useEffect(() => {
    getSprintDetails(match.params.id);
  }, [props]);

  //get the sprint details
  const getSprintDetails = () => {
    let id = props.match.params.id;

    try {
      return apiClient
        .get(`${endpoints().sprintAPI}/${id}`)
        .then((response) => {
          const data = response.data;
          setSprintDetails(data);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(err);
    }
  };

  // Initial values
  const initialValues = {
    name: nameValue
      ? nameValue
      : nameValue == ""
      ? nameValue
      : sprintDetails?.name,
    status:
      sprintStatusOptions &&
      sprintStatusOptions.find(
        (option) => option.label == sprintDetails?.status
      ),
    start_date: startDate ? startDate : sprintDetails?.start_date,
    end_date: endDate ? endDate : sprintDetails?.end_date,
  };

  //Handle Update userRole Details
  const handleUpdate = async (id, values) => {
    const data = new FormData();
    data.append("name", values && values.name ? values.name : "");
    data.append("start_date", values.start_date ? values.start_date : "");
    data.append("end_date", values.end_date ? values.end_date : "");

    await SprintService.update(id, data, {
      sort: Url.GetParam("sort") || "",
      sortBy: Url.GetParam("sortDir") || "",
    }, setEditable(true));
  };

  const handleSprintDelete = (id) => {
    try {
      SprintService.delete(id);
      props.history.push("/sprint");
    } catch (err) {
      console.log(err);
    }
  };

  const startDateChange = (e) => {
    setStartDate(e);
  };

  const endDateChange = (e) => {
    setEndDate(e);
  };

  const breadcrumbList = [
    { label: "Home", link: "/dashboard/ticket" },
    {
      label: "Sprints",
      link: "/sprint",
    },
    {
      label: "Sprint Details",
      link: "",
    },
  ];
  const actionsMenuList = [];

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (showDelete) {
    actionsMenuList.push({
      value: "delete",
      label: "Delete",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const onStatusChange = async (value) => {
    const data = {};
    data.status = value;
    const id = props.match.params.id;
    await SprintService.updateStatus(id, data);
  };

  const onNameChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNameValue(value);
  };

  return (
    <>
      <DeleteModal
        id={sprintId}
        label={sprintDetails?.name}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Sprint"
        deleteFunction={handleSprintDelete}
      />
      <div>
        <BreadCrumb list={breadcrumbList} />
        <div className="mb-3 d-flex justify-content-between">
          <PageTitle label={sprintDetails?.name} />
          <div className="float-end d-flex">
            <div className="mx-2">
              <SelectDropdown
                buttonLabel={
                  sprintDetails?.status ? sprintDetails?.status : "Status"
                }
                hideCaret
                dropdownLinks={sprintStatusOptions}
                handleChange={(values) => onStatusChange(values)}
              />
            </div>
            <div className="ps-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          </div>
        </div>
        <Nav tabs className="admin-tabs mt-2">
          {/* General Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === SprintTab.SUMMARY,
              })}
              onClick={() => {
                toggle(SprintTab.SUMMARY);
                _handleTabChange(SprintTab.SUMMARY);
              }}
            >
              {SprintTab.SUMMARY}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === SprintTab.HISTORY,
              })}
              onClick={() => {
                toggle(SprintTab.HISTORY);
                _handleTabChange(SprintTab.HISTORY);
              }}
            >
              {SprintTab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>

        {activeTab === SprintTab.SUMMARY && (
          <TabPane>
            <Form
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                let id = props.match.params.id;
                handleUpdate(id, values);
              }}
            >
              <div className="card bg-white">
                <div className="card-body">
                  <Text
                    name="name"
                    label="Sprint Name"
                    placeholder="Enter Sprint Name..."
                    required
                    onChange={onNameChange}
                    disabled={editable}
                  />
                  <div className="d-flex justify-content-start">
                    <DateSelector
                      label="Start Date"
                      name="start_date"
                      format="dd-MMM-yyyy"
                      required
                      onChange={startDateChange}
                      disabled={editable}
                    />
                    <div className="ms-1">
                      <DateSelector
                        label="End Date"
                        name="end_date"
                        format="dd-MMM-yyyy"
                        required
                        onChange={endDateChange}
                        disabled={editable}
                      />
                    </div>
                  </div>
                  <HorizontalSpace bottom="2">
                    {!editable && (
                      <>
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => {
                            history.push({ pathname: "/sprint" });
                          }}
                        />
                      </>
                    )}
                  </HorizontalSpace>
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab === SprintTab.HISTORY && (
          <ActivityList
            id={props?.match?.params?.id}
            objectId={props?.match?.params?.id}
            object_name={ObjectName.SPRINT}
            history={history}
          />
        )}
      </div>
    </>
  );
};

export default sprintDetail;
