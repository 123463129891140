import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import ContactList from "./components/ContactList";

const contactListPage = (props) => {
  let { history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <PageTitle
        label={"Contact"}
        buttonHandler={() => {
          openToggle();
        }}
        buttonLabel="Add"
      />

      <ContactList
        history={history}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default contactListPage;
