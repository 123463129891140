import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import AddModal from "../../../components/Modal";
import Quantity from "../../../components/Quantity";
import Button from "../../../components/Button";
import Select from "../../../components/Select";

// Services
import StoreProductService from "../../../services/StoreProductService";
import LocationRackService from "../../../services/LocationRackService";

// Lib
import Url from "../../../lib/Url";
import Drawer from "../../../components/Drawer";

const storeProductBulkUpdateModal = (props) => {
  const {
    isOpen,
    toggle,
    storeIds,
    productId,
    setSelectedCheckBox,
    setSelectedIds,
  } = props;
  const [rackList, setRackList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getLocationRackList()
  }, [])

  const getLocationRackList = async () => {
    let data = await LocationRackService.list();
    setRackList(data)
  }

  const bulkUpdateBody = (
    <>
      <Quantity label="Quantity" name="quantity" maxQuantity={500} />
      <Quantity label="Min Quantity" name="min_quantity" maxQuantity={500} />
      <Quantity label="Max Quantity" name="max_quantity" maxQuantity={500} />
      <Quantity label="Distribution Quantity" name="transferred_quantity" maxQuantity={500} />
      <Quantity label="Replenished Quantity" name="replenish_quantity" maxQuantity={500} />
      <Select name="location_rack" label="Rack#" options={rackList} />

    </>
  );

  const editModelFooter = (
    <Button type="submit" label="Update" />
  );

  const initialValues = {
    min_quantity: "",
    max_quantity: "",
    quantity: "",
    location_rack: ""
  };

  const handleBulkUpdate = (data) => {
    data.storeProductIds = storeIds?.selectedIds;
    data.min_quantity = data.min_quantity;
    data.max_quantity = data.max_quantity;
    data.quantity = data.quantity ? data.quantity : "";
    data.location_rack = data?.location_rack;
    data.replenish_quantity = data?.replenish_quantity;
    data.transferred_quantity = data?.transferred_quantity;
    data.productId = productId;
    dispatch(StoreProductService.bulkUpdate(data, toggle, Url.GetAllParams(), productId));
    setSelectedIds("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  return (
    <>
      <Drawer
        modelTitle="Bulk Update"
        DrawerBody={bulkUpdateBody}
        DrawerFooter={editModelFooter}
        onSubmit={(values) => {
          handleBulkUpdate(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
    </>
  );
};

export default storeProductBulkUpdateModal;
