import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useDispatch } from "react-redux";
import "./portalUser.scss";

// API
import { apiClient } from "../../apiClient";
import { endpoints } from "../../api/endPoints";

// Components
import CancelButton from "../../components/CancelButton";
import Email from "../../components/Email";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import Password from "../../components/Password";
import Phone from "../../components/Phone";
import SaveButton from "../../components/SaveButton";
import AddButton from "../../components/AddButton";
import BreadCrumb from "../../components/Breadcrumb";
import Country from "../../components/Country";
import DeleteModal from "../../components/DeleteModal";
import Select from "../../components/Select";
import SelectDropdown from "../../components/SelectDropdown";
import StateDropdown from "../../components/State";
import Text from "../../components/Text";
import toast from "../../components/Toast";
import Zipcode from "../../components/Zipcode";
import ImageList from "../../components/MediaCarousel";
import UploadAvatar from "../userProfile/components/uploadAvatar";
import UserEmployment from "./components/userEmployment";
import TimeZoneSelect from "../../components/TimeZoneSelect";
import ActivityList from "../../components/ActivityList";
import Action from "../../components/Action";
import AttendanceList from "../attendance/list";
import GetLocationTab from "./components/GetLocationTab";
import Settings from "./components/Setting";
import TeamTab from "./components/TeamTab";
import AddModal from "../../components/Modal";
import UserSelect from "../../components/UserSelect";
import DeviceList from "../mobileApp/deviceList";
import PreferredLocation from "./components/PreferredLocation";
import LocationsTab from "./components/LocationsTab";
import FineList from "../fine/components/FineList";
import SelectStore from "../../components/SelectStore";
import ShiftSelect from "../../components/ShiftSelect";
import SalaryList from "../salary/list";

// Actions
import { deleteCompanyUser, loginByAdmin } from "../../actions/companyUser";
import { fetchList } from "../../actions/table";

// Lib
import { validateStrongPassword } from "../../lib/Helper";
import ArrayList from "../../lib/ArrayList";
import String from "../../lib/String";
import Url from "../../lib/Url";
import Number from "../../lib/Number";
import DateTime from "../../lib/DateTime";
import { SUCCESS_RESPONSE, isBadRequest } from "../../lib/Http";

// Services
import CompanyUserService from "../../services/CompanyUserService";
import UserService from "../../services/UserService";
import AddressService from "../../services/AddressService";
import DeviceInfoService from "../../services/DeviceInfoService";
import TeamMemberService from "../../services/TeamMemberService";
import SlackService from "../../services/SlackService";
import { hasPermission } from "../../services/UserRolePermissionService";
import {
  getUserRole,
  getUserRoleList,
} from "../../services/UserSettingService";

// Helpers
import Permission from "../../helpers/Permission";
import ObjectName from "../../helpers/ObjectName";
import { User } from "../../helpers/User";
import fineService from "../../services/FineService";
import { Link } from "react-router-dom";
import URL from "../../components/Url";
import Drawer from "../../components/Drawer";
import { Status } from "../../helpers/Product";

// Tabs Constants
export const Tab = {
  GENERAL: "General",
  SLACK: "Slack",
};

const userDetail = (props) => {
  const [userDetail, setUserDetail] = useState({});
  const [userRole, setUserRole] = useState([]);
  const [userRolePrefill, setUserRolePrefill] = useState([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [strongPasswordError, setStrongPasswordError] = useState("");
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || User.GENERAL_TAB
  );
  const [UserImageUrl, setUserImageUrl] = useState();

  const [userImageFile, setUserImageFile] = useState();
  const [isNewPassword, setIsNewPassword] = useState();
  const [isConfirmPassword, setIsConfirmPassword] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [status, setImageStatus] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState();

  //Country
  const [selectedOption, setSelectedOption] = useState();
  const [selectedCountryName, setSelectedCountryName] = useState();
  const [selectedCountryId, setSeletedCountryId] = useState();
  const [slackUserList, setSlackUserList] = useState([]);
  const [showEditStatusPermision, setShowEditStatusPermision] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [timeZones, setTimeZone] = useState([]);
  const [deviceDeleteModal, setDeviceDeleteModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isPlOpen, setIsPlOpen] = useState(false);
  const [row, setRow] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [editable, setEditable] = useState(true);
  const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [address, setAddress] = useState([]);
  let showHistory = hasPermission(Permission.USER_HISTORY_VIEW);
  let showActionButton = hasPermission(Permission.USER_MANAGE_OTHERS);
  let showLoginAs = hasPermission(Permission.ALLOW_IMPERSONATE_LOGIN);

  let showEditButton = hasPermission(Permission.USER_EDIT);
  let userDeletePermission = hasPermission(Permission.USER_DELETE);

  const { match, history } = props;

  useEffect(() => {
    getSlackUserList();
    getPermisionList();
    getTimeZones();
  }, []);

  useEffect(() => {
    getSlackUserList();
  }, [activeTab, props]);

  const getTimeZones = () => {
    let timeZones = DateTime.getTimeZones();

    let timeZoneList = new Array();

    for (let i = 0; i < timeZones.length; i++) {
      timeZoneList.push({
        label: timeZones[i],
        value: timeZones[i],
      });
    }

    setTimeZone(timeZoneList);
  };

  const onBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const imagetoggle = (id) => {
    if (id) {
      setModalOpen(!modalOpen);
    } else {
      setModalOpen(!modalOpen);
      setCurrentData("");
      setImageStatus("");
      setSelectedFile("");
      setErrorMessage("");
      setSelectedFile("");
      setFile("");
    }
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    setIsNewPassword("");
    setStrongPasswordError("");
    setConfirmPasswordError("");
    setIsConfirmPassword("");
  };

  const handleStatusChange = (selectStatus) => {
    const data = new FormData();
    // status
    data.append("status", String.Get(selectStatus));

    try {
      apiClient
        .put(`${endpoints().userAPI}/status/${match.params.id}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
            getUserDetail();
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const statusOptions = [
    {
      label: User.STATUS_ACTIVE_TEXT,
      value: User.STATUS_ACTIVE_VALUE,
    },

    {
      label: User.STATUS_INACTIVE_TEXT,
      value: User.STATUS_INACTIVE_VALUE,
    },
  ];

  // Actions menu list
  const actionOptions = [];

  if (userDetail?.status == User?.STATUS_ACTIVE_VALUE) {
    actionOptions.push({
      label: User.STATUS_INACTIVE_TEXT,
      value: User.STATUS_INACTIVE_VALUE,
    });
  }

  if (userDetail?.status == User?.STATUS_INACTIVE_VALUE) {
    actionOptions.push({
      label: User.STATUS_ACTIVE_TEXT,
      value: User.STATUS_ACTIVE_VALUE,
    });
  }

  const getSlackUserList = async () => {
    let { userList } = await SlackService.getUserList();

    let slackUserList = [];
    if (userList && userList.length > 0) {
      for (let i in userList) {
        let { id, name, profile } = userList[i];
        slackUserList.push({
          label: profile?.real_name,
          value: id,
          name: name,
        });
      }
    }
    setSlackUserList(slackUserList);
  };

  // Get Country Details
  const getCountryDetails = async (countryName) => {
    const response = await apiClient.get(
      `${endpoints().countryAPI}/${countryName}`
    );
    const data = response.data;
    setSeletedCountryId(data.id);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const dispatch = useDispatch();

  const getUserDetail = async () => {
    const userDetail = await CompanyUserService.get(match.params.id);
    setUserDetail(userDetail);
  };

  useEffect(() => {
    getUserDetail();
    if (activeTab == User.CONTACT_DETAILS) {
      getCountryDetails();
    }
    getUserRoleValue();
    getUserRolePrefill();
    props.history.push(`?tab=${activeTab}`);
  }, []);

  const getUserRoleValue = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const getUserRolePrefill = async () => {
    const roleData = await getUserRoleList();
    setUserRolePrefill(roleData);
  };

  const updateUser = async (value) => {
    try {
      const response = await apiClient.put(
        `${endpoints().userAPI}/${match.params.id}`,
        value
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        getUserDetail();
        setEditable(true);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  // Validate Fields
  const _validateFields = async (values) => {
    let success = true;
    const email = values.email;
    const passwordSuccess = await validatePassword(
      values.newPassword,
      values.confirmPassword
    );
    if (!email) {
      success = true;
    }

    if (!passwordSuccess) {
      success = false;
    }
    return success;
  };

  const validatePassword = (newPassword, confirmPassword) => {
    let success = true;
    const strongPassword = validateStrongPassword(newPassword);

    if (strongPassword) {
      setStrongPasswordError(strongPassword);
      success = false;
    } else if (newPassword && !confirmPassword) {
      setConfirmPasswordError("Confirm password is required");
      success = false;
    } else if (
      newPassword &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      setConfirmPasswordError("Confirm password did not match");
      success = false;
    }
    return success;
  };

  const handleAdress = async (values) => {
    const data = new FormData();
    data.append(
      "address1",
      values.formattedAddress
        ? String.Get(values.formattedAddress)
        : String.Get(values.address1)
    );
    data.append("user_id", match.params.id);
    data.append("address2", String.Get(values.address2));
    data.append(
      "city",
      values?.address
        ? String.Get(values?.address?.county)
        : String.Get(values.city)
    );
    data.append(
      "state",
      values?.address
        ? String.Get(values?.address?.state)
        : String.Get(values?.state?.value)
    );
    data.append(
      "country",
      values?.address
        ? String.Get(values?.address?.country)
        : String.Get(values?.country?.value)
    );
    data.append(
      "pin_code",
      values?.address
        ? String.Get(values?.address?.postcode)
        : String.Get(values.pin_code)
    );
    data.append(
      "latitude",
      values?.lat ? String.Get(values.lat) : String.Get(values.latitude)
    );
    data.append(
      "longitude",
      values?.lon ? String.Get(values.lon) : String.Get(values.longitude)
    );

    let params = {
      object_id: match.params.id,
      objectName: ObjectName.USER,
    };

    if (userDetail?.addressId) {
      data.append("id", userDetail?.addressId);

      dispatch(
        await AddressService.update(
          userDetail?.addressId,
          data,
          params,
          getUserDetail
        )
      );
    } else {
      dispatch(AddressService.add(data, params, getUserDetail));
    }
  };

  const handleUser = async (values) => {
    values.newPassword = isNewPassword;
    values.confirmPassword = isConfirmPassword;
    const validator = await _validateFields(values);
    const data = {};

    if (values.first_name != undefined) {
      data.first_name = String.Get(values.first_name);
    }

    if (values.last_name != undefined) {
      data.last_name = String.Get(values.last_name);
    }

    if (values.roleId != undefined) {
      data.roleId = values.roleId && values.roleId.value;
    }

    if (values.newPassword != undefined) {
      data.newPassword = String.Get(values.newPassword);
    }

    if (values.confirmPassword != undefined) {
      data.confirmPassword = String.Get(values.confirmPassword);
    }

    if (values.email != undefined) {
      data.email = String.Get(values.email);
    }

    if (values.mobileNumber1 != undefined) {
      data.mobileNumber1 = String.Get(values.mobileNumber1);
    }

    data.avatarUrl = String.Get(userImageFile);

    if (values.currentLocation !== undefined) {
      data.currentLocationId = values.currentLocation
        ? values.currentLocation.id
        : null;
    }

    if (values.currentShift !== undefined) {
      data.currentShiftId = values.currentShift ? values.currentShift.id : null;
    }

    if (values.timeZone !== undefined) {
      data.timeZone = String.Get(
        values && values.timeZone && values.timeZone.value
          ? values.timeZone.value
          : values.timeZone
      );
    }

    if (values.slack_id !== undefined) {
      data.slack_id = values.slack_id && String.Get(values.slack_id.value);
    }

    if (values.slack_name !== undefined) {
      data.slack_id = values.slack_id && String.Get(values.slack_id.value);
    }

    data.allow_leave = userDetail?.allow_leave;

    if (validator == true) {
      setConfirmPasswordError("");
      setStrongPasswordError("");
      await updateUser(data);
    }
  };

  const slackHandleSubmit = async (values) => {
    let data = new FormData();
    if (Number.isNotNull(values?.slack_id)) {
      data.append(
        "slack_id",
        String.Get(values && values.slack_id ? values.slack_id.value : "")
      );
      data.append(
        "slack_name",
        String.Get(values && values.slack_id ? values.slack_id.name : "")
      );
      data.append("first_name", String.Get(values.first_name));
      data.append("last_name", String.Get(values.last_name));
    }
    dispatch(await CompanyUserService.slackUpdate(match.params.id, data));
  };

  const handlePasswordChange = (e) => {
    const newPassword = isNewPassword;
    const confirmPassword = e && e.values && e.values.confirmPassword;
    setIsConfirmPassword(confirmPassword);

    const strongPassword = validateStrongPassword(newPassword);
    if (strongPassword) {
      setStrongPasswordError(strongPassword);
    } else if (newPassword && !confirmPassword) {
      setConfirmPasswordError("Confirm password is required");
    } else if (
      newPassword &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      setConfirmPasswordError("Confirm password did not match");
    }
    if (strongPassword === undefined) {
      setStrongPasswordError("");
    }
    if (newPassword === confirmPassword) {
      setConfirmPasswordError("");
    }
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.values.newPassword;
    const confirmPassword = e.values.confirmPassword;
    setIsNewPassword(newPassword);
    const strongPassword = validateStrongPassword(newPassword);
    if (strongPassword) {
      setStrongPasswordError(strongPassword);
    } else if (newPassword && !confirmPassword) {
      setConfirmPasswordError("Confirm password is required");
    } else if (
      newPassword &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      setConfirmPasswordError("Confirm password did not match");
    }
    if (strongPassword === undefined) {
      setStrongPasswordError("");
    }
    if (newPassword === confirmPassword) {
      setConfirmPasswordError("");
    }
  };

  {
    activeTab === Tab.GENERAL ? (
      <SelectDropdown
        className="px-2"
        visible="visible"
        clearable
        dropdownLinks={statusOptions}
        handleChange={(e) => handleStatusChange(e)}
        buttonLabel={"Status"}
        hideCaret
      />
    ) : (
      ""
    );
  }

  //Hangle Admin Action
  const handleAdminAction = async (value) => {
    switch (value) {
      case "Login As":
        await loginByAdmin(userDetail && userDetail.id, history);
        break;
      case "Delete":
        setDeleteModal(true);
        break;
      case "Force Logout":
        let bodyData = { userIds: [match.params.id], forceLogout: true };
        await dispatch(UserService.bulkUpdate(bodyData, {}));

        break;
      case "Force Sync":
        let data = { userIds: [match.params.id], force_sync: true };
        await dispatch(UserService.bulkUpdate(data, {}));
        break;
        case "Reindex":
        let updateData = { userId: match.params.id };
        await UserService.reIndex(updateData, (res)=>{
          getUserDetail()
        });
        break;
      case "Reset Mobile Data":
        let param = { userIds: [match.params.id], reset_mobile_data: true };
        await dispatch(UserService.bulkUpdate(param, {}));
        break;
      case "Update Photo From Attendance":
        let params = {
          userIds: [match.params.id],
          profile_picture_update: true,
        };
        await dispatch(
          UserService.bulkUpdate(params, {}, (res) => {
            if (res) {
              getUserDetail();
            }
          })
        );
        break;
      case "Bulk Update":
        if (
          selectedIds &&
          selectedIds.selectedIds &&
          selectedIds.selectedIds.length > 0
        ) {
          setDeviceDeleteModal(true);
        }
        break;
      case "edit":
        setEditable(false);
        break;
      default:
        break;
    }
  };

  // Portal logo s3 url
  const userImageWithBaseUrl = UserImageUrl
    ? UserImageUrl
    : userDetail?.avatarUrl;

  //Country Change
  const handleCountryChange = (values) => {
    const selectedOption = values && values.id;
    const selectedCountryName = values && values.label;
    setSelectedOption(selectedOption);
    setSelectedCountryName(selectedCountryName);
  };

  // Set image preview in state
  const setBase64Image = (file, value) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      value(reader.result);
    };
  };

  const toggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  const addUrlForm = (
    <>
      <URL
        name="url"
        label="Address Url"
        placeholder="Enter Address Url"
        required
      />
    </>
  );

  const roleFooter = (
    <>
      <SaveButton label={"Get Address"} />
    </>
  );

  const handleSubmitValue = async (values) => {
    const url = values?.url;
    const regex = /@(-?\d+\.\d{4,}),(-?\d+\.\d{4,})/;

    const match = url.match(regex);



    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      const addressUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

      const response = await fetch(addressUrl);

      const data = await response.json();

      const addressParts = data.display_name.split(', ');

      const formattedAddress = addressParts.slice(0, addressParts.length - 3).join(', ');

      data.formattedAddress = formattedAddress
      handleAdress(data);

    } else {
      console.log("Latitude and longitude not found in the URL.");
    }
  };
  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "User", link: "/users" },
    {
      label: activeTab,
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Reindex",
      label: "Reindex",
    },
    {
      value: "Force Logout",
      label: "Force Logout",
    },
    {
      value: "Force Sync",
      label: "Force Sync",
    },
    {
      value: "Reset Mobile Data",
      label: "Reset Mobile Data",
    },
    {
      value: "Update Photo From Attendance",
      label: "Update Photo From Attendance",
    },
  ];
  if (showLoginAs) {
    actionsMenuList.unshift({
      value: "Login As",
      label: "Login As",
    });
  }

  if (userDeletePermission) {
    actionsMenuList.unshift({
      value: "Delete",
      label: "Delete",
    });
  }

  if (showEditButton && editable && activeTab !== User.HISTORY) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (activeTab === User.DEVICES) {
    actionsMenuList.push({
      value: "Bulk Update",
      label: "Bulk Update",
    });
  }

  const deleteUser = (id) => {
    try {
      apiClient
        .delete(`${endpoints().userAPI}/${id}`)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            toast.success(res?.data?.message);
            props.history.push("/users");
          }
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getPermisionList = async () => {
    const showEditStatusPermision = hasPermission(Permission.USER_EDIT);
    setShowEditStatusPermision(showEditStatusPermision);
  };

  const openTeamToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeTeamToggle = () => {
    setIsOpen(!isOpen);
  };

  const openPreferredLcationModel = () => {
    setIsPlOpen(!isPlOpen);
  };

  const closePreferredLcationModel = () => {
    setRow(null);
    setIsPlOpen(!isPlOpen);
  };

  const teamBody = (
    <>
      <UserSelect name="user" label="User" required />
    </>
  );

  const teamFooter = (
    <div>
      <SaveButton label="Add" />
    </div>
  );

  const handleAddTeamMember = async ({ user: { id } }) => {
    let data = new FormData();
    data.append("team_user_id", id);
    data.append("user_id", match.params.id);
    dispatch(
      await TeamMemberService.create(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "TeamList",
              `${endpoints().TeamMemberAPI}/search`,
              1,
              25,
              {
                user_id: match.params.id,
              }
            )
          );
          closeTeamToggle();
        }
      })
    );
  };

  const deviceDelete = async () => {
    try {
      dispatch(await DeviceInfoService.delete(selectedIds));
      dispatch(
        fetchList("deviceInfo", `${endpoints().userDeviceInfo}/search`, 1, 25, {
          user: Url.GetParam("user"),
        })
      );
      setSelectedIds("");
    } catch (error) {
      console.error(error);
    }
  };

  const Toggle = () => {
    setIsModelOpen(!isModelOpen);
    setRowValue(null);
  };
  return (
    <>
      <DeleteModal
        isOpen={deviceDeleteModal}
        toggle={() => {
          setDeviceDeleteModal(false);
        }}
        title="Delete Devices"
        deleteFunction={deviceDelete}
      />
      <Drawer
        modelTitle={"Add Member"}
        DrawerBody={teamBody}
        DrawerFooter={teamFooter}
        onSubmit={(values) => {
          handleAddTeamMember(values);
        }}
        initialValues={{
          user: "",
        }}
        handleOpenModal={openTeamToggle}
        handleCloseModal={closeTeamToggle}
        handleDrawerClose={closeTeamToggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <DeleteModal
        id={userDetail?.id}
        label={String.concatName(userDetail?.first_name, userDetail?.last_name)}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete User"
        deleteFunction={() => deleteUser(userDetail?.id)}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <div>
          <PageTitle
            label={`${userDetail?.first_name ? userDetail?.first_name : ""} ${
              userDetail?.last_name ? userDetail?.last_name : ""
            }`}
          />
        </div>
        <div className="d-flex">
          {activeTab == User.FILES && (
            <AddButton
              className="me-2"
              label="Add File"
              onClick={(e) => {
                imagetoggle();
              }}
            />
          )}
          {activeTab == User.TEAM && (
            <AddButton
              className="me-2"
              label="Add Team Member"
              onClick={(e) => {
                openTeamToggle();
              }}
            />
          )}
          {activeTab == User.PREFERRED_LOCATIONS && (
            <AddButton
              className="me-2"
              label="Add Preferred Location"
              onClick={(e) => {
                openPreferredLcationModel();
              }}
            />
          )}

          {activeTab == User.FINES && (
            <AddButton
              className="me-2"
              label="Add"
              onClick={(e) => {
                Toggle();
              }}
            />
          )}

          {activeTab == User.ATTENDANCE && (
            <AddButton
              className="me-2"
              label="Add"
              onClick={(e) => {
                setIsAttendanceOpen(true);
              }}
            />
          )}

          {showEditStatusPermision && (
            <div className="mx-2">
              <SelectDropdown
                buttonLabel={
                  userDetail?.status == User?.STATUS_ACTIVE_VALUE
                    ? "Active"
                    : userDetail?.status == User?.STATUS_INACTIVE_VALUE
                    ? "InActive"
                    : "Status"
                }
                dropdownLinks={actionOptions}
                hideCaret
                selectName={"admin_action"}
                handleChange={(e) => handleStatusChange(e)}
              />
            </div>
          )}
          {showActionButton && (
            <Action
              buttonLabel="Actions"
              hideCaret
              dropdownLinks={actionsMenuList}
              handleChange={handleAdminAction}
            />
          )}
        </div>
      </div>
      <Nav tabs className="admin-tabs mb-1">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.GENERAL_TAB,
            })}
            onClick={() => {
              toggle(User.GENERAL_TAB);
              _handleTabChange(User.GENERAL_TAB);
            }}
          >
            General
          </NavLink>
        </NavItem>
        {/* Email Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.ADDRESS,
            })}
            onClick={() => {
              toggle(User.ADDRESS);
              _handleTabChange(User.ADDRESS);
            }}
          >
            Address
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.SECURITY,
            })}
            onClick={() => {
              toggle(User.SECURITY);
              _handleTabChange(User.SECURITY);
            }}
          >
            Password
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.TEAM,
            })}
            onClick={() => {
              toggle(User.TEAM);
              _handleTabChange(User.TEAM);
            }}
          >
            Team
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.EMPLOYMENT,
            })}
            onClick={() => {
              toggle(User.EMPLOYMENT);
              _handleTabChange(User.EMPLOYMENT);
            }}
          >
            Employment
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.FILES,
            })}
            onClick={() => {
              toggle(User.FILES);
              _handleTabChange(User.FILES);
            }}
          >
            Files
          </NavLink>
        </NavItem>

        {/* slack id Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.SLACK,
            })}
            onClick={() => {
              toggle(User.SLACK);
              _handleTabChange(User.SLACK);
            }}
          >
            Slack
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.SALARY,
            })}
            onClick={() => {
              toggle(User.SALARY);
              _handleTabChange(User.SALARY);
            }}
          >
            Salary
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.ATTENDANCE,
            })}
            onClick={() => {
              toggle(User.ATTENDANCE);
              _handleTabChange(User.ATTENDANCE);
            }}
          >
            Attendance
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.FINES,
            })}
            onClick={() => {
              toggle(User.FINES);
              _handleTabChange(User.FINES);
            }}
          >
            Fines
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.DEVICES,
            })}
            onClick={() => {
              toggle(User.DEVICES);
              _handleTabChange(User.DEVICES);
            }}
          >
            Devices
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.GEO_LOCATIONS,
            })}
            onClick={() => {
              toggle(User.GEO_LOCATIONS);
              _handleTabChange(User.GEO_LOCATIONS);
            }}
          >
            {User.GEO_LOCATIONS}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.LOCATIONS,
            })}
            onClick={() => {
              toggle(User.LOCATIONS);
              _handleTabChange(User.LOCATIONS);
            }}
          >
            {User.LOCATIONS}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.PREFERRED_LOCATIONS,
            })}
            onClick={() => {
              toggle(User.PREFERRED_LOCATIONS);
              _handleTabChange(User.PREFERRED_LOCATIONS);
            }}
          >
            {User.PREFERRED_LOCATIONS}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === User.SETTINGS,
            })}
            onClick={() => {
              toggle(User.SETTINGS);
              _handleTabChange(User.SETTINGS);
            }}
          >
            Settings
          </NavLink>
        </NavItem>

        <NavItem>
          {showHistory && (
            <NavLink
              className={classnames({
                active: activeTab === User.HISTORY,
              })}
              onClick={() => {
                toggle(User.HISTORY);
                _handleTabChange(User.HISTORY);
              }}
            >
              History
            </NavLink>
          )}
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab == User.GENERAL_TAB && (
          <TabPane tabId={User.GENERAL_TAB}>
            <Form
              enableReinitialize={true}
              initialValues={{
                ...userDetail,
                roleId: userDetail
                  ? userRolePrefill.find(
                      (data) => userDetail.roleId == data.value
                    )
                  : "",
                timeZone: userDetail?.timeZone
                  ? ArrayList.isArray(timeZones) &&
                    timeZones.find((data) => data.value == userDetail?.timeZone)
                  : null,
                currentLocation:
                  ArrayList.isNotEmpty(locationList) &&
                  locationList.find(
                    (data) => data.id == userDetail.currentLocationId
                  ),
                currentShift:
                  ArrayList.isNotEmpty(shiftList) &&
                  shiftList.find(
                    (data) => data.id == userDetail.currentShiftId
                  ),
              }}
              onSubmit={(values) => {
                handleUser(values);
              }}
            >
              <div className="card bg-white mb-3 pb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="row">
                        <div className="field-wrapper d-flex mt-1 ms-1 mb-3 col-lg-12 col-sm-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Text
                            name="first_name"
                            label="First Name"
                            placeholder="Enter First Name"
                            required={true}
                            disabled={editable}
                            error=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Text
                            name="last_name"
                            label="Last Name"
                            placeholder="Enter Last Name"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Email
                            name="email"
                            label="Email"
                            placeholder="Enter Email"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Phone
                            name="mobileNumber1"
                            label="Mobile Number 1"
                            placeholder="Enter Mobile Number"
                            disabled={editable}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Select
                            name="roleId"
                            label="Role"
                            placeholder="Select Role..."
                            options={userRole ? userRole : []}
                            error=""
                            required={true}
                            isDisabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <TimeZoneSelect
                            name="timeZone"
                            label="Time Zone"
                            disabled={editable}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <SelectStore
                            name="currentLocation"
                            label="Current Location"
                            StoreList={setLocationList}
                            defaultValue={userDetail.currentLocationId}
                            isDisabled={editable}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <ShiftSelect
                            name="currentShift"
                            label="Current Shift"
                            defaultValue={userDetail?.currentShiftId}
                            shiftOption={setShiftList}
                            isDisabled={editable}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <div className="w-100 ps-5 mt-5">
                        <UploadAvatar
                          apiURL={`${endpoints().userAvatarUpdate}`}
                          data={userDetail}
                          getFile={userImageWithBaseUrl}
                          userId={userDetail?.id}
                          disabled={editable}
                        />
                      </div>
                    </div>
                  </div>
                  {!editable && (
                    <div>
                      <SaveButton />
                      <CancelButton onClick={() => history.push("/users")} />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab == User.ADDRESS && (
          <TabPane tabId={User.ADDRESS}>
            <Drawer
              modelTitle="Add Address URL"
              DrawerBody={addUrlForm}
              DrawerFooter={roleFooter}
              onSubmit={handleSubmitValue}
              initialValues={{
                name: "",
                url: "",
              }}
              handleOpenModal={toggleOpen}
              handleCloseModal={toggleOpen}
              handleDrawerClose={toggleOpen}
              isModalOpen={isToggleOpen}
              enableReinitialize
            />
            <Form
              enableReinitialize={true}
              initialValues={{
                ...userDetail,
                roleId: userDetail
                  ? ArrayList.isArray(userRole) &&
                    userRole.find((data) => userDetail?.roleId == data.value)
                  : "",
                state: userDetail?.state
                  ? {
                      label: userDetail?.state,
                      value: userDetail?.state,
                    }
                  : "",
                country: userDetail?.country
                  ? {
                      label: userDetail?.country,
                      value: userDetail?.country,
                    }
                  : "",
              }}
              onSubmit={(values) => {
                handleAdress(values);
              }}
            >
              <div className="card bg-white mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="address1"
                          label="Address 1"
                          placeholder="Enter Address"
                          error=""
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="address2"
                          label="Address 2"
                          placeholder="Enter Address"
                          error=""
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="city"
                          label="City"
                          placeholder="Enter City"
                          error=""
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Country
                          name="country"
                          label="Country"
                          error=""
                          onChange={handleCountryChange}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <StateDropdown
                          name="state"
                          label="State"
                          error=""
                          fontBolded
                          selectedCountry={
                            selectedOption ? selectedOption : selectedCountryId
                          }
                          selectedCountryName={String.Get(selectedCountryName)}
                        />
                      </div>

                      <div className="col-lg-12 col-sm-12">
                        <Zipcode
                          label="Pin Code"
                          name="pin_code"
                          placeholder="Enter Pin Code"
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="longitude"
                          label="Longitude"
                          placeholder="Enter Longitude"
                          error=""
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="latitude"
                          label="Latitude"
                          placeholder="Enter Latitude"
                          error=""
                        />
                      </div>
                      <div className="mb-4">
                        <div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Link onClick={toggleOpen}>Get From URL</Link>
                          </div>
                          <SaveButton />
                          <CancelButton
                            onClick={() => history.push("/users")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab == User.SECURITY && (
          <TabPane tabId={User.SECURITY}>
            <Form
              enableReinitialize={true}
              initialValues={{
                ...userDetail,
                roleId: userDetail
                  ? userRole.find((data) => userDetail.roleId == data.value)
                  : "",
              }}
              onSubmit={(values) => {
                handleUser(values);
              }}
            >
              <div className="card bg-white mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Password
                            name="newPassword"
                            label="New Password"
                            placeholder="Enter New Password"
                            onInputChange={(e) => {
                              handleNewPasswordChange(e);
                            }}
                            error={strongPasswordError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <Password
                            name="confirmPassword"
                            label="Confirm Password"
                            placeholder="Enter Confirm Password"
                            onInputChange={(e) => {
                              handlePasswordChange(e);
                            }}
                            error={confirmPasswordError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <SaveButton />
                  <CancelButton onClick={() => history.push("/users")} />
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab == User.SLACK && (
          <TabPane tabId={User.SLACK}>
            <Form
              enableReinitialize={true}
              initialValues={{
                ...userDetail,
                roleId: userDetail
                  ? userRolePrefill.find(
                      (data) => userDetail.roleId == data.value
                    )
                  : "",
                slack_id: slackUserList.find(
                  (data) => data?.value === userDetail?.slack_id
                ),
              }}
              onSubmit={(values) => {
                slackHandleSubmit(values);
              }}
            >
              <div className="card bg-white">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <Select
                        name="slack_id"
                        label="Slack User"
                        placeholder="Select Slack User"
                        options={slackUserList}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <SaveButton />
                      <CancelButton onClick={() => history.push("/users")} />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab == User.EMPLOYMENT && (
          <TabPane tabId={User.EMPLOYMENT}>
            <UserEmployment
              id={match.params.id}
              history={history}
              userEmploymentDetail={userDetail}
            />
          </TabPane>
        )}
        {activeTab == User.FILES && (
          <TabPane tabId={User.FILES}>
            <ImageList
              title="User"
              objectName="USER"
              objectId={props?.match?.params?.id}
              showFeature={true}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              selectedFile={selectedFile}
              setSelectedFile={selectedFile}
              file={file}
              currentData={currentData}
              setCurrentData={setCurrentData}
              status={status}
              setImageStatus={setImageStatus}
              setFile={setFile}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              toggle={imagetoggle}
              userView
              hideAttachments
            />
          </TabPane>
        )}
        {activeTab == User.SALARY && (
          <TabPane tabId={User.SALARY}>
            <SalaryList
              history={history}
              user={props?.match?.params?.id}
              showBreadcrumb
              showActionDropdown
            />
          </TabPane>
        )}
        {showHistory && activeTab === User.HISTORY && (
          <TabPane tabId={User.HISTORY}>
            <ActivityList
              id={props?.match?.params?.id}
              objectId={props?.match?.params?.id}
              object_name={ObjectName.USER}
            />
          </TabPane>
        )}
        {activeTab === User.ATTENDANCE && (
          <TabPane tabId={User.ATTENDANCE}>
            <AttendanceList
              userView={"false"}
              showUserFilter={false}
              user={props?.match?.params?.id}
              showPageSize={true}
              refreshButton
              history={history}
              bulkSelect={false}
              isOpen={isAttendanceOpen}
              setIsOpen={setIsAttendanceOpen}
            />
          </TabPane>
        )}
        {activeTab == User.SETTINGS && (
          <TabPane tabId={User.SETTINGS}>
            <Settings
              history={history}
              getUserDetail={getUserDetail}
              userDetail={userDetail}
              updateUser={(e) => updateUser(e)}
            />
          </TabPane>
        )}

        {activeTab == User.GEO_LOCATIONS && (
          <TabPane tabId={User.GEO_LOCATIONS}>
            <GetLocationTab
              history={history}
              user_id={props?.match?.params?.id}
            />
          </TabPane>
        )}
        {activeTab == User.TEAM && (
          <TabPane tabId={User.TEAM}>
            <TeamTab history={history} user_id={props?.match?.params?.id} />
          </TabPane>
        )}

        {activeTab == User.DEVICES && (
          <TabPane tabId={User.DEVICES}>
            <DeviceList
              onBulkSelect={onBulkSelect}
              history={history}
              params={{ user: match?.params?.id }}
              showUserFilter={false}
            />
          </TabPane>
        )}
        {activeTab == User.PREFERRED_LOCATIONS && (
          <TabPane tabId={User.PREFERRED_LOCATIONS}>
            <PreferredLocation
              history={history}
              user_id={props?.match?.params?.id}
              isOpen={isPlOpen}
              setIsOpen={setIsPlOpen}
              _toggle={closePreferredLcationModel}
              openToggle={openPreferredLcationModel}
              setRow={setRow}
              row={row}
            />
          </TabPane>
        )}
        {activeTab == User.LOCATIONS && (
          <TabPane tabId={User.LOCATIONS}>
            <LocationsTab
              history={history}
              user_id={props?.match?.params?.id}
            />
          </TabPane>
        )}
        {activeTab == User.FINES && (
          <TabPane tabId={User.FINES}>
            <FineList
              setRowValue={setRowValue}
              rowValue={rowValue}
              Toggle={Toggle}
              setIsOpen={setIsModelOpen}
              bulkSelect={false}
              isOpen={isModelOpen}
              history={history}
              user_id={props?.match?.params?.id}
              showUserFilter={false}
              param={{ isFineType: true }}
              apiURL={endpoints().fineApi}
              service={fineService}
              objectName={ObjectName.FINE}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default userDetail;
