import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

// Helpers
import ObjectName from "../helpers/ObjectName";

// Components
import AccountSelect from "./AccountSelect";
import BrandSelect from "./BrandSelect";
import Button from "./Button";
import CategorySelect from "./CategorySelect";
import DateSelector from "./Date";
import Drawer from "./Drawer";
import PageSearch from "./PageSearch";
import PageSize from "./PageSize";
import PaymentSelect from "./PaymentAccountSelect";
import ProductSelect from "./ProductSelect";
import Select from "./Select";
import SelectDropdown from "./SelectDropdown";
import StatusSelect from "./SelectStatus";
import SelectStore from "./SelectStore";
import SelectType from "./SelectType";
import ShiftSelect from "./ShiftSelect";
import SprintSelect from "./SprintSelect";
import StatusGroupSelect from "./StatusGroupSelect";
import SelectTag from "./TagSelect";
import UserSelect from "./UserSelect";
import ProjectSelect from "./projectSelect";
import SingleCheckbox from "./SingleCheckbox";
import SaveButton from "./SaveButton";
import TimeSelector from "./TimeSelector";
import DropdownWithCheckbox from "./DropdownWithCheckbox";
import ProjectComponent from "./ProjectComponentSelect";
import TicketType from "./TicketType";
import AccountTypeSelect from "./AccountTypeSelect";

// Lib
import DateTime from "../lib/DateTime";

// Services
import StatusService from "../services/StatusService";
import YearSelect from "./YearSelect";
import MonthSelect from "./MonthSelect";
import ArrayList from "../lib/ArrayList";
import DateSelect from "./DateSelect";
import { leaveOption } from "../helpers/User";
import RoleSelect from "./RoleSelect";

const Filter = (props) => {
  let {
    initialValues,
    handleDeleteFilter,
    openModel,
    modelOpen,
    accountType,
    AccountProductBrandList,
    AccountProductCategoryList,
    handleFilter,
    showObjectNameParam = false,
    showStatusParam = true,
    hideQueryParams = {},
    customChild
  } = props;

  const [displayFilter, setDisplayFilter] = useState(true);
  const [spinning, setSpinning] = useState(props.spinning);
  const [statusList, setStatusList] = useState([]);
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let hideParamsValue = {
    ...hideQueryParams,
    ...(showObjectNameParam ? {} : { objectName: true }),
    ...(showStatusParam ? {} : { status: true }),
    pagination: true,
  };

  useEffect(() => {
    if (
      (props.showBrandFilter && props.showCategoryFilter) ||
      props.showDateFilter || props.showCreatedAtFilter
    ) {
      setEnableReinitialize(false);
    }
  }, []);

  let isMultiPro = (key) => {
    let multiProKeys = [
      (props?.isMultiStatus ? "status" : ""),
      (props?.isMultiType ? "type" : "")
    ]
    if (ArrayList.isArray(multiProKeys) && multiProKeys.includes(key)) {
      return true
    } else {
      return false
    }
  }

  const handleRemoveFilter = (obj, key, currentIndex) => {

    if (isMultiPro(key)) {
      let arryValue = obj[key]
      let filteredArry = ArrayList.isArray(arryValue) && arryValue.filter((_, index) => index !== currentIndex) || [];
      if (ArrayList.isArray(filteredArry)) {
        let ids = filteredArry.map((data) => data?.value);
        let objectvalue = {};
        let key = Object.keys(obj)[0];
        objectvalue[key] = ids;
        handleDeleteFilter && handleDeleteFilter(objectvalue);
      } else {
        let objectvalue = {};
        let key = Object.keys(obj)[0];
        objectvalue[key] = "";
        handleDeleteFilter && handleDeleteFilter(objectvalue);
      }
    } else {
      let objectvalue = {};
      let key = Object.keys(obj)[0];
      objectvalue[key] = "";
      handleDeleteFilter && handleDeleteFilter(objectvalue);
    }
  };

  const handleClick = () => {
    props.refreshButtonOnClick();
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 100);
  };

  const getStatus = async (type) => {
    let response = await StatusService.getOption(ObjectName.TICKET, type);
    const status = [];
    if (response && response.length > 0) {
      for (let i = 0; i < response.length; i++) {
        status.push({
          label: response[i].name,
          value: response[i].id,
        });
      }
    }
    setStatusList(status);
  };

  const handleTicketTypeChange = (value) => {
    setEnableReinitialize(false);
    let data =
      value &&
        value.values &&
        value.values.ticketType &&
        value.values.ticketType.value
        ? value.values.ticketType.value
        : "";
    getStatus(data);
  };

  const handleTicketComponentChange = (value) => {
    setEnableReinitialize(false);
    let data =
      value &&
        value.values &&
        value.values.ticketComponent &&
        value.values.ticketComponent.value
        ? value.values.ticketComponent.value
        : "";
    getStatus(data);
  };

  let filteredArrayValues = [];
  let filteredObjectValues = [];
  let filteredNormalValues = [];
  if (initialValues && initialValues !== undefined) {
    const filteredData = Object.fromEntries(
      Object.entries(initialValues).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    if (filteredData) {
      const arrayValues = [];
      const objectValues = [];
      const normalValues = [];

      for (const [key, value] of Object.entries(filteredData)) {
        if (Array.isArray(value)) {
          arrayValues.push({ [key]: value });
        } else if (typeof value === "object" && value !== null) {
          objectValues.push({ [key]: value });
        } else {
          normalValues.push({ [key]: value });
        }
      }

      for (const item of arrayValues) {
        const key = Object.keys(item)[0];
        const value = isMultiPro(key) && item[key].length > 0 ? item[key] : item[key][0];
        let filteredKey =
          Object.keys(hideParamsValue).find((values) => values === key) || "";
        if (value !== undefined && !filteredKey) {
          filteredArrayValues.push({ [key]: isMultiPro(key) ? value : value?.label });
        }
      }

      for (let item of objectValues) {
        const key = Object.keys(item)[0];
        const value = item[key];
        let filteredKey =
          Object.keys(hideParamsValue).find((values) => values === key) || "";
        if (value !== undefined && !filteredKey) {
          if (key === "product") {
            filteredObjectValues.push({ [key]: value?.label?.props?.url });
          } else {
            filteredObjectValues.push({ [key]: value?.label });
          }
        }
      }

      for (let item of normalValues) {
        const key = Object.keys(item)[0];
        const value = item[key];
        let filteredKey =
          Object.keys(hideParamsValue).find((values) => values === key) || "";
        if (value !== undefined && !filteredKey) {
          filteredNormalValues.push({ [key]: value });
        }
      }
    }
  }

  let mergedValues = [
    ...filteredArrayValues,
    ...filteredObjectValues,
    ...filteredNormalValues,
  ];

  const modelBody = (
    <>
      <div className="row d-block px-0 py-0">
        {/*  Date Filter */}
        {props.showSingleDateFilter && (
          <>
            <div className="col">
              <DateSelector
                name="date"
                placeholder="Date"
                // selected={this.state.startSelected}
                onChange={props.handleDateChange}
                isClearable
              />
            </div>
          </>
        )}
        {props.showYearFilter && (
          <div className="col">
            <YearSelect name="year" />
          </div>
        )}

        {props.showMonthFilter && (
          <div className="col">
            <MonthSelect name="month" />
          </div>
        )}

        {props.showProjectFilter && (
          <div className="col">
            <ProjectSelect
              oninputProjectChange={props.handleProjectChange}
              projectList={(e) =>
                props.setProjectList && props.setProjectList(e)
              }
            />
          </div>
        )}

        {/* Brand Filter */}
        {props.showBrandFilter && (
          <div className="col">
            <BrandSelect
              handleBrandChange={props.handleBrandChange}
              brandOption={(x) => props.brandOption(x)}
              AccountProductBrandList={AccountProductBrandList}
            />
          </div>
        )}

        {/* Category Filter */}
        {props.showCategoryFilter && (
          <div className="col">
            <CategorySelect
              handleCategoryChange={props.handleCategoryChange}
              categoryList={(x) => props.categoryList(x)}

              AccountProductCategoryList={AccountProductCategoryList}
            />
          </div>
        )}

        {/* Product Filter */}
        {props.showProductFilter && (
          <div className="col">
            <ProductSelect
              handleProductChange={props.handleProductChange}
              productOption={(x) => props.productOption(x)}

              brandValue={props.brandValue}
              categoryValue={props.categoryValue}
              params={props?.productSelectParams}
            />
          </div>
        )}

        {/* Vendor Filter */}
        {props.showAccountFilter && (
          <div className="col">
            <AccountSelect
              handleVendorChange={props.handleVendorChange}
              vendorList={(x) => props.vendorList(x)}
              placeholder={
                props.vendorPlaceholder
                  ? props.vendorPlaceholder
                  : "Select Account"
              }

              customOption={props.accountCustomOption}
              accountCategory={accountType}
              accountSelectParams={props.accountSelectParams}
            />
          </div>
        )}

        {/* Store Filter */}
        {props.showStockEntryProductTypeFilter && (
          <div className="col">
            <Select
              name="stockEntryProductType"
              options={props.stockProductTypeOption}
              placeholder="Select Type"
              handleChange={props.handleStockEntryProductTypeChange}

            />
          </div>
        )}

        {props.showStoreFilter && (
          <div className="col">
            <SelectStore
              name={props.locationName}
              handleStoreChange={props.handleStoreChange}
              StoreList={props.StoreList}

            />
          </div>
        )}

        {props.showTicketTypeFilter && (
          <div className="col">
            <TicketType
              label=""
              name="ticketType"
              handleTicketTypeChange={handleTicketTypeChange}
              typeList={(x) => props.typeList(x)}
            />
          </div>
        )}

        {props.showTicketComponentFilter && (
          <div className="col">
            <ProjectComponent
              label=""
              name="ticketComponent"
              handleTicketTypeChange={handleTicketComponentChange}
              componentList={(x) => props.componentList(x)}
            />
          </div>
        )}

        {/* Status Filter */}
        {props.showStatusFilter && (
          <div className="col">
            <StatusSelect
              customStatusOption={
                props && props.customStatusOption
                  ? props.customStatusOption
                  : statusList
              }
              handleStatusChange={props.onStatusChange}
              statusOption={(x) => props.statusOption(x)}
              objectName={props.objectName}
              objectId={props.orderTypeGroup}
              allowToView={props.statusParamAllowToView}
              isMulti={props?.isMultiStatus}
            />
          </div>
        )}

        {props.showGstStatusFilter && (
          <div className="col">
            <StatusSelect
              name="gstStatus"
              placeholder="Select GST Status"
              handleStatusChange={props.onGstStatusChange}
              statusOption={(x) => props.gstStatusOption(x)}
              objectName={ObjectName.BILL_GST_STATUS}
            />
          </div>
        )}

        {/* salesExecutive Filter */}
        {props.showSalesExecutiveFilter && (
          <div className="col">
            <UserSelect
              name="salesExecutive"
              placeholder="Sales Executive"
              customUserOption={props.salesExecutiveOption}
              handleUserChange={props.handlesalesExecutiveChange}
              minWidth="200px"
            />
          </div>
        )}

        {/* Shift Filter */}
        {props.showShiftFilter && (
          <div className="col">
            <ShiftSelect
              name={props.shiftName}
              shiftOption={(x) => props.shiftOption(x)}
              handleShiftChange={props.handleShiftChange}
            />
          </div>
        )}

        {/* Payment Type Filter */}
        {props.showPaymentTypeFilter && (
          <div className="col">
            <Select
              name="paymentType"
              placeholder="Select Payment Type"
              options={props.paymentType}
              handleChange={props.handlePaymentTypeChange}
            />
          </div>
        )}

        {/* Order Type Filter */}
        {props.showOrderTypeFilter && (
          <div className="col">
            <Select
              name="orderType"
              placeholder="Select Order Type"
              options={props.orderTypeOption}
              handleChange={props.handleOrderTypeChange}
            />
          </div>
        )}

        {/* Type Filter */}
        {props.showTypeFilter && (
          <div className="col">
            <SelectType
              name={props.typeName}
              handleTypeChange={props.handleTypeChange}
              typeOption={(x) => props.typeOption(x)}
              customTypeOption={props.customTypeOption}
              isMultiType={props?.isMultiType}
            />
          </div>
        )}

        {props.showAccountTypeFilter && (
          <div className="col">
            <AccountTypeSelect
              name="type"
              handleChange={props.handleTypeChange}
              accountTypeOption={(x) => props?.accountTypeOption(x)}
            />
          </div>
        )}

        {props.showRoleFilter && (
          <div className="col">
            <RoleSelect
              name="role"
              placeholder="Select Role"
              handleChange={props.handleRoleChange}
              list={props.setRoleList}
            />
          </div>
        )}

        {/* Type Filter */}
        {props.showGraphDataFilter && (
          <div className="col">
            <Select
              name={"graphData"}
              onInputChange={props.handleGraphDataChange}
              options={props.graphDataTypeOption}
              clearable
              placeholder="Select Graph Type"
            />
          </div>
        )}

        {/* From Store To Store Filter */}
        {props.showFromToLocationFilter && (
          <>
            <div className="col">
              <SelectStore
                name="fromLocation"
                placeholder="Select From Location"
                StoreList={props.StoreList}
                handleChange={props.handleFromStoreChange}
                handleStoreChange={props.handleFromStoreChange}
                showlabel={props.showlabel}
              />
            </div>
            <div className="col">
              <SelectStore
                name="toLocation"
                placeholder="Select To Location"
                StoreList={props.StoreList}
                handleChange={props.handleFromStoreChange}
                handleStoreChange={props.handleToStoreChange}
              />
            </div>
          </>
        )}

        {/* Payment Account filter */}
        {props.showPaymentAccountFilter && (
          <div className="col">
            <PaymentSelect
              name="paymentAccount"
              placeholder="Select Payment Account"
              options={props.bankOption}
              handleChange={props.handlePaymentAccountChange}
            />
          </div>
        )}

        {/* User Filter */}
        {props.showUserFilter ? (
          <div className="col">
            <UserSelect
              placeholder={
                props?.assigneePlaceholder ? props.assigneePlaceholder : ""
              }
              handleUserChange={props.handleUserChange}
              userList={(x) => props.userList(x)}

            />
          </div>
        ) : (
          ""
        )}

        {/* User Filter */}
        {props.showOwnerFilter ? (
          <div className="col">
            <UserSelect
              name={"owner"}
              placeholder={"Select Owner"}
              userList={(x) => props.userList(x)}

            />
          </div>
        ) : (
          ""
        )}

        {props.showReviewerFilter ? (
          <div className="col">
            <UserSelect
              name="reviewer"
              placeholder="Select Reviewer"
              handleUserChange={props.handleReviewerChange}
              userList={(x) => props.reviewerList(x)}

            />
          </div>
        ) : (
          ""
        )}

        {props.showReporterFilter ? (
          <div className="col">
            <UserSelect
              name="reporter"
              placeholder="Select Reporter"
              handleUserChange={props.handleReporterChange}
              userList={(x) => props.reporterList(x)}

            />
          </div>
        ) : (
          ""
        )}

        {props.ShowObjectNameFilter && (
          <div className="col">
            <Select
              name="objectName"
              options={props.objectNameOptions}
              placeholder="Object Name"
              handleChange={props.handleObjectNameChange}

            />
          </div>
        )}

        {props.showTagFilter && (
          <div className="col">
            <SelectTag
              name="tag"
              placeholder={props.tagPlaceholder}
              customTagOption={props.customTagOption}
              params={props.tagParams}
              handleTagChange={props.handleTagChange}
              TagList={(x) => props.TagList && props.TagList(x)}
            />
          </div>
        )}

        {props.showVisitorTypeFilter && (
          <div className="col">
            <SelectTag
              name="visitorType"
              placeholder={props.tagPlaceholder}
              customTagOption={props.customTagOption}
              params={props.tagParams}
              handleTagChange={props.handleTagChange}
              TagList={(x) => props.TagList && props.TagList(x)}
            />
          </div>
        )}

        {props.showManufactureFilter && (
          <div className="col">
            <SelectTag
              name="manufacture"
              placeholder={"Manufacture"}
              params={{
                type: "Manufacture",
              }}
              handleTagChange={props.handleManufactureChange2}
            />
          </div>
        )}

        {props.showStatusOptions && (
          <div className="col">
            <SelectDropdown
              buttonLabel={props.getSelectedStatusLabel}
              dropdownLinks={props.statusOptions}
              color={"var(--dropdown-color)"}
              hideCaret
              width
              handleChange={props.handleStatusByChange}
            />
          </div>
        )}

        {props.showSprintFilter && (
          <div className="col">
            <SprintSelect
              name="sprint"
              options={props.sprintOptions}
              placeholder="Select Sprint"
              handleChange={props.handleSprintChange}

            />
          </div>
        )}

        {/* Reason Filter */}
        {props.showReasonFilter && (
          <div className="col">
            <Select
              name="reason"
              options={props.reasonOption}
              placeholder="Select Reason"
              handleChange={props.handleReasonChange}

            />
          </div>
        )}

        {/* Reason Filter */}
        {props.showStockFilter && (
          <div className="col">
            <Select
              name="stockType"
              options={props.ActionMenu}
              placeholder="Select Stock Type"
              handleChange={props.handleStockTypeChange}

            />
          </div>
        )}

        {props.showCountSortFilter && (
          <div className="col">
            <Select
              name="sortType"
              options={props.countSortOption}
              placeholder="Select Data Type"
              handleChange={props.handleSortTypeChange}

            />
          </div>
        )}

        {props.showStatusGroupFilter && (
          <div className="col">
            <StatusGroupSelect
              onInputChange={props?.groupSelectOnChange}
              groupOption={props?.groupOption}

            />
          </div>
        )}

        {props.showStockEntryDateFilter && (
          <div className="row m-0">
            <div className="col">
              <DateSelector
                name="stockEntryStartDate"
                placeholder="Stock Entry Start Date"
                onChange={props.handleStockEntryStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="stockEntryEndDate"
                placeholder="Stock Entry End Date"
                onChange={props.handleStockEntryEndDateChange}
                isClearable
              />
            </div>
          </div>
        )}

        {props.showOrderDateFilter && (
          <div className="row m-0">
            <div className="col">
              <DateSelector
                name="orderStartDate"
                placeholder="Order Start Date"
                onChange={props.handleOrderStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="orderEndDate"
                placeholder="Order End Date"
                onChange={props.handleOrderEndDateChange}
                isClearable
              />
            </div>
          </div>
        )}

        {props.showTransferDateFilter && (
          <div className="row m-0">
            <div className="col">
              <DateSelector
                name="transferStartDate"
                placeholder="Transfer Start Date"
                onChange={props.handleTransferStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="transferEndDate"
                placeholder="Transfer End Date"
                onChange={props.handleTransferEndDateChange}
                isClearable
              />
            </div>
          </div>
        )}

        {props.showDateFilter && !props.showTimeFilter && (
          <>
            <div className="col">
              <DateSelector
                name="startDate"
                placeholder={props?.startDatePlaceholder ? props?.startDatePlaceholder : "Start Date"}
                // selected={this.state.startSelected}
                onChange={props.handleStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="endDate"
                placeholder={props?.endDatePlaceholder ? props?.endDatePlaceholder : "End Date"}
                // selected={props.startSelected}
                onChange={props.handleEndDateChange}
                isClearable
              />
            </div>
          </>
        )}

        {props.showCreatedAtFilter && (
          <>
            <div className="col">
              <DateSelector
                name="createdStartDate"
                placeholder={"Created StartDate"}
                // selected={this.state.startSelected}
                onChange={props.handleCreatedStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="createdEndDate"
                placeholder={"Created EndDate"}
                // selected={props.startSelected}
                onChange={props.handleCreatedEndDateChange}
                isClearable
              />
            </div>
          </>
        )}

        {props.showCustomDateFilter && (
          <>
            <div className="col">
              <DateSelect
                customDateOption={props.customDateOption}

              />
            </div>
          </>
        )}

        {props.showDateFilter && props.showTimeFilter && (
          <>
            <div className="row m-0">
              <div className="col-7">
                <DateSelector
                  name="startDate"
                  placeholder="Start Date"
                  // selected={this.state.startSelected}
                  onChange={props.handleStartDateChange}
                  isClearable
                />
              </div>
              <div className="col-5">
                <TimeSelector
                  name="startTime"
                  placeholder="Start Time"
                  isClearable={true}
                  onChange={props.handleStartTimeChange}
                  timeInterval={30}
                />
              </div>
            </div>
            <div className="row m-0">
              <div className="col-7">
                <DateSelector
                  name="endDate"
                  placeholder="End Date"
                  // selected={props.startSelected}
                  onChange={props.handleEndDateChange}
                  isClearable
                />
              </div>
              <div className="col-5">
                <TimeSelector
                  name="endTime"
                  placeholder="End Time"
                  isClearable={true}
                  onChange={props.handleStartTimeChange}
                  timeInterval={30}
                />
              </div>
            </div>
          </>
        )}

        {props.showCheckBox && (
          <div className="col">
            <SingleCheckbox
              name="show_duplicate"
              label="Show Duplicate"
              className="accepted-terms mb-2 pb-0"
              handleOnChangeSubmit={(value, name) =>
                props.handleOnChangeSubmit(value, name)
              }
            />
          </div>
        )}

        {props.showCheckedInUser && (
          <div className="col">
            <SingleCheckbox
              name="show_currently_checked_in_user"
              label="Show Currently Checked-In Users Only"
              className="accepted-terms mb-2 pb-0"
              handleOnChangeSubmit={(value, name) =>
                props.handleOnChangeSubmit(value, name)
              }
            />
          </div>
        )}

        {props.showUserAllowLeaveFilter && (
          <div className="col">
            <Select
              label=""
              name="allow_leave"
              placeholder={"Select Allow Leave"}
              options={leaveOption}
            />
          </div>
        )}
      </div>
    </>
  );

  let modelFooter = (
    <>
      <SaveButton label="Apply Filter" />
    </>
  );

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChangeFilter = (values) => {
    handleFilter && handleFilter(values);
    setIsModalOpen(false);
    openModel && openModel();
  };



  const renderMultiProp = (obj) => {
    return (
      obj[Object.keys(obj)[0]].map((data, index) => (
        <p
          className="badge badge-primary font-size-12"
          style={{
            ...style,
            marginRight: 1,
          }}
        >
          {data?.label}
          <FontAwesomeIcon
            icon={faXmark}
            className="cursor-pointer"
            style={{ marginLeft: "5px" }}
            onClick={(e) => handleRemoveFilter(obj, Object.keys(obj)[0], index)}
          />
        </p>
      )
      )
    )
  }

  return (
    <>
      <Drawer
        DrawerBody={customChild ? customChild : modelBody}
        initialValues={initialValues}
        handleOpenModal={openModel ? openModel : handleOpenModal}
        handleCloseModal={openModel ? openModel : handleOpenModal}
        handleDrawerClose={openModel ? openModel : handleOpenModal}
        isModalOpen={modelOpen ? modelOpen : isModalOpen}
        enableReinitialize={enableReinitialize}
        className="filter-width"
        modelTitle="Filter"
        DrawerFooter={modelFooter}
        onSubmit={handleChangeFilter}
      />
      {props.showHeader && !props.disableHeader && (
        <>
          {props.newTableHeading && displayFilter ? (
            // To display show and hide filter using this id---> filterSection
            <div className="d-block">
              <div
                className={`p-3 pb-0 page-heading cover ${props.showSearch
                  ? "justify-content-end"
                  : "justify-content-between"
                  }`}
              >
                <div className={"d-flex py-3 pt-0 justify-content-end"}>
                  {props.showPageSize && (
                    <div className="me-2 d-block">
                      <PageSize
                        onChange={(e) => props.getPageSizeByOptions(e)}
                        selectedPageSize={props.selectedPageSize}
                      />
                    </div>
                  )}

                  {/* <div className="col-9"> */}
                  {!props.showSearch && (
                    <PageSearch
                      width={props.searchBarWidth}
                      value={props.pageSearchValue}
                      classnames="page-search"
                      placeholder={props.searchPlaceholder}
                      onChange={props.pageSearchOnChange}
                      onKeyDown={(e) => props.onKeyDown(e)}
                      onSearchClick={props.onSearchClick}
                      onKeyUp={props.onKeyUp}
                    />
                  )}

                  {!props.sortByDropdown && (
                    <div className="d-none d-sm-block">
                      <SelectDropdown
                        buttonLabel={
                          props.dropdownLabel
                            ? props.dropdownLabel
                            : props.getSelectedSortLabel
                        }
                        dropdownLinks={props.sortByOptions}
                        hideCaret
                        handleChange={props.handleSortByChange}
                      />
                    </div>
                  )}

                  {props.DropdownWithCheckbox && (
                    <div className="ps-2">
                      <DropdownWithCheckbox
                        className="overflow-visible d-flex justify-content-between"
                        buttonLabel=""
                        dropdownLinks={props.FieldLabel}
                        handleChange={props.handleColumnChange}
                        color="gray"
                        hideCaret
                        checkedItems={props.arrayList}
                      />
                    </div>
                  )}

                  {(props.showDateFilter ||
                    props.showTimeFilter ||
                    props.showSingleDateFilter ||
                    props.showCategoryFilter ||
                    props.showBrandFilter ||
                    props.showProductFilter ||
                    props.showStockEntryProductTypeFilter ||
                    props.showStoreFilter ||
                    props.showStatusFilter ||
                    props.showGstStatusFilter ||
                    props.showSalesExecutiveFilter ||
                    props.showShiftFilter ||
                    props.showOrderTypeFilter ||
                    props.showPaymentTypeFilter ||
                    props.showTypeFilter ||
                    props.showFromToLocationFilter ||
                    props.showPaymentAccountFilter ||
                    props.showUserFilter ||
                    props.showReporterFilter ||
                    props.showReviewerFilter ||
                    props.ShowObjectNameFilter ||
                    props.showTagFilter ||
                    props.showVisitorTypeFilter ||
                    props.showManufactureFilter ||
                    props.showStatusOptions ||
                    props.showSprintFilter ||
                    props.showReasonFilter ||
                    props.showStockFilter ||
                    props.showCountSortFilter ||
                    props.showStatusGroupFilter ||
                    props.showStockEntryDateFilter ||
                    props.showOrderDateFilter ||
                    props.showTransferDateFilter ||
                    props.showTicketTypeFilter ||
                    props.showTicketComponentFilter ||
                    props.showProjectFilter ||
                    props.showMonthFilter ||
                    props.showYearFilter ||
                    props.showOwnerFilter ||
                    props.showRoleFilter ||
                    props.showUserAllowLeaveFilter || customChild || props.showCustomDateFilter || props.showCreatedAtFilter) && (
                      <div className="d-flex ms-2 me-0 me-sm-1 pb-1 pb-sm-1">
                        <Button
                          label={props.buttonLabel}
                          onClick={
                            props?.buttonOnClick
                              ? props.buttonOnClick
                              : openModel
                                ? openModel
                                : handleOpenModal
                          }
                          className={"pt-2 ps-2 pe-2 btn btn-primary fa fa-filter bg-dark"}
                        />
                      </div>
                    )}

                  <div className="d-flex pb-1 pb-sm-1">
                    {!props.refreshButton && (
                      <div onClick={handleClick} className="pt-2 ps-2 pe-2 btn btn-dark ms-2">
                        <span
                          className={
                            props.refreshValue || spinning
                              ? "fa fa-sync fa-spin"
                              : "fa fa-refresh"
                          }
                        ></span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-auto">
                  {mergedValues &&
                    mergedValues.length > 0 &&
                    mergedValues.map((obj) => (
                      <div className="col d-flex mx-2 flex-grow-0 pe-0">
                        <p
                          className="d-flex align-items-center shadow px-2 rounded-left text-capitalize font-size-12"
                          style={{
                            backgroundColor: "var(--filter-show-lable-bg-color)",
                          }}
                        >
                          {Object.keys(obj)[0]}:
                        </p>

                        {(isMultiPro(Object.keys(obj)[0]) && Array.isArray(obj[Object.keys(obj)[0]])) ?
                          renderMultiProp(obj)
                          : (
                            <p
                              className="badge badge-primary font-size-12"
                              style={style}
                            >
                              {Object.keys(obj)[0] === "product" ? (
                                <img
                                  src={obj[Object.keys(obj)[0]]}
                                  style={{ width: "100px" }}
                                />
                              ) : Object.keys(obj)[0] === "startTime" ? (
                                DateTime.getUserTimeZoneTime(obj[Object.keys(obj)[0]])
                              ) : Object.keys(obj)[0] === "endTime" ? (
                                DateTime.getUserTimeZoneTime(obj[Object.keys(obj)[0]])
                              ) : (
                                obj[Object.keys(obj)[0]]
                              )}
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="cursor-pointer"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => handleRemoveFilter(obj)}
                              />
                            </p>
                          )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

const style = {
  alignItems: "center",
  display: "flex",
  minWidth: "50px",
  justifyContent: "space-evenly",
  padding: "6px 10px 6px 10px",
  lineHeight: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: "33px",
  maxHeight: "33px",
  backgroundColor:
    "var(--filter-show-value-bg-color)",
}

export default Filter;
