import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Currency from "../../../components/Currency.js";
import DateSelector from "../../../components/Date";
import Text from "../../../components/Text";
import AccountSelect from "../../../components/AccountSelect";
import Percentage from "../../../components/Percentage.js";
import UserSelect from "../../../components/UserSelect.js";
import TextArea from "../../../components/TextArea.js";
import MediaCarousel from "../../../components/MediaCarousel.js";
import Form from "../../../components/Form.js";
import SaveButton from "../../../components/SaveButton.js";
import CancelButton from "../../../components/CancelButton.js";
import BillingNameSelect from "../../../components/BillingNameSelect.js";

// Helpers
import ObjectName from "../../../helpers/ObjectName.js";
import Media from "../../../helpers/Media.js";
import Permission from "../../../helpers/Permission.js";

// API
import { endpoints } from "../../../api/endPoints.js";

// Lib
import Number from "../../../lib/Number.js";
import String from "../../../lib/String.js";
import Url from "../../../lib/Url.js";

// Services
import BillService from "../../../services/BillService.js";
import MediaService from "../../../services/MediaService.js";
import { hasPermission } from "../../../services/UserRolePermissionService.js";

// Actions
import { fetchList } from "../../../actions/table.js";

const billForm = (props) => {
  const {
    className,
    owner_id,
    editable,
    showAccountDetailsPageLink,
    accountId,
    showUserDetailsPageLink,
    userId,
    vendorId,
    billData,
    billId,
    vendorDetails,
    purchaseId,
    isEditPage,
    getBillDetail,
    apiUrl
  } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [billValue, setBillValue] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageurl, setImageUrl] = useState([]);
  const [otherDeductionAmount, setOtherDeductionAmount] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [cashDiscountPercentage, setCashDiscountPercentage] = useState("");
  const [cashDiscountAmount, setCashDiscountAmount] = useState("");
  const [rejectedItemAmount, setRejectedItemsAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [notesChange, setNotesChange] = useState();
  const [expiryReturnProductAmount, setExpiryReturnProductAmount] =
    useState("");
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [accountValue, setAccountValue] = useState({});
  const [netAmount, setNetAmount] = useState("");
  const [invoiceDate, SetInvoiceDate] = useState();
  const [vendorList, SetVendorList] = useState([]);
  const [billingNameOption, SetBillNameList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [ownerValue, setOwnerValue] = useState(null);
  const [grossAmount, setGrossAmount] = useState("");
  const [totalDeductionAmount, setTotalDeductionAmount] = useState("");

  // Props
  let isOwnerEditable = hasPermission(Permission.BILL_ALLOW_TO_CHANGE_OWNER);
  let isDueDateEditable = hasPermission(Permission.BILL_ALLOW_TO_CHANGE_DUE_DATE);
  const billDataRef = useRef({
    invoiceAmount: null,
    discountPercentage: null,
    cashDiscountAmountUse: null,
    otherDeductionAmount: null,
    rejectedProductAmount: null,
    expiryReturnProductAmount: null,
    paybleAmount: null,
    billId: billId ? billId : null,
  });

  useEffect(() => {
    if (selectedFiles) {
      getUrl();
    }
  }, [isLoading, selectedFiles]);

  // handleInvoiceAmount
  const handleInvoiceAmount = (e) => {
    const newValue = e.target.value ? e.target.value : 0;
    setInvoiceAmount(newValue);
    billDataRef.current.invoiceAmount = newValue;
    updateBillData();
  };

  // handleDiscountPerentage
  const handleCashDiscountPerentage = (e) => {
    const newValue = e.target.value ? e.target.value : 0;
    setCashDiscountPercentage(newValue);
    billDataRef.current.discountPercentage = newValue;
    updateBillData();
  };

  // handleOtherDeductionAmount
  const handleOtherDeductionAmount = (e) => {
    const newValue = e.target.value ? e.target.value : 0;
    setOtherDeductionAmount(newValue);
    billDataRef.current.otherDeductionAmount = newValue;
    updateBillData();
  };

  // handleRejectedProductAmount
  const handleRejectedProductAmount = (e) => {
    const newValue = e.target.value ? e.target.value : 0;
    setRejectedItemsAmount(newValue);
    billDataRef.current.rejectedProductAmount = newValue;
    updateBillData();
  };

  // handleTaxAmount
  const handleTaxAmount = (e) => {
    setTaxAmount(e.target.value);
  };

  // handleExpiryReturnedProductAmount
  const handleExpiryReturnedProductAmount = (e) => {
    const value = e.target.value ? e.target.value : 0;
    setExpiryReturnProductAmount(value);
    billDataRef.current.expiryReturnProductAmount = value;
    updateBillData();
  };

  // handleBillName
  const handleBillName = (e) => {
    let value = e && e.label ? e.label : "";
    setBillValue(value);
  };

  // handleDueDateChange
  const handleDueDateChange = (e) => {
    setDueDate(e);
  };

  const handleUserChange = (value) => {
    setOwnerValue(value && value?.id ? value?.id : "");
  };

  // onNetAmountChange
  const onNetAmountChange = (e) => {
    const value = e?.values?.net_amount;
    setNetAmount(value);
  };

  // invoiceDateChange
  const invoiceDateChange = (e) => {
    SetInvoiceDate(e);
  };

  // onInvoiceNumberChange
  const onInvoiceNumberChange = (e) => {
    const value = e.target.value;
    setInvoiceNumber(value);
  };

  // vendorId
  let account_id =
    accountValue && accountValue?.value
      ? accountValue?.value
      : billData && billData?.account_id
        ? billData?.account_id
        : (vendorDetails && vendorDetails?.vendorId) || "";

  // onAccountChange
  const onAccountChange = (e) => {
    const value = e;
    setAccountValue(value);
    setCashDiscountPercentage(e?.cash_discount);
    setBillValue(e?.billing_name);
    billDataRef.current.discountPercentage = e?.cash_discount ? e?.cash_discount : "";
    const data = paymentAmount * (e?.cash_discount / 100);
    billDataRef.current.cashDiscountAmountUse = data;
    setCashDiscountAmount(data);
    updateBillData();
  };

  // handleNotesChange
  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };
  let billingOption = billData && billData.billingOption ? billData.billingOption : billingNameOption || []

  // initialValues
  const initialValues = {
    bill_number: billData && billData.bill_number,
    invoice_date: invoiceDate
      ? invoiceDate
      :
      billData && billData.bill_date
    ,
    due_date: dueDate
      ? dueDate
      : billData?.due_date
        ?
        billData && billData?.due_date

        : "",

    account: vendorList.find((data) => data.value === account_id) || "",
    billing_name:
      billingOption.find(
        (value) =>
          value.label == (billValue !== undefined ? billValue : billData?.billing_name || vendorDetails?.billing_name)
      ),
    invoice_number:
      invoiceNumber == ""
        ? 0
        : invoiceNumber
          ? invoiceNumber
          : billData && billData.invoiceNumber,
    net_amount: netAmount
      ? Number.Float(netAmount)
      : billData?.netAmount
        ? Number.Float(billData.netAmount)
        : "",
    gstAmount: taxAmount
      ? Number.Float(taxAmount)
      : billData?.gstAmount
        ? Number.Float(billData.gstAmount)
        : "",
    otherDeductionAmount:
      otherDeductionAmount === 0
        ? ""
        : otherDeductionAmount || billData?.otherDeductionAmount || "",
    deductionAmount:
     totalDeductionAmount?totalDeductionAmount: billData?.deductionAmount || "",
    grossAmount:
      grossAmount?grossAmount:billData?.grossAmount || "",
    invoice_amount:
      invoiceAmount === 0
        ? ""
        : invoiceAmount
          ? Number.Float(invoiceAmount)
          : billData?.invoiceAmount || "",
    cashDiscountAmount:
      cashDiscountPercentage === 0
        ? ""
        : cashDiscountPercentage == null
          ? ""
          : cashDiscountAmount || billData?.cashDiscountAmount || "",
    cash_discount_percentage:
      cashDiscountPercentage === 0
        ? ""
        : cashDiscountPercentage == null
          ? ""
          : cashDiscountPercentage ||
          billData?.cashDiscountPercentage ||
          vendorDetails?.cash_discount ||
          "",
    rejectedProductAmount:
      rejectedItemAmount === 0
        ? ""
        : rejectedItemAmount || billData?.rejectedProductAmount || "",
    expiryReturnedProductAmount:
      expiryReturnProductAmount === 0
        ? ""
        : expiryReturnProductAmount ||
        billData?.expiryReturnedProductAmount ||
        "",
    owner: ownerValue
      ? userList && userList.find((data) => data.id == ownerValue)
      : (userList && userList.find((data) => data.id == billData?.owner_id)) ||
      "",
    notes: notesChange || billData?.notes || "",
  };

  // onDropImage
  const onDropImage = (images) => {
    handleImageValue({
      ...images,
      id: selectedFiles.length + 1,
    });
  };

  // handleImageValue
  const handleImageValue = (images) => {
    setSelectedFiles((prevFileList) => [...prevFileList, { ...images }]);
  };

  // handleImageRemove
  const handleImageRemove = (deletedvalue) => {
    setIsLoading(true);
    const updatedImageUrlArray = selectedFiles.filter(
      (item) => item.id !== deletedvalue.image_id
    );
    setSelectedFiles(updatedImageUrlArray);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  // getUrl
  const getUrl = () => {
    let url = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const imageUrl = URL.createObjectURL(file && file[0]);
      url.push({ url: imageUrl, image_id: file.id });
    }
    setImageUrl(url);
  };

  // updateBillData
  const updateBillData = () => {
    try {
      const invoiceAmountUse = billDataRef.current.invoiceAmount
        ? Number.Float(billDataRef.current.invoiceAmount)
        : Number.Float(billData?.invoiceAmount);

      const returnAmountUse =
        (billDataRef.current.expiryReturnProductAmount == "" ||
          billDataRef.current.expiryReturnProductAmount !== "") &&
          billDataRef.current.expiryReturnProductAmount !== null
          ? Number.Float(billDataRef.current.expiryReturnProductAmount)
          : billData?.expiryReturnedProductAmount
            ? Number.Float(billData?.expiryReturnedProductAmount)
            : 0;

      const rejectAmountUse =
        (billDataRef.current.rejectedProductAmount == "" ||
          billDataRef.current.rejectedProductAmount !== "") &&
          billDataRef.current.rejectedProductAmount !== null
          ? Number.Float(billDataRef.current.rejectedProductAmount)
          : billData?.rejectedProductAmount
            ? Number.Float(billData?.rejectedProductAmount)
            : 0;

      const otherDeductionAmountUse =
        (billDataRef.current.otherDeductionAmount == "" ||
          billDataRef.current.otherDeductionAmount !== "") &&
          billDataRef.current.otherDeductionAmount !== null
          ? Number.Float(billDataRef.current.otherDeductionAmount)
          : billData?.otherDeductionAmount
            ? Number.Float(billData?.otherDeductionAmount)
            : 0;


            let totalDeductionAmount = Number.Float(returnAmountUse) +Number.Float(rejectAmountUse) +Number.Float(otherDeductionAmountUse)
         setTotalDeductionAmount(totalDeductionAmount)
        setGrossAmount((Number.Float(invoiceAmountUse)-Number.Float(totalDeductionAmount)))
      const paybleAmountuse =
        invoiceAmountUse -
        (returnAmountUse + otherDeductionAmountUse + rejectAmountUse);
      setPaymentAmount(paybleAmountuse);

      let discountPercent =
        billDataRef &&
          (billDataRef.current.discountPercentage == "" ||
            billDataRef.current.discountPercentage !== "") &&
          billDataRef.current.discountPercentage !== null
          ? billDataRef.current.discountPercentage
          : billData && billData.cashDiscountPercentage
            ? billData && billData.cashDiscountPercentage
            : (vendorDetails && vendorDetails.cash_discount) || "";

      const data = paybleAmountuse * (discountPercent / 100);
      setCashDiscountAmount(data);
      let NetAmount = (discountPercent !== null && discountPercent !== "")
        ? paybleAmountuse - data
        : paybleAmountuse;

      setNetAmount(NetAmount);
    } catch (err) {
      console.log(err);
    }
  };

  //Upload Media
  const uploadFile = async (objectId, params, showToastMessage = false) => {
    try {
      if (selectedFiles && selectedFiles.length > 0 && objectId) {
        for (let i = 0; i < selectedFiles.length; i++) {
          const File = selectedFiles[i];

          const mediaFile = File ? File[0] : "";

          const media = File[0]?.name;

          const data = new FormData();

          if (mediaFile) {
            data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
          }
          if (media !== undefined) {
            data.append([Media.MEDIA_NAME], media ? media : "");
          }
          data.append("object", ObjectName.BILL);

          data.append("object_id", objectId);

          data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);

          const response = await MediaService.saveImage(data, showToastMessage);

          if (response) {
            dispatch(
              fetchList(
                "billId",
                apiUrl?apiUrl:`${endpoints().billAPI}/search`,
                1,
                25,
                params
              )
            );
          }

          if (response && response.id) {
            setSelectedFiles("");
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // handleSubmit
  const handleSubmit = async (id, values) => {
    try {
      props.setIsSubmit(true);
      const data = new FormData();
      data.append("date", values?.invoice_date);
      data.append(
        "billing_name",
        values && String.Get(values?.billing_name?.label)
      );
      data.append(
        "invoice_number",
        values && String.Get(values.invoice_number)
      );
      data.append("account_name", values && String.Get(values?.account?.label));
      data.append("account_id", values && values?.account?.value);
      data.append("net_amount", values.net_amount && values.net_amount);
      data.append(
        "cash_discount_amount",
        values?.cashDiscountAmount && values?.cashDiscountAmount
      );
      data.append(
        "cash_discount_percentage",
        values.cash_discount_percentage && values.cash_discount_percentage
      );
      data.append(
        "invoice_amount",
        values.invoice_amount && values?.invoice_amount
      );
      data.append(
        "otherDeductionAmount",
        values.otherDeductionAmount && values?.otherDeductionAmount
      );
      data.append(
        "due_date",
        values?.due_date ? (values.due_date) : ""
      );
      data.append(
        "rejectedProductAmount",
        values?.rejectedProductAmount && values?.rejectedProductAmount
      );

      data.append(
        "expiryReturnedProductAmount",
        values?.expiryReturnedProductAmount &&
        values?.expiryReturnedProductAmount
      );

      if (values && values?.gstAmount) {
        data.append("gstAmount", values?.gstAmount && values?.gstAmount);
      }
      data.append("notes", values.notes ? values.notes : "");
      data.append("owner", values.owner ? values.owner.id : "");

      const params = {
        ...Url.GetAllParams()
      };
      if (vendorId) {
        params.account_id = vendorId;
      }

      if (purchaseId) {
        params.purchase_id = purchaseId;
      }
      if (apiUrl) {
        params.apiUrl = apiUrl;
      }
      if (id) {
        dispatch(await BillService.update(id, data, params, () => {
          if (props && props.setStoreModalOpen) {
            props.setStoreModalOpen(false);
          }
          if (props && props.StoreSelectModal) {
            props.StoreSelectModal();
          }
          if (props && props.handleBillCloseModal) {
            props.handleBillCloseModal();
          }
          if (props && props.setEditable) {
            props.setEditable(true);
          }
          if(getBillDetail){
            getBillDetail &&  getBillDetail()
          }
        }));
      } else {
        dispatch(
          await BillService.create(data, params, props.setIsSubmit, (res) => {
            if (res) {
              dispatch(
                fetchList(
                  "billId",
                  apiUrl?apiUrl:`${endpoints().billAPI}/search`,
                  params.page ? params.page : 1,
                  params.pageSize ? params.pageSize : 25,
                  params
                )
              );
              uploadFile(res?.id, params);

              props.setStoreModalOpen(false);
              props.StoreSelectModal();
              props.handleBillCloseModal();
              setSelectedFiles("");
            } else {
              props.setIsSubmit(false);
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      props.setIsSubmit(false);
    }
  };

  return (
    <>
      {/* Form */}

      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          handleSubmit(billId, values);
        }}
        className="d-flex flex-column h-100"
      >
        <div className={props.form === "Add" || props.form === "Edit" ? `d-flex flex-column pb-5` : `d-flex flex-column pb-2`}>
          <div className={`${className ? className : "col-sm-12"} "card-body"`}>
            <AccountSelect
              handleVendorChange={onAccountChange}
              name="account"
              label="Account"
              required
              isDisabled={editable || vendorId}
              showAccountDetailsPageLink={showAccountDetailsPageLink}
              accountId={accountId}
              defaultValue={account_id}
              vendorList={SetVendorList}
            />
            <div className="row">
              <div className="col-12 col-sm-6">
                <Text
                  label="Invoice Number"
                  name="invoice_number"
                  onChange={onInvoiceNumberChange}
                  required
                  disabled={editable}
                />
              </div>
              <div className="col-12 col-sm-6">
                <DateSelector
                  label="Invoice Date"
                  required={true}
                  name="invoice_date"
                  disabled={editable}
                  onChange={invoiceDateChange}
                  dateOnly
                />
              </div>
            </div>

            <BillingNameSelect
              label="Billing Name"
              required
              name="billing_name"
              isDisabled={editable}
              handleChange={handleBillName}
              list={(value) => SetBillNameList(value)}
            />
            {!props.showBilldetails && (
              <>
                <Currency
                  name="invoice_amount"
                  label="Invoice Amount"
                  onChange={handleInvoiceAmount}
                  disabled={editable}
                />
              </>
            )}
            {props.showBilldetails && (
              <>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Currency
                      name="invoice_amount"
                      label="Invoice Amount"
                      onChange={handleInvoiceAmount}
                      disabled={editable}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Currency
                      label="Tax Amount"
                      name="gstAmount"
                      onChange={handleTaxAmount}
                      disabled={editable}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-12 col-sm-6">
                <Currency
                  label="Rejected Product Amount "
                  name="rejectedProductAmount"
                  onChange={handleRejectedProductAmount}
                  disabled={editable}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Currency
                  label="Expiry Returned Product Amount"
                  name="expiryReturnedProductAmount"
                  onChange={handleExpiryReturnedProductAmount}
                  disabled={editable}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Currency
                  label="Other Deduction Amount"
                  name="otherDeductionAmount"
                  onChange={handleOtherDeductionAmount}
                  disabled={editable}
                />
              </div>
            </div>
            {billId &&
              <div className="row">
                <div className="col-12 col-sm-6">
                  <Currency
                    label="Total Deduction Amount"
                    name="deductionAmount"
                    disabled={true}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <Currency
                    label="Gross Amount"
                    name="grossAmount"
                    disabled={true}
                  />
                </div>
              </div>
            }
            <div className="row">
              <div className="col-12 col-sm-6">
                <Percentage
                  label="Cash Discount %"
                  name="cash_discount_percentage"
                  onChange={handleCashDiscountPerentage}
                  disabled={editable}
                />
              </div>

              <div className="col-12 col-sm-6">
                <Currency
                  disabled={true}
                  label="Cash Discount Amount"
                  name="cashDiscountAmount"
                />
              </div>
            </div>

            <div>
              <Currency
                label="Net Amount"
                name="net_amount"
                required={true}
                onChange={onNetAmountChange}
                disabled={true}
              />
            </div>
            {!props.showBilldetails && (
              <DateSelector
                label="Due Date"
                name="due_date"
                onChange={handleDueDateChange}
                disabled={isEditPage ? (editable ? true : !isDueDateEditable) : billData ? !isDueDateEditable : false}
                dateOnly
              />
            )}

            {props.showBilldetails && (
              <>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <UserSelect
                      name="owner"
                      label="Owner"
                      selectedUserId={owner_id ? owner_id : null}
                      isDisabled={editable ? true : !isOwnerEditable}
                      showUserDetailsPageLink={showUserDetailsPageLink}
                      userId={userId}
                      handleUserChange={handleUserChange}
                      userList={setUserList}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <DateSelector
                      label="Due Date"
                      name="due_date"
                      onChange={handleDueDateChange}
                      disabled={isEditPage ? (editable ? true : !isDueDateEditable) : billData ? !isDueDateEditable : false}
                    />
                  </div>
                </div>
              </>
            )}

            <TextArea
              name="notes"
              label="Notes"
              placeholder="Enter Notes..."
              error=""
              fontBolded
              disabled={editable}
              onChange={handleNotesChange}
            />

            {props.form == "Add" && (
              <div className="col-12">
                <MediaCarousel
                  height="300px"
                  showCarasoul
                  Attachments
                  onDropImage={onDropImage}
                  imageUrl={imageurl}
                  handleImageRemove={handleImageRemove}
                  isLoading={isLoading}
                />
              </div>
            )}

            {billData && billData?.id && props.form == "Edit" && (
              <MediaCarousel
                showCarasoul
                height="300px"
                objectName={ObjectName.BILL}
                objectId={billData && billData.id}
                history={history}
                attachmentsList={true}
                Attachments={"Attachments"}
              />
            )}
          </div>
        </div>
        <div
          className={
            props.form === "Add" || props.form === "Edit"
              ? `position-absolute bottom-0 start-0 end-0 bg-white z-3`
              : ""
          }
          style={
            props.form === "Add" || props.form === "Edit"
              ? {
                borderTop: "1px solid #dee2e6",
                padding: "10px 10px"
              }
              : {}
          }
        >
          <div
            className={
              props.form === "Add" || props.form === "Edit"
                ? "d-flex justify-content-center"
                : "float-start"
            }
          >
            {!editable && (
              <div>
                <SaveButton type="submit" label="Save" />
                <CancelButton
                  onClick={() => {
                    if (props.form === "Add" || props.form === "Edit") {
                      props.handleCancel();
                    } else {
                      props.history.push("/bill");
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};
export default billForm;
