import React from "react";
import Select from "../../../components/Select";
import Text from "../../../components/Text";

// Components
import TextArea from "../../../components/TextArea";

// Constants
import DateSelector from "../../../components/Date";
import Currency from "../../../components/Currency";
import TagSelect from "../../../components/TagSelect";
import { TagTypeName } from "../../../helpers/Tag";
import AccountSelect from "../../../components/AccountSelect";

const AccountForm = (props) => {
  const { showAccountDetailsPageLink, accountId, isEditable, handelNotesChange, handelBankDescriptionChange, handelDescriptionChange, handleTypeChange } = props;
  //Account Type
  const type = [
    {
      value: "Credit",
      label: "Credit",
    },
    {
      value: "Debit",
      label: "Debit",
    },
  ];

  return (
    <>
      <div className="card-body">
        <DateSelector label="Date" name="date" required disabled={isEditable} />

        <Select label="Type" name="type" required={true} options={type} isDisabled={isEditable} handleChange={handleTypeChange}/>

        <Currency label="Amount" name="amount" required={true} disabled={isEditable} />

        <Select
          name="payment_account"
          label="Payment Account"
          placeholder="Select Account Name"
          options={props.bankList}
          required
          isDisabled={isEditable}
        />

        <Text
          className="w-100"
          name="bank_reference_number"
          label="Bank Reference Number"
          disabled={isEditable}
        />

        <TagSelect
          name="account_entry_category"
          label="Category"
          placeholder="Select Category"
          params={{ type: TagTypeName.ACCOUNT_ENTRY_CATEGORY }}
          isDisabled={isEditable}
        />

        <AccountSelect
          name="account"
          label="Account"
          placeholder="Select Account"
          showAccountDetailsPageLink={showAccountDetailsPageLink}
          accountId={accountId}
          isDisabled={isEditable}
        />

        <TextArea
          name="description"
          label="Description"
          placeholder="Enter Description..."
          disabled={isEditable}
          onChange={handelDescriptionChange}
        />

        <TextArea
          name="bank_description"
          label="Bank Description"
          placeholder="Enter Bank Description..."
          disabled={isEditable}
          onChange={handelBankDescriptionChange}
        />

        <TextArea name="notes" label="Notes" placeholder="Enter Notes..." disabled={isEditable} onChange={handelNotesChange}/>
      </div>
    </>
  );
};

export default AccountForm;
