import React, { useEffect, useRef, useState } from "react";

// Components
import PageTitle from "../../components/PageTitle";
import * as statusConstant from "../../components/constants/status";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, TabContent, TabPane } from "reactstrap";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import OutlineButton from "../../components/OutlineButton";
import Currency from "../../lib/Currency";
import Cookie, { getKeyValueByObject, isLoggedIn, setCookie } from "../../lib/Helper";
import Url from "../../lib/Url";
import UserService, { getUserRole } from "../../services/UserService";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddButton from "../../components/AddButton";

import AvatarCard from "../../components/AvatarCard";
import DateSelector from "../../components/Date";
import AddModal from "../../components/Modal";
import SaveButton from "../../components/SaveButton";
import SelectStore from "../../components/SelectStore";
import SelectType from "../../components/SelectType";
import ShiftSelect from "../../components/ShiftSelect";
import StatusText from "../../components/StatusText";
import Tab from "../../components/Tab";
import UserSelect from "../../components/UserSelect";
import ObjectName from "../../helpers/ObjectName";
import DateTime from "../../lib/DateTime";
import OrderService from "../../services/OrderService";
import OrderProductList from "./components/orderProductList";
import Action from "../../components/Action";
import OrderProductService from "../../services/OrderProductService";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";
import { Order } from "../../helpers/Order";
import jsPDF from "jspdf";
import CompanyService from "../../services/CompanyService";
import OrderStatementDownload from "./components/orderStatementDownload";
import Button from "../../components/Button";
import {
  getCompanySettings,
  getThemeList,
  saveSetting,
} from "../../services/SettingService";
import { Setting } from "../../helpers/Setting";
import OrderList from "./components/OrderList";
import Toast from "../../components/Toast";
import ToggleSwitch from "../../components/ToggleSwitch";
import { COOKIE_ORDER_LIST_AUTO_REFRESH, COOKIE_ORDER_PRODUCT_LIST_AUTO_REFRESH } from "../../lib/Cookie";
import ToggleButton from "../../components/ToggleSwitchButton";
import Avatar from "./components/avatar";
import orderTypeService from "../../services/OrderTypeService";
import { TODAY_VALUE } from "../../helpers/Date";

// Tabs Constants
export const Tabs = {
  ACTIVE: "Active",
  ALL: "All",
  DRAFT: "Draft",
  COMPLETED: "Completed",
  ORDERS: "Orders",
  ORDER_PRODUCTS: "Order Products",
  DELIVERY_ORDER_PRODUCTS: "Delivery Order Product",
};

const Orders = (props) => {
  const { history } = props;
  const [activeTab, setActiveTab] = useState(
    Tabs.ORDERS
  );
  const [isOpen, setIsOpen] = useState(false);
  const [orderIds, setOrderIds] = useState([]);
  const [userList, setUserOption] = useState([]);
  const [orderProductIds, setOrderProductIds] = useState([]);
  const [orderInvoiceDetails, setOrderInvoiceDetails] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const [companyLogo, setCompanyLogo] = useState();
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [selectCheckBox, setSelectCheckBox] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  let enableProductEdit = hasPermission(Permission.ORDER_PRODUCT_EDIT);

  let pathName = props?.history?.location?.pathname.replace(/\/\d+$/, "");


  const dispatch = useDispatch();

  useEffect(() => {
    isLoggedIn();
    getUserList();
    fetchCompanyDetail();
    getSettings();
    getOrderTypes()
  }, []);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  const handleToggleChange = () => {
    setAutoRefresh(!autoRefresh)
    setCookie(COOKIE_ORDER_LIST_AUTO_REFRESH, !autoRefresh);

  };

  const handleOrderProductChange = () => {
    setAutoRefresh(!autoRefresh)
    setCookie(COOKIE_ORDER_PRODUCT_LIST_AUTO_REFRESH, !autoRefresh);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const _handleTabChange = (tab) => {
      props.history.push(
        `/orders?tab=${tab}&&date=${TODAY_VALUE}`
      );
  };


  const getOrderTypes=async ()=>{
    let response = await orderTypeService.list();
    let data = response && response?.data
  setOrderTypes(data)
  }

  const fetchCompanyDetail = async () => {
    let data = await CompanyService.get();
    if (data) {
      setCompanyName(data.company_name);
    }
  };
  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let portalLogo =
      settingData &&
      getKeyValueByObject(settingData, Setting.SETTING_PORTAL_LOGO_MEDIA_URL);
    setCompanyLogo(portalLogo);
  };

  const createPdf = async () => {
    if (orderIds && orderIds?.orderIds && orderIds?.orderIds.length > 0) {
      let data = new FormData();

      data.append("orderIds", orderIds?.orderIds);
      const response = await OrderService.getBySelectedIds(data);
      setOrderInvoiceDetails(response && response.data && response.data.data);
    }
    const pdf = new jsPDF({
      format: "a4",
      unit: "pt",
    });
    const element = document.getElementById("download");
    const html = element.innerHTML;
    pdf.html(html, {
      callback: function (pdf) {
        const today = new Date();
        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = today.toLocaleDateString("en-US", options);
        pdf.save(`Order Invoice ${formattedDate}.pdf`);
      },
    });
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <Avatar
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
        <div className="m-2">
          {firstName} {lastName}
        </div>
      </div>
    );
  };

  const getUserList = async () => {
    try{
    const response = await UserService.list();
    const userList = response && response?.data;
    const data = [];
    userList &&
      userList.length > 0 &&
      userList.forEach((list) => {
        data.push({
          label: getUserName(list.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setUserOption(data)
    }catch(err){
      console.log(err);
    }
  };

  const onBulkSelect = (ids) => {
    setOrderIds({ orderIds: ids });
  };

  const paymentType = [
    {
      label: "Cash",
      value: 1,
    },
    {
      label: "Upi",
      value: 2,
    },
  ];


  let bulkUpdateForm = (
    <>
      <DateSelector label="Date" name="date" />
      <SelectStore name="location" label="Location" />
      <UserSelect
        label="owner"
        name="owner"
        customUserOption={userList}
      />
      <ShiftSelect label="Shift" />
      <SelectType
        customTypeOption={paymentType}
        name="paymentType"
        label="Payment Type"
      />
       <SelectType
        customTypeOption={orderTypes}
        name="type"
        label="Type"
      />
    </>
  );

  let bulkUpdateFooter = <SaveButton />;

  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("date", values && values?.date ? values?.date : "");
    data.append(
      "location",
      values && values?.location?.value ? values?.location?.value : ""
    );
    data.append(
      "shift",
      values && values?.shift?.value ? values?.shift?.value : ""
    );
    data.append(
      "owner",
      values && values?.owner?.id
        ? values?.owner?.id
        : ""
    );
    data.append(
      "paymentType",
      values && values?.paymentType?.value ? values?.paymentType?.value : ""
    );
    data.append(
      "type",
      values && values?.type?.value ? values?.type?.value : ""
    );
    data.append("orderIds", orderIds?.orderIds);

    dispatch(await OrderService.bulkUpdate(data, (res) => {
      if (res) {
        toggle()
        dispatch(
          fetchList(
            "Orders",
            `${endpoints().orderAPI}/search`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            {
              sort: Url.GetParam("sort"),
              sortDir: Url.GetParam("sortDir"),
              shift: Url.GetParam("shift"),
              location: Url.GetParam("location"),
              startDate: Url.GetParam("startDate"),
              endDate: Url.GetParam("endDate"),
              status: Url.GetParam("status"),
              user: Url.GetParam("user"),
              paymentType: Url.GetParam("paymentType"),
              search: Url.GetParam("search"),
              type: Url.GetParam("type"),
              date: Url.GetParam("date"),
              showTotalAmount: true
            }
          )
        )
      }
    }));
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
    setOrderIds("");
  };

  const handleUpdateFromProduct = async () => {
    let data = new FormData();
    data.append(
      "orderProductIds",
      orderProductIds && JSON.stringify(orderProductIds)
    );
    dispatch(
      await OrderProductService.bulkUpdateFromProduct(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "OrderProduct",
              `${endpoints().orderProductAPI}/search`,
              1,
              25,
              {
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                startDate: Url.GetParam("startDate"),
                endDate: Url.GetParam("endDate"),
                brand: Url.GetParam("brand"),
                category: Url.GetParam("category"),
                category: Url.GetParam("category"),
                product: Url.GetParam("product"),
                location: Url.GetParam("location"),
                status: Url.GetParam("status"),
              }
            )
          );
          setSelectCheckBox(false);
          setSelectCheckBox(true);
          setOrderProductIds({ orderProductIds: [] });
        }
      })
    );
  };

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
    {
      value: "Create Statement",
      label: "Create Statement",
    },
  ];

  const handleActionChange = (e) => {
    if (!orderIds || !orderIds.orderIds || orderIds.orderIds.length === 0) {
      Toast.error("Select the Order");
      return;
    }
    if (e == "Bulk Update") {
      toggle();
    } else if (e == "Create Statement") {
      createPdf();
    }
  };

  const actionOptions = [
    {
      label: "Update From Product",
      value: "Update From Product",
    },
  ];

  const handleChange = async (e) => {
    if (e == "Update From Product") {
      handleUpdateFromProduct();
    }
  };

  const handleBulkSelect = (ids) => {
    setOrderProductIds({ orderProductIds: ids });
  };

  const handleChanges =(type)=>{
      history.push(`/location/orders/details?type=${type}`);
  }

  let renderStoreTable = (tableId, params) => {
    return (
      <>
        <OrderList
          params={params}
          history={props.history}
          onBulkSelect={onBulkSelect}
          pathName={pathName}
          selectedCheckBox={selectedCheckBox}
          bulkSelect
          autoRefresh={autoRefresh}
          orderTypeOption={orderTypes && orderTypes}
        />
      </>
    );
  };

  return (
    <>
      <AddModal
        modalTitle="Bulk Update"
        modalBody={bulkUpdateForm}
        modalFooter={bulkUpdateFooter}
        isOpen={isOpen}
        toggle={toggle}
        toggleModalClose={toggle}
        initialValues={{
          date: "",
          location: "",
          owner: "",
          shift: "",
          paymentType: "",
        }}
        onSubmit={handleSubmit}
        hideDefaultButtons
      />
      <div id="download" className="d-none">
        <OrderStatementDownload
          orderInvoiceDetails={orderInvoiceDetails}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      </div>
      <div className="d-flex justify-content-between pb-3">
        <PageTitle
          label={"Orders"}
        />

        <div className="d-flex">
          {activeTab === Tabs.ORDERS ? (
            <>
              <div className="pe-2">
                <ToggleButton
                  toggleOffLabel={"Auto Refresh"}
                  handleToggle={() => handleToggleChange()}
                  LabelfontSize={12}
                  toggled={Cookie.get(COOKIE_ORDER_LIST_AUTO_REFRESH) === "true"}
                />
              </div>
              <div>
                <Action
                dropdownLinks={orderTypes}
                handleChange={(e) => handleChanges(e)}
                buttonLabel={"New Order"}
              />
              </div>
              <div className="ps-2">
                <Action
                  dropdownLinks={actionsMenuList}
                  handleChange={handleActionChange}
                />
              </div>
            </>
          ) : (
            ""
          )}
          {activeTab === Tabs.ORDER_PRODUCTS && enableProductEdit && (
            <>
              <ToggleButton
                toggleOffLabel={"Auto Refresh"}
                handleToggle={() => handleOrderProductChange()}
                LabelfontSize={12}
                toggled={
                  Cookie.get(COOKIE_ORDER_PRODUCT_LIST_AUTO_REFRESH) === "true"
                }
              />
              <div className="ps-3">
                <Action
                  dropdownLinks={actionOptions}
                  handleChange={handleChange}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Nav tabs className="admin-tabs mb-1">
        <Tab
          name={
             Tabs.ORDERS
          }
          active={activeTab}
          navigation={
            pathName == "/deliveryOrders"
              ? "/deliveryOrders?tab=DeliveryOrders"
              : "/orders?tab=Orders"
          }
          count={props.orderCount}
          handleChange={(e) => _handleTabChange(e)}
          toggle={toggleTab}
        />
        <Tab
          name={
            pathName == "/deliveryOrders"
              ? Tabs.DELIVERY_ORDER_PRODUCTS
              : Tabs.ORDER_PRODUCTS
          }
          active={activeTab}
          count={props.orderProductCount}
          handleChange={(e) => _handleTabChange(e)}
          toggle={toggleTab}
          navigation={
            pathName == "/deliveryOrders"
              ? "/deliveryOrders?isWeb=true&tab=DeliveryOrderProduct"
              : "/orders?isWeb=true&tab=OrderProduct"
          }
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* Order Tab */}
        {activeTab == Tabs.ORDERS ? (
          <TabPane
            tabId={
             Tabs.ORDERS
            }
          >
            {renderStoreTable(Tabs.ORDERS, {
              tab: Tabs.ORDERS,
              objectName: ObjectName.ORDER_TYPE,
              showTotalAmount: true,
              showBackgroundColor: true,
            })}
          </TabPane>
        ) : (
          ""
        )}

        {/* Order Product List */}
        {activeTab == Tabs.ORDER_PRODUCTS ||
          activeTab == Tabs.DELIVERY_ORDER_PRODUCTS ? (
          <TabPane
            tabId={
              pathName == "/deliveryOrders"
                ? Tabs.DELIVERY_ORDER_PRODUCTS
                : Tabs.ORDER_PRODUCTS
            }
          >
            <OrderProductList
              history={history}
              handleBulkSelect={handleBulkSelect}
              selectedCheckBox={selectCheckBox}
              autoRefresh={autoRefresh}
            />
          </TabPane>
        ) : (
          ""
        )}
      </TabContent>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}
// Map State to props
const mapStateToProps = (state) => {
  const { orders } = state.table;
  const reduxTable = state.table;

  const orderCount =
    reduxTable[Tabs.ORDERS] && reduxTable[Tabs.ORDERS].isFetching == false
      ? reduxTable[Tabs.ORDERS].totalCount
      : 0;

  const orderProductCount =
    reduxTable["OrderProduct"] && reduxTable["OrderProduct"].isFetching == false
      ? reduxTable["OrderProduct"].totalCount
      : 0;

  const sort = orders && !orders.isFetching ? orders.sort : statusConstant.SORT;

  const sortDir =
    orders && !orders.isFetching ? orders.sortDir : statusConstant.SORT_DIR;

  const pageSize =
    orders && !orders.isFetching ? orders.pageSize : statusConstant.PAGESIZE;

  const currentPage =
    orders && !orders.isFetching ? orders.currentPage : statusConstant.PAGE;

  const search = orders && !orders.isFetching ? orders.search : "";

  const status =
    orders && !orders.isFetching ? orders.status : statusConstant.ACTIVE;

  return {
    sort,
    sortDir,
    pageSize,
    currentPage,
    search,
    status,
    orderCount,
    orderProductCount,
  };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Orders);
