import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import CsvDownload from "react-csv-downloader";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
import AddModal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import Select from "../../components/Select";
import AddButton from "../../components/AddButton";
import StatusSelect from "../../components/SelectStatus";
import DeleteModal from "../../components/DeleteModal";
import toast from "../../components/Toast";
import TagSelect from "../../components/TagSelect";
import AccountSelect from "../../components/AccountSelect";
import BillList from "./components/billList";
import Action from "../../components/Action";
import SaveButton from "../../components/SaveButton";
import Drawer from "../../components/Drawer";
import AccountForm from "./components/accountEntryForm";
import MoreDropdown from "../../components/authentication/moreDropdown";
import Amount from "../../components/Currency";
import CancelButton from "../../components/CancelButton";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Currency from "../../lib/Currency";
import DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";
import ArrayList from "../../lib/ArrayList";
import Url from "../../lib/Url";

// Services
import PaymentAccountService from "../../services/PaymentAccountService";
import AccountEntryService from "../../services/AccountEntryService";
import TagService from "../../services/TagService";
import TagTypeService from "../../services/TagTypeService";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { TagTypeName } from "../../helpers/Tag";

// Actions
import { fetchList } from "../../actions/table";

const AccountEntry = (props) => {
  const { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [bankList, setBankList] = useState();
  const [accountEntryDetails, setAccountEntryDetails] = useState();
  const [tagId, setTypeId] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isBillModel, setIsBillModel] = useState();
  const [row, setRow] = useState("");
  const [tag, setTag] = useState("");
  const [paymentAccount, setPaymentAccount] = useState("");
  const [isSubmit, setIsSubmit] = useState(true);
  const [isBulkModelOpen, setIsBulkModelOpen] = useState(false);
  const [bulkSelectIds, setBulkSelectIds] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [accountEntryDeleteModal, setAccountEntryDeleteModal] = useState(false);
  const [accountEntryData, setAccountEntryData] = useState("");
  const [data, setData] = useState();

  let hiddenFileInput = React.useRef(null);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  useEffect(() => {
    getBankList();
    getProductList();
    getTags();
    getTagType();
  }, []);

  const typeList = [
    {
      value: "Credit",
      label: "Credit",
    },
    {
      value: "Debit",
      label: "Debit",
    },
  ];

  const getBankList = async () => {
    PaymentAccountService.getList((response, err) => {
      const bankDetails = response?.data?.data;
      let bankList = [];
      if (bankDetails) {
        bankDetails.forEach((bank) => {
          bankList.push({
            label: bank.payment_account_name,
            value: bank.id,
          });
          setBankList(bankList);
        });
        setBankList(bankList);
      }
    });
  };

  // Get AccountEntry List
  const getProductList = async () => {
    AccountEntryService.getAccountEntry((response, err) => {
      let AccountEntryList = [];
      const accountEntryDetails = response?.data?.data;

      if (accountEntryDetails) {
        accountEntryDetails.forEach((accountEntry) => {
          AccountEntryList.push({
            id: accountEntry.id,
            bank: accountEntry.bank,
            description: accountEntry.description,
            amount: accountEntry.amount,
            unit: accountEntry.unit,
            notes: accountEntry.notes,
            status: accountEntry.status,
            type: accountEntry.type,
            date: accountEntry.date,
          });
        });
      }
      setAccountEntryDetails(AccountEntryList);
    });
  };

  const getTags = async () => {
    let tagList = [];
    let params = { type: TagTypeName.ACCOUNT_ENTRY_CATEGORY };
    const response = await TagService.list(params);
    const tag = response.data.data;
    if (ArrayList.isNotEmpty(tag)) {
      tag.forEach((tag) => {
        tagList.push({
          id: tag.id,
          value: tag.id,
          label: tag.name,
        });
      });
    }
    setCategoryList(tagList);
  };

  //   Get Status List
  const getTagType = async () => {
    const response = await TagTypeService.search(
      TagTypeName.ACCOUNT_ENTRY_CATEGORY
    );
    const userRole = response && response?.data && response?.data?.data;
    let id = [];
    if (userRole && userRole.length > 0) {
      for (let i = 0; i < userRole.length; i++) {
        const values = userRole[i];
        id.push(values?.id);
      }
    }
    setTypeId(id);
  };

  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "date:DESC",
      label: "Date",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  /**
   * Create Page
   *
   * @param data
   */
  const handleCreate = async (values) => {
    setIsSubmit(false);
    try {
      let amount = values.amount.slice(1);
      const datas = new FormData();
      datas.append("payment_account", values?.payment_account?.value);
      datas.append("date", DateTime.toISOStringDate(values?.date));

      datas.append("amount", isFinite(values.amount) ? values.amount : amount);
      datas.append("type", values?.type.value);

      datas.append("notes", values?.notes);
      datas.append("description", values.description);
      datas.append(
        "account_entry_category",
        Number.Get(values?.account_entry_category?.value)
      );
      datas.append("account", Number.Get(values?.account?.value));

      datas.append("bank_reference_number", values?.bank_reference_number);

      datas.append("bank_description", values?.bank_description);

      if (data?.id) {
        dispatch(await AccountEntryService.update(data?.id, datas, params));
        toggle();
        setIsSubmit(true);
      } else {
        dispatch(
          await AccountEntryService.create(datas, setIsSubmit, params, (res) => {
            if (res) {
              dispatch(
                fetchList(
                  "accountEntry",
                  `${endpoints().accountEntryAPI}/list`,
                  params.page ? params.page : 1,
                  params.pageSize ? params.pageSize : 25,
                  {
                    ...params,
                    showTotal: true
                  }
                )
              );
              toggle();
            }
          })
        );

        setIsSubmit(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function convertObjectKeysToLowercase(obj) {
    const convertedObject = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const lowercaseKey = key.toLowerCase();
        convertedObject[lowercaseKey] = obj[key];
      }
    }

    return convertedObject;
  }

  let params = {
    sort: Url.GetParam("sort"),
    search: Url.GetParam("search"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    date: Url.GetParam("date"),
    type: Url.GetParam("type"),
    tag: Url.GetParam("tag"),
    account: Url.GetParam("account"),
    paymentAccount: Url.GetParam("paymentAccount"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate")
  }

  const handleChange = async (id, values) => {
    try {
      const data = new FormData();
      data.append(
        "account_entry_category",
        Number.Get(values?.values?.account_entry_category?.value)
      );

      dispatch(await AccountEntryService.update(id, data, params));
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAccountEntry = async (id) => {
    dispatch(await AccountEntryService.delete(id, history, params));
  };

  const handleAccountChange = async (id, values) => {
    try {
      const data = new FormData();
      data.append("account", Number.Get(values?.value));
      // data.append("account_entry_category", Number.Get(tag));
      dispatch(await AccountEntryService.update(id, data, params));
    } catch (err) {
      console.log(err);
    }
  };

  const handleActionMenuChange = (selectedMenu) => {
    setPaymentAccount(selectedMenu);
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const billList = (
    <>
      <BillList toggle={setIsBillModel} row={row} />{" "}
    </>
  );

  const addAccountEntryForm = (
    <AccountForm
      bankList={bankList}
      showAccountDetailsPageLink={data?.id && data?.account_id ? true : false}
      accountId={data?.id ? data.account_id : null}
    />
  );

  const accountEntryFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={data?.id ? "Save" : "Add"}
    />
  );

  const AddBillModal = () => {
    setIsBillModel(!isBillModel);
  };

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
    {
      value: "Import",
      label: "Import",
    },
    {
      value: "Export",
      label: "Export",
    },
  ];

  const type = [
    {
      value: "Credit",
      label: "Credit",
    },
    {
      value: "Debit",
      label: "Debit",
    },
  ];

  const handleActionChange = (e) => {
    if (e === "Bulk Update") {
      if (bulkSelectIds && bulkSelectIds.length > 0) {
        setIsBulkModelOpen(true);
      }
      else {
        toast.error("Select Account Entry");
      }
    } else if (e === "Import") {
      if (bulkSelectIds && bulkSelectIds.length > 0) {
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
      }
      else {
        toast.error("Select Account Entry");
      }
    } else if (e === "Export") {
      if (bulkSelectIds && bulkSelectIds.length > 0) {
        const csvDownloadElement = document.getElementById("csvDownload");
        setSelectedCheckBox(false);
        if (csvDownloadElement) {
          csvDownloadElement.click();
          setBulkSelectIds([]);
          setTimeout(() => {
            setSelectedCheckBox(true);
          }, 0);
        }
      }
      else {
        toast.error("Select Account Entry");
      }
    }
  };

  const bulkUpdateForm = (
    <>
      <Select label="Type" name="type" options={type} />
      <Amount label="Amount" name="amount" />
      <Select
        name="payment_account"
        label="Payment Account"
        placeholder="Select Account Name"
        options={bankList}
      />
      <StatusSelect
        label="Status"
        name="status"
        objectName={ObjectName.ACCOUNT_ENTRY}
      />
      <TagSelect
        name="account_entry_category"
        label="Category"
        placeholder="Select Category"
        params={{ type: TagTypeName.ACCOUNT_ENTRY_CATEGORY }}
      />
      <AccountSelect
        name="account"
        label="Account"
        placeholder="Select Account"
      />
      <TextArea name="notes" label="Notes" placeholder="Enter Notes..." />
    </>
  );

  let bulkUpdateFooter = (
    <>
      <CancelButton
        onClick={() => {
          bulkCloseToggle();
        }}
      />
      <SaveButton />
    </>
  );

  let initialValues = {
    type: "",
    amount: "",
    payment_account: "",
    status: "",
    account_entry_category: "",
    account: "",
    notes: "",
  };

  const bulkCloseToggle = () => {
    setIsBulkModelOpen(!isBulkModelOpen);
  };

  const handleBulkSelect = (e) => {
    setBulkSelectIds(e);
  };

  const handleBulkUpdate = async (values) => {
    let data = new FormData();

    if (values?.account && values?.account !== "") {
      data.append("account", values?.account?.value);
      data.append("accountName", values?.account?.label);
    }

    if (
      values?.account_entry_category &&
      values?.account_entry_category !== ""
    ) {
      data.append(
        "account_entry_category",
        values?.account_entry_category?.value
      );
      data.append(
        "account_entry_category_name",
        values?.account_entry_category?.label
      );
    }

    if (values?.amount && values?.amount !== "") {
      data.append("amount", values?.amount);
    }

    if (values?.notes && values?.notes !== "") {
      data.append("notes", values?.notes);
    }

    if (values?.payment_account && values?.payment_account !== "") {
      data.append("payment_account", values?.payment_account?.value);
      data.append("payment_account_name", values?.payment_account?.label);
    }

    if (values?.status && values?.status !== "") {
      data.append("status", values?.status?.value);
      data.append("statusName", values?.status?.label);
    }

    if (values?.type && values?.type !== "") {
      data.append("type", values?.type?.value);
      data.append("typeName", values?.type?.label);
    }

    dispatch(
      await AccountEntryService.bulkUpdate(bulkSelectIds, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "accountEntry",
              `${endpoints().accountEntryAPI}/list`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                search: Url.GetParam("search"),
                page: Url.GetParam("page"),
                pageSize: Url.GetParam("pageSize"),
                showTotal: true
              }
            )
          );
          bulkCloseToggle();
          setBulkSelectIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      })
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={accountEntryDeleteModal}
        toggle={() => {
          setAccountEntryDeleteModal(false);
        }}
        title="Delete Account Entry"
        id={accountEntryData.id}
        label={accountEntryData.account_entry_number}
        deleteFunction={() => {
          deleteAccountEntry(accountEntryData.id);
        }}
      />
      <AddModal
        isOpen={isBulkModelOpen}
        toggleModalClose={bulkCloseToggle}
        modalTitle="Bulk Update"
        modalBody={bulkUpdateForm}
        modalFooter={bulkUpdateFooter}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleBulkUpdate(values);
        }}
        style={{ maxWidth: "800PX" }}
        hideDefaultButtons
      />
      <Drawer
        modelTitle={data?.id ? "Edit Account Entry" : "Add Account Entry"}
        DrawerBody={addAccountEntryForm}
        DrawerFooter={accountEntryFooter}
        onSubmit={(values) => {
          handleCreate(values);
        }}
        initialValues={{
          payment_account:
            bankList &&
            bankList.find((data1) => data1?.value === data?.paymentAccountId),

          date: data
            ? DateTime.getDateTimeByUserProfileTimezone(data?.date)
            : "",
          amount: data ? data?.amount : "",
          type: data
            ? {
              label: data?.type,
              value: data?.type,
            }
            : "",
          description: data ? data?.description : "",
          account_entry_category: data
            ? {
              label: data?.account_category,
              value: data?.account_entry_category_id,
            }
            : "",
          account: data
            ? {
              label: data?.account,
              value: data?.accoun_id,
            }
            : "",
          notes: data ? data.notes : "",

          bank_reference_number: data ? data.bank_reference_number : "",

          bank_description: data ? data.bank_description : "",
        }}
        enableReinitialize
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
      // buttonLabel={buttonLabel}
      />

      <AddModal
        isOpen={isBillModel}
        toggle={AddBillModal}
        toggleModalClose={AddBillModal}
        modalTitle="Add Bill"
        hideDefaultButtons
        modalBody={billList}
        initialValues={{
          bill_id: "",
        }}
        enableReinitialize={true}
      />
      <CsvDownload
        filename="transferProducts.csv"
        id="csvDownload"
        extension=".csv"
        datas={accountEntryDetails}
        style={{ display: "none" }}
      />

      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Account Entry" />
        <div className="d-flex">
          <AddButton
            className="ms-2 me-1"
            label="Add New"
            onClick={(e) => {
              setData("");
              toggle();
            }}
          />
          <div className="ms-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <ReduxTable
        id="accountEntry"
        showHeader
        newTableHeading
        apiURL={`${endpoints().accountEntryAPI}/list`}
        sortByOptions={sortByOption}
        searchPlaceholder="Search"
        paramsToUrl={true}
        history={history}
        params={{ showTotal: true }}
        showCustomDateFilter
        showTagFilter
        tagPlaceholder="Select Category"
        showPaymentAccountFilter
        showTypeFilter
        showAccountFilter
        customTypeOption={typeList}
        customTagOption={categoryList}
        bulkSelect
        onBulkSelect={handleBulkSelect}
        selectedCheckBox={selectedCheckBox}
        showBackgroundColor
      >
        <ReduxColumn
          sortBy="account_entry_number"
          isClickable="true"
          type="link"
          minWidth="170px"
          renderField={(row) => (
            <Link
              to={`/accountEntry/details/${row.id}`}
              className="link-opacity-75"
            >
              {row.account_entry_number}
            </Link>
          )}
        >
          AccountEntry#
        </ReduxColumn>
        <ReduxColumn field="date" sortBy="date" minWidth="170px">
          Date
        </ReduxColumn>
        <ReduxColumn field="type" sortBy="type" minWidth="170px">
          Type
        </ReduxColumn>
        <ReduxColumn field="description" sortBy="description" minWidth="170px">
          Description
        </ReduxColumn>
        <ReduxColumn
          field="bank_description"
          sortBy="bank_description"
          minWidth="170px"
        >
          Bank Description
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          sortBy="amount"
          renderField={(row) => (
            <span className="float-end">{Currency.Format(row.amount)}</span>
          )}
          minWidth="170px"
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="payment_account"
          sortBy="payment_account"
          minWidth="170px"
        >
          Payment Account
        </ReduxColumn>
        <ReduxColumn
          field="bank_reference_number"
          sortBy="bank_reference_number"
          minWidth="170px"
        >
          Bank Reference Number
        </ReduxColumn>

        <ReduxColumn
          field="account_category"
          sortBy="name"
          className="tezt-nowrap"
          minWidth="210px"
        >
          Category
        </ReduxColumn>

        <ReduxColumn
          field="bill_id"
          sortBy="bill_id"
          className="text-center"
          minWidth="170px"
          renderField={(row) => (
            <>
              {row?.id &&
                (row.bill_id ? (
                  row.bill_id
                ) : (
                  <span
                    className="cursor-pointer text-decoration-underline"
                    style={{ color: "blue" }}
                    onClick={() => {
                      setRow(row);
                      AddBillModal();
                    }}
                  >
                    Select
                  </span>
                ))}
            </>
          )}
        >
          Bill#
        </ReduxColumn>
        <ReduxColumn
          field="account"
          sortBy="account"
          className="text-center"
          minWidth="210px"
        >
          Account
        </ReduxColumn>
        <ReduxColumn
          field="status"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase mx-auto ${row.status ? "bg-success" : ""
                }`}
            >
              <p>{row.status}</p>
            </div>
          )}
          sortBy="status"
          minWidth="170px"
        >
          Status
        </ReduxColumn>
        <ReduxColumn field="notes" sortBy="notes" minWidth="170px">
          Notes
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setData(row);
                      toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setAccountEntryDeleteModal(true);
                      setAccountEntryData(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default AccountEntry;
