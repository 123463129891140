import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { fetchList } from "../../../actions/table";
import { endpoints } from "../../../api/endPoints";
import DateSelector from "../../../components/Date";
import Drawer from "../../../components/Drawer";
import SaveButton from "../../../components/SaveButton";
import Spinner from "../../../components/Spinner";
import TextArea from "../../../components/TextArea";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";
import AgreementService from "../../../services/AgreementService";

const buttonLabel = true;

const AccountAgreementList = (props) => {
  const {
    history,
    paymentTab,
    isOpen,
    toggles,
    handleOpenModal,
    handleCloseModal,
    setRowValue,
    rowValue = null,
    setDetail,
    detail,
    setIsSubmitting,
    isSubmitting,
    setOpenDeleteModal,
    apiUrl,
    vendorDetails,
    agreementDetail,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [renewalDate, setRenewalDate] = useState();
  const [agreementTerms, setAgreementTerms] = useState();
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  useEffect(() => {
    getDetails();
  }, [rowValue]);

  const getDetails = async () => {
    const data = await AgreementService.get(rowValue?.id);
    if (data) {
      setDetail(data);
    }
  };

  let vendorId = vendorDetails?.data?.vendorId;

  const _toggle = () => {
    setRowValue("");
    handleCloseModal();
    setStartDate("");
    setEndDate("");
    setRenewalDate("");
    setAgreementTerms("");
    setDetail("");
  };

  const initialValues = {
    agreement_start_date: startDate
      ? startDate
      : rowValue?.agreement_start_date
        ? rowValue?.agreement_start_date
        : "",
    agreement_end_date: endDate
      ? endDate
      : rowValue?.agreement_end_date
        ? rowValue?.agreement_end_date
        : "",
    agreement_renewal_date: renewalDate
      ? renewalDate
      : rowValue?.agreement_renewal_date
        ? rowValue?.agreement_renewal_date
        : "",
    agreement_term: agreementTerms
      ? agreementTerms
      : rowValue?.agreement_term
        ? rowValue?.agreement_term
        : agreementDetail?.agreement_term ? agreementDetail?.agreement_term : "",
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(false);
      const data = new FormData();
      let param = {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        startDate: Url.GetParam("startDate"),
        endDate: Url.GetParam("endDate"),
        status: Url.GetParam("status"),
      };

      if (rowValue && rowValue?.id) {
        data.append(
          "agreement_start_date",
          new Date(values.agreement_start_date)
        );
        data.append("vendorId", vendorId);
        data.append("agreement_end_date", new Date(values.agreement_end_date));
        data.append(
          "agreement_renewal_date",
          new Date(values.agreement_renewal_date)
        );
        data.append("agreement_term", values && values.agreement_term);

        setIsLoading(true);
        await AgreementService.update(
          rowValue?.id,
          data,
          setIsSubmitting,
          (res) => {
            if (res) {
              handleCloseModal();
              dispatch(
                fetchList(
                  "accountAgreement",
                  `${endpoints().accountAgreementAPI}/search`,
                  1,
                  25,
                  valueProps
                )
              );
            }
          }
        );
        setIsLoading(false);
        setDetail("");
        setAgreementTerms("");
      } else {
        data.append(
          "agreement_start_date",
          new Date(values.agreement_start_date)
        );
        data.append("agreement_end_date", values && values.agreement_end_date);
        data.append(
          "agreement_renewal_date",
          values && values.agreement_renewal_date
        );
        data.append("vendorId", vendorId);
        data.append("agreement_term", values && values.agreement_term);

        dispatch(
          await AgreementService.create(data, param, toggles, (response) => {
            setIsLoading(true);
            if (response) {
              setIsLoading(false);
              setIsSubmitting(true);
              setDetail("");
              setAgreementTerms("");
              handleCloseModal();
              _toggle();
              // Update the list after successful creation
              dispatch(
                fetchList(
                  "accountAgreement",
                  `${endpoints().accountAgreementAPI}/search`,
                  1,
                  25,
                  valueProps
                )
              );
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartDate = async (startDate) => {
    const date = startDate ? DateTime.toISOStringDate(startDate) : "";
    setStartDate(date);
  };

  const handleEndDate = async (endDate) => {
    const date = endDate ? DateTime.toISOStringDate(endDate) : "";
    setEndDate(date);
  };

  const handleRenewalDate = async (renewalDate) => {
    const date = renewalDate ? DateTime.toISOStringDate(renewalDate) : "";
    setRenewalDate(date);
  };

  const handleAgreementTerms = (e) => {
    setAgreementTerms(e?.target?.value);
  };

  const addAgreementForm = (
    <>
      <DateSelector
        name="agreement_start_date"
        label="Agreement Start Date"
        onChange={handleStartDate}
        required
        isClearable
      />
      <DateSelector
        name="agreement_end_date"
        label="Agreement End Date"
        onChange={handleEndDate}
        required
        isClearable
      />
      <DateSelector
        name="agreement_renewal_date"
        label="Agreement Renewal Date"
        onChange={handleRenewalDate}
        required
        isClearable
      />
      <TextArea
        name="agreement_term"
        label="Agreement Terms"
        placeholder="Agreement Terms"
        onChange={handleAgreementTerms}
        required
      />
    </>
  );

  const agreementFooter = (
    <SaveButton type="submit" loading={isSubmitting == false} />
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Drawer
        modelTitle={rowValue?.id ? "Edit Agreement " : "Add Agreement"}
        DrawerBody={addAgreementForm}
        DrawerFooter={agreementFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
        buttonLabel={buttonLabel}
        enableReinitialize
      />

      <ReduxTable
        id="accountAgreement"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={apiUrl ? apiUrl : `${endpoints().accountAgreementAPI}/search`}
        history={history}
        params={{ account_id: vendorId }}
        sortByOptions={sortByOption}
        disableHeader={paymentTab ? true : false}
        paramsToUrl={true}
      >
        <ReduxColumn
          field="agreement_number"
          sortBy="id"
          className="text-center"
          renderField={(row) => (
            <Link
              to={`/accountAgreement/detail/${row.id}?tab=${Url.GetParam(
                "tab"
              )}`}
              className="link-opacity-75 text-decoration-none"
            >
              {row.agreement_number}
            </Link>
          )}
        >
          Id
        </ReduxColumn>
        <ReduxColumn
          field="agreement_start_date"
          sortBy="agreement_start_date"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="text-center"
          renderField={(row) => (
            <span>{DateTime.getDate(row.agreement_start_date)}</span>
          )}
        >
          Agreement Start Date
        </ReduxColumn>
        <ReduxColumn
          field="agreement_end_date"
          sortBy="agreement_end_date"
          className="text-center"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          renderField={(row) => (
            <span>{DateTime.getDate(row.agreement_end_date)}</span>
          )}
        >
          Agreement End Date
        </ReduxColumn>

        <ReduxColumn
          field="agreement_renewal_date"
          sortBy="agreement_renewal_date"
          className="text-center"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          renderField={(row) => (
            <span>{DateTime.getDate(row.agreement_renewal_date)}</span>
          )}
        >
          Agreement Renewal Date
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue && setRowValue(row);
                      toggles();
                      setIsLoading(true);
                      getDetails();
                      setIsLoading(false);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default AccountAgreementList;
