import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/Breadcrumb";
import Form from "../../../components/Form";
import DateSelector from "../../../components/Date";
import TextArea from "../../../components/TextArea";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import ObjectName from "../../../helpers/ObjectName";
import ActivityList from "../../../components/ActivityList";
import AgreementService from "../../../services/AgreementService";
import Action from "../../../components/Action";
import DeleteModal from "../../../components/DeleteModal";
import DateTime from "../../../lib/DateTime";

const AgreementDetail = (props) => {
  const { history } = props;

  const Tab = {
    SUMMARY: "Summary",
    HISTORY: "History",
  };
  const Param = new URLSearchParams(props.history.location.search);
  const [detail, setDetail] = useState();
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(getSavedActiveTab());
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [renewalDate, setRenewalDate] = useState();
  const [agreementTerms, setAgreementTerms] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    const data = await AgreementService.get(id);
    setDetail(data);
  };

  let accountId = detail && detail?.account_id;

  // Toggle change function
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = async (values) => {
    const data = new FormData();

    data.append("agreement_start_date", values?.agreement_start_date);
    data.append("agreement_end_date", values && values?.agreement_end_date);
    data.append(
      "agreement_renewal_date",
      values && values?.agreement_renewal_date
    );
    data.append("agreement_term", values && values?.agreement_term);

    await AgreementService.update(id, data, (res) => {
      if (res) {
        setIsLoading(true);
        getDetails();
        setIsLoading(false);
      }
    });
  };

  const initialValues = {
    agreement_start_date: startDate
      ? startDate
      : detail?.agreement_start_date
      ? detail?.agreement_start_date
      : "",
    agreement_end_date: endDate
      ? endDate
      : detail?.agreement_end_date
      ? detail?.agreement_end_date
      : "",
    agreement_renewal_date: renewalDate
      ? renewalDate
      : detail?.agreement_renewal_date
      ? detail?.agreement_renewal_date
      : "",
    agreement_term: agreementTerms
      ? agreementTerms
      : detail?.agreement_term
      ? detail?.agreement_term
      : "",
  };

  const breadcrumbList = [
    { label: "Home", link: "/accounts" },
    {
      label: "Account Detail",
      link: `/accounts/${accountId}?${props.location.search}`,
    },
    {
      label: "Agreement Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const accountAgreementDelete = async () => {
    dispatch(await AgreementService.delete(id));
    history.push(`/accounts/${accountId}${props.location.search}`);
  };

  const handleStartDate = async (startDate) => {
    const date = startDate ? DateTime.toISOStringDate(startDate) : "";
    setStartDate(date);
  };

  const handleEndDate = async (endDate) => {
    const date = endDate ? DateTime.toISOStringDate(endDate) : "";
    setEndDate(date);
  };

  const handleRenewalDate = async (renewalDate) => {
    const date = renewalDate ? DateTime.toISOStringDate(renewalDate) : "";
    setRenewalDate(date);
  };

  const handleAgreementTerms = (e) => {
    setAgreementTerms(e?.target?.value);
  };

  if (isLoading) {
    return <Spinner />;
  }

  function getSavedActiveTab() {
    const savedTab = localStorage.getItem("activeTab");
    return savedTab ? savedTab : "Summary";
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Agreement"
        id={detail?.agreement_number}
        label={detail?.agreement_number}
        deleteFunction={accountAgreementDelete}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label={`Agreement Detail #${detail?.agreement_number}`} />
        </div>
        <div className="d-flex">
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>

          {/* History Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                tabToggle(Tab.HISTORY);
                handleTabChange(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY} className="w-100 card card-body">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <div className="">
                <DateSelector
                  name="agreement_start_date"
                  label="Agreement Start Date"
                  onChange={handleStartDate}
                  required
                  isClearable
                />
                <DateSelector
                  name="agreement_end_date"
                  label="Agreement End Date"
                  onChange={handleEndDate}
                  required
                  isClearable
                />
                <DateSelector
                  name="agreement_renewal_date"
                  label="Agreement Renewal Date"
                  onChange={handleRenewalDate}
                  required
                  isClearable
                />
                <TextArea
                  name="agreement_term"
                  label="Agreement Terms"
                  placeholder="Agreement Terms"
                  onChange={handleAgreementTerms}
                  required
                />
                <>
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() =>
                      history.push(
                        `/accounts/${accountId}${props.location.search}`
                      )
                    }
                  />
                </>
              </div>
            </Form>
          </TabPane>
        )}

        <TabPane tabId={Tab.HISTORY} className="w-100">
          <ActivityList
            id={id}
            objectId={id}
            object_name={ObjectName.ACCOUNT_AGREEMENT}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default AgreementDetail;
