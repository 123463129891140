import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../actions/table";
import { deleteTicket } from "../actions/ticket";

// Components
import ReduxTable, { ReduxColumn } from "../components/reduxTable";
import MoreDropdown from "./authentication/moreDropdown";
import DateSelector from "./Date";
import DeleteModal from "./DeleteModal";
import ProjectUserSelect from "./ProjectUserSelect";
import SaveButton from "./SaveButton";
import Spinner from "./Spinner";
import StatusText from "./StatusText";
import UserCard from "./UserCard";
import ProjectSelect from "./projectSelect";
import DraftEditor from "./Draft";
import Drawer from "./Drawer";
import ProjectComponentSelect from "./ProjectComponentSelect";
import Select from "./Select";
import StoryPointSelect from "./StoryPointSelect";
import Text from "./Text";
import TicketType from "./TicketType";

// Lib
import ArrayList from "../lib/ArrayList";
import DateTime from "../lib/DateTime";
import Cookies from "../lib/Helper";
import Url from "../lib/Url";

// Services
import StatusService from "../services/StatusService";
import TicketService from "../services/TicketService";
import { hasPermission } from "../services/UserRolePermissionService";

// API
import { endpoints } from "../api/endPoints";

// Helpers
import Cookie from "../helpers/Cookie";
import ObjectName from "../helpers/ObjectName";
import Permission from "../helpers/Permission";
import * as ticketcolumns from "../helpers/Ticket";

const TicketList = (props) => {
  let {
    newTableHeading,
    showCustomDateFilter,
    showSprintFilter,
    showStatusFilter,
    showUserFilter,
    showReporterFilter,
    showReviewerFilter,
    showProjectFilter,
    history,
    array,
    FieldLabel,
    handleColumnChange,
    startDate,
    endDate,
    refreshButton,
    showSearch,
    showStatusGroupFilter,
    isMultiSelect,
    allCurrentPage,
    allCurrentPageSize,
    projectId,
    setRowValue,
    handleOpenModal,
    setName,
    group_id,
    apiUrl,
    recurring_task_id,
    parent_ticket_id,
    DropdownWithCheckbox,
    showTicketTypeFilter,
    showTicketComponentFilter,
    hideActionColumn,
    showQuickView = true,
    setModalsOpen,
    ModalOpen = false,
    setSelectedIds,
    selectedIds,
    name,
    bulkSelect
  } = props;

  const [list, setList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [trueValue, setTrueValue] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [row, setRow] = useState();
  const [modalType, setModalType] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [projectValue, setProjectValue] = useState("");
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);


  let dispatch = useDispatch();

  useEffect(() => {
    if (ModalOpen) {
      getStatusList();
    }
  }, [ModalOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (type, row) => {
    setModalType(type); // Set the modal type here
    setRow(row);
    toggle();
  };

  useEffect(() => {
    if (trueValue == true) {
      getStatusList();
    }
  }, [trueValue]);

  let showTicketDelete = hasPermission(Permission.TICKET_DELETE);

  const enable_reporter =
    array && ArrayList.getKeyByValue(array, ticketcolumns?.FieldLabel?.REPORTER) ? true : false;
  const enable_project =
    array && ArrayList.getKeyByValue(array, ticketcolumns?.FieldLabel?.PROJECT) ? true : false;
  const enable_sprint =
    array && ArrayList.getKeyByValue(array, ticketcolumns?.FieldLabel?.SPRINT) ? true : false;
  const enable_createdAt =
    array && ArrayList.getKeyByValue(array, ticketcolumns?.FieldLabel?.CREATEDAT) ? true : false;
  const enable_reviewer =
    array && ArrayList.getKeyByValue(array, ticketcolumns?.FieldLabel?.REVIEWER) ? true : false;

  const getStatusList = async () => {
    setIsLoading(true);
    const getStatus = [];
    const status = await StatusService.search(ObjectName.TICKET);
    for (let i = 0; i < status.length; i++) {
      getStatus.push({
        label: status[i].name,
        value: status[i].id,
      });
    }
    setStatusList(getStatus);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setRowValue(null);
    props.setModalsOpen && setModalsOpen(false);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const handleBulkSelect = (ids) => {
    setSelectedIds(ids);
  };

  const oninputProjectChange = (value) => {
    setProjectValue(value);
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const openToggle = () => {
    setModalsOpen(!ModalOpen);
  };

  const initialValues = {
    assignee_id: "",
    status: "",
    statusName: "",
    projectName: "",
    project: "",
    component_id: "",
    type_id: "",
    due_date: "",
    story_points: "",
    summary: "",
    description: "",
  };

  const handleSubmit = (values) => {
    try {
      const data = new FormData();
      data.append(
        "status",
        values && values?.status ? values?.status?.value : ""
      );
      data.append(
        "statusName",
        values && values?.status ? values?.status?.label : ""
      );
      data.append(
        "assignee_id",
        values && values?.assignee_id?.id
          ? values?.assignee_id?.id
          : values?.assignee_id?.value
            ? values?.assignee_id?.value
            : ""
      );
      data.append(
        "projectName",
        values?.projectName?.value ? values?.projectName?.value : ""
      );
      data.append(
        "projectId",
        projectValue && projectValue?.value
          ? projectValue?.value
          : Url.GetParam("projectId")
      );
      data.append(
        "project",
        values && values?.projectName?.value ? values?.projectName?.value : ""
      );
      data.append(
        "component_id",
        values && values?.component_id?.value ? values?.component_id?.value : ""
      );
      data.append(
        "type_id",
        values && values?.type_id?.value ? values?.type_id?.value : ""
      );
      data.append("due_date", values?.due_date ? DateTime.toISOStringDate(values?.due_date) : "");

      data.append(
        "story_points",
        values && values?.story_points ? values?.story_points?.value : ""
      );
      data.append("summary", values?.summary ? values?.summary : "");

      const rawComment = editorState
        ? JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        : "";
      if (rawComment) {
        data.append("description", rawComment);
      }

      data.append("selected_ids", JSON.stringify(selectedIds));
      if (selectedIds && selectedIds.length > 0) {
        dispatch(
          TicketService.BulkUpdate(
            JSON.stringify(selectedIds),
            data,
            (response) => {
              if (response) {
                dispatch(
                  fetchList(
                    "ticket",
                    `${endpoints().ticketAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                      ...Url.GetAllParams(),
                    }
                  )
                );
                handleCloseModal();
                setSelectedIds([]);
                setSelectedCheckBox(false);
                setSelectedCheckBox(true);
              }
            }
          )
        );
      }
      handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };

  const ticketBulkUpdateForm = (
    <>
      <div className="col-12">
        <ProjectSelect
          label="Project"
          oninputProjectChange={oninputProjectChange}
        />
      </div>
      <div className="row">
        <div className="col-12 col-sm-6">
          <TicketType
            label="Ticket Type"
            name="type_id"
            projectId={projectValue?.value}
          />
        </div>
        <div className="col-12 col-sm-6">
          <ProjectComponentSelect
            label="Component"
            name="component_id"
            projectId={projectValue?.value}
          />
        </div>
      </div>
      <div className="col-12">

        <Select label="Status" name="status" options={statusList} />
      </div>
      <div className="col-lg-12 col-12">
        <ProjectUserSelect
          label="Assignee"
          name="assignee_id"
          placeholder="Select Assignee"
          projectId={projectValue && projectValue.value}
          isFullList={true}
        />
      </div>
      <div className="row">
        <div className="col-12 col-sm-6">
          <DateSelector
            name="due_date"
            label="Due Date"
            placeholder="Select Due Date"
          />
        </div>
        <div className="col-12 col-sm-6">
          <StoryPointSelect
            name="story_points"
            label="Story Points"
            placeholder="Select Story Points"
          />
        </div>
      </div>
      <Text
        name="summary"
        label="Summary"
        placeholder="Summary"
      />
      <div className="custom-description-container">
        <DraftEditor
          name="description"
          label="Description"
          editorState={editorState ? editorState : name}
          onChange={handleEditorChange}
        />
      </div>
    </>
  );

  const ticketFooter = (
    <div>
      <SaveButton type="submit" label={"Save"} />
    </div>
  );

  let param = {
    pagination: true,
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    user: Url.GetParam("user"),
    reporter: Url.GetParam("reporter"),
    ticketType: Url.GetParam("ticketType"),
    ticketComponent: Url.GetParam("ticketComponent"),
    reviewer: Url.GetParam("reviewer"),
    sprint: Url.GetParam("sprint"),
    status: Url.GetParam("status"),
    group: Url.GetParam("group"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    search: Url.GetParam("search"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    recurring_task_id: recurring_task_id,
    projectId:
      Url.GetParam("projectId") !== undefined
        ? Url.GetParam("projectId")
        : Cookies.get(Cookie.PROJECT_ID),
    parent_ticket_id: parent_ticket_id ? parent_ticket_id : "",
    project_id: Url.GetParam("project_id") ? Url.GetParam("project_id") : ""
  };

  const handleStatusChange = async (id, statusId) => {
    dispatch(
      await TicketService.updateStatus(
        id,
        {
          status: statusId,
          allCurrentPage,
          allCurrentPageSize,
          param,
        },
        (res) => {
          if (res) {
            setList("");
            getStatusList();
          }
        },
        apiUrl
      )
    );
  };

  const sortByOption = [
    {
      value: "ticket_id:DESC",
      label: "Most Recent",
    },
    {
      value: "name:ASC",
      label: "Name",
    },
  ];

  const handleUserChange = async (value) => {
    let data = {
      assignee: value?.assignee?.id
        ? value?.assignee?.id
        : value?.assignee?.value,
    };

    dispatch(
      await TicketService.update(row && row?.id, data, param, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "ticket",
              `${endpoints().ticketAPI}/search`,
              allCurrentPage ? allCurrentPage : 1,
              allCurrentPageSize ? allCurrentPageSize : 25,
              {
                ...param,
              }
            )
          );
          toggle();
        }
      })
    );
  };

  const handleDeleteTicket = async (id) => {
    dispatch(deleteTicket(id, param));
  };

  const handleDeleteChange = (row) => {
    setRow(row);
    // Open the delete modal
    setDeleteModal(true);
  };

  if (isLoading) {
    <Spinner />;
  }

  const modalBody =
    modalType === "due_date" ? (
      <div className="row">
        <div className="col-12">
          <DateSelector
            name="due_date"
            label={"Due Date"}
            placeholder="Select Due Date"
            isClearable
          />
        </div>
      </div>
    ) : (
      <div className="col-12">
        <ProjectUserSelect
          label="Assignee"
          name="assignee"
          placeholder={"Select Assignee"}
          projectId={row?.projectId}
          required
        />
      </div>
    );

  const modalFooter = (
    <>
      <SaveButton type="submit" label="Save" />
    </>
  );

  const handleDueDate = async (values) => {
    let data = {
      due_date: values && values?.due_date,
    };
    dispatch(
      await TicketService.update(row && row?.id, data, param, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "ticket",
              `${endpoints().ticketAPI}/search`,
              allCurrentPage ? allCurrentPage : 1,
              allCurrentPageSize ? allCurrentPageSize : 25,
              {
                ...param,
              }
            )
          );
          toggle();
        }
      })
    );
  };

  let params = {
    startDate: startDate ? startDate : "",
    endDate: endDate ? endDate : "",
    objectName: ObjectName.TICKET,
    group: group_id ? group_id : Url.GetParam("group"),
    parent_ticket_id: parent_ticket_id ? parent_ticket_id : "",
  };

  if (projectId) {
    params.projectId = projectId;
  }

  if (recurring_task_id) {
    params.recurring_task_id = recurring_task_id;
  }

  return (
    <>
      <div>
        <Drawer
          modelTitle="Bulk Update"
          DrawerBody={ticketBulkUpdateForm}
          DrawerFooter={ticketFooter}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={initialValues}
          enableReinitialize
          handleOpenModal={openToggle}
          handleCloseModal={handleCloseModal}
          handleDrawerClose={handleCloseModal}
          isModalOpen={ModalOpen}
        />
      </div>
      <Drawer
        modelTitle={modalType === "due_date" ? "Change Due Date" : "Change Assignee"}
        DrawerBody={modalBody}
        DrawerFooter={modalFooter}
        onSubmit={(values) => {
          if (modalType === "due_date") {
            handleDueDate(values);
          } else {
            handleUserChange(values);
          }
        }}
        initialValues={
          modalType === "due_date"
            ? {
              due_date: DateTime.getDateTimeByUserProfileTimezone(row?.due_date) || "",
            }
            : {
              assignee: row?.assignee_id
                ? {
                  label: row?.assignee_name,
                  value: row?.assignee_id,
                }
                : "",
            }
        }
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize={true}
      />

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Ticket"
        id={row?.id}
        label={`${row?.ticket_number} - ${row?.summary}`}
        deleteFunction={handleDeleteTicket}
      />

      <div className="mt-4">
        <ReduxTable
          newTableHeading={newTableHeading}
          showSearch={showSearch}
          searchPlaceholder="Search"
          id="ticket"
          apiURL={apiUrl ? apiUrl : `${endpoints().ticketAPI}/search`}
          paramsToUrl={true}
          showStatusFilter={showStatusFilter}
          sortByOptions={sortByOption}
          showUserFilter={showUserFilter}
          showReporterFilter={showReporterFilter}
          showReviewerFilter={showReviewerFilter}
          showSprintFilter={showSprintFilter}
          showTicketTypeFilter={showTicketTypeFilter}
          showTicketComponentFilter={showTicketComponentFilter}
          refreshButton={refreshButton}
          showCustomDateFilter={showCustomDateFilter}
          isMultiSelect={isMultiSelect}
          DropdownWithCheckbox={DropdownWithCheckbox}
          arrayList={array}
          FieldLabel={FieldLabel}
          handleColumnChange={handleColumnChange}
          showStatusGroupFilter={showStatusGroupFilter}
          showProjectFilter={showProjectFilter}
          assigneePlaceholder="Select Assignee"
          projectId={projectId}
          history={history}
          params={params}
          message="You can start by clicking on Add New"
          icon={<FontAwesomeIcon icon={faTasks} />}
          bulkSelect={bulkSelect === false ? false : true}
          onBulkSelect={handleBulkSelect}
          selectedCheckBox={selectedCheckBox}
          showCreatedAtFilter
        >
          <ReduxColumn
            className="text-center text-decoration-none"
            field="id"
            sortBy="id"
            isClickable="true"
            type="link"
            width="180px"
            minWidth="180px"
            maxWidth="180px"
            renderField={(row) => (
              <Link
                target={
                  row.parent_ticket_id && row.ticket_number ? "_blank" : "_self"
                }
                to={`/ticket/${row.slug}/${row.ticket_number}`}
                className="link-opacity-75 text-decoration-none"
              >
                {row.ticket_number}
              </Link>
            )}
          >
            Ticket#
          </ReduxColumn>
          <ReduxColumn
            className="text-wrap text-decoration-none text-truncate text-break"
            field="summary"
            sortBy="summary"
            isClickable="true"
            width="250px"
            minWidth="250px"
            maxWidth="250px"
          >
            Summary
          </ReduxColumn>
          <ReduxColumn
            className="text-start ellipsis"
            field="assignee_name"
            sortBy="name"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            renderField={(row) => (
              <UserCard
                customSize={parseInt(40, 10)}
                firstName={row?.firstName}
                url={row?.avatarUrl}
                lastName={row?.lastName}
              />
            )}
          >
            Assignee
          </ReduxColumn>
          {enable_reviewer && enable_reviewer == true && (
            <ReduxColumn
              className="text-start ellipsis"
              field="reviewer_name"
              sortBy="name"
              width="310px"
              minWidth="310px"
              maxWidth="310px"
              renderField={(row) => (
                <UserCard
                  customSize={parseInt(40, 10)}
                  firstName={row?.reviewerFirstName}
                  url={row?.reviewerUrl}
                  lastName={row?.reviewerLastName}
                />
              )}
            >
              Reviewer
            </ReduxColumn>
          )}
          <ReduxColumn
            field="ticketType"
            sortBy="ticketType"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
          >
            Type
          </ReduxColumn>
          <ReduxColumn
            field="component"
            sortBy="component"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
          >
            Component
          </ReduxColumn>
          <ReduxColumn
            field="statusName"
            sortBy="status"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="text-center"
            renderField={(row) => (
              <StatusText
                backgroundColor={row.statusColor}
                status={row.statusName}
              />
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="due_date"
            sortBy="due_date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
            renderField={(row) => (
              <span>
                {DateTime.getDateByUserProfileTimeZoneFrontEndFormat(row.due_date)}
              </span>
            )}
          >
            Due Date
          </ReduxColumn>
          {enable_reporter && enable_reporter == true && (
            <ReduxColumn
              className="text-center display-flex"
              field="reporter"
              sortBy="reporter"
              width="150px"
              minWidth="250px"
              maxWidth="250px"
              renderField={(row) => (
                <UserCard
                  customSize={parseInt(40, 10)}
                  firstName={row?.reporter}
                  url={row?.reportUrl}
                  lastName={row?.reporterLastName}
                />
              )}
            >
              Reporter
            </ReduxColumn>
          )}

          {enable_project && enable_project == true && (
            <ReduxColumn
              className="text-start"
              minWidth="130px"
              field="project"
              sortBy="project"
            >
              Project
            </ReduxColumn>
          )}
          {enable_sprint && enable_sprint == true && (
            <ReduxColumn
              className="text-start"
              minWidth="130px"
              field="sprint"
              sortBy="sprint"
            >
              Sprint
            </ReduxColumn>
          )}

          {enable_createdAt && enable_createdAt == true && (
            <ReduxColumn
              field="createdAt"
              sortBy="createdAt"
              className="text-center"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
              renderField={(row) => <span>{row.createdAt}</span>}
            >
              Created At
            </ReduxColumn>
          )}
          <ReduxColumn
            field="story_points"
            sortBy="story_points"
            className="text-center"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
          >
            Story Points
          </ReduxColumn>
          {!hideActionColumn && (
            <ReduxColumn
              field="Action"
              width="90px"
              disableOnClick
              renderField={(row) => (
                <div className="text-center action-group-dropdown">
                  <MoreDropdown
                    onClick={(e) => {
                      getStatusList(row && row?.statusId);
                      setTrueValue(true);
                    }}
                  >
                    {showQuickView && (
                      <DropdownItem
                        onClick={() => {
                          setRowValue(row);
                          props.setDueDatePermission &&
                            props.setDueDatePermission(row.dueDatePermission);
                          handleOpenModal();
                          try {
                            setName(
                              row.descriptionData &&
                              EditorState.createWithContent(
                                convertFromRaw(
                                  JSON.parse(row.descriptionData)
                                )
                              )
                            );
                          } catch (error) {
                            console.error("Error parsing JSON data:", error);
                          }
                        }}
                      >
                        Quick View
                      </DropdownItem>
                    )}
                    {list &&
                      list.map((value) => (
                        <DropdownItem
                          onClick={async () => {
                            handleStatusChange(row?.id, value && value?.value);
                          }}
                        >
                          {value?.label}
                        </DropdownItem>
                      ))}
                    {row.dueDatePermission && (
                      <DropdownItem
                        onClick={async () => {
                          openModal("due_date", row);
                        }}
                      >
                        Change Due Date
                      </DropdownItem>
                    )}

                    {row.allow_for_assignee_change_permission && (
                      <DropdownItem
                        onClick={async () => {
                          openModal("assignee", row);
                        }}
                      >
                        Change Assignee
                      </DropdownItem>
                    )}
                    {showTicketDelete ? (
                      <DropdownItem
                        onClick={() => {
                          handleDeleteChange(row);
                        }}
                        className="text-danger"
                      >
                        Delete
                      </DropdownItem>
                    ) : (
                      ""
                    )}
                  </MoreDropdown>
                </div>
              )}
            >
              Actions
            </ReduxColumn>
          )}
        </ReduxTable>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;
  const CurrentPage =
    reduxTable["ticket"] && !reduxTable["ticket"].isFetching
      ? reduxTable["ticket"].currentPage
      : 1;

  const CurrentPageSize =
    reduxTable["ticket"] && !reduxTable["ticket"].isFetching
      ? reduxTable["ticket"].pageSize
      : 25;
  const allCurrentPage =
    reduxTable["ticket"] && reduxTable["ticket"].isFetching == false
      ? reduxTable["ticket"].currentPage
      : 1;
  const allCurrentPageSize =
    reduxTable["ticket"] && reduxTable["ticket"].isFetching == false
      ? reduxTable["ticket"].pageSize
      : 25;

  return {
    CurrentPage,
    CurrentPageSize,
    allCurrentPage,
    allCurrentPageSize,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList);
