import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Form from "../../components/Form";
import DateSelector from "../../components/Date";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import Phone from "../../components/Phone";
import PageTitle from "../../components/PageTitle";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import Spinner from "../../components/Spinner";
import DeleteModal from "../../components/DeleteModal";
import MediaCarousel from "../../components/MediaCarousel";
import BreadCrumb from "../../components/Breadcrumb";
import StatusComponent from "../../components/Status";
import Action from "../../components/Action";

// Lib
import DateTime from "../../lib/DateTime";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Media from "../../helpers/Media";
import MediaUpload from "../../helpers/MediaUpload";
import Permission from "../../helpers/Permission";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";
import LeadService from "../../services/LeadService";

function leadDetail(props) {
  let { history } = props;
  let id = props.match.params.id;
  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [editable, setEditable] = useState(true);

  let showEditButton = hasPermission(Permission.LEADS_EDIT);

  let dispatch = useDispatch();

  useEffect(() => {
    getLeadDetail();
  }, []);

  let initialValues = {
    date: detail ? detail?.date : "",
    mobile: detail ? detail?.mobile : "",
    name: detail ? detail?.name : "",
    notes: detail ? detail?.notes : "",
  };

  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "date",
      values && values?.date ? DateTime.formatDate(values?.date) : ""
    );
    data.append("notes", values && values?.notes ? values?.notes : "");
    data.append("mobile", values && values?.mobile ? values?.mobile : "");
    dispatch(
      await LeadService.update(id, data, (res) => {
        if (res) {
          setIsLoading(true);
          getLeadDetail();
          setIsLoading(false);
          setEditable(true);
        }
      })
    );
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleImageUpload = async (values) => {
    if (id) {
      let mediaParams={
        selectedFile: selectedFile && selectedFile,
        objectId: id,
        ObjectName: ObjectName.LEAD,
        values: values,
        
      }
      await MediaUpload.uploadFile(mediaParams,()=>{});
    }
  };

  const getLeadDetail = async () => {
    let data = await LeadService.get(id);
    setDetail(data);
  };

  const breadcrumbList = [
    { label: "Home", link: "/marketing/dashboard" },
    { label: "Leads", link: "/leads" },
    { label: "Detail Page", link: "" },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const onStatusChange = async (status) => {
    const data = new FormData();
    data.append("status", status ? status : "");
    if (status) {
      dispatch(
        await LeadService.statusUpdate(id, data, (res) => {
          if (res) {
            setIsLoading(true);
            getLeadDetail();
            setIsLoading(false);
          }
        })
      );
    }
  };

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      deleteToggle();
    }
  };

  const handleDelete = async () => {
    dispatch(
      await LeadService.delete(id, (res) => {
        if (res) {
          history.push("/leads");
        }
      })
    );
  };

  const deleteToggle = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        toggle={() => {
          deleteToggle();
        }}
        deleteFunction={handleDelete}
        title="Delete Image"
        label={detail?.name}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Lead Detail Page" />
        <div className="d-flex justify-content-end">
          <div className="me-3">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
          <StatusComponent
            objectName={ObjectName.LEAD}
            handleChange={onStatusChange}
            buttonLabel={detail?.status_name}
            currentStatusId={detail?.status}
          />
        </div>
      </div>
      <div className="card card-body">
        <div className="row">
          <div className="col-sm-7">
            <Form
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleUpdate}
            >
              <DateSelector
                name="date"
                label="Date"
                required
                disabled={editable}
              />
              <Text name="name" label="Name" required disabled={editable} />
              <Phone
                name="mobile"
                label="Mobile Number"
                placeholder="Enter Mobile Number"
                error=""
                required
                fontBolded
                disabled={editable}
              />
              <TextArea
                name="notes"
                label="Notes"
                placeholder="Enter Notes..."
                error=""
                fontBolded
                disabled={editable}
              />
              {!editable && (
                <div>
                  <HorizontalSpace bottom="2">
                    <SaveButton label="Save" />
                    <CancelButton
                      onClick={() => {
                        history.push("/leads");
                      }}
                    />
                  </HorizontalSpace>
                </div>
              )}
            </Form>
          </div>
          <div className="col-sm-5 bill-web-view">
            <MediaCarousel
              showCarasoul
              modalTitle="Upload File"
              title="leads"
              objectName={ObjectName.LEAD}
              objectId={id}
              history={history}
              billView={true}
              attachmentsList={true}
              modalOpen={isModelOpen}
              toggle={toggle}
              setIsModelOpen={setIsModelOpen}
              Attachments={"Attachments"}
              handleImageUpload={handleImageUpload}
              selectedFileValue={setSelectedFile}
              editable={editable}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default leadDetail;
