import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import MoreDropdown from "../../components/authentication/moreDropdown";
import DateSelector from "../../components/Date";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import Phone from "../../components/Phone";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable/index";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import StatusText from "../../components/StatusText";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import UserSelect from "../../components/UserSelect";

// Lib
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";

// Services
import LeadService from "../../services/LeadService";
import LeadTypeService from "../../services/LeadType/LeadTypeService";
import CompanyUserService from "../../services/UserService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import Permission from "../../helpers/Permission";

const LeadListPage = (props) => {
  let { history } = props;

  const [rowValue, setRowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  let dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.LEADS_DELETE);

  useEffect(() => {
    if (isOpen) {
      getUsers();
      getTypeList();
    }
  }, [isOpen]);

  const getUsers = async () => {
    const users = await CompanyUserService.getOption({});
    setUserList(users);
  };

  const getTypeList = async () => {
    const response = await LeadTypeService.list({});
    setTypeList(response);
  };

  let modelBody = (
    <>
      <DateSelector name="date" label="Date" required />
      <Text name="name" label="Name" required />
      <Phone
        name="mobile"
        label="Mobile Number"
        placeholder="Enter Mobile Number"
        error=""
        required
      />
      <UserSelect
        name="owner"
        label="Owner"
        showUserDetailsPageLink={rowValue?.owner_id ? true : false}
        userId={rowValue?.owner_id}
      />
      <UserSelect
        name="reviewer"
        label="Reviewer"
        showUserDetailsPageLink={rowValue?.reviewer_id ? true : false}
        userId={rowValue?.reviewer_id}
      />
      <DateSelector name="due_date" label="Due Date" isClearable />
      <Select name="type" label="Type" options={typeList} />
      <TextArea
        name="notes"
        label="Notes"
        placeholder="Enter Notes..."
        error=""
      />
    </>
  );

  const handleDelete = async () => {
    dispatch(
      await LeadService.delete(rowValue && rowValue?.id, (res) => {
        if (res) {
          dispatch(
            fetchList("leads", `${endpoints().LeadApi}/search`, 1, 25, {})
          );
          setRowValue(null);
        }
      })
    );
  };

  let modelFooter = (
    <>
      <SaveButton
        type="submit"
        label={rowValue && rowValue?.id ? "Edit" : "Add"}
        loading={isSubmit == false}
      />
    </>
  );

  const handleLeadChange = async (values) => {
    try {
      let data = new FormData();
      data.append("name", values && values?.name ? values?.name : "");
      data.append(
        "date",
        values && values?.date ? DateTime.formatDate(values?.date) : ""
      );
      data.append("notes", values && values?.notes ? values?.notes : "");
      data.append("mobile", values && values?.mobile ? values?.mobile : "");
      data.append("owner", values && values?.owner ? values?.owner?.id : "");
      data.append(
        "reviewer",
        values && values?.reviewer ? values?.reviewer?.id : ""
      );
      data.append(
        "due_date",
        values && values?.due_date ? values?.due_date : ""
      );
      data.append("type", values && values?.type ? values?.type?.value : "");
      if (rowValue && rowValue?.id) {
        dispatch(
          await LeadService.update(rowValue && rowValue?.id, data, (res) => {
            if (res) {
              dispatch(
                fetchList("leads", `${endpoints().LeadApi}/search`, 1, 25, {})
              );
              Toggle();
              setRowValue(null);
            }
          })
        );
      } else {
        dispatch(
          await LeadService.create(data, setIsSubmit, (res) => {
            if (res) {
              dispatch(
                fetchList("leads", `${endpoints().LeadApi}/search`, 1, 25, {})
              );
              Toggle();
              setIsSubmit(false);
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const Toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  const closeToggle = () => {
    setIsOpen(!isOpen);
    setRowValue(null);
    setIsSubmit(true);
  };

  return (
    <div>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
          setRowValue("");
        }}
        title="Delete Lead"
        id={rowValue?.id}
        label={rowValue?.name}
        deleteFunction={async () => handleDelete()}
      />
      <Drawer
        modelTitle={rowValue?.id ? "Edit Lead" : "Add Lead"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={(values) => {
          handleLeadChange(values);
        }}
        initialValues={{
          date: rowValue ? rowValue?.date : new Date(),
          mobile: rowValue ? rowValue?.mobile_number : "",
          name: rowValue ? rowValue?.name : "",
          notes: rowValue ? rowValue?.notes : "",
          reviewer: ArrayList.isArray(userList)
            ? userList.find((data) => data?.id == rowValue?.reviewer_id)
            : "",
          owner: ArrayList.isArray(userList)
            ? userList.find((data) => data?.id == rowValue?.owner_id)
            : "",
          type: ArrayList.isArray(typeList)
            ? typeList.find((data) => data?.value == rowValue?.type)
            : "",
          due_date: rowValue ? rowValue?.due_date : "",
        }}
        handleOpenModal={Toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
      />
      <PageTitle
        label="Leads"
        buttonHandler={() => {
          Toggle();
        }}
        buttonLabel="Add"
      />
      <ReduxTable
        id="leads"
        showHeader
        newTableHeading
        showDropdown
        params={{}}
        sortByDropdown={true}
        searchPlaceholder="Search"
        apiURL={`${endpoints().LeadApi}/search`}
        history={history}
        paramsToUrl={true}
        className="lh-lg"
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          className="text-center"
          renderField={(row) => (
            <Link
              to={`/lead/${row.id}`}
              className="link-opacity-75 text-decoration-none"
            >
              {row.name}
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="date"
          sortBy="date"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="mobile_number"
          sortBy="mobile"
          width="110px"
          minWidth="110px"
          maxWidth="110px "
          className="text-center"
        >
          Mobile Number
        </ReduxColumn>
        <ReduxColumn
          field="typeName"
          sortBy="type"
          width="110px"
          minWidth="110px"
          maxWidth="110px "
          className="text-center"
        >
          Type
        </ReduxColumn>
        <ReduxColumn
          field="notes"
          sortBy="notes"
          width="180px"
          minWidth="180px"
          maxWidth="180px "
          className="text-center"
        >
          Notes
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => (
            <StatusText
              backgroundColor={row?.color_code}
              status={row?.status_name}
            />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue(row);
                      Toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {hasDeletePermission && (
                    <DropdownItem
                      className="text-danger cursor-pointer"
                      onClick={() => {
                        setIsDeleteModel(true);
                        setRowValue(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};
export default LeadListPage;
