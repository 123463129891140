import PropTypes from "prop-types";
import React from "react";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import classNames from "classnames";

class AvatarCard extends React.Component {
  render() {
    const {
      title,
      firstName,
      lastName,
      square,
      email,
      url,
      productImageIcon,
      mobileNumber,
      className,
      status,
      statusColor
    } = this.props;

    const names = [];
    if (firstName) {
      names.push(firstName);
    }

    if (lastName) {
      names.push(lastName);
    }


    const name = names.join(" ");

    const handleMailClick = () => {
      window.location.href = `mailto:${email}`;
    };
    const handlePhoneClick = () => {
      window.location.href = `callto:${mobileNumber}`;
    };
    return (
      <div className="d-table">

        {(name || title || url) && (
          <>
            <div className="d-table-cell align-middle"
              style={{
                paddingRight: 10,
              }}>
              <Avatar
                {...this.props} />
            </div>
            <div className={className?className:"d-table-cell align-middle"}>
              {(title || name) && (
                <h6 className="fw-normal">{title || name}</h6>
              )}
              {email && (
                <Link>
                  <h6 onClick={handleMailClick}>{email}</h6>
                </Link>
              )}
              {mobileNumber && (
                <Link>
                  <h6 onClick={handlePhoneClick}>{mobileNumber}</h6>
                </Link>
              )}
            </div>
              {status && (
               <h6 
               className={classNames('fw-normal', 'mx-2', { [`text-${statusColor}`]: "black" })} 
             >
               {status}
             </h6>
              )}
          </>
        )}
      </div>
    );
  }
}

AvatarCard.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.number,
};

export default AvatarCard;
