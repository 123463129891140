import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { endpoints } from "../../api/endPoints";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import UserCard from "../../components/UserCard";
import Currency from "../../lib/Currency";


const OrderProductCancelledReportUserWise = (props) => {
  let { history } = props;

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Order Product Cancelled Report User Wise",
      link: "",
    },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Order Product Cancelled Report User Wise" />
      <ReduxTable
        id={"OrderProductCancelledReportUserWise"}
        searchPlaceholder="Search"
        newTableHeading
        showHeader
        apiURL={`${endpoints().orderProductAPI}/cancelledReportUserWise`}
        paramsToUrl={true}
        history={history}
        icon={<FontAwesomeIcon icon={faTruck} />}
        message="You can start by clicking on Add New"
        params={{showTotal:true}}
        showCustomDateFilter
        showUserFilter
        showBackgroundColor
        sortByDropdown
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          className="text-center"
          renderField={(row) => (
            <>
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row?.name}
                  url={row?.media_url}
                  lastName={row?.last_name}
                />
            </>
          )}
        >
          Owner
        </ReduxColumn>
        <ReduxColumn field="product_count" sortBy="product_count" className="text-center"
        renderField={(row) => (
          <span>{row?.isLastRecord ? row?.totalProductCount: row?.product_count}</span>
        )}
        >
           Product Count
        </ReduxColumn>
        <ReduxColumn field="total_price" sortBy="total_price" className="text-center"
           renderField={(row) => (
            <span>{Currency.Format(row?.isLastRecord ? row?.totalAmount :row?.total_price)}</span>
          )}
        >
          Total Amount
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default OrderProductCancelledReportUserWise;
