import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NoRecordsFound from "../components/NoRecordsFound";
import Spinner from "../components/Spinner";

export const DragAndDropColumn = () => {};

const DragAndDropTable = (props) => {
  let { children, fetchData, handleUpdateRow, rowValues = null } = props;
  const [currentRowsValues, setCurrentRowValues] = useState(rowValues);
  const [isLoading, setIsLoading] = useState(false);

  let rows = rowValues ? rowValues : currentRowsValues;

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    let data = fetchData && (await fetchData());
    setCurrentRowValues(data);
    setIsLoading(false);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const draggedRow = rows[result.source.index];
    const newRows = [...rows];
    newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, draggedRow);

    newRows.forEach((item, index) => {
      item.index = index;
    });
    setCurrentRowValues(newRows);
    handleUpdateRow && (await handleUpdateRow(newRows));
  };

  if (isLoading) {
    return <Spinner />;
  }

  const columns = React.Children.map(children, (child) => child?.props);

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="table-responsive">
          <table className="w-100 table table-hover">
            <thead>
              <tr className="table-dark">
                {React.Children.map(children, (child) => (
                  <th
                    className="mx-3 p-3 text-center"
                    style={{
                      minWidth: child?.props?.minWidth,
                      width: child?.props?.width,
                      maxWidth: child?.props?.maxWidth,
                    }}
                  >
                    {child?.props?.children}
                  </th>
                ))}
              </tr>
            </thead>
            {rows && rows.length > 0 ? (
              <Droppable droppableId="table-rows">
                {(provided) => (
                  <tbody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="bg-white"
                  >
                    {rows &&
                      rows.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`table-row-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {columns.map((fieldProps, colIndex) => (
                                <td
                                  className={`${fieldProps?.className} ${
                                    fieldProps?.type &&
                                    fieldProps?.type === "link"
                                      ? "text-primary text-underline cursor-pointer link-opacity-75"
                                      : ""
                                  }`}
                                  style={fieldProps?.style}
                                  onClick={() =>
                                    fieldProps?.rowOnClick &&
                                    fieldProps?.rowOnClick(item)
                                  }
                                >
                                  {fieldProps?.renderField
                                    ? fieldProps?.renderField(item)
                                    : item[fieldProps?.fieldName]}
                                </td>
                              ))}
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            ) : (
              <tr>
                <td className="text-center" colspan="6">
                  <NoRecordsFound middleHeight={"70vh"} />
                </td>
              </tr>
            )}
          </table>
        </div>
      </DragDropContext>
    </div>
  );
};

export default DragAndDropTable;
