import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, DropdownItem, TabContent, TabPane } from "reactstrap";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../scss/_custom.scss";

// Components Base
import AddModal from "../../components/Modal";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Text from "../../components/Text";
import toast from "../../components/Toast";

// Configs
import { endpoints } from "../../api/endPoints";
// Helper
import { isLoggedIn } from "../../lib/Helper";
import Url from "../../lib/Url";

// Actions
import { fetchList } from "../../actions/table";
import {
  addProductCategory,
  deleteProductCategory,
  updateCategory,
} from "../../actions/productCategory";

// Constant
import * as statusConstant from "../../components/constants/status";

// Api client
import { apiClient } from "../../apiClient";

// Lib
import { isBadRequest } from "../../lib/Http";

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

// Helpers
import { PAGE } from "../../helpers/Status";
import { Category } from "../../helpers/Category";
import Drawer from "../../components/Drawer";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";

export const Tab = { ALL: "All" };

const AllCategory = "allCategory";

const productCategory = (props) => {
  const { ActiveCurrentPage, ActiveCurrentPageSize } = props;

  // To set the toggle Open/close in state
  const [isOpen, setIsOpen] = useState(false);

  // To set the active tab in state
  const [activeTab, setActiveTab] = useState(Tab.ALL);
  const [page, setPage] = useState(Url.GetParam("page"));
  const [row, setRow] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [nameChangeValue, setNameChangeValue] = useState(null);
  const [statusChangeValue, setStatusChangeValue] = useState("");

  // To use dispatch from useDispatch
  const dispatch = useDispatch();

  // Sort By Option Values
  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "updatedAt:DESC",
      label: "Most recent",
    },
  ];

  // Toggle for add modal
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeToggle = () => {
    setIsOpen(!isOpen);
    setNameChangeValue("");
    setRow(null);
  };

  // Use Effect
  useEffect(() => {
    isLoggedIn();
  }, []);

  // Toggle tab for defining the selected category tab
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  // Handle status change for the selected category tab
  const _handleStatusChange = (tabStatus) => {
    props.history.push(`/categories?tab=${tabStatus}`);
  };

  /**
   * Add Product ProductCategory
   *
   * @param data
   */
  const productAdd = (values) => {
    const data = new FormData();
    data.append("name", values.name.trim() || "");
    data.append("status", values?.status?.value ? values?.status?.value : "");
    let params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      status: Url.GetParam("status"),
    };
    if (row && row?.id) {
      dispatch(updateCategory(row?.id, data, toggle, params), closeToggle());
    } else {
      dispatch(addProductCategory(data, toggle, params));
    }
  };

  const handleDelete = async (id) => {
    const params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      status: Url.GetParam("status"),
    };
    dispatch(deleteProductCategory(id, params));
  };

  // To Update the Product ProductCategory status
  const updateProductCategoryStatus = (id, status) => {
    let data = {};
    data.status = status;
    apiClient
      .put(`${endpoints().categoryAPI}/status/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList("All", `${endpoints().categoryAPI}/search`, 1, 25, {
            search: Url.GetParam("search") || "",
          })
        );
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          ActiveCurrentPage, ActiveCurrentPageSize;
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  const nameOnChange = (e) => {
    let value = e?.target?.value;
    setNameChangeValue(value);
  };

  const handleStatusChange = (e) => {
    let value = e?.values?.status;
    setStatusChangeValue(value);
  };

  const statusOptions = [
    {
      value: statusConstant.ACTIVE,
      label: statusConstant.ACTIVE,
    },
    {
      value: statusConstant.IN_ACTIVE,
      label: statusConstant.IN_ACTIVE,
    },
  ];

  // Add Product ProductCategory Form in Add modal
  const addProductCategoryForm = (
    <>
      <Text
        label="Name"
        name="name"
        placeholder="Name"
        onChange={nameOnChange}
        required
      />
      {row?.id && (
        <Select
          label="Status"
          name="status"
          placeholder="Select Status"
          options={statusOptions}
          onInputChange={handleStatusChange}
        />
      )}
    </>
  );

  // Product ProductCategory Footer for Add Modal
  const productCategoryFooter = (
    <SaveButton type="submit" label={row?.id ? "Save" : "Add"} />
  );
  
  return (
    <>
      {/* Page Title */}
      <PageTitle
        label="Categories"
        buttonHandler={(_e) => {
          toggle();
        }}
        buttonLabel="Add New"
      />

      {/* Add Drawer */}
      <Drawer
        modelTitle={row?.id ? "Edit Category" : "Add Category"}
        DrawerBody={addProductCategoryForm}
        DrawerFooter={productCategoryFooter}
        onSubmit={(values) => {
          productAdd(values);
        }}
        initialValues={{
          name: nameChangeValue ? nameChangeValue : row?.name ? row?.name : "",
          status: statusChangeValue
            ? {
              label: statusChangeValue.label,
              value: statusChangeValue.value,
            }
            : row?.name ? row?.status ? {
              label: row.status,
              value: row.status,
            } : "" :
              statusOptions[0],
        }}
        handleOpenModal={toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
      />

      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Category"
        deleteFunction={() => {
          handleDelete(deleteType.id);
        }}
        label={deleteType.name}
        id={deleteType.id}
      />

      {/* / To render the ProductCategory table */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tab.ALL} className="w-100">
          <div className="mt-4">
            <ReduxTable
              id="AllCategory"
              showHeader
              newTableHeading
              searchPlaceholder="Search Categories..."
              apiURL={`${endpoints().categoryAPI}/search`}
              sortByOptions={sortByOption}
              onRowClick
              history={props.history}
              customStatusOption={statusOptions}
              showStatusFilter
              params={{
                tab: Tab.ALL,
                page: page ? page : PAGE,
              }}
              paramsToUrl={true}
              icon={<FontAwesomeIcon icon={faLayerGroup} />}
              message="You can start by clicking on Add New"
              setPage={setPage}
            >
              <ReduxColumn
                field="name"
                width={"550px"}
                minWidth="550px"
                maxWidth="550px"
                type="link"
                isClickable="true"
                sortBy="name"
                renderField={(row) => (
                  <>
                    {" "}
                    <Link
                      to={`/category/${row.id}`}
                      className="link-opacity-75 lh-lg"
                    >
                      {row.name}
                    </Link>{" "}
                    {row && row?.status == Category.STATUS_INACTIVE && (
                      <span className="text-danger">({row?.status})</span>
                    )}
                  </>
                )}
              >
                Name
              </ReduxColumn>
              <ReduxColumn
                field="productCount"
                sortBy="productCount"
                className="text-center"
              >
                Product Count
              </ReduxColumn>
              <ReduxColumn
                minWidth={"100px"}
                width={"100px"}
                maxWidth={"100px"}
                field="status"
                disableOnClick
                className="action-column"
                renderField={(row) => (
                  <div className="text-center landing-group-dropdown">
                    <MoreDropdown>
                      <DropdownItem
                        onClick={() => {
                          setRow(row);
                          toggle();
                        }}
                      >
                        Quick View
                      </DropdownItem>
                      <DropdownItem
                        className={"text-danger"}
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setDeleteType(row);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </MoreDropdown>
                  </div>
                )}
              >
                Action
              </ReduxColumn>
            </ReduxTable>
          </div>
        </TabPane>
      </TabContent>
    </>
  );
};

// Map Dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

// Map State to props
const mapStateToProps = (state) => {
  const { customer } = state.table;
  const reduxTable = state.table;

  const AllCount =
    reduxTable[AllCategory] && reduxTable[AllCategory].isFetching == false
      ? reduxTable[AllCategory].totalCount
      : 0;
  const sort =
    customer && !customer.isFetching ? customer.sort : statusConstant.SORT;

  const sortDir =
    customer && !customer.isFetching
      ? customer.sortDir
      : statusConstant.SORT_DIR;

  const pageSize =
    customer && !customer.isFetching
      ? customer.pageSize
      : statusConstant.PAGESIZE;

  const currentPage =
    customer && !customer.isFetching
      ? customer.currentPage
      : statusConstant.PAGE;

  const search = customer && !customer.isFetching ? customer.search : "";

  const status =
    customer && !customer.isFetching ? customer.status : statusConstant.ACTIVE;

  return {
    sort,
    sortDir,
    pageSize,
    currentPage,
    search,
    status,
    AllCount,
  };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(productCategory);
