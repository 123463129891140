const Cookie = {
  ACTIVITY: "Activity",
  
  ATTENDANCE: "Attendance",

  CANDIDATE: "Candidate",

  LOCATION_PRODUCT_LIST_COLUMNS: "locationProductListColumns",

  ORDER_ID: "orderId",

  ORDER_SELECTED_STORE: "orderSelectedStore",

  ORDER_SELECTED_SHIFT: "orderSelectedShift",

  ORDER_SELECTED_SALESEXECUTIVE: "orderSelectedSalesExecutive",

  ORDER_SELECTED_PAYMENT: "orderSelectedPayment",

  PRODUCT: "Product",

  PRODUCT_ID: "productId",

  PROJECT_ID: "projectId",

  PURCHASE_PRODUCT_LIST_COLUMNS: "purchaseProductListColumns",

  SALARY_PROJECTION_REPORT:"salaryProjectionReport",

  SALE_SHIFT: "SaleShift",

  SALE_DATE: "SaleDate",

  SALE_TYPE: "SaleType",

  SALE_STORE: "SaleStore",

  SALE_SETTLEMENT: "SaleSettlement",

  SALARY_LIST_COLUMNS: "salary_list_columns",

  TICKET: "ticket",

  USER: "User",

  LOCATION_PRODUCT_COLUMN: "locationProductColumn",

};

export default Cookie;
