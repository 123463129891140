import { Tabs } from "../helpers/Tabs";
import DateTime from "../lib/DateTime";
import Urls from "../lib/Url";
import { Status } from "./SchedulerJob";
import { Store } from "./Store";
import { ACTIVE } from "./TransferType";
import { OrderProduct } from "./orderProduct";
import Account from "../helpers/Account";
import { CURRENT_MONTH_VALUE, TODAY_VALUE } from "./Date";

const Url = {
  ACTIVITY_LIST: `/activity?date=${TODAY_VALUE}`,
  LOCATION_LIST: `/location?status=${Store.STATUS_ACTIVE}`,
  BRANDS_LIST: `/brands`,
  CATEGORIES_LIST: `/categories`,
  CUSTOMERS_LIST: `/customers`,
  FINE_LIST: `/fine?date=${TODAY_VALUE}`,
  BONUS_LIST: `/bonus?date=${TODAY_VALUE}`,
  ORDER_LIST: `/orders?tab=${Tabs.ORDERS
    }&&date=${TODAY_VALUE}`,
  PRODUCTS_LIST: `/products`,
  PURCHASE_LIST: `/purchases?tab=${Tabs.PURCHASE}`,
  PURCHASE_ORDER_LIST: `/purchaseorders?tab=${Tabs.PURCHASE_ORDER
    }`,
  SALES_SETTLEMENT_LIST: `/salessettlement?tab=${Tabs.SALES_SETTLEMENT
    }&&date=${TODAY_VALUE}`,
  STOCK_ENTRY_LIST: `/stockEntry?tab=${Tabs.STOCK_ENTRY
    }&&date=${TODAY_VALUE}`,
  TRANSFER_LIST: `/transfers?tab=${Tabs.TRANSFER
    }&date=${TODAY_VALUE}`,
  VENDOR_LIST: `/vendor`,
  Account_LIST: `/accounts?status=${Account.ACTIVE}`,
  TICKET_LIST: `/ticket?projectId=${Urls.GetParam("projectId")}`,
  DASHBOARD_TICKET_LIST: `/dashboard/ticket`,
  REPLENISH_LIST: "/replenish",
  REPLENISHMENT_PRODUCT: "/replenishmentProduct",
  VISITOR_LIST: `/visitor?date=${TODAY_VALUE}`,
  GATE_PASS_LIST: "/gatePass",
  // report url
  ORDER_PRODUCT_REPORT: `/orderProductReport?date=${TODAY_VALUE}`,
  STORE_PRODUCT_REPORT: `/storeProductReport`,
  STORE_PRODUCT_NEGATIVE_STOCK_REPORT: `/stockProductNegativeStockReport`,
  SALES_SETTLEMENT_DISCREPANCY_REPORT: `/salesSettlementDiscrepancyReport?date=${TODAY_VALUE}`,
  STOCK_REPORT: `/stockReport`,
  ORDER_REPORT: `/orderReport?date=${TODAY_VALUE}&&type=Location Wise`,
  ORDER_REPORT_USER_WISE: `/orderReportUserWise?date=${TODAY_VALUE}&type=User Wise`,
  ORDER_UPI_PAYMENT_REPORT: `/orderPaymentReport?date=${TODAY_VALUE}`,
  ORDER_REPORT_USER_SHIFT_WISE: `/orderReportUserMonthWise?date=${CURRENT_MONTH_VALUE}`,

  ORDER_CANCELLED_REPORT: `/orderCancelledReport?date=${TODAY_VALUE}`,
  ORDER_PRODUCT_CANCELLED_REPORT: `/orderProductCancelledReport?date=${TODAY_VALUE}`,
  ORDER_PRODUCT_CANCELLED_REPORT_USER_WISE: `/orderProductCancelledReportUserWise?date=${TODAY_VALUE}`,
  ORDER_PRODUCT_DRAFT_REPORT_USER_WISE: `/orderProductDraftReportUserWise?date=${TODAY_VALUE}`,

  STORE_PRODUCT_NO_STOCK_REPORT: `/storeProductNoStockReport`,
  STORE_PRODUCT_NO_ORDER_REPORT: `/storeProductNoOrderReport?date=${TODAY_VALUE}`,
  TRANSFER_PRODUCT_REPORT: `/transferProductReport?date=${TODAY_VALUE}`,
  SALES_REPORT: `/salesSettlementSummaryReport?date=${TODAY_VALUE}`,
  PURCHASE_PRODUCT_REPORT: `/purchaseProductReport`,
  ORDER_PRODUCT_REPORT: `/orderProductReport?date=${TODAY_VALUE}`,
  ORDER_GRAPH_REPORT: `/orderProductGraphReport?date=${TODAY_VALUE}&graphData=${OrderProduct.REPORT_TYPE_AMOUNT_WISE}&type=${OrderProduct.REPORT_TYPE_BRAND_WISE}&sort=${"amount"}&sortDir=${"DESC"}`,
  PURCHASE_SUMMARY_REPORT: `/purchaseSummaryReport?date=${TODAY_VALUE}`,
  PURCHASE_RECOMMENDATION_REPORT: `/purchaseRecommendationReport?date=${TODAY_VALUE}`,
  ATTENDENCE_SUMMARY_REPORT: `/attendanceSummaryReport?startDate=${DateTime.currentMonthStartDate()}&endDate=${DateTime.getToday()}`,
  ATTENDENCE_REPORT: `/attendanceReport?date=${DateTime.getToday()}`,
  ATTENDANCE_LATE_CHECKIN_ANALYSIS_REPORT: `/attendanceAnalysisReport?date=${CURRENT_MONTH_VALUE}`,
  FINE_REPORT: `/fineReport?startDate=${DateTime.currentMonthStartDate()}&&endDate=${DateTime.getToday()}&sort=user&sortDir=ASC`,
  ACCOUNT_ENTRY_REPORT: `/accountReports/AccountEntryReport?startDate=${DateTime.getToday()}&endDate=${DateTime.getToday()}&type=Account Entry Category`,
  SALES_GST_REPORT: `/accountReports/SalesGstReport?date=${TODAY_VALUE}`,
  PURCHASE_GST_REPORT: `/accountReports/PurchaseGstReport?date=${TODAY_VALUE}`,
  INSPECTION: "/inspections",
  RECURRING_TASK_LIST: `/recurringTask`,
  STOCK_ENTRY_REPORT: `/stockEntryReport?date=${TODAY_VALUE}`,
  LOCATION_ALLOCATION: `/locationAllocation`,
  TRANSFER_PRODUCT_REPORT_USER_WISE: `/transferProductReportUserWise?date=${TODAY_VALUE}`,
  SETTING_TRANSFER: `Transfer?status=${ACTIVE}`,
  REWARD_REPORT: `/rewardReport?startDate=${DateTime.getToday()}&endDate=${DateTime.getToday()}`,
  PURCHASE_REPORT: `/purchaseReport?startDate=${DateTime.getToday()}&endDate=${DateTime.getToday()}`,
  URL_SCHEDULERJOB: `/schedulerJobs?status=${Status.ACTIVE}`,
  REPLENISH_ALLOCATION_REPORT: `/replenishmentAllocationReport`,
  SALES_SETTLEMENT_MISSING_REPORT: `/salesSettlementMissingReport?date=${TODAY_VALUE}`,
  SALARY_PROJECTION_REPORT: `/accountReports/salaryProjectionReport`,
  INVOICE: `/invoice?date=${TODAY_VALUE}`,
  GST: `/gst`,
  PROMOTION: "/promotion",
  LOCATIONPERFORMANCEREPORT: `/locationPerformanceReport?date=${TODAY_VALUE}`,
  PRODUCT_DUPLICATE_REPORT: `/productDuplicateReport`,
  REPLENISHMENT_REPORT: `/replenishmentReport`,
  DAILY_SUMMARY_REPORT: `/dailySummaryReport?date=${DateTime.getToday()}`,

};
export default Url;
