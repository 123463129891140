import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Action from "../../components/Action";
import MediaCarousel from "../../components/MediaCarousel";
import Spinner from "../../components/Spinner";
import UserCard from "../../components/UserCard";
import AttendanceForm from "./attendanceForm";
import NoRecordsFound from "../../components/NoRecordsFound";

// Services
import AttendanceService from "../../services/AttendanceService";
import ShiftService from "../../services/ShiftService";
import { hasPermission } from "../../services/UserRolePermissionService";
import UserService from "../../services/UserService";

// Helpers
import { lateHoursStatusOptions, } from "../../helpers/Attendance";
import Permission from "../../helpers/Permission";
import ObjectName from "../../helpers/ObjectName";
import { TODAY_VALUE } from "../../helpers/Date";

// Lib
import DateTime from "../../lib/DateTime";
import String from "../../lib/String";
import Number from "../../lib/Number";
import Url from "../../lib/Url";

export const tab = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  PUBLISHED: "Published",
  DRAFT: "Draft",
  ALL: "All",
};

export const AttendanceDetailTab = {
  GENERAL: "General",
  HISTORY: "History",
};

const AttendanceDetail = (props) => {
  const [editable, setEditable] = useState(true);
  const id = props.match.params.id;
  // Use States
  const [deleteModal, setDeleteModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [attendanceDetail, setAttendanceDetail] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [shiftList, setShiftList] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [selectTypeValue, setSelectTypeValue] = useState();
  const [storeValue, setStoreValue] = useState();
  const [shiftValue, setShiftValue] = useState();
  const [selectedLogin, setSelectedLogin] = useState();
  const [selectedLogOut, setSelectedLogOut] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [notesChange, setNotesChange] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [statusValue, setStatusValue] = useState();
  const [lateHoursStatusValue, setLateHoursStatusValue] = useState();
  const [ipAddressValue, setIpAddressValue] = useState();
  const [daysCount, setDaysCount] = useState("");
  const [accountTypeOption, setAccountTypeOption] = useState([]);
  const [daysCountOption, setDaysCountOption] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const { history } = props;
  // Defining the param based on url search values
  const param = new URLSearchParams(props.history.location.search);
  // Defining the clicked tab value from section in url
  const role = param.get("section");
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") :
    role ? role : AttendanceDetailTab.GENERAL
  );
  let showHistory = hasPermission(Permission.ATTENDANCE_HISTORY_VIEW);
  let showEditButton = hasPermission(Permission.ATTENDANCE_EDIT);

  // Use Dispatch
  const dispatch = useDispatch();

  // UseEffect
  useEffect(() => {
    getAttendanceDetail();
    getShift();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  // Get Shift List
  const getShift = async () => {
    const list = await ShiftService.search();
    setShiftList(list);
  };

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Attendance", link: `/attendance?date=${TODAY_VALUE}` },
    { label: "Attendance Detail", link: "" },
  ];

  const handleTypeChange = (e) => {
    setSelectTypeValue(e);
  };

  const handleUserChange = (e) => {
    let data = e && e.id ? e.id : "";
    setSelectedUser(data);
  };

  const handleStoreChange = (e) => {
    let data = e && e.value ? e.value : "";
    setStoreValue(data);
  };

  const handleShiftChange = (x) => {
    let data =
      x?.values?.shift && x?.values?.shift ? x?.values?.shift.value : "";
    setShiftValue(data);
  };

  const handleInTimeChange = (e) => {
    setSelectedLogin(e ? e : "");
  };

  const onDateChange = (e) => {
    setSelectedDate(e ? e : "");
  };

  const handleOutTimeChange = (e) => {
    setSelectedLogOut(e ? e : "");
  };

  const handleLateHoursChange = (e) => {
    let value =
      e &&
        e?.values &&
        e?.values?.late_hours_status &&
        e?.values?.late_hours_status.value
        ? e?.values?.late_hours_status.value
        : "";
    setLateHoursStatusValue(value);
  };

  const onNotesChange = (e) => {
    let data = e?.target?.value ? e?.target?.value : "";
    setNotesChange(data);
  };

  const handleIpAddress = (e) => {
    let value = e?.target?.value;
    setIpAddressValue(value);
  };

  const handleDaysCountChange = (e) => {
    setDaysCount(e.target.value || " ");
  };

  const handleStatusChange = (e) => {
    let value =
      e && e?.value
        ? e?.value
        : "";
    setStatusValue(value);
  };

  // Initial Values
  const initialValues = {
    date: attendanceDetail
      ? DateTime.getDateTimeByUserProfileTimezone(attendanceDetail?.date)
      : selectedDate && DateTime.getDateTimeByUserProfileTimezone(selectedDate),
    type: selectTypeValue
      ? accountTypeOption.find(
        (option) => option.value == selectTypeValue.value
      )
      : accountTypeOption.find(
        (option) => option.value == attendanceDetail?.type
      ),
    user:
      usersList &&
      usersList.find(
        (option) => option?.id == (selectedUser || attendanceDetail?.user_id)
      ),
    status:
      statusList &&
      statusList.find(
        (option) => option.value === (statusValue || attendanceDetail?.status)
      ),
    late_hours: DateTime.HoursAndMinutes(attendanceDetail?.late_hours),
    late_hours_status:
      lateHoursStatusOptions &&
      lateHoursStatusOptions.find(
        (option) =>
          option.value ===
          (lateHoursStatusValue || attendanceDetail?.late_hours_status)
      ),
    ip_address: ipAddressValue ? ipAddressValue : attendanceDetail?.ip_address,
    notes: notesChange ? notesChange : attendanceDetail?.notes,
    location:
      locationList &&
      locationList.find(
        (option) => option.value === (storeValue || attendanceDetail?.store_id)
      ),
    shift:
      shiftList &&
      shiftList.find(
        (option) => option.value === (shiftValue || attendanceDetail?.shift_id)
      ),
    login: selectedLogin
      ? selectedLogin
      : DateTime.getTimeZoneDateTime(attendanceDetail?.login),
    logout: selectedLogOut
      ? selectedLogOut
      : DateTime.getTimeZoneDateTime(attendanceDetail?.logout),
    additional_hours: DateTime.HoursAndMinutes(
      attendanceDetail?.additional_hours
    ),
    allow_early_checkout: attendanceDetail?.allow_early_checkout,
    allow_goal_missing: attendanceDetail?.allow_goal_missing,
    approve_late_check_in: attendanceDetail?.approve_late_check_in,
    days_count: Number.Float(daysCount) || Number.Float(attendanceDetail?.days_count),
  };

  // Handle on update
  const handleUpdate = async (id, values) => {
    let data = new FormData();
    data.append("date", DateTime.toISOStringDate(values?.date));
    data.append("user", values?.user.id);
    data.append("type", String.Get(values?.type?.value));
    data.append("status", String.Get(values?.status?.value));
    data.append("late_hours", Number.Get(values?.late_hours));
    data.append(
      "late_hours_status",
      values && values?.late_hours_status?.value
        ? values?.late_hours_status?.value
        : ""
    );
    data.append("ip_address", values?.ip_address ? values?.ip_address : "");
    data.append("notes", String.Get(values?.notes));
    data.append(
      "location",
      values?.location?.value ? values?.location?.value : ""
    );
    data.append("shift", values?.shift?.value ? values?.shift?.value : "");
    data.append("login", values?.login ? values?.login : "");
    data.append("logout", values?.logout ? values?.logout : "");
    data.append(
      "allow_early_checkout",
      values?.allow_early_checkout ? values?.allow_early_checkout : false
    );
    data.append(
      "allow_goal_missing",
      values?.allow_goal_missing ? values?.allow_goal_missing : false
    );
    data.append(
      "approve_late_check_in",
      values?.approve_late_check_in ? values?.approve_late_check_in : false
    );
    data.append(
      "days_count",
      Number.Float(values?.days_count)
    );
    dispatch(
      await AttendanceService.update(id, data, {}, (res) => {
        if (res) {
          getAttendanceDetail();
        }
      })
    );
    setEditable(true);
  };

  const getAttendanceDetail = async () => {
    let id = props.match.params.id;
    let response = await AttendanceService.get(id);
    setAttendanceDetail(response);
  };

  // Toggling the tabs and modals in respective tab
  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setActiveTab(tab || role);
    props.history.push(`?tab=${tab}`);
  };

  const attendanceDelete = async (id, params) => {
    dispatch(
      await AttendanceService.delete(id, params, {
        pagination: true,
        sort: "id",
        sortDir: "DESC",
        search: String.Get(Url.GetParam("search")),
        page: Url.GetParam("page") || "",
        pageSize: Url.GetParam("pageSize") || "",
        history: history,
      })
    );
  };

  const handleActionChange = async (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
    if (e == "Force Logout") {
      if (attendanceDetail) {
        let bodyData = {
          userIds: [attendanceDetail.user_id],
          forceLogout: true,
        };

        dispatch(await UserService.bulkUpdate(bodyData, {}));
      }
    }
  };

  const actionsMenuList = [
    {
      value: "Force Logout",
      label: "Force Logout",
    },
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (!Number.isNotNull(attendanceDetail)) {
    return (
      <NoRecordsFound showMessage boldMessage="Attendance Not Found" middleHeight="90vh" />
    )
  }

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <PageTitle label="Attendance Detail" />

        <div className="ps-2 d-flex">
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Attendance"
        id={props.match.params.id}
        label={`${DateTime.getDate(attendanceDetail?.date)} (${String.concatName(attendanceDetail?.user?.name, attendanceDetail?.user?.last_name)})`}
        deleteFunction={attendanceDelete}
      />
      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === AttendanceDetailTab.GENERAL,
            })}
            onClick={() => {
              toggle(AttendanceDetailTab.GENERAL);
            }}
          >
            {AttendanceDetailTab.GENERAL}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === AttendanceDetailTab.HISTORY,
              })}
              onClick={() => {
                toggle(AttendanceDetailTab.HISTORY);
                _handleTabChange(AttendanceDetailTab.HISTORY);
              }}
            >
              {AttendanceDetailTab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        {activeTab == AttendanceDetailTab.GENERAL && (
          <TabPane tabId={AttendanceDetailTab.GENERAL}>
            <div className="card p-3">
              <div className="row ">
                <div className="col-lg-8 col-sm-8 col-md-8">
                  <Form
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      let id = props.match.params.id;
                      handleUpdate(id, values);
                    }}
                  >
                    <AttendanceForm
                      handleTypeChange={handleTypeChange}
                      selectTypeValue={
                        selectTypeValue
                          ? selectTypeValue
                          : attendanceDetail?.type
                      }
                      handleUserChange={handleUserChange}
                      handleShiftChange={handleShiftChange}
                      handleStoreChange={handleStoreChange}
                      handleStatusChange={handleStatusChange}
                      handleInTimeChange={handleInTimeChange}
                      onDateChange={onDateChange}
                      handleOutTimeChange={handleOutTimeChange}
                      handleLateHoursChange={handleLateHoursChange}
                      onNotesChange={onNotesChange}
                      handleIpAddress={handleIpAddress}
                      accountTypeOption={setAccountTypeOption}
                      handleDaysCountChange={handleDaysCountChange}
                      numberList={setDaysCountOption}
                      editable={editable}
                      userId={attendanceDetail && attendanceDetail?.user_id}
                      setUsersList={setUsersList}
                      setLocationList={setLocationList}
                      locationId={attendanceDetail?.store_id}
                      statusOptions={setStatusList}
                      showTime
                    />

                    {!editable && (
                      <div className="mb-3">
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => {
                            props.history.push(`/attendance?date=${TODAY_VALUE}`);
                          }}
                        />
                      </div>
                    )}
                  </Form>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4">
                  <MediaCarousel
                    showCarasoul
                    modalTitle="Upload File"
                    title="Attendance"
                    objectName={ObjectName.ATTENDANCE}
                    objectId={id}
                    history={history}
                    billView={true}
                    attachmentsList={true}
                    editable={editable}
                  />
                </div>
              </div>
            </div>
          </TabPane>
        )}
        {/* History Tab*/}
        {showHistory && activeTab == AttendanceDetailTab.HISTORY && (
          <TabPane tabId={AttendanceDetailTab.HISTORY}>
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.ATTENDANCE}
              history={props.history}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};
export default AttendanceDetail;
