import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, TabContent, TabPane } from "reactstrap";

// Components
import BreadCrumb from "../../../components/Breadcrumb";
import DeleteModal from "../../../components/DeleteModal";
import Form from "../../../components/Form";
import NumberComponent from "../../../components/Number";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Tab from "../../../components/Tab";
import Text from "../../../components/Text";
import UserSelect from "../../../components/UserSelect";
import Spinner from "../../../components/Spinner";
import StoryPointSelect from "../../../components/StoryPointSelect";
import TicketType from "../../../components/TicketType";
import AddButton from "../../../components/AddButton";
import Action from "../../../components/Action";
import StatusTab from "./statusTab";

// Helpers
import { Projects } from "../../../helpers/Project";
import { 
  TICKET_FIELD_ACCEPTANCE_CRITERIA, 
  TICKET_FIELD_ACTUAL_RESULTS, 
  TICKET_FIELD_AFFECTED_VERSION, 
  TICKET_FIELD_APPLICABLE_DEVICES, 
  TICKET_FIELD_ASSIGNEE, 
  TICKET_FIELD_ATTACHMENT_IMAGE, 
  TICKET_FIELD_ATTACHMENT_PAGE_NAME, 
  TICKET_FIELD_ATTACHMENT_PLATFORM, 
  TICKET_FIELD_ATTACHMENT_SUMMARY,
  TICKET_FIELD_BUILD, 
  TICKET_FIELD_COMMENT, 
  TICKET_FIELD_COMPLETED_AT, 
  TICKET_FIELD_COMPONENT, 
  TICKET_FIELD_CREATED_AT, 
  TICKET_FIELD_CUSTOMER_DELIVERY_DATE, 
  TICKET_FIELD_DATE, 
  TICKET_FIELD_DELIVERY_DATE, 
  TICKET_FIELD_DESCRIPTION, 
  TICKET_FIELD_DEVELOPER, 
  TICKET_FIELD_ENVIRONMENTS, 
  TICKET_FIELD_ESTIMATED_HOURS, 
  TICKET_FIELD_DUE_DATE, 
  TICKET_FIELD_DUE_DATE_TIME, 
  TICKET_FIELD_EXPECTED_RESULTS, 
  TICKET_FIELD_FROM_LOCATION, 
  TICKET_FIELD_HISTORY, 
  TICKET_FIELD_INITIAL_DUE_DATE, 
  TICKET_FIELD_JIRA_ASSIGNEE, 
  TICKET_FIELD_JIRA_CREATED_AT, 
  TICKET_FIELD_JIRA_TICKET_ID, 
  TICKET_FIELD_LABEL, 
  TICKET_FIELD_PARENT_TICKET_ID, 
  TICKET_FIELD_POA, 
  TICKET_FIELD_PRIORITY, 
  TICKET_FIELD_PRODUCTION_STATUS, 
  TICKET_FIELD_PROJECT, 
  TICKET_FIELD_REFERENCE_SCREENSHOTS, 
  TICKET_FIELD_RELEASE_VERSION, 
  TICKET_FIELD_REPORTER, 
  TICKET_FIELD_REVIEWER, 
  TICKET_FIELD_SEVERITY, 
  TICKET_FIELD_SPRINT, 
  TICKET_FIELD_STATUS, 
  TICKET_FIELD_STORY_POINTS, 
  TICKET_FIELD_SUB_TASKS, 
  TICKET_FIELD_SUMMARY, 
  TICKET_FIELD_TASK, 
  TICKET_FIELD_TEST, 
  TICKET_FIELD_TESTER, 
  TICKET_FIELD_TEST_CASES, 
  TICKET_FIELD_TEST_CASE_CREATION_TIME, 
  TICKET_FIELD_TEST_CASE_EXECUTION_TIME, 
  TICKET_FIELD_TEST_STEPS, 
  TICKET_FIELD_TICKET_ID, 
  TICKET_FIELD_TO_LOCATION, 
  TICKET_FIELD_TYPE, 
  TICKET_FIELD_UPDATED_AT, 
  TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID, 
  TICKET_FIELD_USER_IMPACT, 
  TICKET_FIELD_VOICE_NOTES} from "../../../helpers/ProjectTicketType";

// Services
import ProjectTicketTypeService from "../../../services/ProjectTicketTypeService";

// Lib
import Url from "../../../lib/Url";
import TagSelect from "../../../components/TagSelect";
import { TagTypeName } from "../../../helpers/Tag";
import ArrayList from "../../../lib/ArrayList";
import CancelButton from "../../../components/CancelButton";

const Tabs = {
  GENERAL: "General",
  FIELD: "Field",
  STATUS: "Status",
};

const TicketTypeDetail = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL);
  const [projectTicketTypeData, setProjectTicketTypeData] = useState();
  const [userList, setUserList] = useState();
  const [statusValue, setStatusValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultStoryPoint, setDefauleStory] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [subTaskTicketIds, setSubTaskTicketIds] = useState(null);
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [row, setRow] = useState("");
  const [fineTypeList, setFineTypeList] = useState([]);
  const [fineType, setFineType] = useState("")

  let dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getData();
    getTicketTypeList();
  }, []);

  const { projectData } = props;
  const statusOptions = [
    {
      label: Projects.STATUS_ACTIVE,
      value: Projects.STATUS_ACTIVE_VALUE,
    },
    {
      label: Projects.STATUS_INACTIVE,
      value: Projects.STATUS_INACTIVE_VALUE,
    },
  ];

  const getData = async () => {
    const id = props?.match?.params?.typeid;
    const response = await ProjectTicketTypeService.getProjectTicketType(id);
    setProjectTicketTypeData(response);
  };

  const updateData = (values) => {
    const id = props.match.params.typeid;
    (values.status = statusValue?.value),
     
      (values.default_story_point = values?.story_points?.value
        ? values?.story_points?.value
        : null),
      (values.sub_task_ticket_types = subTaskTicketIds);
    ProjectTicketTypeService.updateProjectTicketType(id, values, (res) => {
      if (res) {
        setIsLoading(true);
        getData();
        setIsLoading(false);
      }
    });
  };

  const deleteTicketType = () => {
    const id = props.match.params.typeid;
    dispatch(
      ProjectTicketTypeService.deleteProjectTicketType(id, {
        project_id: props.match.params.id,
      })
    );

    props.history.push(
      `/project/${props.match.params.id}?projectId=${Url.GetParam("projectId")}`
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/dashboard/ticket" },
    {
      label: "Project",
      link: `/project`,
    },
    {
      label: "Project Details",
      link: `/project/${props.match.params.id}?projectId=${Url.GetParam(
        "projectId"
      )}`,
    },
    {
      label: "TicketType Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
   
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  const handleStatusChange = (values) => {
    setStatusValue(values?.values?.status);
  };

  const getTicketTypeList = async () => {
    const data = await ProjectTicketTypeService.list();
    setTypeList(data);
  };

  const initialValues = {
    ...projectTicketTypeData,

    status: statusValue
      ? {
          value: statusValue?.value,
          label: statusValue?.label,
        }
      : statusOptions &&
        statusOptions.find(
          (status) => status.value == projectTicketTypeData?.status
        ),
   
    story_points: defaultStoryPoint
      ? defaultStoryPoint
      : projectTicketTypeData
      ? {
          value: projectTicketTypeData?.default_story_point,
          label: projectTicketTypeData?.default_story_point,
        }
      : "",
    sub_task_ticket_types: subTaskTicketIds
      ? typeList.filter((item) => subTaskTicketIds?.includes(item.value))
      : typeList.filter((item) =>
          projectTicketTypeData?.sub_task_ticket_types
            ?.split(",")
            .map(Number)
            .includes(item?.value)
        ),
        fine_type:fineType ? {
          value: fineType?.value,
          label: fineType?.label,
        } : ArrayList.isArray(fineTypeList) && fineTypeList.find((data)=> data?.value == projectTicketTypeData?.fine_type)
  };


  const handleStoryChange = (e) => {
    setDefauleStory(e.values.story_points);
  };

  const handleTicketTypeChange = ({ values }) => {
    let ids =
      values &&
      values?.sub_task_ticket_types &&
      values?.sub_task_ticket_types?.map((data) => data?.value);
    setSubTaskTicketIds(ids);
  };

  const handleFineTypeChange = (e) => {
    let value = e.values.fine_type
    setFineType(value)
  }

  const _toggle = () => {
    setStatusIsOpen(!statusIsOpen);
  };

  const handleCheckBoxValue = async (values) => {
    let objectKeys = Object.keys(values);
    let objectValues = Object.values(values);

    let data = new FormData();

    let arrayValue = [];
    for (let i = 0; i < objectValues.length; i++) {
      const value = objectValues[i];
      if (value == true) {
        arrayValue.push(objectKeys[i]);
      }
    }
    data.append("checkBoxValue", arrayValue);

    dispatch(
      await ProjectTicketTypeService.updateProjectTicketType(
        props?.match?.params?.typeid,
        data,
        (res) => {}
      )
    );
  };

  let fieldValue =
    projectTicketTypeData &&
    projectTicketTypeData?.field &&
    projectTicketTypeData?.field?.split(",");

  if (isLoading) {
    return <Spinner />;
  }
  const ticketFields = [
    { name: TICKET_FIELD_COMMENT, label: "Comments" },
    { name: TICKET_FIELD_SUB_TASKS, label: "Sub Tasks" },
    { name: TICKET_FIELD_TEST, label: "Tests" },
    { name: TICKET_FIELD_TEST_CASES, label: "Test Cases" },
    { name: TICKET_FIELD_HISTORY, label: "History" },
    { name: TICKET_FIELD_TASK, label: "Tasks" },
    { name: TICKET_FIELD_POA, label: "POA" },
    { name: TICKET_FIELD_TEST_CASE_CREATION_TIME, label: "Test Case Creation Time" },
    { name: TICKET_FIELD_TEST_CASE_EXECUTION_TIME, label: "Test Case Execution Time" },
    { name: TICKET_FIELD_FROM_LOCATION, label: "From Location" },
    { name: TICKET_FIELD_TO_LOCATION, label: "To Location" }
  ];

  const ticketSettingsFields = [
    { name: TICKET_FIELD_PROJECT, label: "Project" },
    { name: TICKET_FIELD_TYPE, label: "Type" },
    { name: TICKET_FIELD_STATUS, label: "Status" },
    { name: TICKET_FIELD_SEVERITY, label: "Severity" },
    { name: TICKET_FIELD_COMPONENT, label: "Component" },
    { name: TICKET_FIELD_LABEL, label: "Label" },
    { name: TICKET_FIELD_AFFECTED_VERSION, label: "Affected Version" },
    { name: TICKET_FIELD_PRIORITY, label: "Priority" },
    { name: TICKET_FIELD_RELEASE_VERSION, label: "Release Version" },
    { name: TICKET_FIELD_SPRINT, label: "Sprint" }
  ];
  const ticketDetailFields = [
    { name: TICKET_FIELD_DESCRIPTION, label: "Description" },
    { name: TICKET_FIELD_USER_IMPACT, label: "User Impact" },
    { name: TICKET_FIELD_PRODUCTION_STATUS, label: "Production Status" },
    { name: TICKET_FIELD_ACCEPTANCE_CRITERIA, label: "Acceptance Criteria" },
    { name: TICKET_FIELD_APPLICABLE_DEVICES, label: "Applicable Devices" },
    { name: TICKET_FIELD_ENVIRONMENTS, label: "Environment" },
    { name: TICKET_FIELD_BUILD, label: "Build" },
    { name: TICKET_FIELD_TEST_STEPS, label: "Test Steps" },
    { name: TICKET_FIELD_ACTUAL_RESULTS, label: "Actual Results" },
    { name: TICKET_FIELD_EXPECTED_RESULTS, label: "Expected Results" },
    { name: TICKET_FIELD_REFERENCE_SCREENSHOTS, label: "Reference Screenshots" },
    { name: TICKET_FIELD_SUMMARY, label: "Summary" }
  ];
  const ticketDetailsPeopleSettings = [
    { name: TICKET_FIELD_REPORTER, label: "Reporter" },
    { name: TICKET_FIELD_ASSIGNEE, label: "Assignee" },
    { name: TICKET_FIELD_REVIEWER, label: "Reviewer" },
    { name: TICKET_FIELD_DEVELOPER, label: "Developer" },
    { name: TICKET_FIELD_TESTER, label: "Tester" }
  ];

  const ticketDetailsDueDates = [
    { name: TICKET_FIELD_DUE_DATE, label: "Due Date" },
    { name: TICKET_FIELD_DUE_DATE_TIME, label: "Due Date Time" },
    { name: TICKET_FIELD_INITIAL_DUE_DATE, label: "Initial Due Date" }
  ];

  const ticketDetailsDates = [
    { name: TICKET_FIELD_CREATED_AT, label: "Created At" },
    { name: TICKET_FIELD_UPDATED_AT, label: "Updated At" },
    { name: TICKET_FIELD_JIRA_CREATED_AT, label: "Jira Created At" },
    { name: TICKET_FIELD_COMPLETED_AT, label: "Completed At" },
    { name: TICKET_FIELD_DATE, label: "Date" }
  ];
  const ticketDetailsHours = [
    { name: TICKET_FIELD_ESTIMATED_HOURS, label: "Estimated Hours" },
    { name: TICKET_FIELD_STORY_POINTS, label: "Story Points" }
  ];
  
  const ticketDetailsDeliveryDates = [
    { name: TICKET_FIELD_DELIVERY_DATE, label: "Delivery Date" },
    { name: TICKET_FIELD_CUSTOMER_DELIVERY_DATE, label: "Customer Delivery Date" }
  ];
  const ticketDetailsTicketIds = [
    { name: TICKET_FIELD_TICKET_ID, label: "Ticket Id" },
    { name: TICKET_FIELD_PARENT_TICKET_ID, label: "Parent Ticket Id" },
    { name: TICKET_FIELD_JIRA_TICKET_ID, label: "Jira Ticket Id" },
    { name: TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID, label: "Update Ticket Id With Jira Id" },
    { name: TICKET_FIELD_JIRA_ASSIGNEE, label: "Jira Assignee" }
  ];

  const ticketAttachments = [
    { name: TICKET_FIELD_ATTACHMENT_PAGE_NAME, label: "Attachment Page Name" },
    { name: TICKET_FIELD_ATTACHMENT_PLATFORM, label: "Attachment Platform" },
    { name: TICKET_FIELD_ATTACHMENT_SUMMARY, label: "Attachment Summary" },
    { name: TICKET_FIELD_ATTACHMENT_IMAGE, label: "Attachment Image" }
  ];
  const ticketVoiceNotes = [{ name: TICKET_FIELD_VOICE_NOTES, label: "Voice Notes" }];

  const CheckboxGroup = ({ fields, title }) => (
    <>
      <h5 className="my-3">{title}</h5>
      {fields.map((field) => (
        <div key={field.name}>
          <SingleCheckbox
            name={field.name}
            label={field.label}
            className="accepted-terms pb-0 me-3"
            handleOnChangeSubmit={(value, name) => handleCheckBoxValue(value, name)}
          />
          <br />
        </div>
      ))}
    </>
  );
  const ticketFieldsValues = [
    TICKET_FIELD_TEST, TICKET_FIELD_TASK, TICKET_FIELD_POA,
    TICKET_FIELD_TEST_CASE_CREATION_TIME, TICKET_FIELD_TEST_CASE_EXECUTION_TIME,
    TICKET_FIELD_PROJECT, TICKET_FIELD_TYPE, TICKET_FIELD_STATUS,
    TICKET_FIELD_SEVERITY, TICKET_FIELD_COMPONENT, TICKET_FIELD_LABEL,
    TICKET_FIELD_AFFECTED_VERSION, TICKET_FIELD_PRIORITY, TICKET_FIELD_RELEASE_VERSION,
    TICKET_FIELD_SPRINT, TICKET_FIELD_DESCRIPTION, TICKET_FIELD_USER_IMPACT,
    TICKET_FIELD_PRODUCTION_STATUS, TICKET_FIELD_ACCEPTANCE_CRITERIA,
    TICKET_FIELD_APPLICABLE_DEVICES, TICKET_FIELD_ENVIRONMENTS, TICKET_FIELD_BUILD,
    TICKET_FIELD_TEST_STEPS, TICKET_FIELD_ACTUAL_RESULTS, TICKET_FIELD_EXPECTED_RESULTS,
    TICKET_FIELD_REFERENCE_SCREENSHOTS, TICKET_FIELD_SUB_TASKS, TICKET_FIELD_REPORTER,
    TICKET_FIELD_ASSIGNEE, TICKET_FIELD_REVIEWER, TICKET_FIELD_DUE_DATE,
    TICKET_FIELD_DUE_DATE_TIME, TICKET_FIELD_INITIAL_DUE_DATE, TICKET_FIELD_CREATED_AT,
    TICKET_FIELD_UPDATED_AT, TICKET_FIELD_JIRA_CREATED_AT, TICKET_FIELD_COMPLETED_AT,
    TICKET_FIELD_ESTIMATED_HOURS, TICKET_FIELD_STORY_POINTS, TICKET_FIELD_DELIVERY_DATE,
    TICKET_FIELD_CUSTOMER_DELIVERY_DATE, TICKET_FIELD_TICKET_ID, TICKET_FIELD_PARENT_TICKET_ID,
    TICKET_FIELD_JIRA_TICKET_ID, TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID,
    TICKET_FIELD_JIRA_ASSIGNEE, TICKET_FIELD_ATTACHMENT_PAGE_NAME, TICKET_FIELD_ATTACHMENT_PLATFORM,
    TICKET_FIELD_ATTACHMENT_SUMMARY, TICKET_FIELD_SUMMARY, TICKET_FIELD_ATTACHMENT_IMAGE,
    TICKET_FIELD_DEVELOPER, TICKET_FIELD_TESTER, TICKET_FIELD_DATE, TICKET_FIELD_COMMENT,
    TICKET_FIELD_TEST_CASES, TICKET_FIELD_HISTORY, TICKET_FIELD_FROM_LOCATION,
    TICKET_FIELD_TO_LOCATION, TICKET_FIELD_VOICE_NOTES
  ];
  
  const initialValue = ticketFieldsValues.reduce((acc, field) => {
    acc[field] = fieldValue && fieldValue.includes(field.toString());
    return acc;
  }, {});
  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <PageTitle label="Ticket Type details " />
        <div className="d-flex">
          {activeTab == Tabs.STATUS && (
            <AddButton
              label={"Add New"}
              className="me-2 mt-1"
              onClick={() => {
                _toggle();
                setRow("");
              }}
            />
          )}
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}  
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Ticket Type"
        label={projectTicketTypeData?.name}
        deleteFunction={() => {
          deleteTicketType();
        }}
      />
      <Nav tabs className="admin-tabs">
        <Tab
          name={Tabs.GENERAL}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.FIELD}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.STATUS}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.GENERAL}>
          <div className="card">
            <div className="col-12 col-sm-8 col-md-8 col-lg-6 p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <Form
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    updateData(values);
                  }}
                >
                  <Text name="name" label="Name" placeholder="Enter Name" />

                  <Select
                    name="status"
                    label="Status"
                    options={statusOptions}
                    onInputChange={handleStatusChange}
                  />
                  <NumberComponent label="Sort" name="sort" fontBolded />

                
                  <StoryPointSelect
                    name="story_points"
                    label="Story Points"
                    placeholder="Select Story Points"
                    onChange={handleStoryChange}
                  />
                  <TicketType
                    name="sub_task_ticket_types"
                    label="Sub Task Ticket Type"
                    projectId={Url.GetParam("projectId")}
                    isMulti
                    handleTicketTypeChange={handleTicketTypeChange}
                  />
                    <TagSelect  
                    name={"fine_type"}
                    label="Fine Type"
                    params={{ type: TagTypeName.FINE_TYPE }}
                    TagList={setFineTypeList}
                    onChange={handleFineTypeChange}
                    />
                  <br />

                  <div className="mt-2">
                    <SaveButton />
                    <CancelButton
                      onClick={() => {
                        props.history.push({ pathname: `/project/${props.match.params.id}?projectId=${Url.GetParam("projectId")}`});
                      }}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.FIELD}>
          <div className="mb-5 mb-sm-0">
          <Form
          enableReinitialize
            initialValues={initialValue}
          >
    <CheckboxGroup fields={ticketFields} title="Ticket Details Tabs Settings" />
    <CheckboxGroup fields={ticketSettingsFields} title="Ticket Details Settings" />
    <CheckboxGroup fields={ticketDetailFields} title="Ticket Detail Fields" />
    <CheckboxGroup fields={ticketDetailsPeopleSettings} title="Ticket Details People Settings" />
    <CheckboxGroup fields={ticketDetailsDueDates} title="Ticket Details Due Date Settings" />
    <CheckboxGroup fields={ticketDetailsDates} title="Ticket Details Dates Settings" />
    <CheckboxGroup fields={ticketDetailsHours} title="Ticket Details Hours Settings" />
    <CheckboxGroup fields={ticketDetailsDeliveryDates} title="Ticket Details Delivery Date Settings" />
    <CheckboxGroup fields={ticketDetailsTicketIds} title="Ticket Details Ticket Ids Settings" />
    <CheckboxGroup fields={ticketAttachments} title="Ticket Attachments Settings" />
    <CheckboxGroup fields={ticketVoiceNotes} title="Ticket Voice Notes Settings" />
          </Form>
          </div>
        </TabPane>
        <TabPane tabId={Tabs.STATUS}>
          <StatusTab
            _toggle={_toggle}
            isOpen={statusIsOpen}
            history={props.history}
            project_id={
              Url.GetParam("projectId") ? Url.GetParam("projectId") : ""
            }
            row={row}
            setRow={setRow}
            ticketTypeId={props.match.params.typeid}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default TicketTypeDetail;
