import React, { useState } from "react";

// Components
import DateSelector from "../../../components/Date";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import MediaCarousel from "../../../components/MediaCarousel";
import UserSelect from "../../../components/UserSelect";
import StoreSelector from "../../location/components/storeSelector";

// Helpers
import ObjectName from "../../../helpers/ObjectName";

const UpdateForm = ({
  id,
  history,
  usersList,
  activityList,
  statusList,
  showUserDetailsPageLink,
  userId,
  editable,
  showAttachment,
  setLocationList,
}) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const __toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <>
      <div className="row">
        <div className={!showAttachment ? "col-lg-7" : ""}>
          <div className="row">
            <div className="col-12 col-sm-6">
              <DateSelector
                label="Date"
                name="date"
                format="dd-MMM-yyyy"
                disabled={editable}
              />
            </div>
            <div className="col-12 col-sm-6">
              <UserSelect
                name="owner"
                label="Owner"
                userList={usersList}
                showUserDetailsPageLink={showUserDetailsPageLink}
                userId={userId}
                isDisabled={editable}
              />
            </div>
          </div>
          <Text
            name="activity"
            label="Activity Name"
            placeholder="Activity Name"
            disabled={editable}
          />
          <div className="row">
            <div className="col-12 col-sm-6">
              <Select
                label="Activity Type"
                name="activity_type"
                options={activityList}
                isDisabled={editable}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Select
                label="Status"
                name="status"
                options={statusList}
                isDisabled={editable}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <DateSelector
                label="Started At"
                name="started_at"
                format="dd-MMM-yyyy hh:mm aa"
                disabled={editable}
              />
            </div>
            <div className="col-12 col-sm-6">
              <DateSelector
                label="Completed At"
                name="completed_at"
                format="dd-MMM-yyyy hh:mm aa"
                disabled={editable}
              />
            </div>
          </div>
          <StoreSelector
            name="location_id"
            label="Location"
            setStoreList={setLocationList}
            isSingleSelect
            disabled={editable}
          />

          <TextArea label="Notes" name="notes" disabled={editable} />
        </div>
        <div className={!showAttachment ? "col-sm-5" : ""}>
          {!showAttachment && (
            <MediaCarousel
              showCarasoul
              title="Bill"
              objectName={ObjectName.ACTIVITY}
              objectId={id}
              history={history}
              billView={true}
              attachmentsList={true}
              modalOpen={isModelOpen}
              toggle={__toggle}
              setIsModelOpen={setIsModelOpen}
              imageCount={setImageCount}
              numberOfImage={imageCount}
              Attachments={"Attachments"}
              editable={editable}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateForm;
