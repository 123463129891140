import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import { deletePurchaseProductEntry } from "../../../actions/purchase";
import CurrencyComponent from "../../../components/Currency.js";
import DeleteModal from "../../../components/DeleteModal";
import Percentages from "../../../components/Percentage";
import Quantity from "../../../components/Quantity";
import SaveButton from "../../../components/SaveButton";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ProductCard from "../../product/components/productCard";
import Action from "../../../components/Action";
import Drawer from "../../../components/Drawer";
import StatusText from "../../../components/StatusText";
import Select from "../../../components/Select.js";

// Api
import { endpoints } from "../../../api/endPoints";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import Permission from "../../../helpers/Permission";
import * as Constants from "../../../helpers/Purchase.js";
import {
  PurchaseProductStatus,
  purchaseProduct,
} from "../../../helpers/purchaseProduct.js";

// Lib
import formatCurrency, { Percentage } from "../../../lib/Currency";
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";
import Number from "../../../lib/Number.js";

// Services
import ProductService from "../../../services/ProductService";
import PurchaseProductService from "../../../services/PurchaseProductService";
import StatusService from "../../../services/StatusService";
import { hasPermission } from "../../../services/UserRolePermissionService";
import String from "../../../lib/String.js";
import Currency from "../../../lib/Currency";
import ProductSearch from "../../../components/productSearch.js";
import DateSelector from "../../../components/Date.js";
import AccountProductService from "../../../services/AccountProductService.js";
import { Status } from "../../../helpers/Product.js";

const productForm = (props) => {
  const {
    history,
    arrays,
    storeId,
    purchaseId,
    onBulkSelect,
    bulkSelect,
    vendorId,
    showActionButton,
    FieldLabel,
    handleColumnChange,
    selectedCheckBox,
    arrayList,
    detail,
    setIsOpen,
    isOpen,
    DropdownWithCheckbox = true,
    getBillDetail
  } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState("");

  const dispatch = useDispatch();

  const [rowValues, setRowValue] = useState("");

  const [quantity, setQuantity] = useState("");

  const [productId, setProductId] = useState("");

  const [net_amount, setAmount] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [mrp, setMrp] = useState("");
  const [purchaseProductId, setPurchaseProductId] = useState("");
  const [purchaseData, setPurchaseData] = useState({});
  const [taxableAmount, setTaxableAmount] = useState(null);
  const [purchaseQuantity, setPurchaseQuantity] = useState("");
  const [status, setStatus] = useState([]);
  const [storeProductList, setStoreProductList] = useState([]);
  const [productname, setProductName] = useState();
  const [statusDetail, setStatusDetail] = useState("");
  const [date, setDate] = useState();


  const buttonLabel = true;

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    getStoreProducts();
  }, [vendorId]);

  const dataRef = useRef({
    productDetail: null,
    net_amount: null,
    quantity: null,
    mrp: null,
    taxableAmount: null,

  });

  let hasPurchaseEditPermission = hasPermission(Permission.PURCHASE_EDIT);

  const getProductDetails = (
    productName,
    productImage,
    brandName,
    size,
    unit,
    salePrice,
    mrp,
    productId,
    brandId,
    packSize
  ) => {
    return (
      <ProductCard
        id={productId}
        brand_id={brandId}
        productImageIcon
        square
        productName={productName}
        url={productImage}
        brandName={brandName}
        size={size != "null" ? size : ""}
        unit={unit != "null" ? unit : ""}
        salePrice={salePrice != "null" ? salePrice : ""}
        mrp={mrp != "null" ? mrp : ""}
        packSize={packSize}
      />
    );
  };

  const getStoreProducts = async () => {
    try {
      //get location product list
      let storeProductList = new Array();

      //validate location Id exist or not
      if (storeId) {
        let params = {
          pagination: false,
          accountId: vendorId,
        };
        let response = await AccountProductService.search(params);

        //validate response exist or not
        if (response && response.data && response.data.data) {
          //get location products
          let storeProducts = response.data.data;
          //validate location products
          if (storeProducts && storeProducts.length > 0) {
            //loop the location rpdocuts
            for (let i = 0; i < storeProducts.length; i++) {
              let productDetails = storeProducts[i];
              //push the location prroducts
              storeProductList.push({
                label: getProductDetails(
                  productDetails?.name,
                  storeProducts[i]?.image,
                  productDetails?.brand_name,
                  productDetails?.size,
                  productDetails?.unit,
                  productDetails?.sale_price,
                  productDetails?.mrp,
                  productDetails?.product_id,
                  productDetails?.brand_id,
                  productDetails?.pack_size
                ),
                value: productDetails?.name + productDetails?.brand_name,
                id: storeProducts[i]?.product_id,
              });
            }
          }
          //set value in state
          setStoreProductList(storeProductList);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //   Get Status List
  const getStatusList = async (currentStatusId) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.PURCHASE_PRODUCT,
        currentStatusId
      );
      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  };

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const enable_discount_amount =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.DISCOUNT_AMOUNT)
      ? true
      : false;
  const enable_manufacture_date =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.MANUFACTURED_DATE)
      ? true
      : false;
  const enable_CGST =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CGST);
  const enable_SGST =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.SGST);
  const enable_IGST =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.IGST);
  const enable_CESS =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CESS);
  const enable_CGST_AMOUNT =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CGST_AMOUNT);
  const enable_SGST_AMOUNT =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.SGST_AMOUNT);
  const enable_IGST_AMOUNT =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.IGST_AMOUNT);
  const enable_CESS_AMOUNT =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CESS_AMOUNT);

  const getStatus = async () => {
    const getStatus = [];
    const status = await StatusService.search(ObjectName.PURCHASE_PRODUCT);
    for (let i = 0; i < status.length; i++) {
      getStatus.push({
        label: status[i].name,
        value: status[i].id,
        default_owner: status[i]?.default_owner,
      });
    }
    setStatus(getStatus);
    props.statusValue && props.statusValue(getStatus);
  };

  const DeletepurchaseProduct = () => {
    try {
      //validate selected prodect exist or not
      if (selectedProduct) {
        //cretae parms
        let params = {
          storeId: storeId,
          purchaseId: purchaseId,
          pagination: true,
          vendorId: vendorId ? vendorId : "",
        };

        dispatch(
          deletePurchaseProductEntry(
            selectedProduct.id,
            params,
            (res) => {
              if (res) {
                getBillDetail && getBillDetail()
                closeDeleteModal
              }
            }
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setRowValue("");
    setPurchaseQuantity("");
    setPurchaseData({});
  };

  const actionsMenuList = [
    {
      value: "Sync Tax From Product",
      label: "Sync Tax From Product",
    },
  ];

  let handleActionChange = async (value) => {
    if (value == "Sync Tax From Product") {
      let response = await ProductService.get(rowValues?.product_id);
      dataRef.current.productDetail = response;
      getPurchaseData();
    }
  };

  const handleQuantityChange = async (e) => {
    //qty
    let qtyValue = e?.values?.quantity?.value ? e?.values?.quantity?.value : 0;
    setQuantity(qtyValue);
    dataRef.current.quantity = qtyValue;
    getPurchaseData();
  };

  const handleDateChange = (values) => {
    setDate(values);
  };

  const handleStatusChange = (values) => {
    setStatusDetail(values?.values?.status ? values?.values?.status : "");
  };

  const handleMrpChange = async (e) => {
    let mrpValue = e && e.target.value ? e.target.value : 0;
    setMrp(mrpValue);
    dataRef.current.mrp = mrpValue;
    getPurchaseData();
  };

  const handleAmountChange = (e) => {
    let totalAmount = e.target.value ? e.target.value : null;
    if (e.target.value) {
      setAmount(totalAmount);
      dataRef.current.net_amount = totalAmount;

      getPurchaseData();
    }
  };

  const handleTaxableAmountChange = (e) => {
    let taxableAmount = e.target.value ? e.target.value : "";
    setTaxableAmount(taxableAmount);
    setAmount("");
    dataRef.current.taxableAmount = taxableAmount;

    getPurchaseData();
  };

  const calculateAmountAndTax = (params) => {
    const {
      totalAmount,
      cgstPercentage,
      sgstPercentage,
      igstPercentage,
      cessPercentage,
      quantity,
      taxableAmount,
    } = params;

    //  combined GST percentage
    const combinedGstPercentage =
      Number.Float(cgstPercentage) +
      Number.Float(sgstPercentage) +
      Number.Float(igstPercentage) +
      Number.Float(cessPercentage);

    //  net_amount before tax
    const taxable_amount = taxableAmount
      ? taxableAmount
      : totalAmount
        ? Number.Float(totalAmount / (1 + combinedGstPercentage / 100))
        : "";

    //  tax amounts
    const cgstAmount = (taxable_amount && cgstPercentage) ? taxable_amount * (cgstPercentage / 100):null;

    const sgstAmount =  (taxable_amount && sgstPercentage) ? taxable_amount * (sgstPercentage / 100):null;

    const igstAmount =  (taxable_amount && igstPercentage) ? taxable_amount * (igstPercentage / 100):null;

    const cessAmount =  (taxable_amount && cessPercentage) ? taxable_amount * (cessPercentage / 100):null;
    
    // gst net_amount
    let cgstTotalAmount = cgstAmount >=0 ? cgstAmount : rowValues.cgst_amount;

    let igstTotalAmount = igstAmount >=0 ? igstAmount : rowValues.igst_amount;

    let sgstTotalAmount = sgstAmount >=0 ? sgstAmount : rowValues.sgst_amount;

    let cessTotalAmount = cessAmount >=0 ? cessAmount : rowValues.cess_amount;

    // totalTax
    let totalTaxAmount =
      Number.Float(cgstTotalAmount) +
      Number.Float(igstTotalAmount) +
      Number.Float(sgstTotalAmount) +
      Number.Float(cessTotalAmount) || null;
    // final total Amount
    let finalTotalAmount = totalAmount
      ? totalAmount
      : taxable_amount && totalTaxAmount
        ? Number.Float(taxable_amount) + Number.Float(totalTaxAmount)
        : Number.Float(rowValues.net_amount);

    let finalTaxbleAmount = taxable_amount
      ? taxable_amount
      : finalTotalAmount - totalTaxAmount;

    return {
      taxableAmount: finalTaxbleAmount,
      cgstAmount: cgstTotalAmount,
      sgstAmount: sgstTotalAmount,
      igstAmount: igstTotalAmount,
      cessAmount: cessTotalAmount,
      totalTaxAmount: totalTaxAmount,
      totalAmount: finalTotalAmount,
    };
  };

  let getPurchaseData = () => {
    let productDetail = dataRef && dataRef.current && dataRef.current.productDetail;

    let cgst_percentage =
      productDetail && productDetail?.product_id && productDetail?.cgst_percentage && (productDetail?.cgst_percentage !== null ||
        productDetail?.cgst_percentage == null)
        ? productDetail?.cgst_percentage
        : Number.Float(rowValues.cgst_percentage);

    let sgst_percentage =
      productDetail && productDetail?.product_id && productDetail?.sgst_percentage && (productDetail?.sgst_percentage !== null ||
        productDetail?.sgst_percentage == null)
        ? productDetail?.sgst_percentage
        : Number.Float(rowValues.sgst_percentage);

    let cess_percentage =
      productDetail && productDetail?.product_id && (productDetail?.tax_percentage && productDetail?.tax_percentage !== null ||
        productDetail?.tax_percentage == null)
        ? productDetail?.tax_percentage
        : Number.Float(rowValues.cess_percentage);

    let igst_percentage =
      productDetail && productDetail?.product_id && productDetail?.igst_percentage && (productDetail?.igst_percentage !== null ||
        productDetail?.igst_percentage == null)
        ? productDetail?.igst_percentage
        : Number.Float(rowValues.igst_percentage);

    // sum of tax percentage
    let totalTax =
      Number.Float(cgst_percentage) +
      Number.Float(sgst_percentage) +
      Number.Float(cess_percentage) +
      Number.Float(igst_percentage);

    // quantity
    let quantityValue = dataRef && dataRef.current.quantity ? dataRef.current.quantity : rowValues?.quantity;

    // mrp
    let mrpValue = dataRef && dataRef.current.mrp ? dataRef.current.mrp : rowValues?.mrp;

    let param = {
      totalAmount: dataRef && dataRef.current.net_amount ? dataRef.current.net_amount : rowValues?.net_amount,
      cgstPercentage: cgst_percentage,
      sgstPercentage: sgst_percentage,
      igstPercentage: igst_percentage,
      cessPercentage: cess_percentage,
      quantity: quantityValue,
      taxableAmount: dataRef && dataRef.current.taxableAmount && dataRef.current.taxableAmount,
    };

    // caluclated result
    const result = calculateAmountAndTax(param);

    let netAmount = dataRef && dataRef.current.net_amount ? dataRef.current.net_amount
      : Number.Float(result.taxableAmount) +
      Number.Float(result.totalTaxAmount);
    // unitPrice
    let unit_price = netAmount / quantityValue;

    // marginamount
    let marginValue = mrpValue - unit_price;

    // margin percentage
    let unitMarginPercentage = (marginValue / mrpValue) * 100;

    // return data
    let data = {
      quantity: Number.Float(quantityValue),
      mrp: mrpValue,
      unit_price: Number.Float(unit_price),
      cgst_percentage: Number.getEmpty(cgst_percentage),
      sgst_percentage: Number.getEmpty(sgst_percentage),
      igst_percentage: Number.getEmpty(igst_percentage),
      cess_percentage: Number.getEmpty(cess_percentage),
      totalTaxAmount: Number.getEmpty(result.totalTaxAmount),
      taxable_amount: Number.getEmpty(result.taxableAmount),
      cgst_amount: Number.getEmpty(result.cgstAmount),
      sgst_amount: Number.getEmpty(result.sgstAmount),
      cess_amount: Number.getEmpty(result.cessAmount),
      igst_amount: Number.getEmpty(result.igstAmount),
      totalTax: Number.getEmpty(totalTax),
      net_amount: dataRef && dataRef.current.net_amount ? dataRef.current.net_amount
        : Number.Float(result.taxableAmount) +
        Number.Float(result.totalTaxAmount),
      unitMarginAmount: Number.getEmpty(marginValue),
      marginAmount: Number.getEmpty(marginValue),
      marginPercentage: Number.getEmpty(unitMarginPercentage),
    };

    // assign  data to state
    setPurchaseData(data);
  };

  const handleProductChange = async (values) => {
    setProductId(
      values?.values?.storeProduct?.id ? values?.values?.storeProduct?.id : ""
    );
    setProductName(
      values?.values?.storeProduct?.label
        ? values?.values?.storeProduct?.label
        : ""
    );

    let response = await ProductService.get(
      values?.values?.storeProduct?.id ? values?.values?.storeProduct?.id : ""
    );
    dataRef.current.productDetail = response;
    getPurchaseData();
  };

  // edit modal form
  const editProduct = (
    <>
      {!rowValues && (
        <>
          <ProductSearch
            storeProductList={storeProductList}
            onInputChange={handleProductChange}
          />
        </>
      )}
      {rowValues && (
        <div className="d-flex justify-content-between">
          <ProductCard
            productImageIcon
            square
            productName={
              rowValues.product_name ? rowValues.product_name : rowValues.name
            }
            brandName={
              rowValues.brand_name ? rowValues.brand_name : rowValues.brandName
            }
            packSize={rowValues?.pack_size}
            id={rowValues?.product_id ? rowValues?.product_id : rowValues?.id}
            url={rowValues?.image ? rowValues?.image : rowValues?.url}
            mrp={rowValues?.mrp}
            salePrice={rowValues?.sale_price}
            unit={rowValues?.unit}
            size={rowValues?.size}
          />
          {hasPurchaseEditPermission && (
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          )}
        </div>
      )}
      <DateSelector
        label="Manufactured Date"
        name="manufactured_date"
        onChange={handleDateChange}
      />
      <>
        <div className="row mt-1">
          <div className="col-6">
            <Quantity
              maxQuantity={100}
              label="Quantity"
              onChange={handleQuantityChange}
              required
            />
          </div>
          <div className="col-6">
            <Select
              name="status"
              label="Status"
              placeholder="Select Status"
              options={status}
              onInputChange={handleStatusChange}
              required
            />
          </div>
        </div>

        <div className="card card-body pt-1 pb-0 shadow">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <CurrencyComponent
                label="MRP"
                name="mrp"
                onChange={handleMrpChange}
              />
            </div>
            {detail && detail.invoiceLineItemAmountIncludesTax == "true" && (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <CurrencyComponent
                  label="Net Amount"
                  name="net_amount"
                  required
                  onChange={handleAmountChange}
                />
              </div>
            )}
            {(detail?.invoiceLineItemAmountIncludesTax == "false" ||
              detail?.invoiceLineItemAmountIncludesTax == null) && (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <CurrencyComponent
                    label="Taxable Amount"
                    name="taxable_amount"
                    onChange={handleTaxableAmountChange}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="card card-body pt-1 pb-0 mt-2 shadow">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <CurrencyComponent
                label="Unit Price"
                name="unit_amount"
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <Percentages
                name="margin_percentage"
                label="Margin %"
                disabled
                allowNegative
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <CurrencyComponent
                label="Unit Margin Amount"
                name="unit_margin_amount"
                disabled
                allowNegative
              />
            </div>
          </div>
        </div>
        <div className="card card-body pt-1 mt-2 border shadow">
          <div className="row">
            <div className="col-6">
              <Percentages name="cgst_percentage" label=" CGST%" disabled />
            </div>
            <div className="col-6">
              <CurrencyComponent
                label="CGST Amount"
                name="cgst_amount"
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Percentages name="sgst_percentage" label=" SGST%" disabled />
            </div>
            <div className="col-6">
              <CurrencyComponent
                label="SGST Amount"
                name="sgst_amount"
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Percentages name="igst_percentage" label="IGST%" disabled />
            </div>
            <div className="col-6">
              <CurrencyComponent
                label="IGST Amount"
                name="igst_amount"
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Percentages name="cess_percentage" label=" CESS%" disabled />
            </div>
            <div className="col-6">
              <CurrencyComponent
                label="CESS Amount"
                name="cess_amount"
                disabled
              />
            </div>
          </div>

          <div className="card card-body pt-1 pb-0 shadow">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <CurrencyComponent
                  label="Tax Amount"
                  name="tax_amount"
                  disabled
                />
              </div>
              {(detail?.invoiceLineItemAmountIncludesTax == "false" ||
                detail?.invoiceLineItemAmountIncludesTax == null) && (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <CurrencyComponent
                      label="Net Amount"
                      name="net_amount"
                      required
                      onChange={handleAmountChange}
                      disabled
                    />
                  </div>
                )}
              {detail && detail.invoiceLineItemAmountIncludesTax == "true" && (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <CurrencyComponent
                    label="Taxable Amount"
                    name="taxable_amount"
                    onChange={handleTaxableAmountChange}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );

  // edit modal footer
  const editFooter = (
    <div>
      <SaveButton type="submit" label={rowValues ? "Save" : "Add"} />
    </div>
  );

  // edit modal toggle
  const _toggle = (Id) => {
    setIsOpen(!isOpen);
    setAmount("");
    setMrp("");
    setTaxableAmount(null);
  };

  const _toggleClose = (Id) => {
    setIsOpen(!isOpen);
    setAmount("");
    setMrp("");
    setRowValue("");
    setPurchaseQuantity("");
    setQuantity("");
    setPurchaseData({});
    setTaxableAmount(null);
    setProductName(null);
    setDate("");
    setStatusDetail("");
    setProductId(null);
    dataRef.current = {
      productDetail: null,
      net_amount: null,
      quantity: null,
      mrp: null,
      taxableAmount: null,
    };
  };

  // Edit modal initial values
  const initialValues = {
    cgst_percentage:
      purchaseData?.cgst_percentage ?? rowValues?.cgst_percentage ?? null,
    cgst_amount: purchaseData?.cgst_amount ?? rowValues?.cgst_amount ?? null,
    sgst_percentage:
      purchaseData?.sgst_percentage ?? rowValues?.sgst_percentage ?? null,
    sgst_amount: purchaseData?.sgst_amount ?? rowValues?.sgst_amount ?? null,
    cess_amount: purchaseData?.cess_amount ?? rowValues?.cess_amount ?? null,
    cess_percentage:
      purchaseData?.cess_percentage ?? rowValues?.cess_percentage ?? null,
    igst_percentage:
      purchaseData?.igst_percentage ?? rowValues?.igst_percentage ?? null,
    igst_amount: purchaseData?.igst_amount ?? rowValues?.igst_amount ?? null,
    mrp: purchaseData?.mrp ?? rowValues?.mrp ?? null,
    quantity: {
      label:
        purchaseData?.quantity ??
        purchaseQuantity ??
        rowValues?.quantity ??
        null,
      value:
        purchaseData?.quantity ??
        purchaseQuantity ??
        rowValues?.quantity ??
        null,
    },
    net_amount: purchaseData?.net_amount
      ? purchaseData?.net_amount
      : rowValues?.net_amount ?? null,
    unit_amount: purchaseData?.unit_price ?? rowValues?.unit_price ?? null,
    margin_percentage: purchaseData?.marginPercentage >= 0
      ? purchaseData?.marginPercentage
      : rowValues?.margin_percentage,
    unit_margin_amount: purchaseData?.unitMarginAmount >= 0
      ? purchaseData?.unitMarginAmount
      : rowValues?.unit_margin_amount,
    taxable_amount:
      taxableAmount === ""
        ? ""
        : taxableAmount
          ? taxableAmount
          : purchaseData?.taxable_amount
            ? purchaseData?.taxable_amount
            : rowValues?.taxable_amount ?? null,
    tax_amount: purchaseData?.totalTaxAmount ?? rowValues?.tax_amount ?? null,
    status: statusDetail
      ? statusDetail
      : status.find((value) => value.value == rowValues?.statusId),
    storeProduct: productname
      ? {
        label: productname ? productname : "",
        value: productId ? productId : "",
      }
      : "",
    manufactured_date: date
      ? DateTime.getTodayDateByUserTimeZone(date)
      : rowValues?.manufactured_date
        ? rowValues?.manufactured_date
        : "",
  };

  //Edit purchase Details
  const EditPurchaseProduct = async (values) => {
    // setIsLoading(true);
    try {
      //cretae parms
      const data = new FormData();
      data.append("purchase_id", purchaseId);
      data.append("product_id", productId);
      data.append("cgst_percentage", values && values.cgst_percentage);
      data.append("sgst_percentage", values && values.sgst_percentage);
      data.append("cess_percentage", values && values.cess_percentage);
      data.append("cess_amount", values && values.cess_amount);
      data.append("sgst_amount", values && values.sgst_amount);
      data.append("cgst_amount", values && values.cgst_amount);
      data.append("igst_amount", values && values.igst_amount);
      data.append("igst_percentage", values && values.igst_percentage);
      data.append("discount_percentage", values && values.discount_percentage);
      data.append(
        "taxable_amount",
        values && values.taxable_amount ? values.taxable_amount : null
      );
      data.append("mrp", values && values.mrp);
      data.append(
        "discount_amount",
        values && Number.Float(values.discount_amount)
      );

      data.append("tax_amount", values && Number.Float(values.tax_amount));

      data.append("net_amount", values && values.net_amount);

      data.append("tax_percentage", values && Number.Float(values.tax));

      data.append(
        "quantity",
        values && values.quantity && values.quantity.value
      );

      data.append("unit_price", values && values.unit_amount);
      data.append("margin_percentage", values && values.margin_percentage);
      data.append("unit_margin_amount", values && values.unit_margin_amount);
      data.append("status", values && values.status && values.status.value);
      data.append(
        "manufactured_date",
        values && values.manufactured_date && values.manufactured_date
      );
      data.append("purchaseId", purchaseId);
      data.append("productId", productId);

      let params = {
        storeId,
        purchaseId,
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search"),
        vendorId: vendorId ? vendorId : "",
      };
      if (rowValues) {
        dispatch(
          PurchaseProductService.update(
            purchaseProductId,
            data,
            params,
            (res) => {
              if (res) {
                getBillDetail && getBillDetail()
                _toggleClose();
              }
            }
          )
        );
      } else {
        dispatch(
          PurchaseProductService.add(data, params, (res) => {
            if (res) {
              getBillDetail && getBillDetail()
              _toggleClose();
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "product_name:ASC",
      label: "Name",
    },
  ];

  return (
    <>
      <div>
        {/* Delete confirmation Modal */}
        <DeleteModal
          isOpen={openDeleteModal}
          label={selectedProduct.product_name}
          toggle={closeDeleteModal}
          title="Delete Product "
          deleteFunction={DeletepurchaseProduct}
        />
        {/* Edit Modal */}

        <Drawer
          modelTitle={
            rowValues ? "Edit Purchase Product" : "Add Purchase Product"
          }
          DrawerBody={editProduct}
          DrawerFooter={editFooter}
          onSubmit={(values) => {
            dispatch(EditPurchaseProduct(values));
          }}
          initialValues={initialValues}
          handleOpenModal={_toggle}
          handleCloseModal={_toggleClose}
          handleDrawerClose={_toggleClose}
          isModalOpen={isOpen}
          buttonLabel={buttonLabel}
          enableReinitialize={true}
        />

        {/* Stock Product Entry List */}
        <ReduxTable
          id="purchaseProduct"
          showHeader
          paramsToUrl={true}
          showParamsInUrl={{
            page: true,
            tab: Url.GetParam("tab"),
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            search: Url.GetParam("search"),
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
            vendorId: vendorId ? vendorId : "",
          }}
          DropdownWithCheckbox={DropdownWithCheckbox}
          selectedCheckBox={selectedCheckBox}
          FieldLabel={FieldLabel}
          handleColumnChange={handleColumnChange}
          arrayList={arrayList}
          searchPlaceholder="Search"
          apiURL={`${endpoints().purchaseProductAPI}/list`}
          newTableHeading
          sortByOptions={sortByOption}
          defaultSortOption={[
            {
              value: "product_name:ASC",
              label: "Name",
            },
          ]}
          params={{
            purchaseId: purchaseId ? purchaseId : "",
            storeId: storeId ? storeId : "",
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            vendorId: Url.GetParam("vendorId") || vendorId,
            billId: props.billId ? props.billId : ""

          }}
          history={history}
          purchaseProducttotalAmount
          totalTaxAmount
          totalTaxableAmount
          totalMarginAmount
          bulkSelect={bulkSelect}
          customCheckBoxId="product_id"
          onBulkSelect={onBulkSelect}
          totalRejectedProductAmount={props.totalRejectedProductAmount}
          totalNotReceivedProductAmount={props.totalNotReceivedProductAmount}
        >
          <ReduxColumn
            field="product_name"
            sortBy="product_name"
            width="400px"
            minWidth="400px"
            maxWidth="400px"
            className="ellipsis text-wrap"
            type="link"
            isClickable="false"
            renderField={(row) => (
              <>
                <ProductCard
                  productImageIcon
                  square
                  param={row}
                  productName={row.product_name}
                  brandName={row.brand_name}
                  productLink={row.image}
                  url={row.image}
                  size={row.size}
                  packSize={row.pack_size}
                  unit={row.unit}
                  salePrice={row.sale_price}
                  mrp={row.mrp}
                  id={row.product_id}
                  hsn_code={row.hsn_code}
                  brand_id={row.brand_id}
                  status={row.productStatus == Status.INACTIVE_VALUE ? Status.INACTIVE : ""}
                />
              </>
            )}
          >
            {" "}
            Product
          </ReduxColumn>
          <ReduxColumn
            className="ellipsis text-center"
            field="quantity"
            sortBy="quantity"
          >
            Quantity
          </ReduxColumn>
          <ReduxColumn
            className="text-center"
            field="mrp"
            sortBy="mrp"
            renderField={(row) => <span>{formatCurrency.Format(row.mrp)}</span>}
          >
            MRP
          </ReduxColumn>
          <ReduxColumn
            className="ellipsis text-end"
            field="unit_price"
            sortBy="unit_price"
            renderField={(row) => (
              <span>{formatCurrency.Format(row.unit_price)}</span>
            )}
          >
            Unit Price
          </ReduxColumn>
          {enable_discount_amount && (
            <ReduxColumn
              className="ellipsis text-end"
              field="discount_amount"
              sortBy="discount_amount"
              renderField={(row) => (
                <span>{formatCurrency.Format(row.discount_amount)}</span>
              )}
            >
              Discount Amount
            </ReduxColumn>
          )}
          <ReduxColumn
            className="ellipsis text-end"
            field="taxable_amount"
            sortBy="taxable_amount"
            renderField={(row) => (
              <span>{formatCurrency.Format(row.taxable_amount)}</span>
            )}
          >
            Taxable Amount
          </ReduxColumn>
          <ReduxColumn
            className="ellipsis text-end"
            field="tax_amount"
            sortBy="tax_amount"
            renderField={(row) => (
              <span>{formatCurrency.Format(row.tax_amount)}</span>
            )}
          >
            Tax Amount
          </ReduxColumn>
          {enable_CGST && (
            <ReduxColumn
              className="text-end"
              field="cgst_percentage"
              sortBy="cgst_percentage"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Percentage(row.cgst_percentage)}</span>
              )}
            >
              CGST %
            </ReduxColumn>
          )}
          {enable_SGST && (
            <ReduxColumn
              className="text-end"
              field="sgst_percentage"
              sortBy="sgst_percentage"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Percentage(row.sgst_percentage)}</span>
              )}
            >
              SGST %
            </ReduxColumn>
          )}
          {enable_IGST && (
            <ReduxColumn
              className="text-end"
              field="igst_percentage"
              sortBy="igst_percentage"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Percentage(row.igst_percentage)}</span>
              )}
            >
              SGST %
            </ReduxColumn>
          )}
          {enable_CESS && (
            <ReduxColumn
              className="text-end"
              field="cess_percentage"
              sortBy="cess_percentage"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Percentage(row.cess_percentage)}</span>
              )}
            >
              SGST %
            </ReduxColumn>
          )}
          {enable_CGST_AMOUNT && (
            <ReduxColumn
              className="text-end"
              field="cgst_amount"
              sortBy="cgst_amount"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Currency.Format(row.cgst_amount)}</span>
              )}
            >
              CGST Amount
            </ReduxColumn>
          )}
          {enable_SGST_AMOUNT && (
            <ReduxColumn
              className="text-end"
              field="sgst_amount"
              sortBy="sgst_amount"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Currency.Format(row.sgst_amount)}</span>
              )}
            >
              SGST Amount
            </ReduxColumn>
          )}
          {enable_IGST_AMOUNT && (
            <ReduxColumn
              className="text-end"
              field="igst_amount"
              sortBy="igst_amount"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Currency.Format(row.igst_amount)}</span>
              )}
            >
              IGST Amount
            </ReduxColumn>
          )}
          {enable_CESS_AMOUNT && (
            <ReduxColumn
              className="text-end"
              field="cess_amount"
              sortBy="cess_amount"
              minWidth="110px"
              maxWidth="110px"
              width="110px"
              renderField={(row) => (
                <span>{Currency.Format(row.cess_amount)}</span>
              )}
            >
              IGST Amount
            </ReduxColumn>
          )}
          <ReduxColumn
            className="ellipsis text-end"
            field="net_amount"
            sortBy="net_amount"
            renderField={(row) => (
              <span>{formatCurrency.Format(row.net_amount)}</span>
            )}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn
            className="ellipsis text-end"
            field="margin_percentage"
            sortBy="margin_percentage"
            renderField={(row) => (
              <>
                <span>{Percentage(row.margin_percentage)}</span>
                <span>
                  {Number.Float(row.margin_percentage) > Number.Float(row.accountProductPercentage) ? (
                    <h6 className="fw-bold text-primary text-end me-0 ms-0">
                      {String.Get(
                        `${purchaseProduct.STATUS_NOT_MATCHED_TEXT} (${row.percentageDiff}%)`
                      )}
                    </h6>
                  ) : (Number.Float(row.margin_percentage)) == Number.Float(row.accountProductPercentage) ? (
                    <h6 className="fw-bold text-success text-end me-0 ms-0">
                      {String.Get(
                        `${purchaseProduct.MARGIN_STATUS_MATCHED_TEXT} (${row.percentageDiff}%)`
                      )}
                    </h6>
                  ) : (
                    <h6 className="fw-bold text-danger text-end me-0 ms-0">
                      {String.Get(
                        `${purchaseProduct.STATUS_NOT_MATCHED_TEXT} (${row.percentageDiff}%)`
                      )}
                    </h6>
                  )}
                </span>
              </>
            )}
          >
            Margin %
          </ReduxColumn>
          <ReduxColumn
            className="ellipsis text-end"
            field="unit_margin_amount"
            sortBy="unit_margin_amount"
            renderField={(row) => (
              <span>{formatCurrency.Format(row.unit_margin_amount)}</span>
            )}
          >
            Margin Amount
          </ReduxColumn>
          <ReduxColumn
            field="status"
            width="110px"
            maxWidth="110px"
            minWidth="110px"
            renderField={(row) => <StatusText status={row.status} />}
          >
            Status
          </ReduxColumn>
          {enable_manufacture_date && (
            <ReduxColumn
              className="text-center"
              width="100px"
              minWidth="180px"
              maxWidth="150px"
              sortBy="manufactured_date"
              renderField={(row) =>
                row.manufactured_date && (
                  <span>{DateTime.getDate(row.manufactured_date)}</span>
                )
              }
            >
              Manufactured Date
            </ReduxColumn>
          )}

          {showActionButton && (
            <ReduxColumn
              field="Action"
              disableOnClick
              width="70px"
              renderField={(row) => (
                <>
                  <div className="text-center action-group-dropdown">
                    <MoreDropdown
                      onClick={() => {
                        setStatusList([]);
                        getStatusList(row.statusId);
                      }}
                    >
                      <DropdownItem
                        onClick={() => {
                          setRowValue(row);
                          setProductId(row.product_id);
                          setPurchaseQuantity(row.quantity);
                          setPurchaseProductId(row?.id);
                          return _toggle(row.id);
                        }}
                      >
                        Quick View
                      </DropdownItem>
                      {statusList &&
                        statusList.length > 0 &&
                        statusList.map((data) => {
                          return (
                            <DropdownItem
                              onClick={() => {
                                dispatch(
                                  PurchaseProductService.updateStatus(
                                    row.id,
                                    data.value,
                                    row.product_id,
                                    {
                                      pagination: true,
                                      sort: Url.GetParam("sort"),
                                      sortDir: Url.GetParam("sortDir"),
                                      search: Url.GetParam("search") || "",
                                      page: Url.GetParam("page"),
                                      pageSize: Url.GetParam("pageSize"),
                                      storeId,
                                      purchaseId,
                                      vendorId: vendorId ? vendorId : "",
                                    }
                                  )
                                );
                              }}
                            >
                              {data.label}
                            </DropdownItem>
                          );
                        })}
                      <DropdownItem
                        className=" text-danger cursor-pointer"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setSelectedProduct(row);
                        }}
                      >
                        Delete
                      </DropdownItem>
                      <></>
                    </MoreDropdown>
                  </div>
                </>
              )}
            >
              Action
            </ReduxColumn>
          )}
        </ReduxTable>
      </div>
    </>
  );
};
export default productForm;
