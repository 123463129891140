import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/Breadcrumb";
import Form from "../../../components/Form";
import TextArea from "../../../components/TextArea";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import ObjectName from "../../../helpers/ObjectName";
import ActivityList from "../../../components/ActivityList";
import Action from "../../../components/Action";
import DeleteModal from "../../../components/DeleteModal";
import TagSelect from "../../../components/TagSelect";
import Select from "../../../components/Select";
import RatingService from "../../../services/RatingService";
import Spinner from "../../../components/Spinner";
import RatingTypeSelect from "../../../components/RatingTypeSelect";
import Account from "../../../helpers/Account";

const RatingDetail = (props) => {
  const { history } = props;

  const Tab = {
    SUMMARY: "Summary",
    HISTORY: "History",
  };
  const Param = new URLSearchParams(props.history.location.search);
  const [detail, setDetail] = useState();
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(getSavedActiveTab(tab));
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [ratingTypeList, setRatingTypeList] = useState([]);
  const [typeDetails, setTypeDetails] = useState(null);
  const [ratingValue, setRatingValue] = useState();
  const [ratingComment, setRatingComment] = useState();
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    const data = await RatingService.get(id);
    setDetail(data);
  };

  let accountId = detail && detail?.account_id;

  // Toggle change function
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = async (values) => {
    const data = new FormData();

    data.append("type", values && values.type.value);
    data.append("rating", values && values.rating.value);
    data.append("comment", values && values.comment);

    setIsLoading(true);
    await RatingService.update(id, data, (res) => {
      if (res) {
        handleCloseModal();
        dispatch(
          fetchList(
            "rating",
            `${endpoints().ratingAPI}/search`,
            1,
            25,
            valueProps
          )
        );
      }
    });
    setEditable(true);
    setIsLoading(false);
  };

  const handleTypeChange = (e) => {
    setTypeDetails(e);
  };

  const handleRatingChange = (e) => {
    let data = e.values?.rating;
    setRatingValue(data);
  };

  const handleCommentTerms = (e) => {
    setRatingComment(e?.target?.value);
  };

  const initialValues = {
    comment: ratingComment
      ? ratingComment
      : detail?.comment
      ? detail?.comment
      : "",
    type: typeDetails
      ? {
          label: typeDetails.label,
          value: typeDetails.value,
        }
      : ratingTypeList &&
        ratingTypeList.find((data) => data?.value == detail?.rating_tag_id),
    rating: ratingValue
      ? ratingValue
      : detail?.rating
      ? { label: detail?.rating, value: detail?.rating }
      : "",
  };

  const breadcrumbList = [
    { label: "Home", link: `/accounts?status=${Account.ACTIVE}` },
    {
      label: "Rating List",
      link: `/accounts/${accountId}?${props.location.search}`,
    },
    {
      label: "Rating Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const accountRatingDelete = async () => {
    dispatch(await RatingService.delete(id));
    history.push(`/accounts/${accountId}${props.location.search}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  function getSavedActiveTab() {
    const savedTab = localStorage.getItem("activeTab");
    return savedTab ? savedTab : "Summary";
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const numbers = Array.from({ length: 9 }, (_, index) => 1 + index * 0.5);

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Rating"
        id={detail?.id}
        label={initialValues?.type?.label}
        deleteFunction={accountRatingDelete}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label={`Rating Detail #${detail?.id}`} />
        </div>
        <div className="d-flex">
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>

          {/* History Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                tabToggle(Tab.HISTORY);
                handleTabChange(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY} className="w-100 card card-body">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <div className="">
                <RatingTypeSelect
                  name="type"
                  label="Type"
                  placeholder="Type"
                  params={{ type: "RatingType" }}
                  handleTagChange={handleTypeChange}
                  required
                  // defaultValue={typeId}
                  RatingTypeList={setRatingTypeList}
                  isDisabled={editable}
                />
                <Select
                  onInputChange={handleRatingChange}
                  name="rating"
                  label="Rating"
                  options={numbers.map((number) => ({
                    value: number.toString(),
                    label: number.toString(),
                  }))}
                  required
                  isDisabled={editable}
                />
                <TextArea
                  name="comment"
                  label="Comments"
                  placeholder="Comments"
                  onChange={handleCommentTerms}
                  required
                  disabled={editable}
                />
                {!editable && (
                  <>
                    <SaveButton label="Save" />
                    <CancelButton
                      onClick={() =>
                        history.push(
                          `/accounts/${accountId}${props.location.search}`
                        )
                      }
                    />
                  </>
                )}
              </div>
            </Form>
          </TabPane>
        )}

        <TabPane tabId={Tab.HISTORY} className="w-100">
          <ActivityList
            id={id}
            objectId={id}
            object_name={ObjectName.ACCOUNT_RATING}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default RatingDetail;
