import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import Barcode from "react-barcode";
import "../../scss/_custom.scss";
import "./product.scss";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import AddButton from "../../components/AddButton";
import BreadCrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import CancelButton from "../../components/CancelButton";
import Currency from "../../components/Currency";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import FeatureImage from "../../components/Image";
import ImageList from "../../components/MediaCarousel";
import AddModal from "../../components/Modal";
import NumberComponent from "../../components/Number";
import PageTitle from "../../components/PageTitle";
import PercentageComponent from "../../components/Percentage";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import SingleCheckbox from "../../components/SingleCheckbox";
import Spinner from "../../components/Spinner";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import URL from "../../components/Url";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PrintPriceTag from "./components/printPriceTag";
import StoreList from "./components/storeList";
import BrandSelect from "../../components/BrandSelect";
import CategorySelect from "../../components/CategorySelect";
import Quantity from "../../components/Quantity";
import Toast from "../../components/Toast";
import QuantityModal from "../../components/modal/QuantityModal";
import ProductCard from "../product/components/productCard";
import ProductPriceEditModal from "./components/ProductPriceEditModal";
import PurchaseList from "./components/purchaseList";
import StoreBulkUpdateModal from "./components/storeProductBulkUpdateModal";
import NumberSelect from "../../components/NumberSelect";
import Notes from "../../components/Notes";
import LocationProductList from "./components/LocationProductList";
import NavTab from "../../components/NavTab";
import ProductSelectModal from "../../components/ProductSelectModal";
import StatusText from "../../components/StatusText";
import VendorList from "../product/components/VendorList";
import StockEntryDetailPage from "../stockEntry/stockEntryDetailPage";
import TransferProductList from "../transfer/Components/transferProductList";
import ProductTagSelector from "../tag/components/TagSelector";

// Actions
import { deleteProduct, updateProduct } from "../../actions/storeProduct";
import { addStore } from "../../actions/storeProductDetail";
import { fetchList } from "../../actions/table";

// ApiClient
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../api/endPoints";

// Assets
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Lib
import Boolean from "../../lib/Boolean";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";
import { isBadRequest } from "../../lib/Http";
import CurrencyFormat, { Percentage } from "../../lib/Currency";

// Services
import ProductPriceService from "../../services/ProductPriceService";
import productService from "../../services/ProductService";
import StoreProductService from "../../services/StoreProductService";
import TransferService from "../../services/TransferService";
import { hasPermission } from "../../services/UserRolePermissionService";
import { VendorProductService } from "../../services/VendorProductService";
import AccountService from "../../services/AccountService";
import StatusService from "../../services/StatusService";
import ProductService from "../../services/ProductService";

// Helpers
import ProductPriceStatus from "../../helpers/ProductPriceStatus";
import LocationProduct from "../../helpers/LocationProduct";
import Permission from "../../helpers/Permission";
import { Store } from "../../helpers/Store";
import ObjectName from "../../helpers/ObjectName";
import * as Product from "../../helpers/Product";
import { toast } from "react-toastify";

// Tab Constants
export const tab = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  PUBLISHED: "Published",
  DRAFT: "Draft",
  ALL: "All",
};

// Product Detail Tabs
export const ProductDetailTab = {
  GENERAL: "General",
  INVENTORY: "Inventory",
  PRICE: "Prices",
  MEDIA: "Media",
  SHOPIFY: "Shopify",
  SEO: "SEO",
  TAGS: "Tags",
  LOCATION: "Locations",
  VENDOR: "Vendors",
  HISTORY: "History",
  PURCHASE: "Purchases",
  ORDERS: "Orders",
  TAX: "Tax",
  TRANSFER: "Transfers",
  STOCK_ENTRY_PRODUCT: "Stock Entries",
};

const ProductDetail = (props) => {
  // Product detail props
  const { history } = props;
  const role = Url.GetParam("tab");
  let showHistory = hasPermission(Permission.PRODUCT_HISTORY_VIEW);
  let showEditButton = hasPermission(Permission.PRODUCT_EDIT);
  let showProductPriceAdd = hasPermission(Permission.PRODUCT_PRICE_ADD);
  let showProductPriceEdit = hasPermission(Permission.PRODUCT_PRICE_EDIT);
  let showProductPriceDelete = hasPermission(Permission.PRODUCT_PRICE_DELETE);

  // State values
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [addStoreModal, setAddStoreModal] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [initialValues, setInitialValues] = useState([]);
  const [locationName, setLocation] = useState([]);
  const [activeTab, setActiveTab] = useState(
    role ? role : ProductDetailTab.GENERAL
  );
  const [vendorList, setVendorList] = useState();
  const [storeIds, setStoreIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addCloneModal, setAddCloneModal] = useState(false);
  const [mergeModal, setMergeModal] = useState(false);
  const [confirmMergeModal, setconfirmMergeModal] = useState(false);
  const [sgstPercentageValue, setSgstPercentageValue] = useState(null);
  const [openQuantityModal, setOpenQuantityModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [cgstPercentageValue, setCgstPercentageValue] = useState(null);
  const [igstPercentageValue, setIgstPercentageValue] = useState(null);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([0]);
  const [priceEditModal, setPriceEditModal] = useState(false);
  const [selectedPriceData, setSelectedPriceData] = useState("");
  const [openPriceDeleteModal, setPriceDeletModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [imagestatus, setImageStatus] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState();
  const [priviousBarCode, setPriviousBarCode] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedProductData, setSelectedProductData] = useState("");
  const [priceStatus, setPriceStatus] = useState();
  const [editable, setEditable] = useState(true);
  const [isSubmit, setIsSubmit] = useState(true);
  const [selectedProduct, setSelectProduct] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isClone, setIsClone] = useState(false);
  const [mrpValue, setMrp] = useState("");
  const [salePriceValue, setSalePrice] = useState("");

  const [discountValue, setDiscount] = useState("");
  const [defaultValue, setIsDefault] = useState(false);
  const [costPriceValue, setCostPrice] = useState(null);
  const [barCodeValue, setBarcode] = useState("");
  const [dateValue, setDate] = useState(new Date());
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  let selectedProductid = selectedProduct.join(",");

  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!openBulkUpdateModal);
  };

  const handleBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  // Product id
  let productId = props.match.params.tab || productData.id;

  let params = {
    productId: productId,
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    status: Store.STATUS_ACTIVE,
    tab: Url.GetParam("tab"),
  };

  const ActionMenu = [
    { label: "All", value: "All" },
    { label: "No Stock", value: "NoStock" },
    { label: "Excess", value: "Excess" },
    { label: "Shortage", value: "Shortage" },
  ];

  // Defining dispatch from useDispatch
  const dispatch = useDispatch();

  // Use Effect
  useEffect(() => {
    getProductDetail();
    if (activeTab == ProductDetailTab.LOCATION) {
      getStoreId();
    }
    getSelectedProductDetail(selectedProductid);
    if (activeTab == ProductDetailTab.VENDOR) {
      getVendorDetails();
    }

  }, []);

  useEffect(() => {
    getSelectedProductDetail(selectedProductid);
  }, [selectedProductid]);

  let id = props.match.params.tab;

  //Get Product Detail
  const getProductDetail = async () => {
    let id = props.match.params.tab;
    const status = [];
    const initialValues = {};
    const selectedTags = [];

    try {
      const response = await apiClient.get(`${endpoints().productAPI}/${id}`);
      const data = response.data && response.data.productData;
      // Product tag Initail value
      if (data.tag) {
        data.tag.forEach((result) => {
          selectedTags.push({
            id: result.id,
            value: result.name,
            label: result.name,
          });
        });
        initialValues["tags"] = selectedTags;
      }

      setProductData(data);
      setInitialValues(initialValues);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getStatusList = async (currentStatusId, allowed_statuses) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.PRODUCT_PRICE,
        currentStatusId,
        "",
        allowed_statuses
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  };

  const getSelectedProductDetail = async (selectedProductid) => {
    try {
      if (selectedProductid !== "") {
        const response = await apiClient.get(
          `${endpoints().productAPI}/${selectedProductid}`
        );
        const data = response?.data?.productData;
        setSelectedProductData(data);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Toggling the tabs and modals in respective tab
  const toggle = async (tab) => {
    if (tab == ProductDetailTab.LOCATION) {
      await getStoreId();
    }
    setIsOpen(!isOpen);
    setActiveTab(tab || role);
    setIsSubmit(true);
  };

  const mergeModelToggle = () => {
    setIsOpen(!isOpen);
  };

  // Product Status Options
  const productStatusOptions = [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "InActive",
      label: "InActive",
    },
  ];

  // Supplier sort by Option
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  //Store Name and MostRecent
  const sortByOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "order_date:ASC",
      label: "Date",
    },
  ];

  const onStatusChange = async (value) => {
    const data = new FormData();

    data.append("status", value);

    dispatch(await productService.updateStatus(id, data));
  };


  /**
   * Delete Product
   *
   * @param data
   */
  const productDelete = (id) => {
    dispatch(deleteProduct(id, {}, history));
  };

  // Handling on changing the detail tab
  const _handleStatusChange = (tabStatus) => {
    props.history.push(`/product/${productData.id}?tab=${tabStatus}`);
  };

  // Add location modal toggling
  const addStoreToggle = () => {
    setAddStoreModal(false);
    setLocation("");
  };

  // Add location modal toggling
  const addVendorToggle = () => {
    setOpenVendorModal(false);
  };

  // Add clone modal toggling
  const addCloneToggle = () => {
    setAddCloneModal(false);
  };

  // Add clone modal toggling
  const mergeToggle = () => {
    setMergeModal(false);
  };

  // Handle location Add
  const handleStoreAdd = async () => {
    const data = new FormData();
    data.append("productId", productData.id);
    data.append("storeIds", locationName && locationName ? locationName : "");
    dispatch(
      addStore(
        data,
        {
          productId: productId,
          pagination: true,
          excludeIds: storeIds,
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
          status: Store.STATUS_ACTIVE,
        },
        addStoreToggle,
        getProductDetail
      )
    );
    setIsLoading(true);
    setLocation("");
    getStoreId();
    setIsLoading(false);
  };

  const addVendor = (values) => {
    const data = new FormData();
    data.append("name", productData.name);
    data.append("url", values.vendor_url);
    data.append("vendor_id", values.vendor_name.id);
    data.append("product_id", id);
    dispatch(
      VendorProductService.create(
        data,
        { product_id: id, pagination: true },
        addVendorToggle
      )
    );
  };



  const handleTaxUpdate = (id, values) => {
    let bodyData = new Object();
    if (values.cess_percentage !== undefined) {
      bodyData.cess_percentage = values && values.cess_percentage;
    }

    if (values.cgst_percentage !== undefined) {
      bodyData.cgst_percentage = values && values.cgst_percentage;
    }

    if (values.sgst_percentage !== undefined) {
      bodyData.sgst_percentage = values && values.sgst_percentage;
    }

    if (values.igst_percentage !== undefined) {
      bodyData.igst_percentage = values && values.igst_percentage;
    }
    dispatch(updateProduct(id, bodyData, {}, getProductDetail));
    setEditable(true);
  };

  //Handle Update Product Details
  const handleGeneralUpdate = (id, values) => {
    let bodyData = new Object();

    if (values.name !== undefined) {
      bodyData.name = values && values.name && values.name.trim();
    }

    if (values.slug !== undefined) {
      bodyData.slug = values && values.slug;
    }

    if (values && values.brand_id !== undefined) {
      bodyData.brand_id = values && values.brand_id && values.brand_id.id;
    }

    if (values && values.brand_id && values.brand_id.__isNew__ == true) {
      bodyData.brand = values && values.brand_id && values.brand_id.value;
    }

    if (values && values.category_id !== undefined) {
      bodyData.category_id =
        values && values.category_id && values.category_id.id;
    }

    if (values && values.category_id && values.category_id.__isNew__ == true) {
      bodyData.category =
        values && values.category_id && values.category_id.value;
    }

    if (values.Unit !== undefined) {
      bodyData.Unit = values && values.Unit && values.Unit.value;
    }

    if (values.Size !== undefined) {
      bodyData.Size = values && values.Size;
    }
    if (values.discount_percentage !== undefined) {
      bodyData.discount_percentage = values && values.discount_percentage;
    }
    if (values.margin_percentage !== undefined) {
      bodyData.margin_percentage = values && values.margin_percentage;
    }

    if (values.print_name !== undefined) {
      bodyData.print_name = values && values.print_name;
    }

    if (values.description !== undefined) {
      bodyData.description = values && values.description;
    }
    if (values.notes !== undefined) {
      bodyData.notes = values && values.notes;
    }

    if (values.vendor_url !== undefined) {
      bodyData.bigbasketUrl = values && values.vendor_url;
    }

    if (values && values.tags !== undefined) {
      bodyData.tags = values && values.tags && JSON.stringify(values.tags);
    }
    if (values?.manufacture !== undefined) {
      bodyData.manufacture_id =
        values && values?.manufacture?.value ? values?.manufacture?.value : "";
    }
    if (values?.manufacture !== undefined) {
      bodyData.manufacture_name =
        values && values?.manufacture?.label ? values?.manufacture?.label : "";
    }

    bodyData.reward =
      values && values?.reward ? values?.reward?.value : "";

    dispatch(updateProduct(id, bodyData, {}, getProductDetail));
    setEditable(true);
  };

  const handleInventorySubmit = async (id, values) => {
    let bodyData = new Object();
    if (values.sku !== undefined) {
      bodyData.sku = values && values.sku;
    }
    if (values.min_stock_days !== undefined) {
      bodyData.min_stock_days = values && values.min_stock_days;
    }
    if (values.max_stock_days !== undefined) {
      bodyData.max_stock_days = values && values.max_stock_days;
    }

    if (values.min_quantity !== undefined) {
      bodyData.min_quantity = values && values.min_quantity;
    }

    if (values.max_quantity !== undefined) {
      bodyData.max_quantity = values && values.max_quantity;
    }

    if (values.pack_size !== undefined) {
      bodyData.pack_size = values?.pack_size && values?.pack_size?.value;
    }

    if (values.shelf_life !== undefined) {
      bodyData.shelf_life = values?.shelf_life && values?.shelf_life?.value;
    }
    if (values.rack_number !== undefined) {
      bodyData.rack_number = values?.rack_number && values?.rack_number;
    }

    if (values.hsn_code !== undefined) {
      bodyData.hsn_code = values.hsn_code && values.hsn_code;
    }

    bodyData.allow_sell_out_of_stock = values && values.allow_sell_out_of_stock;

    bodyData.allow_transfer_out_of_stock =
      values && values.allow_transfer_out_of_stock;

    bodyData.track_quantity = values && values.track_quantity;
    bodyData.allow_online_sale = values && values?.allow_online_sale;
    dispatch(updateProduct(id, bodyData, {}, getProductDetail));
    setEditable(true);
  };

  const handleSEOUpdate = (id, values) => {
    let bodyData = new Object();

    if (values.seo_title != undefined) {
      bodyData.seo_title = values && values.seo_title ? values.seo_title : "";
    }
    if (values.seo_keyword != undefined) {
      bodyData.seo_keyword =
        values && values.seo_keyword ? values.seo_keyword : "";
    }

    if (values.seo_description != undefined) {
      bodyData.seo_description =
        values && values.seo_description ? values.seo_description : "";
    }

    dispatch(updateProduct(id, bodyData, {}, getProductDetail));
    setEditable(true);
  };

  const handleUpdateShopify = (id, values) => {
    let bodyData = new Object();

    if (values && values.shopify_quantity != undefined) {
      bodyData.shopify_quantity = values && values.shopify_quantity;
    }
    if (values && values.shopifyOutOfStock != undefined) {
      bodyData.shopifyOutOfStock = values && values.shopifyOutOfStock;
    }

    if (values && values.shopify_price != undefined) {
      bodyData.shopify_price = values && values.shopify_price;
    }

    dispatch(updateProduct(id, bodyData, {}, getProductDetail));
    setEditable(true);
  };

  // Bulk update handler
  const handleUpdateStore = (ids) => {
    setLocation(ids);
  };

  // Modal Body of Add Store
  const addStoreForm = (
    <Form initialValues={{}}>
      <StoreList
        id="activeStore"
        name={"location"}
        excludeIds={storeIds}
        onBulkSelect={handleUpdateStore}
      />
    </Form>
  );

  // Modal Footer of Add Store
  const storeFooter = (
    <Button
      type="button"
      label="Add Location"
      showButtonLabelName
      onClick={() => {
        handleStoreAdd();
      }}
    />
  );

  const addClone = async (values) => {
    const data = new FormData();
    data.append("mrp", values.mrp);
    data.append("cost_price", values.cost_price);
    data.append("sale_price", values.sale_price);
    data.append("product_id", id);
    dispatch(
      await productService.clone(id, data, (res) => {
        if (res.data) {
          Toast.success(res.data.message);
          addCloneToggle();
          props.history.push(`/products?&tab=Draft`);
        }
      })
    );
  };

  const handleMergeFunction = async (values) => {
    if (values) {
      setMergeModal(false);
      setconfirmMergeModal(true);
    }
  };

  const handleMerge = async () => {
    const data = new FormData();
    data.append("selectedProductId", selectedProduct);
    dispatch(
      await productService.merge(productData.id, data, (res) => {
        if (res) {
          setconfirmMergeModal(false);
          setMergeModal(false);
        }
      })
    );
  };

  const handleReIndex = async () => {
      dispatch(
        await StoreProductService.reIndex(id, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "storeProduct",
                `${endpoints().storeProductAPI}/search`,
                1,
                25,
                params
              )
            );
            setSelectedIds("");
            setSelectedCheckBox(false);
            setSelectedCheckBox(true);
          }
        })
      );
  };

  const handleAddToStockEntry = async () => {
    let data = {
      locationIds: selectedIds,
    };
    dispatch(
      await StoreProductService.addToStockEntry(id, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "storeProductReplenish",
              `${endpoints().storeProductAPI}/replenish/search`,
              1,
              25,
              params
            )
          );
          setSelectedIds("");
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      })
    );
  };

  const addCloneForm = (
    <div>
      <Currency name="mrp" label="Mrp" required={true} />
      <Currency name="cost_price" label="Cost Price" />
      <Currency name="sale_price" label="Sale Price" required={true} />
    </div>
  );

  const cloneFooter = (
    <Button type="submit" label="Add Clone" />
  );

  const mergeConfirmationModel = (
    <div>
      <h4>From:</h4>
      <ProductCard
        productImageIcon
        square
        productName={productData?.name}
        url={productData?.url}
        brandName={productData?.brandName}
        size={productData?.size}
        unit={productData?.unit}
        salePrice={productData?.sale_price}
        mrp={productData?.mrp}
        packSize={productData?.pack_size}
        disableLink
        status={productData?.status}
      />
      <h4 className="mt-2">To:</h4>
      <ProductCard
        productImageIcon
        square
        productName={selectedProductData?.name}
        url={selectedProductData?.url}
        brandName={selectedProductData?.brandName}
        size={selectedProductData?.size}
        unit={selectedProductData?.unit}
        salePrice={selectedProductData?.sale_price}
        mrp={selectedProductData?.mrp}
        packSize={selectedProductData?.pack_size}
        disableLink
      />
    </div>
  );

  const mergeCOnfirmationModelFooter = (
    <>
      <SaveButton type="submit" label="Yes" />
      <CancelButton
        onClick={() => {
          setconfirmMergeModal(false);
        }}
      />
    </>
  );

  const getVendorDetails = async () => {
    const { data } = await AccountService.searchVendor();
    let vendorList = [];
    data.forEach((values) => {
      vendorList.push({
        id: values.id,
        label: values.vendorName,
        value: values.vendorName,
      });
    });
    setVendorList(vendorList);
  };

  const addVendorForm = (
    <div>
      <Select name="vendor_name" label="Vendor" options={vendorList} />
      <Text name="vendor_url" label="Vendor Product Url" />
    </div>
  );

  const vendorFooter = (
    <Button type="submit" label="Add Vendor" />
  );

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Products",
      link: "/products",
    },
    {
      label: activeTab,
      link: "",
    },
  ];

  // Actions menu list
  const actionsMenuList = [];

  if (
    activeTab === ProductDetailTab.GENERAL ||
    activeTab === ProductDetailTab.INVENTORY ||
    activeTab === ProductDetailTab.TAX ||
    activeTab === ProductDetailTab.SEO ||
    (activeTab === ProductDetailTab.SHOPIFY && showEditButton && editable)
  ) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (activeTab === ProductDetailTab.LOCATION) {
    actionsMenuList.push(
      {
        label: LocationProduct.ADD_TO_STOCK_ENTRY,
        value: LocationProduct.ADD_TO_STOCK_ENTRY,
      },
      {
        label: LocationProduct.LOCATION_PRODUCT_REINDEX,
        value: LocationProduct.LOCATION_PRODUCT_REINDEX,
      },
      {
        label: LocationProduct.UPDATE_MIN_MAX_ORDER_QUANTITY,
        value: LocationProduct.UPDATE_MIN_MAX_ORDER_QUANTITY,
      },
      {
        label: LocationProduct.BULK_UPDATE,
        value: LocationProduct.BULK_UPDATE,
      },
      {
        label: LocationProduct.CORRECT_SYSTEM_QUANTITY,
        value: LocationProduct.CORRECT_SYSTEM_QUANTITY,
      },
    );
  }

  if (activeTab === ProductDetailTab.GENERAL) {
    actionsMenuList.push(
      { label: "Clone", value: "Clone" },
      { label: "Merge To", value: "Merge To" },

      { label: "Print Price Tag", value: "Print Price Tag" },
      { label: "Reindex", value: "Reindex" },

      { label: "Delete", value: "Delete" }
    );
  }

  if (activeTab === ProductDetailTab.PRICE) {
    actionsMenuList.push({ label: "Reindex", value: "Reindex" });
  }

  const handleChange = async (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }

    if (e == "Clone") {
      setAddCloneModal(true);
    }

    if (e === LocationProduct.UPDATE_MIN_MAX_ORDER_QUANTITY) {
        StoreProductService.updateMinMaxQuantity(
          props.match.params.tab,
          (err, res) => {
            if (!err && res) {
              dispatch(
                fetchList(
                  "storeProduct",
                  `${endpoints().storeProductAPI}/search`,
                  1,
                  25,
                  params
                )
              );
            }
          }
        );
    }
    if (e === LocationProduct.CORRECT_SYSTEM_QUANTITY) {
        StoreProductService.correctSystemQuantity(
          props.match.params.tab,
          (err, res) => {
            if (!err && res) {
              dispatch(
                fetchList(
                  "storeProduct",
                  `${endpoints().storeProductAPI}/search`,
                  1,
                  25,
                  params
                )
              );
            }
          }
        );
    }

    if (e == "Print Price Tag") {
      document.getElementById("print-price-tag").click();
    }

    if (e == "Merge To") {
      setMergeModal(true);
    }

    if (e === "Reindex") {
      if (productId) {
        dispatch(ProductService.reIndex([productId]));
      }
    }

    if (e == LocationProduct.LOCATION_PRODUCT_REINDEX) {
      handleReIndex();
    }

    if (e == LocationProduct.ADD_TO_STOCK_ENTRY) {
      handleAddToStockEntry();
    }

    if (e == "Bulk Update") {
      if (
        selectedIds &&
        selectedIds.selectedIds &&
        selectedIds.selectedIds.length > 0
      ) {
        toggleBulkUpdateModal();
      } else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const imagetoggle = (id) => {
    if (id) {
      setModalOpen(!modalOpen);
    } else {
      setModalOpen(!modalOpen);
      setCurrentData("");
      setImageStatus("");
      setSelectedFile("");
      setErrorMessage("");
      setSelectedFile("");
      setFile("");
    }
  };

  const getStoreId = async () => {
    // setIsLoading(true);
    const responce = await apiClient.get(
      `${endpoints().storeProductAPI}/replenish/search?productId=${productId}`
    );
    const data = responce?.data?.data;
    setIsLoading(false);
    let id = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        id.push(data[i].store_id);
      }
    }
    setStoreIds(id);
  };

  const getProductPriceValue = async () => {
    let params = {
      product_id: props?.match?.params?.tab,
    };
    let response = await ProductPriceService.search(params);
    let data = response && response?.data && response?.data?.data;
    if (data && data.length > 0) {
      data.map((data) => {
        if (data?.type == "Default") setPriviousBarCode(data?.barCode);
        return;
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const handleReplenish = async (selectedQuantity) => {
    if (selectedQuantity && selectedQuantity.quantity && selectedRow) {
      let bodyData = {
        toLocationId: selectedRow?.store_id,
        quantity: selectedQuantity.quantity.value,
        productId: selectedRow.productId,
      };

      await TransferService.replenish(bodyData, () => {
        toggleQuantitySelectModal();

        dispatch(
          fetchList(
            "storeProductReplenish",
            `${endpoints().storeProductAPI}/replenish/search`,
            1,
            25,
            params
          )
        );
      });
      setEditable(true);
    }
  };

  const generalTabChange = () => {
    toggle(ProductDetailTab.GENERAL);
    _handleStatusChange(ProductDetailTab.GENERAL);
  };

  const inventoryTabChange = () => {
    toggle(ProductDetailTab.INVENTORY);
    _handleStatusChange(ProductDetailTab.INVENTORY);
  };

  const priceTabChange = () => {
    toggle(ProductDetailTab.PRICE);
    _handleStatusChange(ProductDetailTab.PRICE);
  };

  const taxTabChange = () => {
    toggle(ProductDetailTab.TAX);
    _handleStatusChange(ProductDetailTab.TAX);
  };

  const mediaTabChange = () => {
    toggle(ProductDetailTab.MEDIA);
    _handleStatusChange(ProductDetailTab.MEDIA);
  };

  const seoTabChange = () => {
    toggle(ProductDetailTab.SEO);
    _handleStatusChange(ProductDetailTab.SEO);
  };

  const locationTabChange = () => {
    toggle(ProductDetailTab.LOCATION);
    _handleStatusChange(ProductDetailTab.LOCATION);
  };

  const shopifyTabChange = () => {
    toggle(ProductDetailTab.SHOPIFY);
    _handleStatusChange(ProductDetailTab.SHOPIFY);
  };

  const purchaseTabChange = () => {
    toggle(ProductDetailTab.PURCHASE);
    _handleStatusChange(ProductDetailTab.PURCHASE);
  };

  const orderTabChange = () => {
    toggle(ProductDetailTab.ORDERS);
    _handleStatusChange(ProductDetailTab.ORDERS);
  };

  const vendorTabChange = () => {
    toggle(ProductDetailTab.VENDOR);
    _handleStatusChange(ProductDetailTab.VENDOR);
  };

  const historyTabChange = () => {
    toggle(ProductDetailTab.HISTORY);
    _handleStatusChange(ProductDetailTab.HISTORY);
  };

  const stockEntryTabChange = () => {
    toggle(ProductDetailTab.STOCK_ENTRY_PRODUCT);
    _handleStatusChange(ProductDetailTab.STOCK_ENTRY_PRODUCT);
  };

  const transferTabChange = () => {
    toggle(ProductDetailTab.TRANSFER);
    _handleStatusChange(ProductDetailTab.TRANSFER);
  };

  const toggleQuantitySelectModal = () => {
    setOpenQuantityModal(false);
    setSelectedRow("");
  };

  const closeDeleteModal = () => {
    setPriceDeletModal(!openPriceDeleteModal);
    setSelectedPriceData("");
  };

  const NavTabList = [
    {
      label: ProductDetailTab.GENERAL,
      onClick: generalTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.GENERAL }),
    },
    {
      label: ProductDetailTab.INVENTORY,
      onClick: inventoryTabChange,
      className: classNames({
        active: activeTab === ProductDetailTab.INVENTORY,
      }),
    },
    {
      label: ProductDetailTab.PRICE,
      onClick: priceTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.PRICE }),
    },
    {
      label: ProductDetailTab.TAX,
      onClick: taxTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.TAX }),
    },
    {
      label: ProductDetailTab.MEDIA,
      onClick: mediaTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.MEDIA }),
    },
    {
      label: ProductDetailTab.SEO,
      onClick: seoTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.SEO }),
    },
    {
      label: ProductDetailTab.SHOPIFY,
      onClick: shopifyTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.SHOPIFY }),
    },
    {
      label: ProductDetailTab.LOCATION,
      onClick: locationTabChange,
      className: classNames({
        active: activeTab === ProductDetailTab.LOCATION,
      }),
    },
    {
      label: ProductDetailTab.PURCHASE,
      onClick: purchaseTabChange,
      className: classNames({
        active: activeTab === ProductDetailTab.PURCHASE,
      }),
    },
    {
      label: ProductDetailTab.ORDERS,
      onClick: orderTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.ORDERS }),
    },

    {
      label: ProductDetailTab.TRANSFER,
      onClick: transferTabChange,
      className: classNames({
        active: activeTab === ProductDetailTab.TRANSFER,
      }),
    },

    {
      label: ProductDetailTab.STOCK_ENTRY_PRODUCT,
      onClick: stockEntryTabChange,
      className: classNames({
        active: activeTab === ProductDetailTab.STOCK_ENTRY_PRODUCT,
      }),
    },
    {
      label: ProductDetailTab.VENDOR,
      onClick: vendorTabChange,
      className: classNames({ active: activeTab === ProductDetailTab.VENDOR }),
    },
    ...(showHistory
      ? [
          {
            label: ProductDetailTab.HISTORY,
            onClick: historyTabChange,
            className: classNames({
              active: activeTab === ProductDetailTab.HISTORY,
            }),
          },
        ]
      : []),
  ];

  const togglePriceModal = () => {
    setPriceEditModal(false);
    setIsSubmit(true);
    setSelectedDate(new Date());
    setSelectedPriceData("");
    setPriceDeletModal(false);
    getProductDetail();
    setPriviousBarCode(null);
    setIsClone(false);
    setMrp("");
    setBarcode("");
    setPriceStatus("");
    setCostPrice("");
    setIsDefault(false);
    setSalePrice("");
    setDiscount("");
    setDate("");
  };

  const deleteProductPrice = async () => {
    if (selectedPriceData) {
      dispatch(
        await ProductPriceService.delete(
          selectedPriceData.id,
          Url.GetAllParams(),
          togglePriceModal
        )
      );
    }
  };

  const updatePriceStatus = async (priceId, statusId, params) => {
    dispatch(
      await ProductPriceService.updateStatus(
        priceId,
        { status: statusId },
        {
          ...params,
          sort: Url.GetParam("sort") ? Url.GetParam("sort") : "",
          sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "",
        }
      )
    );
  };

  const updatePrice = async (priceId) => {
    dispatch(
      await ProductPriceService.updateIsDefault(
        priceId,
        { id: priceId, isDefault: true },
        Url.GetAllParams()
      )
    );
  };

  const getProductDetails = (
    productName,
    productImage,
    brandName,
    size,
    unit,
    salePrice,
    mrp,
    brand_id,
    status,
    packSize
  ) => {
    return (
      <ProductCard
        productImageIcon
        square
        productName={productName}
        url={productImage}
        brandName={brandName}
        size={size}
        unit={unit}
        salePrice={salePrice}
        mrp={mrp}
        status={status}
        packSize={packSize}
        brand_id={brand_id}
        disableLinks
      />
    );
  };

  const multiselect = (values) => {
    if (values.length == 1) {
      setSelectProduct(values);
    }
  };

  const calculateSalePrice = (mrp, discount) => {
    let mrpValue = mrp ? mrp : selectedPriceData.mrp;
    let discountValue = discount
      ? discount
      : selectedPriceData.discount_percentage;
    if (!isNaN(mrpValue) || !isNaN(discountValue)) {
      const salePrice = discountValue
        ? mrpValue - (mrpValue * discountValue) / 100
        : mrpValue;
      setSalePrice(salePrice);
    }
  };

  const handleSalePrice = (data) => {
    let value = (data && data.values && data.values.salePrice) || " ";
    setSalePrice(value);
  };

  const handleMrpChange = (data) => {
    let value = (data && data.values && data.values.mrp) || " ";
    setMrp(value);
    calculateSalePrice(value, discountValue);
  };

  const handleDiscountPercentChange = (data) => {
    let value = (data && data.values && data.values.discount_percentage) || " ";
    setDiscount(value);
    calculateSalePrice(mrpValue, value);
  };

  const handleCostPrice = (data) => {
    let value = (data && data.values && data.values.costPrice) || "";
    setCostPrice(value);
  };

  const handleBarCode = (data) => {
    let value = data && data.target && data.target.value;
    setBarcode(value);
  };

  const handleStatusChange = (data) => {
    let value = data;
    setPriceStatus(value);
  };

  const handleDate = (data) => {
    const dateValue = data ? DateTime.toISOStringDate(data) : "";
    setDate(dateValue);
  };

  const handleIsDefault = (data) => {
    let value = data && data.isDefault;
    setIsDefault(value);
  };

  return (
    <>
      {/* Bread crumb section */}
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        {/* Page Title */}
        <PageTitle
          label={getProductDetails(
            productData?.name,
            productData?.url,
            productData?.brandName,
            productData?.size,
            productData?.unit,
            productData?.sale_price,
            productData?.mrp,
            productData?.brand_id,
            productData?.status,
            productData?.pack_size
          )}
        />

        <QuantityModal
          handleSubmit={handleReplenish}
          toggle={openQuantityModal}
          onModalClose={toggleQuantitySelectModal}
          modalTitle={"Add To Transfer"}
          confirmLabel={"Add"}
          quantity={selectedRow && selectedRow.replenishQuantity}
        />

        {/* Action Menu */}
        <div className="d-flex justify-content-between">
          {activeTab == "Vendor" && (
            <AddButton
              label="Add"
              onClick={(e) => {
                setOpenVendorModal(true);
              }}
            />
          )}
          {activeTab == ProductDetailTab.PRICE && showProductPriceAdd && (
            <AddButton
              label="Add Price"
              className="my-1 me-2"
              onClick={() => {
                getProductPriceValue();
                setPriceEditModal(true);
              }}
              onFocus={() => getProductPriceValue()}
            />
          )}
          {activeTab == ProductDetailTab.MEDIA && (
            <AddButton
              className="ms-3 me-2 mt-1"
              label="Add"
              onClick={(e) => {
                imagetoggle();
              }}
            />
          )}
          {activeTab == ProductDetailTab.LOCATION && (
            <div className="d-flex  d-flex">
              {/* Add Button */}
              <div className="d-flex">
                <div className="me-2">
                  <StoreBulkUpdateModal
                    isOpen={openBulkUpdateModal}
                    toggle={toggleBulkUpdateModal}
                    storeIds={selectedIds}
                    productId={productId}
                    setSelectedIds={setSelectedIds}
                    setSelectedCheckBox={setSelectedCheckBox}
                  />
                </div>
                <AddButton
                  label="Add"
                  className="me-2 mt-0 mt-sm-1"
                  onClick={(e) => {
                    setAddStoreModal(true);
                  }}
                />
              </div>
            </div>
          )}

          <div className="me-2">
            <Action
              buttonLabel={productData.status}
              dropdownLinks={productStatusOptions}
              handleChange={onStatusChange}
            />
          </div>
          <div>
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>

      {/* Product delete modal */}
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Product"
        id={productData.id}
        label={productData.name}
        deleteFunction={productDelete}
      />

      {/* Store Add Modal */}
      {addStoreModal && (
        <AddModal
          isOpen={addStoreModal}
          toggle={toggle}
          toggleModalClose={addStoreToggle}
          modalTitle="Add"
          modalBody={addStoreForm}
          modalFooter={storeFooter}
          hideDefaultButtons
        />
      )}

      {openVendorModal && (
        <AddModal
          isOpen={openVendorModal}
          toggle={toggle}
          toggleModalClose={addVendorToggle}
          modalTitle="Add"
          modalBody={addVendorForm}
          modalFooter={vendorFooter}
          initialValues={{ vendor_name: "", vendor_url: "" }}
          onSubmit={addVendor}
          hideDefaultButtons
        />
      )}

      <AddModal
        isOpen={addCloneModal}
        toggle={toggle}
        toggleModalClose={addCloneToggle}
        modalTitle="Add"
        modalBody={addCloneForm}
        modalFooter={cloneFooter}
        initialValues={{ mrp: "", cost_price: "", sale_price: "" }}
        hideDefaultButtons
        onSubmit={addClone}
      />

      <ProductSelectModal
        modalOpen={mergeModal}
        toggle={mergeModelToggle}
        toggleModalClose={mergeToggle}
        handleSubmit={handleMergeFunction}
        BulkSelect={multiselect}
        history={history}
        footerLabel="Select Product"
        showBrandFilter
        showCategoryFilter
        checkBoxLimit={1}
      />

      <AddModal
        isOpen={confirmMergeModal}
        toggle={setconfirmMergeModal}
        toggleModalClose={() => {
          setconfirmMergeModal(false);
        }}
        initialValues={{ storeProduct: "" }}
        modalTitle="Are You Sure Want To Merge"
        modalBody={mergeConfirmationModel}
        hideDefaultButtons
        modalFooter={mergeCOnfirmationModelFooter}
        onSubmit={handleMerge}
      />

      <PrintPriceTag productData={productData} />

      {/* Nav Section for product detail tabs navigation */}
      <NavTab list={NavTabList} />

      {/* Form Section */}
      <div className="mb-5 w-100">
        {/* Tab Content starts */}
        <TabContent activeTab={activeTab}>
          <Form
            enableReinitialize={true}
            initialValues={{
              vendor_url: productData.vendor_url,
              Size: productData.size,
              discount_percentage:
                productData && productData.discount_percentage,
              margin_percentage: productData && productData.margin_percentage,
              name: productData.name,
              slug: productData.slug,
              Unit:
                productData.unit == null
                  ? []
                  : {
                    label: productData.unit,
                    value: productData.unit,
                  },
              description: productData.description,
              notes: productData.notes,
              print_name: productData.print_name ? productData.print_name : "",

              category_id: productData.category_id
                ? [
                  {
                    value: productData.category_id,
                    label: productData.categoryName,
                    id: productData.category_id,
                  },
                ]
                : [],

              manufacture:
                productData.manufacture_id == null
                  ? []
                  : {
                    label: productData.manufacture_name,
                    value: productData.manufacture_id,
                  },

              brand_id: productData.brand_id
                ? [
                  {
                    value: productData.brand_id,
                    label: productData.brandName,
                    id: productData.brand_id,
                  },
                ]
                : [],
              // tags initialValues
              ...initialValues,
              reward: productData?.reward
                ? {
                  label: productData?.reward,
                  value: productData?.reward,
                }
                : "",
            }}
            onSubmit={(values) => {
              let id = props.match.params.tab;
              handleGeneralUpdate(id, values);
            }}
          >
            {/* General Tab */}
            {activeTab == ProductDetailTab.GENERAL && (
              <TabPane tabId={ProductDetailTab.GENERAL}>
                <div className="card bg-white mt-3 col-lg-12">
                  <div className="card-body">
                    <div className=" field-wrapper row">
                      <div className="col-sm-6">
                        <Text name="name" label="Name" disabled={editable} />

                        <Text
                          name="print_name"
                          label="Print Name"
                          disabled={editable}
                        />

                        <Text name="slug" label="Slug" disabled={editable} />

                        <TextArea
                          className="w-100 mt-2 pull-left"
                          name="description"
                          label="Description"
                          disabled={editable}
                        />
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <NumberComponent
                              name="Size"
                              label="Size"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <Select
                              name="Unit"
                              placeholder="Select Unit"
                              label="Unit"
                              options={Product.weightUnitOptions}
                              isDisabled={editable}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <PercentageComponent
                              name="discount_percentage"
                              label="Discount%"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <PercentageComponent
                              name="margin_percentage"
                              label="Margin%"
                              disabled={editable}
                            />
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <NumberSelect
                            name="reward"
                            label="Reward"
                            limit={100}
                            isDisabled={editable}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <FeatureImage
                          src={productData?.url}
                          alt="Feature product image"
                          size={"large"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Product Organization section */}
                <div className="mt-3 card card-body">
                  <h4>Product Organization</h4>
                  <h6>Brand</h6>
                  <BrandSelect
                    name="brand_id"
                    isDisabled={editable}
                    showBrandDetailsPageLink={productData && productData?.brand_id ? true : false}
                    brandId={productData && productData?.brand_id ? productData?.brand_id : ""}
                  />
                  <div className="mt-3">
                    <h6>Category</h6>
                    <CategorySelect
                      name="category_id"
                      isDisabled={editable}
                      showCategoryDetailsPageLink={productData && productData?.category_id ? true : false}
                      categoryId={productData && productData?.category_id ? productData?.category_id : ""}
                    />
                  </div>
                  <div class="d-flex bd-highlight">
                    <div class="w-100 bd-highlight mt-3">
                      <URL
                        label="Vendor Url"
                        name="vendor_url"
                        disabled={editable}
                      />
                    </div>
                    <div class="p-2 flex-shrink-1 bd-highlight mt-5">
                      <a
                        href={productData.vendor_url}
                        className="text-dark"
                        target="_blank"
                      >
                        <i class="fa fa-lg fa-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div className="my-2 mb-3">
                    <ProductTagSelector
                      id="tags"
                      name="tags"
                      placeholder="Select Product Tag"
                      label="Tag"
                      selectedTagId={productData.tag_id}
                      disabled={editable}
                    />
                  </div>
                </div>
                <div className="mt-3 card card-body">
                  <Notes name="notes" label="Notes" disabled={editable} />
                </div>

                {/* Save and Cancel Button Section */}
                {!editable && (
                  <div className="mt-3 card card-body">
                    <HorizontalSpace>
                      <SaveButton label="Save" />
                      <CancelButton
                        onClick={(e) => {
                          history.push("/products");
                        }}
                      />
                    </HorizontalSpace>
                  </div>
                )}
              </TabPane>
            )}
          </Form>

          {/* Inventory Tab */}
          {activeTab == ProductDetailTab.INVENTORY && (
            <TabPane tabId={ProductDetailTab.INVENTORY}>
              <>
                <Form
                  enableReinitialize={true}
                  initialValues={{
                    sku: productData.sku,
                    max_quantity: productData.max_quantity,
                    min_quantity: productData.min_quantity,
                    pack_size:
                      productData.pack_size == null
                        ? null
                        : {
                          label: productData.pack_size,
                          value: productData.pack_size,
                        },
                    shelf_life: productData?.shelf_life == null
                      ? null
                      : {
                        label: productData?.shelf_life,
                        value: productData?.shelf_life,
                      },
                    rack_number: productData?.rack_number ? productData?.rack_number : null,
                    hsn_code: productData.hsn_code,
                    allow_sell_out_of_stock: Boolean.Get(
                      productData.allow_sell_out_of_stock
                    ),
                    allow_transfer_out_of_stock:
                      productData.allow_transfer_out_of_stock,
                    track_quantity: Boolean.Get(productData.track_quantity),
                    min_stock_days: productData.min_stock_days,
                    max_stock_days: productData.max_stock_days,
                    allow_online_sale: Boolean.Get(
                      productData.allow_online_sale
                    ),
                  }}
                  onSubmit={(values) => {
                    let id = props.match.params.tab;
                    handleInventorySubmit(id, values);
                  }}
                >
                  {/* Inventory Section */}
                  <div className="mt-3 card card-body">
                    <div className="row">
                      {/* Fields */}
                      <div className="col-lg-6 col-sm-6">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <Text name="sku" label="SKU" disabled={editable} />
                          </div>
                          <div className="col-12 col-sm-6">
                            <NumberComponent
                              label="HSN Code"
                              name="hsn_code"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <Quantity
                              name="pack_size"
                              placeholder="Select Pack Size"
                              label="Pack Size"
                              maxQuantity={100}
                              isDisabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <NumberSelect
                              name="shelf_life"
                              label="Shelf Life"
                              isDisabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <Text
                              name="rack_number"
                              label="Rack#"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <NumberComponent
                              name="min_quantity"
                              label="Min Quantity"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <NumberComponent
                              name="max_quantity"
                              label="Max Quantity"
                              disabled={editable}
                            />
                          </div>

                          <div className="col-12 col-sm-6">
                            <Text
                              name="min_stock_days"
                              label="Min Stock Days"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <Text
                              name="max_stock_days"
                              label="Max Stock Days"
                              disabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <SingleCheckbox
                              name="allow_sell_out_of_stock"
                              label="Continue selling when out of stock"
                              className="py-1"
                              enabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <SingleCheckbox
                              name="allow_transfer_out_of_stock"
                              label="Allow transfer when out of stock"
                              className="py-1"
                              enabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <SingleCheckbox
                              name="track_quantity"
                              label="Track quantity"
                              className="py-1"
                              enabled={editable}
                            />
                          </div>
                          <div className="col-12">
                            <SingleCheckbox
                              className="py-1"
                              name="allow_online_sale"
                              label={"Allow Online Sale"}
                              enabled={editable}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Barcode */}
                      <div className="col-lg-6 col-sm-6 d-flex align-items-center justify-content-center">
                        <Barcode
                          value={productData.barcode ? productData.barcode : ""}
                        />
                      </div>
                    </div>

                    {/* Save and Cancel Button Section */}
                    {!editable && (
                      <div className="mt-3">
                        <div>
                          <HorizontalSpace>
                            <SaveButton label="Save" />
                            <CancelButton
                              onClick={(e) => {
                                history.push("/products");
                              }}
                            />
                          </HorizontalSpace>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              </>
            </TabPane>
          )}

          {/* price Tab */}
          {activeTab == ProductDetailTab.PRICE && (
            <TabPane tabId={ProductDetailTab.PRICE}>
              <>
                <ProductPriceEditModal
                  isOpen={priceEditModal}
                  toggle={togglePriceModal}
                  productId={props?.match?.params?.tab}
                  priceData={selectedPriceData}
                  selectedDate={selectedDate}
                  priviousBarCode={priviousBarCode}
                  setIsSubmit={setIsSubmit}
                  isSubmit={isSubmit}
                  showProductPriceEdit={showProductPriceEdit}
                  isClone={isClone}
                  handleMrpChange={handleMrpChange}
                  handleDiscountPercentChange={handleDiscountPercentChange}
                  salePriceValue={salePriceValue}
                  mrpValue={mrpValue}
                  discountValue={discountValue}
                  handleBarCode={handleBarCode}
                  handleCostPrice={handleCostPrice}
                  handleIsDefault={handleIsDefault}
                  defaultValue={defaultValue}
                  costPriceValue={costPriceValue}
                  barCodeValue={barCodeValue}
                  priceStatus={priceStatus}
                  handleStatusChange={handleStatusChange}
                  handleDate={handleDate}
                  dateValue={dateValue}
                  handleSalePrice={handleSalePrice}
                />

                <DeleteModal
                  isOpen={openPriceDeleteModal}
                  toggle={closeDeleteModal}
                  title="Delete Price"
                  id={productData.id}
                  label={selectedPriceData?.salePrice}
                  deleteFunction={deleteProductPrice}
                />

                <div className="mt-2">
                  <ReduxTable
                    id="productPrice"
                    newTableHeading
                    noRecordFoundHeight={"7vh"}
                    searchPlaceholder="Search"
                    params={{
                      product_id: props?.match?.params?.tab,
                      sort: "id",
                      sortDir: "DESC",
                      objectName: ObjectName.PRODUCT_PRICE,
                    }}
                    apiURL={`${endpoints().productPrice}/search`}
                    paramsToUrl={true}
                    history={history}
                    showStatusFilter
                    sortByOptions={sortByOption}
                  >
                    <ReduxColumn
                      field="id"
                      sortBy="id"
                      isClickable="true"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      type="link"
                      className="text-center"
                      renderField={(row) => (
                        <Link to={`/product/${row?.product_id}/price/${row?.id}`}>
                          {row?.id}
                        </Link>
                      )}
                    >
                      Price#
                    </ReduxColumn>
                    <ReduxColumn
                      sortBy="date"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      className="text-center"
                      renderField={(row) => (
                        <span>
                          {DateTime.getDateByUserProfileTimeZoneFrontEndFormat(
                            row?.date
                          )}
                        </span>
                      )}
                    >
                      Date
                    </ReduxColumn>
                    <ReduxColumn
                      field="mrp"
                      sortBy="mrp"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      className="text-center"
                      renderField={(row) => (
                        <span>{CurrencyFormat.Format(row?.mrp)}</span>
                      )}
                    >
                      MRP
                    </ReduxColumn>
                    <ReduxColumn
                      field="costPrice"
                      sortBy="cost_price"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      className="text-center"
                      renderField={(row) => (
                        <span>{CurrencyFormat.Format(row?.costPrice)}</span>
                      )}
                    >
                      Cost Price
                    </ReduxColumn>
                    <ReduxColumn
                      field="salePrice"
                      sortBy="sale_price"
                      className="text-center"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      renderField={(row) => (
                        <span>{CurrencyFormat.Format(row?.salePrice)}</span>
                      )}
                    >
                      Sale Price
                    </ReduxColumn>
                    <ReduxColumn
                      field="margin_percentage"
                      sortBy="margin_percentage"
                      className="text-center"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      disableColumnSort
                      disableOnClick
                      renderField={(row) => (
                        <span>{Percentage(row?.margin_percentage)}</span>
                      )}
                    >
                      Margin %
                    </ReduxColumn>
                    <ReduxColumn
                      field="margin_amount"
                      sortBy="margin_amount"
                      className="text-center"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      disableColumnSort
                      disableOnClick
                      renderField={(row) => (
                        <span>{CurrencyFormat.Format(row?.margin_amount)}</span>
                      )}
                    >
                      Margin Amount
                    </ReduxColumn>
                    <ReduxColumn
                      field="barCode"
                      sortBy="barcode"
                      className="text-center"
                      width="190px"
                      minWidth="190px"
                      maxWidth="190px"
                    >
                      BarCode
                    </ReduxColumn>
                    <ReduxColumn
                      field="statusText"
                      sortBy="status"
                      width="130px"
                      minWidth="130px"
                      maxWidth="130px"
                      renderField={(row) => (
                        <StatusText
                          backgroundColor={
                            row?.statusText == ProductPriceStatus?.ACTIVE_TEXT
                              ? "green"
                              : row?.statusText ==
                                ProductPriceStatus?.INACTIVE_TEXT
                                ? "red"
                                : ""
                          }
                          status={row?.statusText}
                        />
                      )}
                    >
                      Status
                    </ReduxColumn>
                    <ReduxColumn
                      field="type"
                      sortBy="is_default"
                      className="text-center"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                    >
                      Type
                    </ReduxColumn>
                    <ReduxColumn
                      field="createdAt"
                      sortBy="createdAt"
                      className="text-center"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      renderField={(row) => (
                        <span>
                          {row.createdAt}
                        </span>
                      )}
                    >
                      Created At
                    </ReduxColumn>
                    <ReduxColumn
                      className="text-center"
                      field="status"
                      isClickable="true"
                      renderField={(row) => (
                        <div className="action-group-dropdown">
                          <MoreDropdown
                            onClick={() => {
                              setStatusList([]);
                              getStatusList(row.status);
                            }}
                          >
                            <DropdownItem
                              onClick={() => {
                                setPriceEditModal(true);
                                setSelectedPriceData(row);
                              }}
                            >
                              Quick View
                            </DropdownItem>
                            {statusList &&
                              statusList.length > 0 &&
                              statusList.map((data) => {
                                return (
                                  <DropdownItem
                                    onClick={() => {
                                      updatePriceStatus(row.id, data.value, {
                                        search: Url.GetParam("search") || "",
                                        page:
                                          Url.GetParam("page") ||
                                          props.currentPage,
                                        product_id: productId,
                                        pageSize:
                                          Url.GetParam("pageSize") ||
                                          props.currentPageSize,
                                      });
                                    }}
                                  >
                                    {data.label}
                                  </DropdownItem>
                                );
                              })}
                            <DropdownItem
                              onClick={() => {
                                updatePrice(row.id);
                              }}
                            >
                              Set as Default
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setPriceEditModal(true);
                                setSelectedPriceData(row);
                                setIsClone(true);
                              }}
                            >
                              Clone
                            </DropdownItem>
                            {showProductPriceDelete && (
                              <DropdownItem
                                className="text-danger"
                                onClick={() => {
                                  setPriceDeletModal(true);
                                  setSelectedPriceData(row);
                                }}
                              >
                                Delete
                              </DropdownItem>
                            )}
                          </MoreDropdown>
                        </div>
                      )}
                    >
                      Actions
                    </ReduxColumn>
                  </ReduxTable>
                </div>
              </>
            </TabPane>
          )}

          {/* Tax tab */}
          {activeTab == ProductDetailTab.TAX && (
            <TabPane tabId={ProductDetailTab.TAX}>
              <Form
                enableReinitialize={true}
                initialValues={{
                  cess_percentage: productData.tax_percentage,
                  cgst_percentage:
                    cgstPercentageValue == ""
                      ? ""
                      : cgstPercentageValue
                        ? cgstPercentageValue
                        : productData.cgst_percentage,
                  sgst_percentage:
                    sgstPercentageValue == ""
                      ? ""
                      : sgstPercentageValue
                        ? sgstPercentageValue
                        : productData.sgst_percentage,
                  igst_percentage:
                    igstPercentageValue == ""
                      ? ""
                      : igstPercentageValue
                        ? igstPercentageValue
                        : productData.igst_percentage,
                }}
                onSubmit={(values) => {
                  let id = props.match.params.tab;
                  handleTaxUpdate(id, values);
                }}
              >
                {/* Image List Table Component */}
                <div className="mt-3 card card-body">
                  <div className="row mt-0">
                    <div className="col-12 col-sm-6">
                      <PercentageComponent
                        name="cgst_percentage"
                        label="CGST%"
                        disabled={editable}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <PercentageComponent
                        name="sgst_percentage"
                        label="SGST%"
                        disabled={editable}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <PercentageComponent
                        name="cess_percentage"
                        label="CESS%"
                        disabled={editable}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <PercentageComponent
                        name="igst_percentage"
                        label="IGST%"
                        disabled={editable}
                      />
                    </div>
                  </div>
                  {!editable && (
                    <div>
                      <HorizontalSpace>
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={(e) => {
                            history.push("/products");
                          }}
                        />
                      </HorizontalSpace>
                    </div>
                  )}
                </div>
              </Form>
            </TabPane>
          )}

          {/* Media Tab */}
          {activeTab == ProductDetailTab.MEDIA && (
            <TabPane tabId={ProductDetailTab.MEDIA}>
              {/* Image List Table Component */}
              <ImageList
                title="Product"
                objectName={ObjectName.PRODUCT}
                objectId={props.match.params.tab}
                showFeature={true}
                history={props.history}
                getDetails={getProductDetail}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                selectedFile={selectedFile}
                setSelectedFile={selectedFile}
                file={file}
                currentData={currentData}
                setCurrentData={setCurrentData}
                status={imagestatus}
                setImageStatus={setImageStatus}
                setFile={setFile}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                toggle={imagetoggle}
                hideAttachments
                isDetail={true}
              />
            </TabPane>
          )}

          {/* SEO Tab */}
          {activeTab == ProductDetailTab.SEO && (
            <TabPane tabId={ProductDetailTab.SEO}>
              <Form
                enableReinitialize={true}
                initialValues={{
                  seo_title: productData.seo_title,
                  seo_keyword: productData.seo_keyword,
                  seo_description: productData.seo_description,
                }}
                onSubmit={(values) => {
                  let id = props.match.params.tab;
                  handleSEOUpdate(id, values);
                }}
              >
                <div className="card bg-white mt-3 col-lg-12">
                  <div className="card-body">
                    <div className=" field-wrapper row">
                      <div className="col-sm-6">
                        <Text
                          name="seo_title"
                          label="SEO Title"
                          disabled={editable}
                        />
                        <Text
                          name="seo_keyword"
                          label="SEO Keyword"
                          disabled={editable}
                        />
                        <TextArea
                          name="seo_description"
                          label="SEO Description"
                          disabled={editable}
                        />
                        {!editable && (
                          <HorizontalSpace top="5">
                            <SaveButton label="Save" />
                            <CancelButton
                              onClick={(e) => {
                                history.push("/products");
                              }}
                            />
                          </HorizontalSpace>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </TabPane>
          )}

          {/* Stores Tab */}
          {activeTab == ProductDetailTab.LOCATION && (
            <TabPane tabId={ProductDetailTab.LOCATION}>
              <div className="mt-2">
                <LocationProductList
                  history={history}
                  handleBulkSelect={handleBulkSelect}
                  productId={productId}
                  ActionMenu={ActionMenu}
                  selectedCheckBox={selectedCheckBox}
                  showTotal={true}
                />
              </div>
            </TabPane>
          )}

          {/* Shopify Tab */}
          {activeTab == ProductDetailTab.SHOPIFY && (
            <TabPane tabId={ProductDetailTab.SHOPIFY}>
              <Form
                enableReinitialize={true}
                initialValues={{
                  shopify_quantity: productData.shopify_quantity,
                  shopify_price: productData.shopify_price,
                  shopifyOutOfStock:
                    productData.shopify_out_of_stock == 1 ? true : false,
                }}
                onSubmit={(values) => {
                  let id = props.match.params.tab;
                  handleUpdateShopify(id, values);
                }}
              >
                <div className="card bg-white mt-3 col-lg-12">
                  <div className="card-body">
                    <div className="field-wrapper row">
                      <div className="col-sm-12">
                        <NumberComponent
                          name="shopify_quantity"
                          label="Shopify Quantity"
                          disabled={editable}
                        />
                        <SingleCheckbox
                          name="shopifyOutOfStock"
                          label="Continue selling when out of stock"
                          className="accepted-terms  mb-2 pb-0 me-3"
                          enabled={editable}
                        />
                        <div className="mt-3 row col-sm-6">
                          <Currency
                            name="shopify_price"
                            label="Price"
                            disabled={editable}
                          />
                        </div>
                        {!editable && (
                          <HorizontalSpace top="5">
                            <SaveButton label="Save" />
                            <CancelButton
                              onClick={(e) => {
                                history.push("/products");
                              }}
                            />
                          </HorizontalSpace>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </TabPane>
          )}

          {/* Vendor Tab */}
          {activeTab == ProductDetailTab.VENDOR && (
            <TabPane tabId={ProductDetailTab.VENDOR}>
              <VendorList
                id="allvendors"
                tabName={ProductDetailTab.VENDOR}
                activeTab={activeTab}
                history={props.history}
                sortByOption={sortByOption}
                icon={<FontAwesomeIcon icon={faTruck} />}
                apiURL={`${endpoints().accountProductAPI}/search`}
                productId={id}
                vendorList={vendorList}
                toggle={toggle}
              />
            </TabPane>
          )}

          {/* Purchase Tab */}
          {activeTab == ProductDetailTab.PURCHASE && (
            <TabPane tabId={ProductDetailTab.PURCHASE} className="w-100">
              <PurchaseList product_id={productId} />
            </TabPane>
          )}
          {activeTab == ProductDetailTab.STOCK_ENTRY_PRODUCT && (
            <TabPane
              tabId={ProductDetailTab.STOCK_ENTRY_PRODUCT}
              className="w-100"
            >
              <StockEntryDetailPage
                product_id={productId}
                showBrandFilter={false}
                showCategoryFilter={false}
                showStockEntryProductTypeFilter={false}
                history={history}
              />
            </TabPane>
          )}

          {activeTab == ProductDetailTab.TRANSFER && (
            <TabPane tabId={ProductDetailTab.TRANSFER} className="w-100">
              <TransferProductList
                product_id={productId}
                history={history}
                showBrandFilter={false}
                showCategoryFilter={false}
                showTagFilter={false}
                showReasonFilter={false}
              />
            </TabPane>
          )}

          {/* History Tab */}
          {showHistory && activeTab == ProductDetailTab.HISTORY && (
            <TabPane tabId={ProductDetailTab.HISTORY} className="w-100">
              <ActivityList
                id={id}
                objectId={id}
                object_name={ObjectName.PRODUCT}
                history={history}
              />
            </TabPane>
          )}

          {/* Orders Tab */}
          {activeTab == ProductDetailTab.ORDERS && (
            <TabPane tabId={ProductDetailTab.ORDERS}>
              <div className="mt-2">
                <ReduxTable
                  id="orderProduct"
                  newTableHeading
                  noRecordFoundHeight={"7vh"}
                  searchPlaceholder="Search"
                  params={{
                    productId: productId,
                    isWeb: true,
                    startDate: Url.GetParam("startDate"),
                    endDate: Url.GetParam("endDate"),
                    tab: Url.GetParam("tab"),
                    sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
                    sortDir: Url.GetParam("sortDir")
                      ? Url.GetParam("sortDir")
                      : "DESC",
                    excludeCancelledStatus: true,
                    showTotal: true,
                  }}
                  apiURL={`${endpoints().orderProductAPI}/search`}
                  paramsToUrl={true}
                  sortByOptions={sortByOptions}
                  history={history}
                  showCustomDateFilter
                  showStoreFilter
                  showBackgroundColor
                >
                  <ReduxColumn
                    sortBy="order_id"
                    className="text-center"
                    type="link"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    renderField={(row) => (
                      <Link to={`/order/${row.order_id}`}>
                        {row?.order_number}
                      </Link>
                    )}
                  >
                    Order#
                  </ReduxColumn>
                  <ReduxColumn
                    field="orderDate"
                    sortBy="order_date"
                    className="text-center"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    renderField={(row) => (
                      <span>
                        {row.orderDate &&
                          DateTime.getDateTimeByUserProfileTimezone(
                            row.orderDate
                          )}
                      </span>
                    )}
                  >
                    Date
                  </ReduxColumn>
                  <ReduxColumn
                    field="locationName"
                    sortBy="location"
                    width="130px"
                    minWidth="130px"
                    maxWidth="130px"
                  >
                    Location
                  </ReduxColumn>
                  <ReduxColumn
                    field="quantity"
                    sortBy="quantity"
                    className="text-center"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                  >
                    Quantity
                  </ReduxColumn>
                  <ReduxColumn
                    sortBy="price"
                    className="text-end"
                    field="name"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    renderField={(row) => CurrencyFormat.Format(row?.price)}
                  >
                    Price
                  </ReduxColumn>
                  <ReduxColumn
                    sortBy="amount"
                    className="text-end"
                    field="name"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    renderField={(row) => CurrencyFormat.Format(row?.amount)}
                  >
                    Amount
                  </ReduxColumn>
                </ReduxTable>
              </div>
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
};
export default ProductDetail;
