import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { toast } from "react-toastify";

// Components
import AddButton from "../../components/AddButton";
import Avatar from "../../components/Avatar";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AvatarCard from "../../components/AvatarCard";
import SaveButton from "../../components/SaveButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import Link from "../../components/Link";
import MoreDropdown from "../../components/authentication/moreDropdown";
import Action from "../../components/Action";
import AttendanceForm from "./attendanceForm";
import BulkUpdateModal from "./bulkUpdateModal";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Helpers
import * as Constants from "../../helpers/Attendance";
import { Attendance } from "../../helpers/Attendance";
import Cookie from "../../helpers/Cookie";
import {
  lateHoursStatusOptions,
  statusOptions
} from "../../helpers/Attendance";
import Permission from "../../helpers/Permission";
import { HttpStatus } from "../../helpers/HttpStatus";

// Lib
import DateTime from "../../lib/DateTime";
import Cookies, { setCookie } from "../../lib/Helper";
import Number from "../../lib/Number";
import String from "../../lib/String";
import Url from "../../lib/Url";

// Services
import ShiftService from "../../services/ShiftService";
import AttendanceService from "../../services/AttendanceService";
import { hasPermission } from "../../services/UserRolePermissionService";
import { getUserRole } from "../../services/UserSettingService";
import AttendanceTypeService from "../../services/AttendanceTypeService";

const AttendanceList = (props) => {
  let { bulkSelect = true, showStoreFilter = true, showActionColumn = true } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [row, setRow] = useState(null);
  // Bulk Select const
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSubmit, setIsSubmit] = useState(true);
  const [page, setPage] = useState(Url.GetParam("page"));

  const [selectTypeValue, setSelectTypeValue] = useState();
  const [storeValue, setStoreValue] = useState();
  const [shiftValue, setShiftValue] = useState();
  const [selectedLogin, setSelectedLogin] = useState();
  const [selectedLogOut, setSelectedLogOut] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [notesChange, setNotesChange] = useState();
  const [currentData, setCurrentData] = useState(null);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [attendance, setAttendance] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [statusValue, setStatusValue] = useState("")
  const [lateHoursStatusValue, setLateHoursStatusValue] = useState()
  const [ipAddressValue, setIpAddressValue] = useState()
  const [userRole, setUserRole] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [accountTypeOption, setAccountTypeOption] = useState([]);
  const [daysCount, setDaysCount] = useState();
  const [typeList, setTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  let showDelete = hasPermission(Permission.ATTENDANCE_DELETE);
  let attendanceManageOthers = hasPermission(Permission.ATTENDANCE_MANAGE_OTHERS);

  useEffect(() => {
    getShift();
    getUserRoleValue();
    attendanceTypeList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [props]);

  const getUserRoleValue = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const handleTypeChange = (e) => {
    setSelectTypeValue(e);
  };

  const handleStoreChange = (x) => {
    let data = x ? x : "";
    setStoreValue(data);
  };

  const handleShiftChange = (x) => {
    let data = x?.values?.shift ? x?.values?.shift : "";
    setShiftValue(data);
  };

  const handleInTimeChange = (x) => {
    setSelectedLogin(x ? x : "");
  };

  const onDateChange = (x) => {
    setSelectedDate(x ? x : "");
  };

  const handleOutTimeChange = (x) => {
    setSelectedLogOut(x ? x : "");
  };

  const handleLateHoursChange = (e) => {
    let value = e && e?.values && e?.values?.late_hours_status;
    setLateHoursStatusValue(value);
  };

  const onNotesChange = (x) => {
    let data = x?.target?.value ? x?.target?.value : "";
    setNotesChange(data);
  };

  // Toggling The Bulk Update Modal
  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!openBulkUpdateModal);
  };

  // Bulk update handler for Select
  const handleBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const handleIpAddress = (e) => {
    let value = e?.target?.value
    setIpAddressValue(value)
  }

  const handleStatusChange = (e) => {
    let value = e && e?.value && e?.value
    setStatusValue(value)
  }

  //Get User Details and list Start
  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <Avatar
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
        <div className="edit-profile-name m-2">
          {firstName} {lastName}
        </div>
      </div>
    );
  };

  const getUserList = async () => {
    let data;
    await apiClient
      .get(`${endpoints().userAPI}/list`)
      .then((response) => {
        data = response?.data?.data;
        if (data && data.length > 0) {
          const userList = [];
          data.forEach((list) => {
            userList.push({
              value: list.first_name,
              id: list.id,
              label: getUserName(
                list.avatarUrl,
                list.first_name,
                list.last_name
              ),
            });
          });
          // Set the User List Options in State
          setUsersList(userList);
        }
      });
    return data;
  };

  //Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  // Use Dispatch
  const dispatch = useDispatch();

  // Use Effect
  useEffect(() => {
    const checkedList = Cookies.get(Cookie.ATTENDANCE);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  // Toggling for add modal popup
  const toggle = () => {
    setIsOpen(false);
    setSelectTypeValue("");
    setStoreValue("");
    setSelectedUser("");
    setShiftValue("");
    setSelectedLogin("");
    setSelectedLogOut("");
    setNotesChange("");
    setLateHoursStatusValue("");
    setStatusValue("");
    setRow("");
    setIpAddressValue("");
    setIsSubmit(true);
    setSelectedDate("");
    setDaysCount("")
    props?.setIsOpen && props?.setIsOpen(false)
  };

  // Get Shift List
  const getShift = async () => {
    const list = await ShiftService.getShiftList();
    setShiftList(list);
  };


  const attendanceTypeList = async () => {
    let typeList = new Array();

    const list = await AttendanceTypeService.list();
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        typeList.push({ label: list[i].label, value: list[i].value, type: list[i].type });
      }
    }
    setTypeList(typeList)
  }

  // Type options
  const typeOptions = [
    {
      value: "Absent",
      label: "Absent",
    },
    {
      value: "Additional Day",
      label: "Additional Day",
    },
    {
      value: "Additional Leave",
      label: "Additional Leave",
    },
    {
      value: "Compensation Day",
      label: "Compensation Day",
    },
    {
      value: "Leave",
      label: "Leave",
    },

    {
      value: "Non-Working Day",
      label: "Non-Working Day",
    },
    {
      value: "Working Day",
      label: "Working Day",
    },
  ];

  const handleUserChange = (values) => {
    setSelectedUser(values);
  };

  const handleDaysCountChange = (e) => {
    setDaysCount(e.target.value||"");
  };

  // Add Modal Body Section
  const addAttendance = (
    <>
      <AttendanceForm
        handleTypeChange={handleTypeChange}
        selectTypeValue={selectTypeValue ? selectTypeValue : row?.type}
        handleUserChange={handleUserChange}
        handleShiftChange={handleShiftChange}
        handleStoreChange={handleStoreChange}
        handleStatusChange={handleStatusChange}
        handleInTimeChange={handleInTimeChange}
        onDateChange={onDateChange}
        handleOutTimeChange={handleOutTimeChange}
        handleLateHoursChange={handleLateHoursChange}
        onNotesChange={onNotesChange}
        handleIpAddress={handleIpAddress}
        accountTypeOption={setAccountTypeOption}
        handleDaysCountChange={handleDaysCountChange}
        setUsersList={setUsersList}
        userId={row?.userId}
        row={row}
        statusOptions={setStatusList}
        setStoreList={setStoreList}
      />
    </>
  );

  // Add Modal Footer section
  const addFooter = (
    <>
      <SaveButton type="submit" loading={isSubmit == false} label={row?.id ? "Save " : "Add"} />
    </>
  );

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.ATTENDANCE, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const FieldLabel = [
    {
      value: Constants.FieldLabel.LATE_HOURS,
      label: Constants.FieldLabel.LATE_HOURS,
    },

    {
      value: Constants.FieldLabel.IP_ADDRESS,
      label: Constants.FieldLabel.IP_ADDRESS,
    },
    {
      value: Constants.FieldLabel.DAYS_COUNT,
      label: Constants.FieldLabel.DAYS_COUNT,
    },
    {
      value: Constants.FieldLabel.CHECK_IN,
      label: Constants.FieldLabel.CHECK_IN,
    },
    {
      value: Constants.FieldLabel.CHECK_OUT,
      label: Constants.FieldLabel.CHECK_OUT,
    },
    {
      value: Constants.FieldLabel.ADDITIONAL_HOURS,
      label: Constants.FieldLabel.ADDITIONAL_HOURS,
    },
  ];

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e === "Bulk Update") {
      if (selectedIds &&
        selectedIds?.selectedIds.length > 0) {
        toggleBulkUpdateModal();
      } else {
        toast.error("Select Atleast One Item");
      }
    }
    if (e === "Delete" && selectedIds && selectedIds?.selectedIds.length > 0) {
      setIsDeleteModel(true);
    }
  };

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const enable_late_hours =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.LATE_HOURS)
      ? true
      : false;
  const enable_ip_address =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.IP_ADDRESS)
      ? true
      : false;
  const enabelDaysCount =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.DAYS_COUNT)
      ? true
      : false;
  const enable_check_in =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CHECK_IN)
      ? true
      : false;
  const enable_check_out =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CHECK_OUT)
      ? true
      : false;
  const enable_additional_hours =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.ADDITIONAL_HOURS)
      ? true
      : false;

  // Form Initial Values
  const initialValues = {
    date: row ? DateTime.getDateTimeByUserProfileTimezone(row?.date) : selectedDate && DateTime.getDateTimeByUserProfileTimezone(selectedDate),
    type: selectTypeValue ? accountTypeOption.find((option) => option.value == selectTypeValue?.value) : accountTypeOption.find((option) => option.value == row?.type),
    user: selectedUser ? selectedUser :
      usersList &&
      usersList.find((option) => option?.id == row?.userId),
    status:  statusList &&
    statusList.find((option) => option?.value == (statusValue || row?.statusId)),
    late_hours: row?.lateHours,
    late_hours_status: lateHoursStatusValue ? lateHoursStatusValue :
      lateHoursStatusOptions &&
      lateHoursStatusOptions.find(
        (option) => option?.value === row?.lateHoursStatus
      ),
    ip_address: ipAddressValue ? ipAddressValue : row?.ipAddress ? row?.ipAddress : "",
    notes: notesChange ? notesChange : row?.notes ? row?.notes : "",
    location: (selectedUser && selectedUser?.primary_location_id) ? storeList.find((option) => option.value == selectedUser?.primary_location_id) :  storeValue ? storeValue : row ? {

      value: row?.location,
      label: row?.locationName
    } : "",
    shift: (selectedUser && selectedUser?.primary_shift_id) ? shiftList.find((option) => option.value == selectedUser?.primary_shift_id) : shiftValue ?
      shiftList &&
      shiftList.find((option) => option.value == shiftValue?.value) : shiftList &&
      shiftList.find((option) => option.value == row?.shift_id),
    login: selectedLogin ? selectedLogin : DateTime.getTimeZoneDateTime(row?.login),
    logout: selectedLogOut ? selectedLogOut : DateTime.getTimeZoneDateTime(row?.logout),
    additional_hours:
      row?.additionalHours,
    allow_early_checkout: row?.allow_early_checkout,
    allow_goal_missing: row?.allow_goal_missing,
    approve_late_check_in: row?.approve_late_check_in,
    days_count: daysCount ? daysCount : row?.days_count,
  };

  // Adding the attendance
  const handleAdd = async (values) => {
    try {
      setIsSubmit(true);
      // Form Data
      let data = new FormData();
      data.append("date", DateTime.toISOStringDate(values.date));
      data.append("user", values.user.id);
      data.append("type", String.Get(values && values.type ? values.type.value : ""));
      data.append("status", String.Get(values && values.status ? values.status.value : ""));
      data.append("late_hours", Number.Get(values.late_hours));
      data.append(
        "late_hours_status",
        values && values?.late_hours_status?.value
          ? values?.late_hours_status?.value
          : null
      );
      data.append(
        "ip_address",
        values.ip_address ? values.ip_address : ""
      );
      data.append("notes", String.Get(values.notes ? values.notes : ""));
      data.append("location", values && values.location && values.location.value ? values.location.value : "");
      data.append("shift", values && values.shift && values.shift.value ? values.shift.value : "");
      data.append("login", values.login ? values.login : "");
      data.append("logout", values.logout ? values.logout : "");
      data.append(
        "allow_early_checkout",
        values?.allow_early_checkout ? values?.allow_early_checkout : false
      );
      data.append(
        "allow_goal_missing",
        values?.allow_goal_missing ? values?.allow_goal_missing : false
      );
      data.append(
        "approve_late_check_in",
        values?.approve_late_check_in ? values?.approve_late_check_in : false
      );
      if(values && Number.isNotNull(values?.days_count)){
        data.append("days_count", values?.days_count ? values?.days_count:"");
      }else{
        data.append("days_count", values?.type ? values?.type?.days_count :"");

      }
      if (row?.id) {
        dispatch(await AttendanceService.update(row?.id, data, {
          ...Url.GetAllParams()
        }, (res) => {
          if (res.status == HttpStatus.OK) {
            toggle()
          } else if (res.status == HttpStatus.BAD_REQUEST) {
            setIsSubmit(true);
          }
        }));
      }
      else {
        dispatch(
          await AttendanceService.create(
            data,
            {
              ...Url.GetAllParams()
            },
            (res) => {
              if (res.id) {
                toggle();
              }
            },
          )
        );
      }

    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(true);
    }
  };

  const attendanceDelete = async (id) => {
    dispatch(
      await AttendanceService.delete(id, null, {
        ...Url.GetAllParams()

      })
    );
    setCurrentData(null)
    setIsDeleteModel(false);
  };

  const attendanceBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds?.selectedIds };
    dispatch(
      await AttendanceService.bulkDelete(ids, null, {
        ...Url.GetAllParams()
      })
    );
    setSelectedIds([]);
    setIsDeleteModel(false);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  }

  const attendanceEarlyCheckoutHandler = async (value) => {
    if (value) {
      await AttendanceService.EarlyCheckout({ attendanceId: value?.id, allowEarlyCheckout: true }, dispatch);
      dispatch(
        fetchList("attendance", `${endpoints().attendanceAPI}/list`, page ? page : 1, 25, {
          ...Url.GetAllParams()
        })
      );
    }
  }


  const handleAllowGoalMissing = async (value) => {
    if (value) {
      await AttendanceService.GoalMissing({ attendanceId: value?.id, allowGoalMissing: true }, dispatch);
      dispatch(
        fetchList("attendance", `${endpoints().attendanceAPI}/list`, page ? page : 1, 25, {
          ...Url.GetAllParams()
        })
      );
    }
  }

  const handleApproveLateCheckIn = async (value) => {
    if (value) {
      await AttendanceService.ApproveLateCheckIn({ attendanceId: value?.id, approveLateCheckIn: true }, dispatch);
      dispatch(
        fetchList("attendance", `${endpoints().attendanceAPI}/list`, page ? page : 1, 25, {
          ...Url.GetAllParams()
        })
      );
    }
  }

  let params = {
    ...Url.GetAllParams()
  };

  const attendanceHandler = async (value) => {
    if (value && value?.login) {
      let response = await AttendanceService.CheckOutValidation(value?.id);
      if (response && response.data) {
        await AttendanceService.Checkout({ attendanceId: value?.id }, dispatch);
        dispatch(
          fetchList(
            "attendance",
            `${endpoints().attendanceAPI}/list`,
            page ? page : 1,
            25,
            {
              ...Url.GetAllParams()
            }
          )
        );
      }
    }
  };

  let renderAttendanceTable = (params) => {
    return (
      <>
        {/* Redux Table */}
        <ReduxTable
          id="attendance"
          bulkSelect={bulkSelect}
          onBulkSelect={handleBulkSelect}
          paramsToUrl={true}
          showHeader
          newTableHeading
          searchPlaceholder="Search"
          setPage={setPage}
          params={{ ...params }}
          apiURL={`${endpoints().attendanceAPI}/list`}
          selectedCheckBox={selectedCheckBox}
          sortByOptions={sortByOption}
          customTypeOption={typeList}
          showUserFilter={props.userView || !attendanceManageOthers ? false : true}
          // disableHeader={props.disableHeader}
          showSearch={props.showSearch}
          showPageSize={props.showPageSize}
          showStoreFilter={showStoreFilter}
          roleList={userRole}
          showRoleFilter
          showCustomDateFilter
          showShiftFilter
          showTypeFilter
          sortByDropdown={props.sortByDropdown}
          refreshButton={props.refreshButton}
          history={props.history}
          DropdownWithCheckbox
          handleColumnChange={handleColumnChange}
          FieldLabel={FieldLabel}
          arrayList={arrayList}
          hideQueryParams={props.hideQueryParams}
        >
          <ReduxColumn
            className="text-center"
            field="date"
            sortBy="date"
            type="link"
            width="110px"
            minWidth="110px"
            maxWidth="110px "
            renderField={(row) => (
              <>
                <Link
                  url={`/attendance/${row.id}`}
                  text={DateTime.getDate(row.date)}
                  className="link-opacity-75"
                />
              </>
            )}
          >
            Date
          </ReduxColumn>
          <ReduxColumn
            field="userName"
            width="210px"
            sortBy="name"
            minWidth="210px"
            maxWidth="210px"
            renderField={(row) => (
              <div className="d-flex text-break">
                <Link
                  url={`/user/${row.userId}`}
                  className="link-opacity-75"
                  text={
                    <AvatarCard
                      id="avatar"
                      firstName={row.userName}
                      lastName={row.lastName}
                      url={row.check_in_media_id}
                    />
                  }
                />
              </div>
            )}
          >
            User
          </ReduxColumn>
          <ReduxColumn
            field="typeName"
            sortBy="type"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            renderField={(row) => (
              <div
                className={`${row?.attendanceTypeDetail && row?.attendanceTypeDetail?.category == Constants.AttendanceCategory.CATEGORY_LEAVE ? "text-danger" : ""
                  }`}
              >
                <p>{row.typeName}</p>
              </div>
            )}
          >
            Type
          </ReduxColumn>
          <ReduxColumn
            className="text-start"
            field="locationName"
            width="170px"
            minWidth="170px"
            sortBy="location"
            maxWidth="170px"
            renderField={(row) => (
              <>
                <Link url={`/location/${row.location}`} text={row.locationName} className="link-opacity-75" />
              </>
            )}
          >
            Location
          </ReduxColumn>
          <ReduxColumn
            className="text-center"
            field="shiftName"
            width="110px"
            minWidth="110px"
            disableOnClick
            maxWidth="110px"
          >
            Shift
          </ReduxColumn>
          {enable_check_in && (
            <ReduxColumn
              className="text-center"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
              sortBy="login"
              renderField={(row) => (
                <span>{DateTime.getUserTimeZoneTime(row.login)}</span>
              )}
            >
              Check-In
            </ReduxColumn>
          )}

          {enable_check_out && (
            <ReduxColumn
              className="text-center"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
              sortBy="logout"
              renderField={(row) => (
                <span>{DateTime.getUserTimeZoneTime(row.logout)}</span>
              )}
            >
              Check-Out
            </ReduxColumn>
          )}

          {enable_late_hours && enable_late_hours == true && (
            <ReduxColumn
              field="lateHours"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
            >
              Late Hours
            </ReduxColumn>
          )}
          {enable_additional_hours && (
            <ReduxColumn
              field="additionalHours"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
            >
              Additional Hours
            </ReduxColumn>
          )}
          {enabelDaysCount &&
            <ReduxColumn
              className="text-center"
              field="days_count"
              width="110px"
              minWidth="110px"
              disableOnClick
              maxWidth="110px"
            >
              Days Count
            </ReduxColumn>
          }
          {enable_ip_address && enable_ip_address == true && (
            <ReduxColumn
              field="ipAddress"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
            >
              Ip Address
            </ReduxColumn>
          )}

          <ReduxColumn
            field="created_at"
            sortBy="created_at"
            className="text-center"
            width="150px"
            minWidth="110px"
            maxWidth="150px"
            renderField={(row) => (
              <span>
                {row.created_at}
              </span>
            )}
          >
            Created At
          </ReduxColumn>
          <ReduxColumn
            field="status"
            width="80px"
            minWidth="110px"
            maxWidth="80px"
            className="text-center"

          >
            Status
          </ReduxColumn>
          {showActionColumn && <ReduxColumn
            field="Action"
            width={"70px"}
            disableOnClick
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRow(row);
                      setIsOpen(!isOpen);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {!row.logout &&
                    row.type !== Attendance.TYPE_LEAVE &&
                    row.type !== Attendance.TYPE_ABSENT && (
                      <DropdownItem
                        onClick={() => {
                          attendanceHandler(row);
                        }}
                      >
                        Check-Out
                      </DropdownItem>
                    )}
                  {!row.allow_early_checkout && (
                    <DropdownItem
                      onClick={() => {
                        attendanceEarlyCheckoutHandler(row);
                      }}
                    >
                      Allow Early Checkout
                    </DropdownItem>
                  )}

                  {!row.allow_goal_missing && (
                    <DropdownItem
                      onClick={() => {
                        handleAllowGoalMissing(row);
                      }}
                    >
                      Allow Goal Missing
                    </DropdownItem>
                  )}
                  {!row.approve_late_check_in && (
                    <DropdownItem
                      onClick={() => {
                        handleApproveLateCheckIn(row);
                      }}
                    >
                      Approve Late CheckIn
                    </DropdownItem>
                  )}
                  {showDelete && (
                    <DropdownItem
                      className="text-danger cursor-pointer"
                      onClick={() => {
                        setCurrentData(row);
                        setAttendance(row?.userName);
                        setIsDeleteModel(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>}
        </ReduxTable>
      </>
    )
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        {/* Page Title */}
        {!props.userView && <PageTitle label="Attendance" />}
        <div className="d-flex">
          {!props.userView && (
            <DeleteModal
              isOpen={isDeleteModel}
              toggle={() => {
                setIsDeleteModel(false);
                setCurrentData(null);
              }}
              title="Attendance Delete"
              id={currentData?.id}
              label={`${DateTime.getDate(currentData?.date)} (${attendance && currentData?.id ? attendance : "Delete"})`}
              deleteFunction={() => {
                if (currentData && currentData?.id) {
                  attendanceDelete(currentData?.id);
                } else {
                  attendanceBulkDelete(selectedIds);
                }
              }}
            />
          )}

          {!props.userView && (
            <div className="ps-1">
              <BulkUpdateModal
                isOpen={openBulkUpdateModal}
                toggle={toggleBulkUpdateModal}
                usersList={usersList}
                shiftList={shiftList}
                typeOptions={typeOptions}
                attendanceIds={selectedIds}
                params={params}
                setSelectedCheckBox={setSelectedCheckBox}
                setSelectedIds={setSelectedIds}
              />
            </div>
          )}

          {/* Add button for add modal */}
          {!props.userView && (
            <AddButton
              label="Add"
              onClick={() => {
                setRow("");
                setIsOpen(true)
              }}
              className="mx-1"
            />
          )}
          {!props.userView && (
            <div className="ps-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
          <Drawer
            modelTitle={row?.id ? "Edit Attendance" : "Add Attendance"}
            DrawerBody={addAttendance}
            DrawerFooter={addFooter}
            onSubmit={(values) => {
              handleAdd(values);
            }}
            initialValues={initialValues}
            enableReinitialize
            handleOpenModal={()=> setIsOpen(true)}
            handleCloseModal={toggle}
            handleDrawerClose={toggle}
            isModalOpen={props?.isOpen ? props?.isOpen : isOpen}
          />
        </div>
      </div>

      <div className="tab-content-wrapper">
        {renderAttendanceTable({
          user: props && props?.user ? props?.user : "",
          role: Url.GetParam("role"),
        })}
      </div>
    </div>
  );
};

export default AttendanceList;