import React, { useEffect, useState } from "react";
import Select from "./Select";
import ArrayList from "../lib/ArrayList";
import AddressService from "../services/AddressService";
import ObjectName from "../helpers/ObjectName";
import String from "../lib/String";

const BillingNameSelect = (props) => {
  let { name, label, required, handleChange, isDisabled, placeholder,list } = props;

  const [billingOption, setBillingOption] = useState([]);

  useEffect(() => {
    List();
  }, []);

  const List = async () => {
    try {
      let addressList = [];
      const response = await AddressService.get(null, ObjectName.COMPANY);
      const details = response.data.data;
      if (details && details.length > 0) {
        details.forEach((address) => {
          const title = String.Get(address.title);
          const name = address.name ? String.Get(address.name) : "";
          const label = name ? `${title}, (${name})` : title;
          addressList.push({ label, value: address.id });
        });
      }
      setBillingOption(addressList);
      list && list(addressList)
    } catch (err) {
      console.log(err);
    }
  };

  const onFocus = () => {
    if (ArrayList.isEmpty(billingOption)) {
      List();
    }
  };

  return (
    <>
      <Select
        name={name ? name : "billingName"}
        placeholder={placeholder ? placeholder : "Select Billing Name"}
        options={billingOption}
        handleChange={handleChange}
        label={label}
        required={required}
        isDisabled={isDisabled}
        autoFocus={onFocus}
        menuPortal={props.menuPortal}
      />
    </>
  );
};
export default BillingNameSelect;
