import Address from "../actions/address";
import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { HttpStatus } from "../helpers/HttpStatus";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
import Url from "../lib/Url";

class AddressService {
  static add = (data, params, toggle,getUserDetail,) => {
    return async (dispatch) => {
      try {
        const response = await apiClient.post(
          `${endpoints().addressAPI}`,
          data
        );
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }
        toggle();
        getUserDetail && getUserDetail();
        dispatch(
          fetchList(
            "address",
            `${endpoints().addressAPI}/search`,
            1,
            25,
            params
          )
        );
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      }
    };
  };

  static update = (id, data, params, toggle, getUserDetail, callback) => {
    return async (dispatch) => {
      try {
        apiClient
          .put(`${endpoints().addressAPI}/${id}`, data)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
            }
            toggle && toggle();
            getUserDetail && getUserDetail();
            dispatch(
              fetchList(
                "address",
                `${endpoints().addressAPI}/search`,
                1,
                25,
                params
              )
            );
          });
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      }
    };
  };

  static Delete = (id, params) => {
    return (dispatch) => {
      dispatch(Address.requestDeleteAddress());

      apiClient
        .delete(`${endpoints().addressAPI}/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(
            fetchList("address", `${endpoints().addressAPI}/search`, 1, 25, {
              pagination: true,
              object_id: params.object_id,
              objectName: params.objectName,
            })
          );
        })
        .catch((error) => {
          dispatch(Address.receiveAddressDeleteError(error));
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    };
  };

  static get = async (id, objectName, object_id) => {
    if (objectName || object_id) {
      // Construct query parameters based on the provided values
      const queryParams = [];
      if (objectName) {
        queryParams.push(`objectName=${encodeURIComponent(objectName)}`);
      }
      if (object_id) {
        queryParams.push(`object_id=${encodeURIComponent(object_id)}`);
      }
      const queryString = queryParams.join("&");
      const data = await apiClient.get(
        `${endpoints().addressAPI}/search?${queryString}`
      );
      return data;
    } else if (id !== null && id !== undefined) {
      // Fetch data based on id
      const data = await apiClient.get(`${endpoints().addressAPI}/${id}`);
      return data;
    }
  };

  static search = async () => {
    const data = await apiClient.get(`${endpoints().addressAPI}/search`);
    return data;
  };
  static list = async () => {
    const data = await apiClient.get(`${endpoints().addressAPI}/list`);
    return data;
  };
}
export default AddressService;
