import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AddSchedulerModal from "./addModal";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import Toast from "../../components/Toast";
import DeleteModal from "../../components/DeleteModal";
import MoreDropdown from "../../components/authentication/moreDropdown";

// Helpers
import { Status } from "../../helpers/SchedulerJob";

// Lib
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";

// Actions
import { fetchList } from "../../actions/table";

// Services
import SchedulerJobService from "../../services/SchedulerJobService";

//Config
import { endpoints } from "../../api/endPoints";

//AP Client
import { apiClient } from "../../apiClient";
import { toast } from "react-toastify";

const SchedulerJob = (props) => {
  const [schedulerModalOpen, setIsSchedulerModalOpen] = useState(false);

  const [selectedSchedulerJob, setSelectedSchedulerJob] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [row, setRow] = useState("");
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const sortByOption = [
    {
      value: Status.ACTIVE,
      label: "Active",
    },
    {
      value: Status.INACTIVE,
      label: "InActive",
    },
  ];

  const sortByOptions = [
    {
      value: "job_name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const toggle = () => {
    setIsSchedulerModalOpen(!schedulerModalOpen);
  };

  //dispatch
  const dispatch = useDispatch();

  let params = {
    status: Url.GetParam("status"),
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
  };

  const handleJobRun = async (row, Id) => {
    try {
      let response = await apiClient.post(`${row.api_url}/manualRun?id=${Id}`);
      if (response && response.data) {
        Toast.success(response.data.message);
        dispatch(
          fetchList(
            "schedulerJob",
            `${endpoints().schedulerJobAPI}/list`,
            params.page ? params.page : 1,
            params.pageSize ? params.pageSize : 25,
            params
          )
        );
      }
    } catch (err) { }
  };

  const getIntervalTime = (value) => {
    if (value) {
      let interval = DateTime.getSchedulerTime().find(
        (data) => data.value == value
      );

      return interval ? interval.label : "";
    }
  };

  const actionsMenuList = [
    {
      value: "Force Stop",
      label: "Force Stop",
    },
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const actionMenuChange = async (selectedValue) => {
    if (selectedValue && selectedValue == "Force Stop") {
      if (selectedIds && selectedIds.length > 0) {
        let bodydata = { ScheduleJobIds: selectedIds, forceStop: true };
        dispatch(
          await SchedulerJobService.bulkUpdate(bodydata, (res) => {
            if (res) {
              dispatch(
                fetchList(
                  "schedulerJob",
                  `${endpoints().schedulerJobAPI}/list`,
                  Url.GetParam("page") ? Url.GetParam("page") : 1,
                  Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                  {
                    ...Url.GetAllParams(),
                  }
                )
              );
              setSelectedCheckBox(false);
              setSelectedCheckBox(true);
              setSelectedIds("");
            }
          })
        );
      } else {
        toast.error("Select Scheduler Jobs");
      }
    }

    else if (selectedValue && selectedValue == "Delete" && selectedIds.length > 0) {
      setIsDeleteModel(true);
    } else {
      toast.error("Select Scheduler Jobs");
    }
  };

  const handleBulkSelect = async (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const deleteSchedulerJob = async (id) => {
    try {
      dispatch(await SchedulerJobService.deleteJob(id, params));
    } catch (err) { }
  };

  const bulkDeleteSchedulerJob = async (selectedIds) => {
    dispatch(
      await SchedulerJobService.bulkDeleteSchedulerJob(selectedIds, ""),
      setSelectedIds({ selectedIds: [] })
    );
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  return (
    <>
      {/* Add Scheduler Modal */}
      <AddSchedulerModal
        toggle={toggle}
        isOpen={schedulerModalOpen}
        onModalClose={toggle}
        hideDefaultButtons
        row={row}
      />
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Delete Scheduler Job"
        id={row?.id}
        label={row && row?.name ? row?.name : "Delete"}
        deleteFunction={() => {
          if (row?.id) {
            deleteSchedulerJob(row?.id);
          } else {
            bulkDeleteSchedulerJob(selectedIds);
          }
        }}
      />

      {/* /.page-heading */}
      <div className="mb-1">
        <PageTitle
          label="Scheduler Jobs"
          buttonHandler={() => {
            setRow("");
            toggle();
          }}
          buttonLabel="Add New"
          dropdownLinks={actionsMenuList}
          handleChange={actionMenuChange}
        />
      </div>

      {/* Redux table*/}
      <ReduxTable
        id="schedulerJob"
        showHeader
        searchPlaceholder="Search"
        paramsToUrl={true}
        history={props.history}
        apiURL={`${endpoints().schedulerJobAPI}/list`}
        newTableHeading
        params={{}}
        bulkSelect
        onBulkSelect={handleBulkSelect}
        customStatusOption={sortByOption}
        sortByOptions={sortByOptions}
        showStatusFilter
        selectedCheckBox={selectedCheckBox}
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          renderField={(row) => (
            <div onClick={() => setSelectedSchedulerJob(row)}>
              <Link
                to={`/schedulerJobs/detail/${row.id}`}
                className="link-opacity-75 text-decoration-none"
              >
                {row.name}
              </Link>
            </div>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="recurring_type"
          sortBy="type"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{row.type}</span>}
        >
          Recurring Type
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="date"
          sortBy="date"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{row.date}</span>}
        >
          Recurring Date
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="start_time"
          sortBy="start_time"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{DateTime.getUserTimeZoneTime(row.startTime)}</span>
          )}
        >
          Start Time
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="end_time"
          sortBy="end_time"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{DateTime.getUserTimeZoneTime(row.endTime)}</span>
          )}
        >
          End Time
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          field="interval"
          sortBy="interval"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{getIntervalTime(row.interval)}</span>}
        >
          Interval
        </ReduxColumn>
        <ReduxColumn
          field="started_at"
          sortBy="started_at"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>
              {DateTime.getDateTimeByUserProfileTimezone(row.started_at)}
            </span>
          )}
        >
          Started At
        </ReduxColumn>
        <ReduxColumn
          field="completed_at"
          sortBy="completed_at"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>
              {DateTime.getDateTimeByUserProfileTimezone(row.completed_at)}
            </span>
          )}
        >
          Completed At
        </ReduxColumn>
        <ReduxColumn
          field="start_date"
          sortBy="start_date"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{DateTime.getDate(row.start_date)}</span>}
        >
          Start Date
        </ReduxColumn>
        <ReduxColumn
          field="end_date"
          sortBy="end_date"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{DateTime.getDate(row.end_date)}</span>}
        >
          End Date
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase my-3 mx-auto ${row.status == 1
                ? "bg-success"
                : row.status === 2
                  ? "bg-dark bg-opacity-50"
                  : "bg-dark bg-opacity-50"
                }`}
            >
              <p>{row.status == 1 ? "Active" : "InActive"}</p>
            </div>
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <>
              <div className=" d-flex justify-content-center landing-group-dropdown">
                <Button
                  id={row.id}
                  label={"Run"}
                  loadingLabel="Running..."
                  width="100px"
                  disabled={
                    row && !row.started_at
                      ? false
                      : row.started_at && row.completed_at
                        ? false
                        : true
                  }
                  className="text-center"
                  icon
                  onClick={() => {
                    handleJobRun(row, row.id);
                  }}
                  showButtonLabelName
                />
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
        <ReduxColumn
          width="110px"
          minWidth="110px"
          disableOnClick
          maxWidth="110px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRow(row);
                      toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger  cursor-pointer"
                    onClick={() => {
                      setIsDeleteModel(true);
                      setRow(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        ></ReduxColumn>
      </ReduxTable>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;

  const CurrentPage =
    reduxTable["schedulerJob"] && !reduxTable["schedulerJob"].isFetching
      ? reduxTable["schedulerJob"].currentPage
      : 1;

  const CurrentPageSize =
    reduxTable["schedulerJob"] && !reduxTable["schedulerJob"].isFetching
      ? reduxTable["schedulerJob"].pageSize
      : 25;

  return {
    CurrentPage,
    CurrentPageSize,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerJob);
