import React from "react";

// Components
import Text from "../../../components/Text";
import Select from "../../../components/Select";
import DraftEditor from "../../../components/Draft";
import Number from "../../../components/Number";
import SingleCheckbox from "../../../components/SingleCheckbox";
import JobCategorySelect from "../../../components/JobCategorySelect";

const JobForm = (props) => {
  let {
    StatusOptions,
    jobTypeOptions,
    editorDescriptionState,
    handleEditorDescriptionChange,
    editorResponsibilityState,
    handleEditorResponsibilityChange,
    handleEditorBenefitsChange,
    editorBenefitsState,
    editorState,
    handleEditorChange,
    handleTitleChange,
    slug
  } = props;
  return (
    <div>
      <Text
        name="job_title"
        label="Title"
        placeholder="Job Title..."
        onChange={handleTitleChange}
        error=""
        value={slug}
        required={true}
      />
      <Select name="status" label="Status" fontBolded options={StatusOptions} />
      <Text
        name="slug"
        label="Slug"
        fontBolded
        placeholder="slug"
        error=""
        required
      />
      <JobCategorySelect
        name="category"
        label="Category"
        fontBolded
      />
      <Select
        name="job_type"
        label="Type"
        fontBolded
        options={jobTypeOptions}
      />
      <Text
        name="location"
        label="Location"
        fontBolded
        placeholder="location"
        error=""
      />
      <Text
        name="experience"
        label="Experience"
        fontBolded
        placeholder="Experience"
        error=""
      />
      <Select name="sort" label="Sort" fontBolded />
      <div className="mt-3 mb-2">
        <label className="fw-bold">Description</label>
        <DraftEditor
          name="job_description"
          placeholder="Enter Description"
          editorState={editorDescriptionState}
          onChange={handleEditorDescriptionChange}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Responsibilities</label>
        <DraftEditor
          name="responsibilities"
          placeholder="Enter Responsibilities"
          editorState={editorResponsibilityState}
          onChange={handleEditorResponsibilityChange}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Requirements</label>
        <DraftEditor
          name="requirements"
          placeholder="Enter Requirements"
          editorState={editorState}
          onChange={handleEditorChange}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Benefits</label>
        <DraftEditor
          name="benefits"
          placeholder="Enter Benefits"
          editorState={editorBenefitsState}
          onChange={handleEditorBenefitsChange}
        />
      </div>
      <Text name="mandatory_skills" label="Mandatory Skill" fontBolded />
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <Number
            name="minimum_experience"
            label="Minimum Experience"
            fontBolded
            placeholder="Minimum Experience"
            error=""
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <Number
            label="Maximum Experience"
            placeholder="Maximum Experience"
            fontBolded
            name="maximum_experience"
            error={""}
          />
        </div>
      </div>
      <Text
        name="maximum_salary"
        label="Maximum Salary"
        placeholder="Maximum Salary"
        fontBolded
      />

      <div className="mt-4">
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_current_salary"
              label="Show Current Salary"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_expected_salary"
              label="Show Expected Salary"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_skills"
              label="Show Skills"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_employment_eligibility"
              label="Select Employment Eligibility"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_current_address"
              label="Select Current Address"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_home_town_address"
              label="Show Home Town Address"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name="show_employment"
              label="Show Employment"
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
        <div>
          <h5 className="my-3">Post Resume Fields</h5>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="age"
                label="Age"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="current_address"
                label="Current Address"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="email_address"
                label="Email Address"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="expected_salary"
                label="Expected Salary"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="first_name"
                label="First Name"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="gender"
                label="Gender"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="highest_education"
                label="Highest Education"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="last_name"
                label="Last Name"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="marital_status"
                label="Marital Status"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="mobile_number"
                label="Mobile Number"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="message"
                label="Message"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="permanent_address"
                label="Permanent Address"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="position"
                label="Position"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="profile_picture"
                label="Profile Picture"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="resume"
                label="Resume"
                className="accepted-terms"
              />
            </div>
          </div>
          <div className="form-wrapper mb-2">
            <div className="field-wrapper">
              <SingleCheckbox
                name="staying_in"
                label="Staying In/With"
                className="accepted-terms"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
