import Text from "../../components/Text";
import Select from "../../components/Select";
import RoleSelect from "../../components/RoleSelect";
import DateSelector from "../../components/Date";
import SaveButton from "../../components/SaveButton";
import Holiday from "../../helpers/Holiday";
import DateTime from "../../lib/DateTime";
import { useDispatch } from "react-redux";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import Url from "../../lib/Url";
import HolidayService from "../../services/HolidayService";
import Form from "../../components/Form";
import CancelButton from "../../components/CancelButton";
import ArrayList from "../../lib/ArrayList";

const HolidayForm = ({
  detail,
  closeToggle,
  history,
  form,
  editable,
  setEditable,
}) => {
  let dispatch = useDispatch();

  const holidayTypeOptions = [
    {
      value: Holiday.COMPULSORY_VALUE,
      label: Holiday.COMPULSORY_TEXT,
    },
    {
      value: Holiday.OPTIONAL_VALUE,
      label: Holiday.OPTIONAL_TEXT,
    },
  ];

  let initialValues = {
    name: detail?.name || "",
    date: detail?.date || "",
    type: holidayTypeOptions.find((value) => value.value == detail?.type) || "",
    role: detail?.role,
  };

  let handleSubmit = async (values) => {
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append("date", DateTime.toISOStringDate(values.date));
    data.append("type", values && values?.type ? values?.type?.value : "");

    let roleValue = ArrayList.isArray(values?.role)
      ? values?.role.map((value) => value.value)
      : [];

    data.append("role", roleValue);
    if (detail?.id) {
      data.append("id", detail?.id);
      dispatch(
        await HolidayService.update(detail?.id, data, (res) => {
          if (setEditable && setEditable) {
            setEditable(true);
          }
          if (form === "Add") {
            dispatch(
              fetchList(
                "holiday",
                `${endpoints().holidayAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  ...Url.GetAllParams(),
                }
              )
            );
            closeToggle();
          }
        })
      );
    } else {
      dispatch(
        await HolidayService.create(data, (res) => {
          dispatch(
            fetchList(
              "holiday",
              `${endpoints().holidayAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          closeToggle();
        })
      );
    }
  };

  return (
    <div>
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        className="d-flex flex-column h-100"
      >
        <div
          className={
            form === "Add"
              ? `d-flex flex-column pb-5`
              : `d-flex flex-column pb-2`
          }
        >
          <Text name="name" label="Name" required disabled={!editable} />
          <Select
            fullWidth={true}
            label="Type"
            name="type"
            isClearable
            options={holidayTypeOptions}
            required
            isDisabled={!editable}
          />
          <RoleSelect
            fullWidth={true}
            label="Role"
            name="role"
            required
            isMulti
            disabled={!editable}
          />
          <DateSelector
            name="date"
            label="Date"
            required
            isClearable
            disabled={!editable}
          />
        </div>
        <div
          className={`bottom-0 start-0 end-0 bg-white z-3 ${
            form === "Add" ? "position-absolute" : ""
          }`}
          style={{
            padding: "10px 10px",
          }}
        >
          <div
            className={`d-flex ${
              form === "Add"
                ? "justify-content-center"
                : "justify-content-start"
            }`}
          >
            {editable && (
              <div>
                <SaveButton
                  type="submit"
                  label={form === "Add" ? "Add" : "Save"}
                />
                <CancelButton
                  onClick={() => {
                    form === "Add" ? closeToggle() : history.push("/bill");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};
export default HolidayForm;
