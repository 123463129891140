import { faCartShopping, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

// API
import { endpoints } from "../../../api/endPoints";

// Components
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import StatusText from "../../../components/StatusText";

// Helpers
import { Order } from '../../../helpers/Order';
import * as Constants from "../../../helpers/Order";

// Lib
import { COOKIE_ORDER_LIST_AUTO_REFRESH } from '../../../lib/Cookie';
import Currency from "../../../lib/Currency";
import Cookie from '../../../lib/Helper';
import Number from '../../../lib/Number';
import Cookies, { setCookie } from "../../../lib/Helper";

const OrderList = (props) => {

  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);

  const orderSortOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];




  let isVerifiedUpiPayment = (verifiedValue) => {
    if (Number.isNotNull(verifiedValue) || verifiedValue == 0) {
      return verifiedValue == Order.UPI_PAYMENT_VERIFIED ? (
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          className="ml-2"
          style={{ color: "green" }}
        />
      ) : verifiedValue == Order.UPI_PAYMENT_NOT_VERIFIED ? (
        <FontAwesomeIcon
          icon={faXmark}
          size="2x"
          className="ml-2"
          style={{ color: "red" }}
        />
      ) : (
        ""
      );
    }
  };

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.ORDER);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  const FieldLabel = [
    {
      value: Constants.FieldLabel.CUSTOMER,
      label: Constants.FieldLabel.CUSTOMER,
    },
  ];

  const enable_customer =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CUSTOMER)
      ? true
      : false;

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.ORDER, arrayList);
    setArray(array);
    setArrayList(array);
  };

  return (
    <>
      <ReduxTable
        id={"Orders"}
        showHeader
        newTableHeading
        apiURL={`${endpoints().orderAPI}/search`}
        searchPlaceholder="Search"
        params={props && props?.params}
        sortByOptions={orderSortOptions}
        FieldLabel={FieldLabel}
        arrayList={arrayList}
        handleColumnChange={handleColumnChange}
        DropdownWithCheckbox
        paramsToUrl={true}
        history={props.history}
        bulkSelect={props?.bulkSelect}
        onBulkSelect={props?.onBulkSelect}
        selectedCheckBox={props.selectedCheckBox}
        autoRefresh={Cookie.get(COOKIE_ORDER_LIST_AUTO_REFRESH) == "true" ? true : false}
        ShowOrderFilter
        showStoreFilter
        showCustomDateFilter
        showStatusFilter
        showOwnerFilter
        showShiftFilter
        showPaymentTypeFilter
        showTypeFilter
        customTypeOption={props && props.orderTypeOption}
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        message="You can start by clicking on Add Order"
      >
        <ReduxColumn
          width="130px"
          minWidth="100px"
          maxWidth="90px"
          field="order_number"
          sortBy="order_number"
          type="link"
          isClickable="true"
          className="text-center"
          renderField={(row) => (
            <Link to={props?.pathName == "/deliveryOrders" ? `/deliveryOrder/${row.id}` : `/order/${row.id}`}
              className='link-opacity-75'
            >
              {row.order_number}
            </Link>
          )}
        >
          Order#
        </ReduxColumn>
        <ReduxColumn
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="date"
          renderField={(row) => (
            <span>{row.date}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          className="ellipsis"
          sortBy="locationName"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Location
        </ReduxColumn>

        <ReduxColumn
          field="owner"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          className="ellipsis"
          sortBy="owner"
          renderField={(row) => (
            <>
              <AvatarCard
                firstName={row?.owner_firstName}
                lastName={row?.owner_lastName}
                url={row?.owner_media_url}
              />
            </>
          )}
        >
          Owner
        </ReduxColumn>

        <ReduxColumn
          field="shift"
          className="ellipsis text-center"
          sortBy="shift"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Shift
        </ReduxColumn>
        <ReduxColumn
          field="type"
          className="ellipsis text-center"
          sortBy="payment_type"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{row?.type &&row?.type?.name}</span>
          )}
        >
          Type
        </ReduxColumn>
        {enable_customer &&
          enable_customer == true && (
            <ReduxColumn
              field="customer_account"
              className="ellipsis text-center"
              sortBy="customer_account"
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              renderField={(row) => (
                <span>{row?.customer_account &&row?.customer_account?.first_name}</span>
              )}
            >
              Customer
            </ReduxColumn>
          )}
        <ReduxColumn
          field="cash_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="cash_amount"
          className="text-right"
          renderField={(row) => (
            <span>{Currency.Format(row?.cash_amount)}</span>
          )}
        >
          Cash Amount
        </ReduxColumn>
        <ReduxColumn
          field="upi_amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-right"
          sortBy="upi_amount"
          renderField={(row) => (
            <span>{Currency.Format(row?.upi_amount)}{!row?.isLastRecord && isVerifiedUpiPayment(row?.upi_payment_verified)}</span>
          )}
        >
          UPI Amount
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-right"
          sortBy="total_amount"
          renderField={(row) => (
            <span>{Currency.Format(row.total_amount)}</span>
          )}
        >
          Total Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy={"status"}
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          className="column-status"
          renderField={(row) => (
            <StatusText backgroundColor={row?.statusDetail?.color_code} status={row?.status} />
          )}
        >
          Status
        </ReduxColumn>
      </ReduxTable>
    </>
  );
}

export default OrderList
