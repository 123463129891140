import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DefaultContent from "../../components/content/defaultContent";
import DateSelector from "../../components/Date";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import toast from "../../components/Toast";
import StockEntryProduct from "./components/product";
import Action from "../../components/Action";
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Spinner from "../../components/Spinner";
import UserSelect from "../../components/UserSelect";
import ActivityList from "../../components/ActivityList";
import ProductSelectModal from "../../components/ProductSelectModal";
import SelectStore from "../../components/SelectStore";
import StatusComponent from "../../components/Status";
import Form from "../../components/Form";
import Select from "../../components/Select";
import Modal from "../../components/Modal";
import StatusSelect from "../../components/SelectStatus";
import HorizontalSpace from "../../components/HorizontalSpace";

// Action
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Services
import StockEntryService from "../../services/StockEntryService";
import StockEntryProductService from "../../services/StockProductEntryService";
import { hasPermission } from "../../services/UserRolePermissionService";
import ShiftService from "../../services/ShiftService";

//  Lib
import { isBadRequest, SUCCESS_RESPONSE } from "../../lib/Http";
import Url from "../../lib/Url";
import DateTime from "../../lib/DateTime";

// Actions
import { fetchList } from "../../actions/table";

// Helpers
import Permission from "../../helpers/Permission";
import ObjectName from "../../helpers/ObjectName";
import Urls from "../../helpers/Url";
import { HttpStatus } from "../../helpers/HttpStatus";

export const Tab = {
  SUMMARY: "Summary",
  PRODUCTS: "Products",
  HISTORY: "History",
};

const StockEntryDetails = (props) => {
  let { history } = props;
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tab.SUMMARY
  );
  const [detail, setDetail] = useState();
  const [locationList, setLocationList] = useState([]);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [PermissionList, setPermissionList] = useState();
  const [addStoreModal, setAddProductModal] = useState(false);
  const [MultiSelectProduct, setMultiSelectProduct] = useState([]);
  const [ownerValue, setownerValue] = useState();
  const [StockOwner, setStockOwner] = useState();
  const [PermissionLists, setPermissionLists] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const [productIds, setProductIds] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [date, setDate] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [dueDate, setDueDate] = useState();
  const [shift, setShift] = useState();
  const [shiftList, setShiftList] = useState("");
  const [isBulkModelOpen, setIsBulkModelOpen] = useState(false);
  const [bulkSelectIds, setBulkSelectIds] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [editable, setEditable] = useState(true);

  let stockEntryId = props.match.params.stockEntryId;
  let showHistory = hasPermission(Permission.STOCK_ENTRY_HISTORY_VIEW);
  let showDeleteOption = hasPermission(Permission.STOCK_ENTRY_DELETE);

  useEffect(() => {
    getDetails();
    getRolePermissions();
    getRolePermission();
    getUserDetail();
    getShift();
  }, []);

  useEffect(() => {
    getproductId();
  }, [MultiSelectProduct]);

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Stock Entry",
      link: Urls.STOCK_ENTRY_LIST,
    },
    {
      label: activeTab,
      link: "",
    },
  ];

  // Status Option
  const statusOptions = [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "Review",
      label: "Review",
    },
    {
      label: "Reopen",
      value: "Reopen",
    },
    {
      value: "Complete",
      label: "Complete",
    },
  ];

  const getUserDetail = async () => {
    try {
      let response = await apiClient.get(`${endpoints().userAPI}/`);
      const createdBy = response.data.name;
      setStockOwner(createdBy);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getRolePermission = async () => {
    const statusPermission = hasPermission(
      Permission.STOCK_ENTRY_MANAGE_OTHERS
    );
    setPermissionLists(statusPermission);
    setIsLoading(false);
  };

  //  Get Stock Entry Details
  const getDetails = async () => {
    const response = await apiClient.get(
      `${endpoints().stockEntry}/${stockEntryId}`
    );
    setDetail(() => response?.data);
  };

  const getproductId = async () => {
    let params = { pagination: false, stockEntryId: stockEntryId };
    const response = await StockEntryProductService.search(params);

    const data = response.data.data;
    let productsId = [];
    for (let i = 0; i < data.length; i++) {
      let { product_id } = data[i];
      productsId.push(product_id);
    }
    setProductIds(productsId);
  };

  // Handle form Submit
  const submit = async (values) => {
    try {
      const data = new FormData();
      // store id
      data.append("storeId", values?.location?.id);

      // date
      data.append("date", DateTime.toISOStringDate(values?.date));
      data.append("due_date", DateTime.toISOStringDate(values?.due_date));
      data.append("shift", values?.shift ? values?.shift?.value : "");

      data.append(
        "owner",
        values?.owner?.id ? values?.owner?.id : values?.owner?.value
      );

      apiClient
        .put(`${endpoints().stockEntry}/${stockEntryId}`, data)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            toast.success(res?.data?.message);
            setEditable(true);
          }
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddStockProductEntry = (values) => {
    try {
      const data = new FormData();
      data.append("productIds", MultiSelectProduct);
      data.append("stockEntryId", stockEntryId);
      data.append("storeId", detail?.store_id);

      if (stockEntryId) {
        //cretae parms,
        let params = { stockEntryId: stockEntryId, pagination: true };
        //add stock product entry data
        dispatch(
          StockEntryProductService.create(data, addProductToggle, {
            stockEntryId,
            pagination: true,
            sort: "id",
            sortDir: "DESC",
            brand: Url.GetParam("brand"),
            category: Url.GetParam("category"),
          })
        );
      }
      dispatch(
        fetchList("product", `${endpoints().productAPI}/search`, 1, 25, {
          excludeIds: productIds,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }

    if (e == "Bulk Update") {
      if (bulkSelectIds && bulkSelectIds.length > 0) {
        openModel();
      } else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const actionOptions = [];

  if (showDeleteOption) {
    actionOptions.push(
      {
        label: "Delete",
        value: "Delete",
      },
    )
  }

  if (PermissionList && editable) {
    actionOptions.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (activeTab == Tab.PRODUCTS) {
    actionOptions.unshift({
      label: "Bulk Update",
      value: "Bulk Update",
    })
  }

  const deleteStockEntry = async (id) => {
    try {
      StockEntryService.delete(id, props);
    } catch (err) {
      console.log(err);
    }
  };

  // Handle status change
  const handleStatusChange = (status) => {
    const data = new FormData();
    // status
    data.append("status", status ? status : "");

    dispatch(StockEntryService.update(stockEntryId, data, getDetails, {}));
    setStatus("");
  };

  const handleChangeStatus = (selectStatus) => {
    if (selectStatus) {
      setStatus(selectStatus);
    }
    StockEntryService.update(stockEntryId, status, {});
  };

  if (isLoading) {
    return <Spinner />;
  }

  //Get Role Permission
  const getRolePermissions = async () => {
    const statusPermission = hasPermission(
      Permission.STOCK_ENTRY_STATUS_UPDATE
    );
    setPermissionList(statusPermission);
    setIsLoading(false);
  };

  // Add stock modal toggling
  const addProductToggle = () => {
    setAddProductModal(false);
    setMultiSelectProduct("");
  };

  // MultiSelect Stock Values
  const multiselect = (values) => {
    setMultiSelectProduct(values);
  };

  const handleStoreChange = (e) => {
    let value = e ? e : "";
    setSelectedStore(value);
  };

  const handleDateChange = (e) => {
    let value = e;
    setDate(value);
  };

  const handleDueDateChange = (e) => {
    let value = e;
    setDueDate(value);
  };

  const handleUserChange = (e) => {
    let value = e;
    setSelectedUser(value);
  };

  const onShiftChange = (e) => {
    const shiftValue = e.values.shift.value;
    setShift(shiftValue);
  };

  const getShift = async () => {
    const list = await ShiftService.getShiftList();
    setShiftList(list);
  };

  const locationName =
    locationList && locationList.find((data) => data.id == detail?.store_id);

  const productModelOpenToggle = () => {
    history.push(
      `/stockEntry/${stockEntryId}?sort=${Url.GetParam(
        "sort"
      )}&sortDir=${Url.GetParam("sortDir")}&tab=${Url.GetParam("tab")}`
    );
    setAddProductModal(true);
  };

  let bulkUpdateBody = (
    <>
      <StatusSelect
        label="Status"
        objectName={ObjectName.STOCK_ENTRY_PRODUCT}
        required
      />
    </>
  );

  let bulkUpdateFooter = (
    <>
      <SaveButton label="Update" />
    </>
  );

  let closeModel = () => {
    setIsBulkModelOpen(!isBulkModelOpen);
  };

  let openModel = () => {
    setIsBulkModelOpen(!isBulkModelOpen);
  };

  let initialValues = {
    status: "",
  };

  let handleBulkUpdate = (values) => {
    if (bulkSelectIds && bulkSelectIds.length > 0) {
      let body = { selectedIds: bulkSelectIds, status: values?.status?.value };
      dispatch(
        StockEntryProductService.updateStatus(
          body,
          {
            ...Url.GetAllParams(),
          },
          (res) => {
            if (res.status == HttpStatus.OK) {
              setIsLoading(false);
              setBulkSelectIds([]);
              closeModel();
            }
          }
        )
      );
      setSelectedCheckBox(false);
      setSelectedCheckBox(true);
    }
  };

  return (
    <>
      <Modal
        modalTitle="Bulk Update"
        modalBody={bulkUpdateBody}
        modalFooter={bulkUpdateFooter}
        isOpen={isBulkModelOpen}
        toggle={openModel}
        toggleModalClose={closeModel}
        initialValues={initialValues}
        hideDefaultButtons
        onSubmit={(values) => {
          handleBulkUpdate(values);
        }}
      />
      {/* Stock Delete Modal */}
      <DeleteModal
        id={stockEntryId}
        label={detail?.stock_entry_number}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete"
        deleteFunction={() => deleteStockEntry(stockEntryId)}
      />
      {/* Stock Add Modal */}

      <ProductSelectModal
        modalOpen={addStoreModal}
        toggle={toggle}
        toggleModalClose={addProductToggle}
        BulkSelect={multiselect}
        history={history}
        handleSubmit={AddStockProductEntry}
        params={{ excludeIds: productIds }}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <PageTitle
          label={`Stock Entry# ${detail?.stock_entry_number} (${locationName?.label} )`}
        />
        <div className="d-flex">
          {activeTab == Tab.PRODUCTS ? (
            <AddButton
              label={"Add New"}
              onClick={productModelOpenToggle}
              className="mx-1"
            />
          ) : (
            ""
          )}
          <div className="me-2">
            {activeTab == Tab.SUMMARY ? (
              <StatusComponent
                objectName={ObjectName.STOCK_ENTRY}
                handleChange={handleStatusChange}
                buttonLabel={detail?.status}
                currentStatusId={detail?.statusId}
              />
            ) : (
              ""
            )}
          </div>
          <Action
            dropdownLinks={actionOptions}
            handleChange={handleChange}
          />
        </div>
      </div>

      <Nav tabs className="admin-tabs mb-1">
        {/* SUMMARY Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => {
              toggle(Tab.SUMMARY);
              _handleTabChange(Tab.SUMMARY);
            }}
          >
            Summary
          </NavLink>
        </NavItem>

        {/* PRODUCT tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.PRODUCTS,
            })}
            onClick={() => {
              toggle(Tab.PRODUCTS);
              _handleTabChange(Tab.PRODUCTS);
            }}
          >
            Products
          </NavLink>
        </NavItem>
        <NavItem>
          {showHistory && (
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
                _handleTabChange(Tab.HISTORY);
              }}
            >
              History
            </NavLink>
          )}
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY}>
            <DefaultContent>
              <Form
                enableReinitialize={true}
                initialValues={{
                  location: selectedStore
                    ? selectedStore
                    : Array.isArray(locationList) &&
                      locationList.find((data) => data.id == detail?.store_id),
                  status: statusOptions.find(
                    (data) => detail?.status == data.value
                  ),
                  date: date
                    ? DateTime.getDateTimeByUserProfileTimezone(date)
                    : DateTime.getDateTimeByUserProfileTimezone(detail?.date),
                  due_date: dueDate
                    ? DateTime.getDateTimeByUserProfileTimezone(dueDate)
                    : detail?.due_date
                    ? DateTime.getDateTimeByUserProfileTimezone(
                        detail?.due_date
                      )
                    : "",
                  shift:
                    shiftList && shiftList.find((data) => data.id == shift)
                      ? shiftList && shiftList.find((data) => data.id == shift)
                      : Array.isArray(shiftList) &&
                        shiftList.find((data) => data.id == detail?.shift_id),
                  owner: selectedUser
                    ? selectedUser
                    : Array.isArray(ownerValue) &&
                      ownerValue.find((data) => data.id == detail?.owner_id)
                    ? Array.isArray(ownerValue) &&
                      ownerValue.find((data) => data.id == detail?.owner_id)
                    : Array.isArray(ownerValue) &&
                      ownerValue.find((data) => data.label == StockOwner),
                }}
                onSubmit={(values) => {
                  submit(values);
                }}
              >
                <SelectStore
                  name="location"
                  label="Location"
                  handleStoreChange={handleStoreChange}
                  StoreList={setLocationList}
                  defaultValue={detail?.store_id}
                  isDisabled={editable}
                />
                <DateSelector
                  name="date"
                  fontBolded
                  label="Date"
                  disabled={editable}
                  onChange={handleDateChange}
                />
                <Select
                  name="shift"
                  label="Shift"
                  options={shiftList}
                  onInputChange={onShiftChange}
                  isDisabled={editable}
                />
                <UserSelect
                  label="Owner"
                  name="owner"
                  isDisabled={editable}
                  showUserDetailsPageLink={detail?.owner_id ? true : false}
                  userId={detail?.owner_id}
                  handleUserChange={handleUserChange}
                  userList={setownerValue}
                />
                <DateSelector
                  name="due_date"
                  fontBolded
                  label="Due Date"
                  disabled={editable}
                  onChange={handleDueDateChange}
                />
                {!editable && (
                    <div>
                      <HorizontalSpace bottom="2">
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => {
                            history.push(Urls.STOCK_ENTRY_LIST);
                          }}
                        />
                      </HorizontalSpace>
                    </div>
                  )}
              </Form>
            </DefaultContent>
          </TabPane>
        )}
        {activeTab == Tab.PRODUCTS && (
          <TabPane tabId={Tab.PRODUCTS}>
            {activeTab === Tab.PRODUCTS && (
              <StockEntryProduct
                storeId={detail?.store_id}
                id={stockEntryId}
                history={props.history}
                stock_entry_date={detail?.date}
                setBulkSelectIds={setBulkSelectIds}
                selectedCheckBox={selectedCheckBox}
              />
            )}
          </TabPane>
        )}
        {showHistory && activeTab === Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={stockEntryId}
              objectId={stockEntryId}
              object_name={ObjectName.STOCK_ENTRY}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default StockEntryDetails;
