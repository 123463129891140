
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Permission from "../../helpers/Permission";
import Url from "../../helpers/Url";
import { hasPermission } from "../../services/UserRolePermissionService";

const ReportList = () => {
  const [toggleState, setToggleState] = useState({
    orderSection: true,
    purchaseSection: true,
    purchaseProductSection: true,
    stockSection: true,
    salesSettlementSection: true,
    storeProductSection: true,
    transferProductSection: true,
    replenishmentSection: true,
    stockEntrySection: true,
  });

  const handleToggle = (section) => {
    setToggleState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const showOrderGraphReport = hasPermission(Permission.ORDER_PRODUCT_GRAPH_REPORT_VIEW);
  const showOrderReport = hasPermission(Permission.ORDER_REPORT_VIEW);
  const showProductReport = hasPermission(Permission.ORDER_PRODUCT_REPORT_VIEW);
  const showPurchaseReport = hasPermission(Permission.PURCHASE_REPORT_VENDOR_WISE_VIEW);
  const purchaseRecommendedationReport = hasPermission(Permission.PURCHASE_RECOMMENDATION_REPORT_VIEW);
  const showPurchaseProductReport = hasPermission(Permission.PURCHASE_PRODUCT_REPORT_VIEW);
  const showSalesReport = hasPermission(Permission.SALES_SETTLEMENT_REPORT_VIEW);
  const showStockReport = hasPermission(Permission.STOCK_REPORT_VIEW);
  const showOrdrSalesDiscrepancyReport = hasPermission(Permission.SALES_SETTLEMENT_DISCREPANCY_REPORT_VIEW);
  const showNoOrderReport = hasPermission(Permission.STORE_PRODUCT_NO_ORDER_REPORT_VIEW);
  const showNoStockReport = hasPermission(Permission.STORE_PRODUCT_NO_STOCK_REPORT_VIEW);
  const showStoreProductReport = hasPermission(Permission.STORE_PRODUCT_STORE_PRODUCT_REPORT_VIEW);
  const showStoreProductNegativeStockReport = hasPermission(Permission.STORE_PRODUCT_NEGATIVE_STOCK_REPORT_VIEW);
  const showTransferProduct = hasPermission(Permission.TRANSFER_PRODUCT_REPORT_VIEW);
  const showOrderProductCancelledReport = hasPermission(Permission.ORDER_PRODUCT_CANCELLED_REPORT_VIEW);
  const showReplenishAllocationReport = hasPermission(Permission.REPLENISHMENT_ALLOCATION_REPORT_VIEW);

  const reportStoreProduct = [];
  if (showNoOrderReport) {
    reportStoreProduct.push({ title: "Store Product - No Order Report", link: Url.STORE_PRODUCT_NO_ORDER_REPORT });
  }
  if (showNoStockReport) {
    reportStoreProduct.push({ title: "Store Product - No Stock Report", link: Url.STORE_PRODUCT_NO_STOCK_REPORT });
  }
  if (showStoreProductReport) {
    reportStoreProduct.push({ title: "Store Product Report", link: Url.STORE_PRODUCT_REPORT });
  }
  if (showStoreProductNegativeStockReport) {
    reportStoreProduct.push({ title: "Store Product - Negative Stock Report", link: Url.STORE_PRODUCT_NEGATIVE_STOCK_REPORT });
  }

  const reportSalesProduct = [];
  if (showSalesReport) {
    reportSalesProduct.push({ title: "Sales Settlement Summary Report", link: Url.SALES_REPORT });
  }
  if (showOrdrSalesDiscrepancyReport) {
    reportSalesProduct.push({ title: "Sales Settlement Discrepancy Report", link: Url.SALES_SETTLEMENT_DISCREPANCY_REPORT });
  }
  reportSalesProduct.push({ title: "Sales Settlement Missing Report", link: Url.SALES_SETTLEMENT_MISSING_REPORT });

  const reportOrderProduct = [];
  if (showOrderGraphReport) {
    reportOrderProduct.push({ title: "Order Product Graph Report", link: Url.ORDER_GRAPH_REPORT });
  }
  if (showProductReport) {
    reportOrderProduct.push({ title: "Order Product Report", link: Url.ORDER_PRODUCT_REPORT });
  }
  if (showOrderReport) {
    reportOrderProduct.push({ title: "Order Summary Report", link: "/orderSummaryReport" });
  }
  reportOrderProduct.push({ title: "Order Owner Report", link: Url.ORDER_REPORT_USER_WISE });
  if (showOrderProductCancelledReport) {
    reportOrderProduct.push({ title: "Order Product Cancelled Report", link: Url.ORDER_PRODUCT_CANCELLED_REPORT });
  }
  reportOrderProduct.push({ title: "Order Product Cancelled Report User Wise", link: Url.ORDER_PRODUCT_CANCELLED_REPORT_USER_WISE });

  reportOrderProduct.push({ title: "Reward Report", link: Url.REWARD_REPORT });
  reportOrderProduct.push({ title: "Order Report", link: Url.ORDER_REPORT });
  reportOrderProduct.push({ title: "Order Upi Payment Report", link: Url.ORDER_UPI_PAYMENT_REPORT });
  reportOrderProduct.push({ title: "Order Product(Draft) Report - User Wise", link: Url.ORDER_PRODUCT_DRAFT_REPORT_USER_WISE });


  const Replenishment = [];
  if (showReplenishAllocationReport) {
    Replenishment.push({ title: "Replenish Allocation Report", link: Url.REPLENISH_ALLOCATION_REPORT });
  }

  const reportTransferProduct = [];
  if (showTransferProduct) {
    reportTransferProduct.push({ title: "Transfer Product Report", link: Url.TRANSFER_PRODUCT_REPORT });
  }
  reportTransferProduct.push({ title: "Transfer Product Report (User Wise)", link: Url.TRANSFER_PRODUCT_REPORT_USER_WISE });

  const reportStock = [];
  if (showStockReport) {
    reportStock.push({ title: "Stock Report", link: Url.STOCK_REPORT });
  }

  const reportPurchase = [];
  if (showPurchaseReport) {
    reportPurchase.push({ title: "Purchase Summary Report", link: Url.PURCHASE_SUMMARY_REPORT });
  }
  if (purchaseRecommendedationReport) {
    reportPurchase.push({ title: "Purchase: Recommendation Report", link: Url.PURCHASE_RECOMMENDATION_REPORT });
  }
  reportPurchase.push({ title: "Purchase Report", link: Url.PURCHASE_REPORT });

  const reportPurchaseProduct = [];
  if (showPurchaseProductReport) {
    reportPurchaseProduct.push({ title: "Purchase Products Report", link: Url.PURCHASE_PRODUCT_REPORT });
  }

  const reportStockEntry = [];
  reportStockEntry.push({ title: "Stock Entry Report", link: Url.STOCK_ENTRY_REPORT });

  return (
    <>
      <div className="mb-4 pb-4 mb-sm-0 pb-sm-0">
        <PageTitle label="Reports" />
        <div className="accordion mt-3" id="accordionReports">
          {showOrderGraphReport || showOrderReport || showProductReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOrder">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.orderSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('orderSection')}
                  aria-expanded={toggleState.orderSection}
                >
                  <h5 className="fw-normal">Order</h5>
                </button>
              </h2>
              <div
                id="collapseOrder"
                className={`accordion-collapse collapse ${toggleState.orderSection ? 'show' : ''}`}
                aria-labelledby="headingOrder"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportOrderProduct.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showPurchaseReport || purchaseRecommendedationReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingPurchase">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.purchaseSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('purchaseSection')}
                  aria-expanded={toggleState.purchaseSection}
                >
                  <h5 className="fw-normal">Purchase</h5>
                </button>
              </h2>
              <div
                id="collapsePurchase"
                className={`accordion-collapse collapse ${toggleState.purchaseSection ? 'show' : ''}`}
                aria-labelledby="headingPurchase"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportPurchase.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showPurchaseProductReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingPurchaseProduct">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.purchaseProductSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('purchaseProductSection')}
                  aria-expanded={toggleState.purchaseProductSection}
                >
                  <h5 className="fw-normal">Purchase Product</h5>
                </button>
              </h2>
              <div
                id="collapsePurchaseProduct"
                className={`accordion-collapse collapse ${toggleState.purchaseProductSection ? 'show' : ''}`}
                aria-labelledby="headingPurchaseProduct"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportPurchaseProduct.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showStockReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingStock">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.stockSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('stockSection')}
                  aria-expanded={toggleState.stockSection}
                >
                  <h5 className="fw-normal">Stock</h5>
                </button>
              </h2>
              <div
                id="collapseStock"
                className={`accordion-collapse collapse ${toggleState.stockSection ? 'show' : ''}`}
                aria-labelledby="headingStock"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportStock.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showSalesReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSales">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.salesSettlementSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('salesSettlementSection')}
                  aria-expanded={toggleState.salesSettlementSection}
                >
                  <h5 className="fw-normal">Sales Settlement</h5>
                </button>
              </h2>
              <div
                id="collapseSales"
                className={`accordion-collapse collapse ${toggleState.salesSettlementSection ? 'show' : ''}`}
                aria-labelledby="headingSales"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportSalesProduct.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showStoreProductReport || showStoreProductNegativeStockReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingStoreProduct">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.storeProductSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('storeProductSection')}
                  aria-expanded={toggleState.storeProductSection}
                >
                  <h5 className="fw-normal">Store Product</h5>
                </button>
              </h2>
              <div
                id="collapseStoreProduct"
                className={`accordion-collapse collapse ${toggleState.storeProductSection ? 'show' : ''}`}
                aria-labelledby="headingStoreProduct"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportStoreProduct.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showTransferProduct ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTransferProduct">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.transferProductSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('transferProductSection')}
                  aria-expanded={toggleState.transferProductSection}
                >
                  <h5 className="fw-normal">Transfer Product</h5>
                </button>
              </h2>
              <div
                id="collapseTransferProduct"
                className={`accordion-collapse collapse ${toggleState.transferProductSection ? 'show' : ''}`}
                aria-labelledby="headingTransferProduct"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportTransferProduct.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {showReplenishAllocationReport ? (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingReplenishment">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.replenishmentSection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('replenishmentSection')}
                  aria-expanded={toggleState.replenishmentSection}
                >
                  <h5 className="fw-normal">Replenishment</h5>
                </button>
              </h2>
              <div
                id="collapseReplenishment"
                className={`accordion-collapse collapse ${toggleState.replenishmentSection ? 'show' : ''}`}
                aria-labelledby="headingReplenishment"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {Replenishment.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {reportStockEntry.length > 0 && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingStockEntry">
                <button
                  className={`accordion-button bg-body-tertiary ${toggleState.stockEntrySection ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle('stockEntrySection')}
                  aria-expanded={toggleState.stockEntrySection}
                >
                  <h5 className="fw-normal">Stock Entry</h5>
                </button>
              </h2>
              <div
                id="collapseStockEntry"
                className={`accordion-collapse collapse ${toggleState.stockEntrySection ? 'show' : ''}`}
                aria-labelledby="headingStockEntry"
                data-bs-parent="#accordionReports"
              >
                <div className="accordion-body">
                  {reportStockEntry.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 link-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportList;
