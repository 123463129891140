import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "../style.scss";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import Spinner from "../../../components/Spinner";
import SaveButton from "../../../components/SaveButton";
import SelectStore from "../../../components/SelectStore";
import ShiftSelect from "../../../components/ShiftSelect";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import NoRecordsFound from "../../../components/NoRecordsFound";

// Lib
import String from "../../../lib/String";

// Services
import PreferredLocationService from "../../../services/PreferredLocationService";

const PreferredLocation = (props) => {
  let { _toggle, isOpen, row, setRow, openToggle, user_id } = props;
  const [rows, setRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let dispatch = useDispatch()

  useEffect(() => {
    getDetails();
  }, [props.row]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
      setIsLoading(true);
   let data = await PreferredLocationService.search({user: user_id });
   setRows(data)
      setIsLoading(false);
    };
  
    const onDragEnd = (result) => {
      if (!result.destination) return;
      const draggedRow = rows[result.source.index];
      const newRows = [...rows];
      newRows.splice(result.source.index, 1);
      newRows.splice(result.destination.index, 0, draggedRow);
  
      newRows.forEach((item, index) => {
        item.index = index;
      });
      setRows(newRows);
      PreferredLocationService.updateOrder(newRows);
    };
  
  
    const modelBody = (
        <>
     <SelectStore name="location" label="Location" required/>
     <ShiftSelect name="shift"  label="Shift" required/>
     </>
    );
  
    const addFooter = (
      <div className="d-flex align-items-center">
        <SaveButton type="submit" label={row?.id ? "Save" : "Add"} />{" "}
      </div>
    );
  
    const  handleSubmit = async (values) => {
      const data = new FormData();
      data.append("user", user_id)
      data.append("shift", String.isNotNull(values?.shift) ? values?.shift?.value : "")
      data.append("location", String.isNotNull(values?.location) ? values?.location?.value : "")

      if(row){
        dispatch(await PreferredLocationService.update(row?.id,data,(res)=>{
          getDetails()
          _toggle()
         }))
      }else{
        dispatch(await PreferredLocationService.create(data,(res)=>{
         getDetails()
         _toggle()
        }))
      }
   
    };
  
    if (isLoading) {
      return <Spinner />;
    }
  
    const deletePreferredLocation = async (id) => {
      dispatch(await PreferredLocationService.delete(id,(res)=>{
        getDetails()
        setDeleteModal(false);
        setRow(null)
       }))
    };
  
    return (
      <>
        <DeleteModal
          label={row?.id}
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
          }}
          title="Delete Preferred Location"
          deleteFunction={() => deletePreferredLocation(row?.id)}
        />
  
        <Drawer
          DrawerBody={modelBody}
          DrawerFooter={addFooter}
          modelTitle={row?.id ? "Edit Preferred Location" : "Add Preferred Location"}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={{
            shift: row && row?.shift_id ? {
            label: row?.shiftName,
            value: row?.shift_id
            } :"",
            location: row && row?.location_id ? {
              label: row?.locationName,
              value: row?.location_id
            }: ""
          }}
          handleOpenModal={openToggle}
          handleCloseModal={_toggle}
          handleDrawerClose={_toggle}
          isModalOpen={isOpen}
        />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="table-responsive">
          <table className="w-100 table table-hover">
            <thead>
              <tr className="table-dark">
                <th className="text-center mx-3 p-3">Location</th>
                <th className="text-center mx-3 p-3">Shift</th>
                <th className="text-center mx-3 p-3">Action</th>
              </tr>
            </thead>
            {rows && rows.length > 0 ? (
              <Droppable droppableId="table-rows">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef} className="bg-white">
                    {rows &&
                      rows.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`table-row-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >

                              <td className="m-2 p-4  text-center fw-normal">
                                {item?.locationName}
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                {item?.shiftName}
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <MoreDropdown>
                                  <DropdownItem
                                    onClick={() => {
                                      setRow(item);
                                      openToggle();
                                    }}
                                  >
                                    Quick View
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setRow(item);
                                      setDeleteModal(true)
                                    }}
                                    className="text-danger"
                                  >
                                    Delete
                                  </DropdownItem>
                                </MoreDropdown>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  <NoRecordsFound
                    showMessage={true}
                    hideCard={true}
                    message="No Status Found"
                    middleHeight={"70vh"}
                  />
                </td>
              </tr>
            )}
          </table>
        </div>
      </DragDropContext>
    </>
  );
}

export default PreferredLocation
