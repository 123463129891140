import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DropdownItem } from "reactstrap";
import "../../../../scss/_custom.scss";
import "../../../../components/reduxTable/styles.scss";

// Components
import DeleteModal from "../../../../components/DeleteModal";
import Drawer from "../../../../components/Drawer";
import SaveButton from "../../../../components/SaveButton";
import Text from "../../../../components/Text";
import NumberComponent from "../../../../components/Number";
import MoreDropdown from "../../../../components/authentication/moreDropdown";
import Select from "../../../../components/Select";
import NoRecordsFound from "../../../../components/NoRecordsFound";

// Lib
import { isLoggedIn } from "../../../../lib/Helper";

// Services
import AppMenuService from "../../../../services/AppMenuService";

// Helpers
import { MenuLocationOptions } from "../../../../helpers/AppMenu";

const MenuTab = (props) => {
    const { __toggle, isModel, app_id } = props;

    const [isOpen, setIsOpen] = useState(isModel || false);
    const [name, setName] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [row, setRow] = useState(null);
    const [id, setId] = useState("");
    const [status, setStatus] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [appId, setAppId] = useState("")
    const [redirectUrlValue, setRedirectUrlValue] = useState();
    const [nameChangeValue, setNameChangeValue] = useState(null);
    const [sortOrderChange, setSortOrderChange] = useState();
    const [menuLocationChange, setMenuLocationChange] = useState();
    const [reorderedItems, setReorderedItems] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        isLoggedIn();
        getMenuLists();
    }, []);

    useEffect(() => {
        if (row) {
            setNameChangeValue(row?.name || "");
            setRedirectUrlValue(row?.redirect_url || "");
            setSortOrderChange(row?.sort_order || "");
            setMenuLocationChange(row?.menu_location || "");
        }
    }, [row]);

    const getMenuLists = async () => {
        let appId = app_id;

        AppMenuService.search({ appId }, (data) => {
            if (data && data?.data) {
                setReorderedItems(data?.data);
            }
        });
    };

    const _toggle = (row, id) => {
        __toggle && __toggle();
        setId(id || 0);
        setIsOpen(!isOpen);
        setIsSubmit(false);
        setRow(row);
    };

    const toggle = () => {
        __toggle && __toggle();
        setIsOpen(!isOpen);
        setName("");
        setId("");
        setAppId("");
        setRow(null);
        setIsSubmit(false);
        setNameChangeValue("");
        setRedirectUrlValue("");
        setSortOrderChange("");
        setMenuLocationChange("");
    };

    const closeDeleteToggle = () => {
        setOpenDeleteModal(false);
        setRow('');
        setNameChangeValue("");
        setRedirectUrlValue("");
        setSortOrderChange("");
        setMenuLocationChange("");
    }

    const handleRedirectUrlChange = (e) => {
        let value = e?.target?.value;
        setRedirectUrlValue(value);
    };

    const handleSortOrderChange = (values) => {
        let data = values && values?.values && values?.values?.sort_order;
        setSortOrderChange(data);
    };

    const handleMenuLocationChange = (values) => {
        let data = values && values?.values && values?.values?.menu_location && values?.values?.menu_location?.value;
        setMenuLocationChange(data);
    };

    const handleNameChange = (e) => {
        let value = e?.target?.value;
        setNameChangeValue(value);
    };

    const handleDelete = async (id) => {
        dispatch(
            await AppMenuService.delete(id, (res) => {
                getMenuLists();
            })
        );
        closeDeleteToggle();
        setOpenDeleteModal(false);
    };

    const handleSubmit = async (values) => {
        try {
            setIsSubmit(true);
            const data = new FormData();
            data.append("name", values.name.trim());
            data.append("app_id", values.app_id || app_id || "");
            data.append("sort_order", values.sort_order || "");
            data.append("menu_location", values.menu_location && values.menu_location.value ? values.menu_location.value : "");
            data.append("redirect_url", values.redirect_url || "");

            if (!id) {
                dispatch(
                    await AppMenuService.create(data, (res) => {
                        getMenuLists();
                        toggle();
                    })
                );
            } else {
                data.append("id", id);
                data.append("status", status);
                dispatch(
                    await AppMenuService.update(data, (res) => {
                        getMenuLists();
                        toggle();
                    })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsSubmit(false);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(reorderedItems);

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        items.forEach((item, index) => {
            item.sort_order = index + 1;
        });

        setReorderedItems(items);

        try {
            await AppMenuService.updateOrder(items);
        } catch (error) {
            console.error(error);
        }
    };

    const addMenuForm = (
        <>
            <Text
                name="name"
                label="Name"
                required={true}
                onChange={handleNameChange}
            />
            <Text
                name="redirect_url"
                label="Redirect Url"
                onChange={handleRedirectUrlChange}
            />
            <NumberComponent
                label="Sort Order"
                name="sort_order"
                onInputChange={handleSortOrderChange}
            />
            <Select
                fullWidth={true}
                label="Location"
                name="menu_location"
                isClearable
                options={MenuLocationOptions}
                onInputChange={handleMenuLocationChange}
                required
            />
        </>
    );

    const modalOpen = isModel !== undefined ? isModel : isOpen;

    const itemsToRender = Array.isArray(reorderedItems) ? reorderedItems : reorderedItems?.data;

    const menuFooter = (
        <SaveButton
            type="submit"
            loading={isSubmit}
            label={id ? "Save" : "Add"}
        />
    );

    let initialValues = {
        name: nameChangeValue || "",
        redirect_url: redirectUrlValue || "",
        sort_order: sortOrderChange || "",
        menu_location: MenuLocationOptions.find(option => option.value === menuLocationChange) || null,
    };

    return (
        <div>
            <>
                <Drawer
                    DrawerBody={addMenuForm}
                    DrawerFooter={menuFooter}
                    modelTitle={id ? "Edit Menu" : "Add Menu"}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    initialValues={initialValues}
                    handleOpenModal={toggle}
                    handleCloseModal={toggle}
                    handleDrawerClose={toggle}
                    isModalOpen={modalOpen}
                />
                <DeleteModal
                    isOpen={openDeleteModal}
                    toggle={closeDeleteToggle}
                    title="Delete Menu"
                    deleteFunction={() => {
                        handleDelete(row?.id);
                    }}
                    label={row?.name}
                    id={row?.id}
                />
            </>
            <DragDropContext onDragEnd={onDragEnd}>

                <div className="table-responsive">
                    <table className="w-100 table table-hover">
                        <thead>
                            <tr className="table-dark">
                                <th className="text-center p-3">Name</th>
                                <th className="text-center p-3">Sort Order</th>
                                <th className="text-center p-3">Location</th>
                                <th className="text-center p-3">Redirect URL</th>
                                <th className="text-center p-3">Action</th>
                            </tr>
                        </thead>
                        {itemsToRender && itemsToRender.length > 0 ? (
                            <Droppable droppableId="menu-table" type="row">
                                {(provided) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                        {itemsToRender?.map((row, index) => (
                                            <Draggable key={row.id} draggableId={`item-${row.id}`} index={index}>
                                                {(provided) => (
                                                    <tr
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}

                                                    >
                                                        <td className="text-left">{row.name}</td>
                                                        <td className="text-center">{row.sort_order !== null && row.sort_order !== undefined ? row.sort_order : ""}</td>
                                                        <td className="text-center">{row.menu_location !== null && row.menu_location !== undefined ? row.menu_location : ""}</td>
                                                        <td className="text-center">{row.redirect_url}</td>
                                                        <td className="text-center">
                                                            <MoreDropdown>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        _toggle(row, row.id);
                                                                    }}
                                                                >
                                                                    Quick View
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        setRow(row);
                                                                        setOpenDeleteModal(true);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </DropdownItem>
                                                            </MoreDropdown>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="8">
                                    <NoRecordsFound
                                        showMessage={true}
                                        hideCard={true}
                                        message="No Status Found"
                                        middleHeight={"70vh"}
                                    />
                                </td>
                            </tr>
                        )}
                    </table>
                </div>

            </DragDropContext>
        </div>
    );
};

export default MenuTab;